import { Injectable } from '@angular/core';
import { LoaderComponent } from '../common/loader/loader.component';
import { DomService } from 'wacom';
import { CoreService } from 'wacom';

@Injectable({
	providedIn: 'root'
})
export class LoaderService {
	private loader: any;
	
	constructor(private dom: DomService, private core: CoreService) { }
	show(opts: any, element = this.core.document.body) {
		if (this.loader) this.destroy();
		this.loader = this.dom.appendComponent(LoaderComponent, opts, element);
		if (opts.modal && element != this.core.document.body) {
			(element as HTMLElement).style.overflow = 'hidden';
			this.loader.modalElement = element;
		}
		if (!opts.container && element != this.core.document.body) {
			(element as HTMLElement).style.position = 'relative';
			this.loader.element = element;
		}
		if (opts.container && element != this.core.document.body) {
			(element as HTMLElement).style.position = 'relative';
			(element as HTMLElement).style.overflow = 'hidden';
			(element as HTMLElement).style.display = 'block';
			this.loader.container = element;
			const containerStyles = window.getComputedStyle(element);
			(this.loader.nativeElement as HTMLElement).style.cssText = `position: sticky; display: block; width: 100%; height: 100%; top: 0px; bottom: 0px; z-index: 90;`;
			(this.loader.nativeElement.querySelector('.loader-background') as HTMLElement).style.cssText = `width: calc(100% + ${containerStyles.getPropertyValue('padding-left')} + ${containerStyles.getPropertyValue('padding-right')} + 2px); height: calc(100% + ${containerStyles.getPropertyValue('padding-top')} + ${containerStyles.getPropertyValue('padding-bottom')} + 2px); margin: -${containerStyles.getPropertyValue('padding-top')} -${containerStyles.getPropertyValue('padding-left')}; left: -1px; top: -1px;`;
		}
		if (opts.timeout && opts.timeout != 0) {
			setTimeout(() => {
				this.remove();
			}, opts.timeout);
		}
	}

	remove(cb: any = () => {}) {
		if (this.loader?.nativeElement) {
			this.loader.nativeElement.querySelector('.loader-background').classList.add('_close');
			setTimeout(() => {
				if (this.loader?.modalElement) {
					(this.loader.modalElement as HTMLElement).style.overflow = '';
				}
				if (this.loader?.element) {
					(this.loader.element as HTMLElement).style.position = '';
				}
				if (this.loader?.container) {
					(this.loader.container as HTMLElement).style.overflow = '';
					(this.loader.container as HTMLElement).style.position = '';
					(this.loader.container as HTMLElement).style.display = '';
				}
				if (this.loader?.nativeElement) this.loader.nativeElement.remove();
				this.loader = null;
				if (typeof cb == 'function') cb(true);
			}, 200);
		}
	}

	destroy() {
		this.loader = null
		const loaders = document.querySelectorAll('loader');
		for (let i = 0; i < loaders.length; i++) {
			loaders[i].remove();
		}
	}

	get isLoaderShowing() {
		return document.querySelector('loader') ? true : false;
	}
}
