import { Component, ElementRef, OnInit, AfterViewInit, HostListener, OnDestroy } from '@angular/core';
import { ModalService, HttpService, AlertService as Alert, MongoService } from 'wacom';
import { UserService, AlertService, AppointmentService, ServiceService, QuestionService, NoteService, LoaderService, HashService } from '@services'
import { ActivatedRoute, Router } from '@angular/router';
import { flyAnimation } from 'src/app/common/animation/animation.component';
import { ClientbookingsComponent } from 'src/app/modals/clientbookings/clientbookings.component';
@Component({
	selector: 'app-clients-profile',
	templateUrl: './clients-profile.component.html',
	styleUrls: ['./clients-profile.component.scss'],
	animations: [flyAnimation]
})
export class ClientsProfileComponent implements OnInit, AfterViewInit, OnDestroy {
	public services:any;
	public selected_staff:any;
	public date: any;
	public statuses: any;
	public direction: any = 'desc';
	public filterShow: any;
	public settings: any;
	public tab: any = 1;
	public pastOpen: any;
	public comingOpen: any;
	public profileBarPopup: any;
	public link: any = 0;
	public selectStatuses = [
		'Canceled',
		'New',
		'Completed',
		'Confirmed' 
	];
	public selectDirection = [
		{_id: 'desc', name: 'Latest'},
		{_id: 'asc', name: 'Earliest'}
	];

	public model: any = {
		isRange: false,
		singleDate: {
			jsDate: new Date(),
			formatted: (new Date().getMonth()+1)+'/'+new Date().getDate()+'/'+new Date().getFullYear()
		}
	};
	constructor(public modal: ModalService,
		public qs: QuestionService,
		private http: HttpService,
		public ss: ServiceService,
		public als: AlertService,
		public aps: AppointmentService,
		public us: UserService,
		public nt: NoteService,
		public alert: Alert,
		private router: Router,
		private route: ActivatedRoute,
		private mongo: MongoService,
		private loader: LoaderService,
		private eref: ElementRef,
		public hash: HashService) {
		this.http.get('/api/google/status', settings=>{
			this.settings = settings;
		});
		this.us.headerTitle = 'Profile';
		this.us.headerSubtitle = 'Clients';
	}
	ngOnInit() {
		this.us.backRoute = () => {
			this.router.navigate(['/clients']);
		};
		this.loader.show({container: true}, this.eref.nativeElement.querySelector('.containerBox'));

		this.mongo.on('user appointment note service question alert', () => {
			var waitForUser = setInterval(() => {
				if(this.us.user) {
					clearInterval(waitForUser);
					this.loader.remove();
					// this.aps.getAllForClient(this.us.user._id, () => {
					// 	this.loader.remove();
					// });
					if(this.hash.get('files')) this.link = 1;
					if(this.hash.get('notes')) this.link = 2;
					if(this.hash.get('questionnaire')) this.link = 3;
				}
			}, 1);
		});
	}
	ngAfterViewInit() {
		
	}
	ngOnDestroy() {
		this.us.backRoute = null;
	}

	disable(){
		this.http.get('/api/google/disable');
		this.settings.connected = false;
	}
	edit_client(profile) {
		this.router.navigate([],{ queryParams: { modal: 'open' }, fragment: this.route.snapshot.fragment });
		this.modal.show({
			component: 'client',
			profile: JSON.parse(JSON.stringify(profile))
		});
	}
	edit_alert(profile: any = {}) {
		this.router.navigate([],{ queryParams: { modal: 'open' }, fragment: this.route.snapshot.fragment });
		if (!profile && !this.us.user) {
			return setTimeout(() => {
				this.edit_alert(profile)
			}, 500);
		}
		if (!profile.client) profile.client = this.us.user._id;
		this.modal.show({
			component: 'modal',
			profile: profile ? JSON.parse(JSON.stringify(profile)) : {},
			title: 'Alert',
			components: [{
				type: 'EDITOR',
				input: 'description',
				label: 'Alert message*',
				placeholder: 'Alert message',
				reqText: 'Message is required',
				required: true,
				size: 'small'
			}],
			alertOpts: {
				title: 'Delete alert',
				body: `Are you sure you want to delete alert?`
			},
			onDelete: (profile: any) => {
				this.als.delete(profile, () => {
					this.modal.destroy();
				});
			},
			onSave: (profile: any) => {
				this.als.create(profile, () => {
					this.modal.destroy();
				});
			}
		});	
	}
	edit_appointment(profile) {
		this.router.navigate([],{ queryParams: { modal: 'open' }, fragment: this.route.snapshot.fragment });
		this.aps.pre_set(profile, this.model);
		this.modal.show({
			component: 'appointment',
			profile: JSON.parse(JSON.stringify(profile))
		});
	}
	deleteClient() {
		this.router.navigate([],{ queryParams: { modal: 'open' }, fragment: this.route.snapshot.fragment });
		this.modal.show({
			component: 'deleteAlert',
			title: 'Delete client',
			body: `Are you sure you want to delete client ${this.us.user.firstName} ${this.us.user.lastName}`,
			on_add: ()=> {
				this.loader.show({container: true, transparent: true}, this.eref.nativeElement.querySelector('.containerBox'));
				this.us.delete(this.us.user, () => {
					this.loader.remove();
					this.router.navigate(['/clients']);
				});
			}
		});
	}
	ngDoCheck(){
		if( this.route.snapshot.queryParams['modal']){
		}
		else{
		  this.modal.destroy()
		}
	}
	allModal(){
		this.router.navigate([],{ queryParams: { modal: 'open' }, fragment: this.route.snapshot.fragment });
		this.modal.show({component: ClientbookingsComponent, type: 'allbookings',  class: 'big'});  
	}
	canceledModal(){
		this.router.navigate([],{ queryParams: { modal: 'open' }, fragment: this.route.snapshot.fragment });
		this.modal.show({component: ClientbookingsComponent, type: 'canceledbookings',  class: 'big'}); 
	}
	completedModal(){
		this.router.navigate([],{ queryParams: { modal: 'open' }, fragment: this.route.snapshot.fragment }); 
		this.modal.show({component: ClientbookingsComponent, type: 'completedbookings',  class: 'big'}); 
	}
	@HostListener('window:popstate', ['$event'])
    onPopState(event: PopStateEvent) {
		if(this.hash.get('files')) this.link = 1;
		else if(this.hash.get('notes')) this.link = 2;
		else if(this.hash.get('questionnaire')) this.link = 3;
		else this.link = 0;
    }
}