import { Component, OnInit } from '@angular/core';
import { AlertService, LoaderService, UserService } from '@services';
import { AlertService as Alert, ModalService, MongoService} from 'wacom';
import { toHTML } from 'ngx-editor';
@Component({
	selector: 'alertprofile',
	templateUrl: './alertprofile.component.html',
	styleUrls: ['./alertprofile.component.scss']
})
export class AlertprofileComponent implements OnInit{
	public profile: any = {};
	public noteEditor: any;
	public close;
	public submitted: Boolean
	public clients: any = [];
	public characterLimit: string = null;
	constructor(public us: UserService, public ass: AlertService,
		public alert: Alert,
		public modal: ModalService,
		private mongo: MongoService,
		private loader: LoaderService) {}
	
	ngOnInit(): void {
		if (this.profile.description) this.noteEditor = JSON.parse(this.profile.description);	
		this.mongo.on('user', () => {
			this.clients = this.clients.concat(this.us.clients);
			if(this.profile.client && this.us._users[this.profile.client]?.deleted && !this.clients.find((c) => {return c._id == this.profile.client})) this.clients.unshift(this.us._users[this.profile.client]);
		});
	}
	save() {
		this.submitted = true;
		if (this.noteEditor) this.profile.description = JSON.stringify(this.noteEditor);
		if(!this.profile.description||!this.profile.client || toHTML(this.noteEditor) === '<p></p>') {
			this.profile.description = '';
			return this.alert.error({
				text: "Please fill required fields",
			});
		}
		if(this.characterLimit) return this.alert.error({
			text: "Exceeded maximum character limit",
		});

		this.loader.show({ 
			modal: true,
			transparent: true,
			preventClick: true
		}, document.querySelector('.modal-box'));

		this.ass.create(this.profile, () => {
			this.loader.remove();
			this.close();
		});
	}
	delete() {
		this.modal.show({
			component: 'deleteAlert',
			title: 'Delete alert',
			body: `Are you sure you want to delete alert?`,
			on_add: ()=> {
				this.loader.show({ 
					modal: true,
					transparent: true,
					preventClick: true
				}, document.querySelector('.modal-box'));
				
				this.ass.delete(this.profile, () => {
					this.loader.remove();
					this.close();
				});
			}
		});
	}
}
