<!-- ===  Clients List Table === -->
<div class="table-container tablet-col3 mobile-col3">
	<table class="table" [class.navShown]="!us.sidebarToggle" cellpadding="0" cellspacing="0">
		<thead>
			<tr>
				<th scope="col" class="sort" (click)="sort('name')">
					<div class="ai-c">
						<span>Client name</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'name'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'name'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
				<th scope="col" class="sort" (click)="sort('data.address')">
					<div class="ai-c">
						<span>Address</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'data.address'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'data.address'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
				<th scope="col" class="sort" (click)="sort('email')">
					<div class="ai-c">
						<span>Email</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'email'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'email'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
				<th scope="col" class="sort" (click)="sort('data.phone')">
					<div class="ai-c">
						<span>Phone</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'data.phone'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'data.phone'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
				<th scope="col" class="sort" (click)="sort('data.gender')">
					<div class="ai-c">
						<span>Gender</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'data.gender'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'data.gender'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
				<th scope="col" class="sort hidden-2" (click)="sort('data.birthday.singleDate.epoc')">
					<div class="ai-c">
						<span>Date of Birth</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'data.birthday.singleDate.epoc'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'data.birthday.singleDate.epoc'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
				<th scope="col" class="sort hidden-1" (click)="sort('_id')">
					<div class="ai-c">
						<span>Client Since</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == '_id'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == '_id'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
				<th *ngIf="us.is.admin||us.is.owner"></th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let user of us.clients|search: searching: 'name email inc data.address data.phone data.additionalPhone data.gender data.birthday.singleDate.formatted'|sort: sort_type:us.now">
				<td data-label="Name" class="ovf-hidden" (click)="profile(user);" [tooltip]="user.name" tooltipMobileDisabled="true" tooltipClass="tableTooltip" tooltipShowDelay="200">
					<div class="table__user">
						<div class="table__user__img" [class._letter]="!user.avatarUrl">
							<picture [user]="user"></picture>
						</div>
						<div class="table__user__info">
							<span class="table__user__info-name">{{user.firstName}} <wbr> {{user.lastName}}</span>
							<div class="table__user__info-desc">{{user.inc}}</div>
						</div>
					</div>
				</td>
				<td data-label="Address" class="text-elipsis" [tooltip]="user.data.address" tooltipClass="tableTooltip" tooltipShowDelay="200">{{user.data.address}}</td>
				<td data-label="Email" class="text-elipsis" [tooltip]="user.email" tooltipClass="tableTooltip" tooltipShowDelay="200">{{user.email}}</td>
				<td data-label="Phone" class="text-elipsis" [tooltip]="user.data.phone" tooltipClass="tableTooltip" tooltipShowDelay="200">{{user.data.phone}}</td>
				<td data-label="Gender" class="text-elipsis" [tooltip]="user.data.gender" tooltipClass="tableTooltip" tooltipShowDelay="200">{{user.data.gender}}</td>
				<td data-label="Date of Birth" class="text-elipsis hidden-2" [tooltip]="user.data.birthday?.singleDate?.formatted||''" tooltipClass="tableTooltip" tooltipShowDelay="200">{{user.data.birthday?.singleDate?.formatted||''}}</td>
				<td data-label="Client Since" class="text-elipsis hidden-1" style="grid-column: span 2;" [tooltip]="user._id|mongodate|date:'M/d/yyyy'" tooltipClass="tableTooltip" tooltipShowDelay="200">{{user._id|mongodate|date:'M/d/yyyy'}}</td>
				<td *ngIf="us.is.admin||us.is.owner">
					<div class="actions">
                        <button mat-icon-button color="primary" tooltip="Edit" tooltipMobileDisabled="true" aria-label="Edit" (click)="edit(user);" class="waw-btn__icon primary mr10 _tablet">
                            <span class="icon-pencil"></span>
                        </button>
                        <button mat-icon-button color="warn" tooltip="Delete" tooltipMobileDisabled="true" aria-label="Delete" (click)="delete(user);" class="waw-btn__icon warn _tablet">
                            <span class="icon-delete"></span>
                        </button>
                    </div>
				</td>
			</tr>
		</tbody>
	</table>
</div>
