<div class="ntf" [attr.data-id]="notif._id">
    <div class="ntf__head">
        <div *ngIf="notif.message" class="ntf-icon">
            <picture [user]="us._users[notif.data?.user]"></picture>
        </div>
        <div class="ntf-title">{{notif.title}}</div>
    </div>
    <div class="ntf-unread" *ngIf="!notif.read"></div>
    <div class="ntf-desc">{{notif.body}}</div>
    <div class="ntf-clock">
        <span [title]="notif.date | date: 'M/d/yy, h:mm a z'">{{notif.date | relativeTime: now}}</span>
    </div>
</div>