import { Component, Input } from '@angular/core';
@Component({
	selector: 'loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
    @Input() darkBackground: boolean = false;

    @Input() transparent: boolean = false;

    @Input() modal: boolean = false; 

    @Input() preventClick: boolean = false;

    @Input() whiteBackground: boolean = false;

    constructor() {}
}