import { Injectable } from '@angular/core';
import { MongoService, AlertService } from 'wacom';

@Injectable({
	providedIn: 'root'
})
export class ResourceService {
	public resources: any = [];
	public _resources: any = {};
	constructor(private alert: AlertService, private mongo: MongoService) { 
		this.resources = mongo.get('resource', (arr, obj) => {
			this._resources = obj;
		});
	}
	create(resource) {
		if(resource._id) return this.update(resource);
		this.mongo.create('resource', resource, ()=>{
			this.alert.show({
				text: 'Resource has been created.'
			});
		}); 
	}
	update(resource) {
		this.mongo.afterWhile(resource, ()=> {
			this.mongo.update('resource', resource);
		});
	}
	delete(resource) {
		this.mongo.delete('resource', resource);
	}
}
