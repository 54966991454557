import { Component, OnInit } from '@angular/core';
import { AppointmentService, LoaderService, UserService } from '@services';
import { Router } from '@angular/router';
import { AlertService } from 'wacom';
@Component({
  selector: 'app-rebook',
  templateUrl: './rebook.component.html',
  styleUrls: ['./rebook.component.scss']
})
export class RebookComponent implements OnInit {
	public close;
	public appointment:any = {};
	public kinds = [{name: 'Daily'}, {name: 'Weekly'}, {name: 'Monthly'}, {name: 'Yearly'}];
	public repeat:any = {
		kind: this.kinds[0],
		count: 1,
		enable: true
	};
	public submitted = false;
	constructor(public us: UserService, public aps: AppointmentService, public router: Router, private loader: LoaderService, public alert: AlertService) { }
	ngOnInit(): void {
		
	}
	save() {
		this.submitted = true;

		if(!this.repeat.kind||!this.repeat.count) return this.alert.error({
			text: "Please fill required fields",
		});

		this.loader.show({ 
			modal: true,
			transparent: true,
			preventClick: true
		}, document.querySelector('.modal-box'));

		this.aps.rebook(this.appointment, this.repeat, true, () => {
			this.submitted = false;
			this.loader.remove();
			this.close();
		});
	}
}
