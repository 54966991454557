import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GroupService, LoaderService, LocationService, PlatformService, RoleService, RoomService, ServiceService, UserService } from '@services';
import { AlertService, ModalService, MongoService } from 'wacom';
@Component({
	selector: 'registration',
	templateUrl: './registration.component.html',
	styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
	public close = () => {};
	private navigate: boolean = true;
	public submitted: boolean = false;
	public steps: any = [1, 2, 3, 4, 5, 6];
	public step = 1;
	public location: any = { data: {business_hours: {Monday: [], Tuesday: [], Wednesday: [], Thursday: [], Friday: [], Saturday: [], Sunday: []} }};
	public room: any = { availability: {Monday: [], Tuesday: [], Wednesday: [], Thursday: [], Friday: [], Saturday: [], Sunday: []} };
	public staff: any = { data: {
		working_hours: { 
			default: {
				Monday: [], 
				Tuesday: [], 
				Wednesday: [], 
				Thursday: [], 
				Friday: [], 
				Saturday: [], 
				Sunday: [],
			}
		}
	} };
	public services: any = [];
	public group: any = {};
	@ViewChild('servicePriceRef', { static: true }) servicePriceRef: ElementRef;
	@ViewChild('serviceDurationRef', { static: true }) serviceDurationRef: ElementRef;

	constructor(
		private router: Router, 
		public alert: AlertService,
		public ls: LocationService,
		private mongo: MongoService,
		public rms: RoomService,
		public us: UserService,
		public rs: RoleService,
		public ss: ServiceService,
		public gs: GroupService,
		private ps: PlatformService,
		private loader: LoaderService,
		private modal: ModalService
	) {}
	ngOnInit() {
		if(!(this.us.is.admin || this.us.is.owner)) {
			if (this.navigate) this.router.navigate(['/profile']);					
			this.close();
		}
		this.mongo.on('location platform user service room role group service', () => {
			var step = false;
			if(this.ls.locations?.length) {
				this.location = this.ls.primary[0];
				if (!step) {
					this.step = 2;
				}
			} else {
				step = true;
				this.location.name = this.ps.platform.data.name;
				this.location.email = this.us.email;
			}
			let days = false; 
			for(let day of this.us.days_of_week) {
				if(this.location.data.business_hours[day].length && !days) days = true;
			}
			if(days) {
				if (!step) {
					this.step = 3;
				}
			} else step = true;
			if(this.rms.rooms?.length && this.rms.by_locations[this.location._id]?.length) {
				this.room = this.rms.by_locations[this.location._id][0];
				if (!step) {
					this.step = 4;
				}
			} else step = true;
			if(this.us.allowed_appointments?.length) {
				this.staff = this.us.allowed_appointments[0];
				if (!step) {
					this.step = 5;
				}
			} else step = true;
			if( this.staff.data?.working_hours?.default && Object.values(this.staff.data?.working_hours?.default).some((day: any) => day?.length > 0) ) {
				if (!step) {
					this.step = 6;
				}
			} else step = true;
			if(this.gs.groups?.length && this.ss.services?.length) {
				this.group = this.gs.groups[0];
				if(this.ss.group[this.group._id]?.length) {
					this.services = this.ss.group[this.group._id];
					if (!step) {
						if (this.navigate) this.router.navigate(['/profile']);					
						this.close();
					}
				}
			}
		});
	}
	cancel() {
		if (this.navigate) this.router.navigate(['/profile']);	
		this.close();
	}
	previousStep() {
		this.step --;
	}
	nextStep() {
		const convertTimeToMinutes = (time: string) => {
			return Number(time.split(':')[0]) * 60 + Number(time.split(':')[1]);
		}

		switch(this.step) {
			case 1: 
				this.submitted = true;
				if(!this.location.name || !this.location.number || !this.location.email || !this.location.country || !this.location.address || !this.location.city || !this.location.state || !this.location.zip || !this.location.timezone) return this.alert.error({
					text: "Please fill required fields",
				});
				this.location.primary = true;
				this.location.primary = true;

				this.loader.show({ 
					modal: true,
					transparent: true,
					preventClick: true
				}, document.querySelector('.modal-box#modal'));

				this.ls.create(this.location, (created) => {
					if(created) {
						this.location = created;
						this.room.location = this.location._id;
					}
					this.loader.remove();
					this.step ++;
					this.submitted = false;
				});
				break;
			case 2: 
				this.submitted = true;
				for(let day of this.us.days_of_week) {
					for(const [index, hour] of this.location.data.business_hours[day].entries()) {
						if(!hour.from || !hour.to) return this.alert.error({
							text: "Please fill required fields",
						});
						if( index > 0 && convertTimeToMinutes(hour.from) <= convertTimeToMinutes(this.location.data.business_hours[day][index-1].to) || !!this.location.data.business_hours[day]?.[index+1] && convertTimeToMinutes(hour.to) >= convertTimeToMinutes(this.location.data.business_hours[day][index+1].from) || convertTimeToMinutes(hour.to) <= convertTimeToMinutes(hour.from)) {
							return this.alert.error({
								text: "Please resolve the time conflict",
							});
						}
					}
				}
				this.ls.save(this.location);
				this.room.availability = this.location.data.business_hours;
				this.step ++;
				this.submitted = false;
				break;
			case 3: 
				this.submitted = true;
				if(!this.room.name) {
					document.querySelector('#modal').scrollIntoView({ behavior: "smooth", block: "start"})
					return this.alert.error({
						text: "Please fill required fields",
					});
				}
				for(let day of this.us.days_of_week) {
					for(const [index, hour] of this.room.availability[day].entries()) {
						if(!hour.from || !hour.to) return this.alert.error({
							text: "Please fill required fields",
						});
						if( index > 0 && convertTimeToMinutes(hour.from) <= convertTimeToMinutes(this.room.availability[day][index-1].to) || !!this.room.availability[day]?.[index+1] && convertTimeToMinutes(hour.to) >= convertTimeToMinutes(this.room.availability[day][index+1].from) || convertTimeToMinutes(hour.to) <= convertTimeToMinutes(hour.from)) {
							return this.alert.error({
								text: "Please resolve the time conflict",
							});
						}
					}
				}		
				this.room.location = this.location._id;
				this.rms.create(this.room, (created) => {
					if(created) this.room = created;
				}); 
				this.step ++;
				this.submitted = false;
				break;
			case 4:
				this.submitted = true;
				if(!this.staff.firstName||!this.staff.lastName||!this.staff.email) return this.alert.error({
					text: "Please fill required fields",
				});
				this.staff.make_staff = true;
				this.staff.appointment = true;
				this.staff.location = [this.location._id]
				this.staff.role = this.rs.roles[0]._id;
				this.us.create(this.staff, (created_staff)=>{
					if(created_staff) this.staff = created_staff;
					this.step ++;
					this.submitted = false;
				}, () => {
					this.submitted = false;
				});
				break;
			case 5: 
				this.submitted = true;
				for(let day of this.us.days_of_week) {
					for(const [index, hour] of this.staff.data.working_hours.default[day].entries()) {
						if(!hour.from || !hour.to) {
							return this.alert.error({
								text: "Please fill required fields",
							});
						}
						if( index > 0 && this.convertTimeToMinutes(hour.from) <= this.convertTimeToMinutes(this.staff.data.working_hours.default[day][index-1].to) || !!this.staff.data.working_hours.default[day]?.[index+1] && this.convertTimeToMinutes(hour.to) >= this.convertTimeToMinutes(this.staff.data.working_hours.default[day][index+1].from) || this.convertTimeToMinutes(hour.to) <= this.convertTimeToMinutes(hour.from)) {
							return this.alert.error({
								text: "Please resolve the time conflict",
							});
						}
					}
				}
				this.us.save(this.staff);
				this.step ++;
				this.submitted = false;
				break;
			case 6:
				this.submitted = true;
				if(!this.group.name || !this.services.length) {
					if(!this.group.name) this.alert.error({
						text: "Please fill required fields",
					});
					if(!this.services.length) this.alert.error({
						text: "Please add at least one service",
					});
					return;
				}
				for(let service of this.services) {
					service.color = this.us.randomColor;
					service.staffs = [this.staff._id];
					service.locations = [this.location._id];
					service.room = this.room._id;
				}
				if (!this.group._id) {
					this.gs.create(this.group, (created_group) => {
						this.group = created_group;
						for(let service of this.services) {
							service.group = this.group._id;
							this.ss.create(service, (created_service) => {
								service = created_service;
							});
						}
					});
				} else {
					for(let service of this.services) {
						service.group = this.group._id;
						this.ss.create(service, (created_service) => {
							service = created_service;
						});
					}
				}
				this.submitted = false;
				localStorage.removeItem('registration');
				if (this.navigate) this.router.navigate(['/profile']);					
				this.close();
				break;
		}
	}

	service(index = null) {
		var service = {};
		if(index != null) {
			service = {
				_id: index,
				name: this.services[index].name,
				price_type: this.services[index].price_type,
				price: this.services[index].price,
				time: this.services[index].time
			};
		}

		this.router.navigate([],{ queryParams: { modal: 'open' }});
		this.modal.show({
			class: 'small',
			component: 'modal',
			profile: service,
			title: 'service',
			components: [
				{
					type: 'INPUT',
					input: 'name',
					label: 'Service name *',
					placeholder: 'Service name',
					reqText: 'Service name is required',
					required: true,
				}, {
					type: 'SELECT',
					input: 'price_type',
					label: 'Price Type *',
					placeholder: 'Select Price Type',
					required: true,
					reqText: 'Type is required',
					items: this.ss.price_types,
					clearable: false,
					searchable: false,
					bindValue: "name",
					bindLabel: "name"
				}, {
					type: 'NUMBER',
					min: 0.01,
					input: 'price',
					customRef: this.servicePriceRef,
					required: true,
				}, {
					type: 'NUMBER',
					min: 1,
					input: 'time',
					customRef: this.serviceDurationRef,
					required: true,
				}
			],
			closeAutomatically: true,
			onDelete: (profile: any) => {
				this.services.splice(index, 1);
			},
			onSave: (profile: any) => {
				if(index == null) {
					this.services.push(profile);
				} else {
					this.services[index] = profile;
				}
			}
		});
	}

	@HostListener('window:popstate', ['$event'])
	onPopState(event) {
		this.cancel();
	}

	convertTimeToMinutes(time: string) {
		return Number(time.split(':')[0]) * 60 + Number(time.split(':')[1]);
	}
}
