import { Component, HostListener, OnInit, Input, OnChanges, ElementRef, AfterViewInit } from '@angular/core';
import { UserService, LocationService, AppointmentService, ServiceService, ClosedService, LoaderService } from '@services';
import { AppointmentsDatePipe } from '@pipes';
import { DatePipe } from '@angular/common';
import { MongoService } from 'wacom';

@Component({
	selector: 'app-dashboard-appointments',
	templateUrl: './dashboard-appointments.component.html',
	styleUrls: ['./dashboard-appointments.component.scss', '../dashboard.component.scss']
})
export class DashboardAppointmentsComponent implements OnInit, OnChanges, AfterViewInit {

	@Input() selector: any = {
		start: new Date(),
		end: new Date()
	};
	@Input() interval: string = 'day';
	@Input() location: any = null;

	public occupancy: any = {
		working_hours: 0,
		booked_hours: 0,
		unbooked_hours: 0,
		percentage: 0,
		value: '0%',
		difference: '0%',
	};

	public appointments_by_status: any = [
		{
			name: 'Completed',
			value: 0,
		}, {
			name: 'Confirmed',
			value: 0,
		}, {
			name: 'No-Shows',
			value: 0,
		}, {
			name: 'Canceled',
			value: 0,
		}
	];

	public maxYValue: number = 0;

	public apptsLineChart: any = [
		{
			"name": "Appointments",
			"series": []
		}
	];

	public occupancyBarChart: any = [];

	public appointments_by_duration: any = [
		{
			name: 'Short',
			extra_name: '(0-15 min)',
			value: 0,
		}, {
			name: 'Medium',
			extra_name: '(16-30 min)',
			value: 0,
		}, {
			name: 'Long',
			extra_name: '(31-60 min)',
			value: 0,
		}, {
			name: 'Extended',
			extra_name: '(61-90 min)',
			value: 0,
		}, {
			name: 'Extra-Long',
			extra_name: '(91+ min)',
			value: 0,
		}
	];

	public windowWidth: number;

	public yAxisAppts = this.yAxisApptsFormatting.bind(this);

	public yAxisOccupancy = this.yAxisOccupancyFormatting.bind(this);

	public apptColumns = [
		{
			title: 'clients',
			field: 'client_name'
		}, {
			title: 'date',
			field: 'date'
		}, {
			title: 'duration',
			field: 'duration'
		}, {
			title: 'services',
			field: 'service_name'
		}, {
			title: 'price',
			field: 'service_price'
		}, {
			title: 'status',
			field: 'status'
		}, 
	];

	public apptRows = [];

	public afterViewInit: boolean = false;

	private informationLoaded: any = {
		occupancy: false,
		appointmentsStatus: false,
		apptsLineChart: false,
		occupancyBarChart: false,
		apptsDuration: false,
		apptRows: false,
	};

	constructor(
		public us: UserService, 
		public loc: LocationService, 
		public aps: AppointmentService, 
		private appointmentsDatePipe: AppointmentsDatePipe,
		public ss: ServiceService,
		private datePipe: DatePipe,
		private mongo: MongoService,
		private cls: ClosedService,
		private loader: LoaderService,
		private eref: ElementRef
	) {}

	ngOnInit(): void {
		if (!this.loader.isLoaderShowing) this.loader.show({container: true}, this.eref.nativeElement.closest('.containerTab'));
		
		this.windowWidth = window.innerWidth;
		this.mongo.on('appointment', () => {
			this.initializeAppointmentsStatus();
			this.initializeApptsLineChart();
			this.initializeOccupancyBarChart();
			this.initializeApptsDuration();
		});
		this.mongo.on('user location closed', () => {
			this.initializeOccupancy();
		});
		this.mongo.on('user appointment service', () => {
			this.initializeApptRows();
		});
	}

	ngOnChanges() {
		if(this.afterViewInit) {
			if (!this.loader.isLoaderShowing) this.loader.show({container: true, transparent: true}, this.eref.nativeElement.closest('.containerTab'));

			this.informationLoaded = {
				occupancy: false,
				appointmentsStatus: false,
				apptsLineChart: false,
				occupancyBarChart: false,
				apptsDuration: false,
				apptRows: false,
			};
	
			let waitForLoader = setInterval(() => {
				if (this.loader.isLoaderShowing) {
					clearInterval(waitForLoader);
					this.refresh();
				}
			}, 10);
		}
	}

	ngAfterViewInit() {
		this.mongo.on('user appointment location closed service', () => {
			this.afterViewInit = true;
			let waitForInformationLoading = setInterval(() => {
				if (Object.values(this.informationLoaded).every(value => value === true)) {
					clearInterval(waitForInformationLoading);
					this.loader.remove();
				}
			}, 1);
		});
	}
	
	refresh() {
		this.initializeOccupancy();
		this.initializeAppointmentsStatus();
		this.initializeApptsLineChart();
		this.initializeOccupancyBarChart();
		this.initializeApptsDuration();
		this.initializeApptRows();

		let waitForInformationLoading = setInterval(() => {
			if (Object.values(this.informationLoaded).every(value => value === true)) {
				clearInterval(waitForInformationLoading);
				this.refreshCharts();
				if(this.afterViewInit) this.loader.remove();
			}
		}, 1);
	}

	refreshCharts() {
		document.querySelectorAll('.circular-chart .circle').forEach(element => {
			if(element.classList.contains('_animation')) element.classList.remove('_animation');
			setTimeout(() => {
				element.classList.add('_animation');
			}, 10);
		});
	}

	getOccupancyHours(startDate, endDate) {
		var res: any = {
			working_minutes: 0,
			booked_minutes: 0,
			unbooked_minutes: 0,
			percentage: 0
		};
		
		for(let d = new Date(startDate); d <= new Date(endDate); d.setDate(d.getDate() + 1)) {
			d.setHours(0, 0, 0, 0);
			let day = this.datePipe.transform(new Date(d), 'EEEE');
			let format = this.datePipe.transform(new Date(d), 'M/d/yyyy');
			
			for(let location of this.loc.locations) {
				if((!this.location && location.data.business_hours?.[day]?.length) || (this.location == location._id && location.data.business_hours?.[day]?.length)) {
					
					let closed = false;
					for( let i = this.cls.closeds.length-1; i >= 0; i-- ) {	
						if(
							this.cls.closeds[i].locations.find(l => l == location._id) && 
							(
								(
									!this.cls.closeds[i].holiday &&
									new Date(this.cls.closeds[i].start?.singleDate?.formatted) <= new Date(d) && 
									new Date(this.cls.closeds[i].end?.singleDate?.formatted) >= new Date(d)
								) ||
								(
									this.cls.closeds[i].holiday &&
									this.cls.isHoliday(new Date(d), this.cls.closeds[i].holiday, this.cls.closeds[i].substitute )
								)
							)
						) {					
							closed = true;
							break;
						}
					} 
					if(!closed) {

						for(let staff of this.us.allowed_appointments) {
							if(staff.location.find(l => l == location._id)) {
								
								if(staff.data.working_hours?.[format]?.hours?.length && !staff.data.working_hours?.[format]?.vacation) {
									res.working_minutes += this.findIntersection(location.data.business_hours[day], staff.data.working_hours[format].hours);
								} else if(staff.data.working_hours?.default?.[day]?.length && !staff.data.working_hours?.[format]?.vacation) {
									res.working_minutes += this.findIntersection(location.data.business_hours[day], staff.data.working_hours.default[day]);
								}

							}
						}
					} 
				}
			}	
		}
		const appointments = this.appointmentsDatePipe.transform(this.aps._appointments.date, {start: startDate, end: endDate}, this.location);
		for (let appt of appointments) {
			res.booked_minutes += appt.duration;
		}

		res.unbooked_minutes = res.working_minutes - res.booked_minutes;
		res.percentage = res.booked_minutes ? Math.round(res.booked_minutes * 100 / res.working_minutes) : 0;

		return res;
	}

	initializeOccupancy() {
		switch(this.interval) {
			case 'day':
				let date = new Date(this.selector.start);
				date.setDate(new Date(this.selector.start).getDate() - 1);
				var prev = this.getOccupancyHours(date, date);
				break;
			case 'week':
				let weekStart = new Date(this.selector.start);
				weekStart.setDate(this.selector.start.getDate() - 7);
				let weekEnd = new Date(this.selector.end);
				weekEnd.setDate(this.selector.end.getDate() - 7);
				var prev = this.getOccupancyHours(weekStart, weekEnd);
				break;
			case 'month':
				let monthStart = new Date(this.selector.start.getFullYear(), this.selector.start.getMonth() - 1, 1);
				let monthEnd = new Date(this.selector.end.getFullYear(), this.selector.end.getMonth(), 0);
				var prev = this.getOccupancyHours(monthStart, monthEnd);
				break;
			case 'year':
				let yearStart = new Date(this.selector.start.getFullYear() - 1, 0, 1);
				let yearEnd = new Date(this.selector.end.getFullYear() - 1, 11, 31);
				var prev = this.getOccupancyHours(yearStart, yearEnd);
				break;
			case 'quarter':
				let quarterStart = new Date(this.selector.start.getFullYear(), this.selector.start.getMonth() - 3, 1);
				let quarterEnd = new Date(this.selector.start.getFullYear(), this.selector.start.getMonth(), 0);
				var prev = this.getOccupancyHours(quarterStart, quarterEnd);
				break;
		}
		this.occupancy = this.getOccupancyHours(this.selector.start, this.selector.end);
		this.occupancy.difference = ((this.occupancy.percentage - prev.percentage) > 0 ? '+' : '') + (Math.round(this.occupancy.percentage - prev.percentage)).toString() + '%';
		this.occupancy.value = this.occupancy.percentage + '%';
		this.informationLoaded.occupancy = true;
	}

	initializeAppointmentsStatus() {
		const appts = this.appointmentsDatePipe.transform(this.aps._appointments.date, this.selector, this.location);
		this.appointments_by_status = [
			{
				name: 'Completed',
				value: 0,
			}, {
				name: 'Confirmed',
				value: 0,
			}, {
				name: 'No-show',
				value: 0,
			}, {
				name: 'Canceled',
				value: 0,
			}
		];
		for(let appt of appts) {
			if(this.appointments_by_status.find(s => s.name == appt.status)) {
				this.appointments_by_status.find(s => s.name == appt.status).value++;
			}
		}
		this.informationLoaded.appointmentsStatus = true;
	}

	initializeApptsLineChart() {
		this.maxYValue = 0;
		this.apptsLineChart = [
			{
				"name": "Appointments",
				"series": []
			}
		];

		switch(this.interval) {
			case 'day':
				for(let i = 0; i < 24; i++) {
					let buf = 0;
					this.appointmentsDatePipe.transform(this.aps._appointments.date, this.selector, this.location).forEach((obj) => {
						const start = obj.start.split(':')[0];
						if (start == i) {
							buf++;
						}
					});
					let d = new Date(this.selector.start);
					d.setHours(i, 0, 0);

					if(buf > this.maxYValue) this.maxYValue = buf;
					
					this.apptsLineChart[0].series.push({
						name: this.datePipe.transform(d, 'shortTime'),
						value: buf,
						title: this.datePipe.transform(d, 'EEEE d MMMM, h:mm a')
					});
				}
				break;
			case 'week':
				for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
					if(this.appointmentsDatePipe.transform(this.aps._appointments.date, {start: d}, this.location)?.length > this.maxYValue) this.maxYValue = this.appointmentsDatePipe.transform(this.aps._appointments.date, {start: d}, this.location)?.length;

					this.apptsLineChart[0].series.push({
						name: this.datePipe.transform(d, 'shortDate'),
						value: this.appointmentsDatePipe.transform(this.aps._appointments.date, {start: d}, this.location)?.length,
						title: this.datePipe.transform(d, 'EEEE d MMMM')
					});
				}
				break;
			case 'month':
				for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
					if(this.appointmentsDatePipe.transform(this.aps._appointments.date, {start: d}, this.location)?.length > this.maxYValue) this.maxYValue = this.appointmentsDatePipe.transform(this.aps._appointments.date, {start: d}, this.location)?.length;
					
					this.apptsLineChart[0].series.push({
						name: this.datePipe.transform(d, 'shortDate'),
						value: this.appointmentsDatePipe.transform(this.aps._appointments.date, {start: d}, this.location)?.length,
						title: this.datePipe.transform(d, 'EEEE d MMMM')
					});
				}
				break;
			case 'year':
				for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setMonth(d.getMonth() + 1)) {
					let end = new Date(d.getFullYear(), d.getMonth() + 1, 0);
					
					if(this.appointmentsDatePipe.transform(this.aps._appointments.date, {start: d, end: end}, this.location)?.length > this.maxYValue) this.maxYValue = this.appointmentsDatePipe.transform(this.aps._appointments.date, {start: d, end: end}, this.location)?.length;
					
					this.apptsLineChart[0].series.push({
						name: this.datePipe.transform(d, 'MMM'),
						value: this.appointmentsDatePipe.transform(this.aps._appointments.date, {start: d, end: end}, this.location)?.length,
						title: this.datePipe.transform(d, 'MMMM')
					});
				}
				break;
			case 'quarter':
				for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 7)) {
					let end = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 6);
					if(end > this.selector.end) end = new Date(this.selector.end);
					
					if(this.appointmentsDatePipe.transform(this.aps._appointments.date, {start: d, end: end}, this.location)?.length > this.maxYValue) this.maxYValue = this.appointmentsDatePipe.transform(this.aps._appointments.date, {start: d, end: end}, this.location)?.length;
					
					this.apptsLineChart[0].series.push({
						name: this.datePipe.transform(d, 'shortDate'),
						value: this.appointmentsDatePipe.transform(this.aps._appointments.date, {start: d, end: end}, this.location)?.length,
						title: ((this.datePipe.transform(d, 'MMMM') === this.datePipe.transform(end, 'MMMM')) ? this.datePipe.transform(d, 'd') : this.datePipe.transform(d, 'd MMMM')) + (end.getDate() != d.getDate() ? ' - ' + this.datePipe.transform(end, 'd MMMM') : this.datePipe.transform(end, ' MMMM'))
					});
				}
				break;
		}

		this.informationLoaded.apptsLineChart = true;
	}
	
	yAxisApptsFormatting(value) {
		return value;
	}

	initializeOccupancyBarChart() {
		this.occupancyBarChart = [];

		switch(this.interval) {
			case 'week':
				for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
					let buf = this.getOccupancyHours(d, d);

					this.occupancyBarChart.push({
						name: this.datePipe.transform(d, 'shortDate'),
						series: [
							{
								name: 'Booked hours',
								value: (buf.booked_minutes / 60),
								title: `Booked \u2022 ${this.datePipe.transform(d, 'EEEE d MMMM')}`,
								tooltip_value: `${this.formatMinutesToHoursAndMinutes(buf.booked_minutes) } (${buf.percentage}%)`
							}, {
								name: 'Unbooked hours',
								value: (buf.unbooked_minutes / 60),
								title: `Unbooked \u2022 ${this.datePipe.transform(d, 'EEEE d MMMM')}`,
								tooltip_value: `${this.formatMinutesToHoursAndMinutes(buf.unbooked_minutes)} (${100 - buf.percentage}%)`
							}
						]
					});
				}
				break;
			case 'month':
				for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
					let buf = this.getOccupancyHours(d, d);

					this.occupancyBarChart.push({
						name: this.datePipe.transform(d, 'shortDate'),
						series: [
							{
								name: 'Booked hours',
								value: (buf.booked_minutes / 60),
								title: `Booked \u2022 ${this.datePipe.transform(d, 'EEEE d MMMM')}`,
								tooltip_value: `${this.formatMinutesToHoursAndMinutes(buf.booked_minutes) } (${buf.percentage}%)`
							}, {
								name: 'Unbooked hours',
								value: (buf.unbooked_minutes / 60),
								title: `Unbooked \u2022 ${this.datePipe.transform(d, 'EEEE d MMMM')}`,
								tooltip_value: `${this.formatMinutesToHoursAndMinutes(buf.unbooked_minutes)} (${100 - buf.percentage}%)`
							}
						]
					});
				}
				break;
			case 'year':
				for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setMonth(d.getMonth() + 1)) {
					let end = new Date(d.getFullYear(), d.getMonth() + 1, 0);

					let buf = this.getOccupancyHours(d, end);

					this.occupancyBarChart.push({
						name: this.datePipe.transform(d, 'MMMM'),
						series: [
							{
								name: 'Booked hours',
								value: (buf.booked_minutes / 60),
								title: `Booked \u2022 ${this.datePipe.transform(d, 'MMMM')}`,
								tooltip_value: `${this.formatMinutesToHoursAndMinutes(buf.booked_minutes) } (${buf.percentage}%)`
							}, {
								name: 'Unbooked hours',
								value: (buf.unbooked_minutes / 60),
								title: `Unbooked \u2022 ${this.datePipe.transform(d, 'MMMM')}`,
								tooltip_value: `${this.formatMinutesToHoursAndMinutes(buf.unbooked_minutes)} (${100 - buf.percentage}%)`
							}
						]
					});
				}
				break;
			case 'quarter':
				for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 7)) {
					let end = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 6);
					if(end > this.selector.end) end = new Date(this.selector.end);

					let buf = this.getOccupancyHours(d, end);

					this.occupancyBarChart.push({
						name: this.datePipe.transform(d, 'shortDate'),
						series: [
							{
								name: 'Booked hours',
								value: (buf.booked_minutes / 60),
								title: `Booked \u2022 ${((this.datePipe.transform(d, 'MMMM') === this.datePipe.transform(end, 'MMMM')) ? this.datePipe.transform(d, 'd') : this.datePipe.transform(d, 'd MMMM')) + (end.getDate() != d.getDate() ? ' - ' + this.datePipe.transform(end, 'd MMMM') : this.datePipe.transform(end, ' MMMM'))}`,
								tooltip_value: `${this.formatMinutesToHoursAndMinutes(buf.booked_minutes) } (${buf.percentage}%)`
							}, {
								name: 'Unbooked hours',
								value: (buf.unbooked_minutes / 60),
								title: `Unbooked \u2022 ${((this.datePipe.transform(d, 'MMMM') === this.datePipe.transform(end, 'MMMM')) ? this.datePipe.transform(d, 'd') : this.datePipe.transform(d, 'd MMMM')) + (end.getDate() != d.getDate() ? ' - ' + this.datePipe.transform(end, 'd MMMM') : this.datePipe.transform(end, ' MMMM'))}`,
								tooltip_value: `${this.formatMinutesToHoursAndMinutes(buf.unbooked_minutes)} (${100 - buf.percentage}%)`
							}
						]
					});
				}
				break;
		}

		this.informationLoaded.occupancyBarChart = true;
	}

	yAxisOccupancyFormatting(value) {
		return this.formatMinutesToHoursAndMinutes(value * 60);
	}

	formatMinutesToHoursAndMinutes(minutes) {
		if(minutes == 0) return '0min';
		const hours = Math.floor(minutes / 60);
		const remainingMinutes = minutes % 60;
	  
		const hoursText = hours > 0 ? `${hours}h` : '';
		const minutesText = remainingMinutes > 0 ? `${remainingMinutes}min` : '';
	  
		return `${hoursText} ${minutesText}`.trim();
	}

	initializeApptsDuration() {
		let short = 0, medium = 0, long = 0, extended = 0, extra = 0;

		this.appointmentsDatePipe.transform(this.aps._appointments.date, this.selector, this.location).forEach((obj) => {
			switch(true) {
				case(obj.duration <= 15):
					short++;
					break;
				case(obj.duration <= 30):
					medium++;
					break;
				case(obj.duration <= 60):
					long++;
					break;
				case(obj.duration <= 90):
					extended++;
					break;
				case(obj.duration > 90):
					extra++;
					break;
			}
		});

		this.appointments_by_duration = [
			{
				name: 'Short',
				extra_name: '(0-15 min)',
				value: short,
			}, {
				name: 'Medium',
				extra_name: '(16-30 min)',
				value: medium,
			}, {
				name: 'Long',
				extra_name: '(31-60 min)',
				value: long,
			}, {
				name: 'Extended',
				extra_name: '(61-90 min)',
				value: extended,
			}, {
				name: 'Extra-Long',
				extra_name: '(91+ min)',
				value: extra,
			}
		];

		this.informationLoaded.apptsDuration = true;
	}

	initializeApptRows() {
		this.apptRows = [];
		this.apptRows = this.appointmentsDatePipe.transform(this.aps._appointments.date, this.selector, this.location).map((a) => {
			a.client_name = this.us._users[a.client]?.name;
			let d = new Date(a.day?.singleDate?.jsDate);
			d.setHours(a.start.split(':')[0], a.start.split(':')[1]);
			a.date = d;
			a.service_name = this.ss._services[a.service]?.name;
			a.service_price = this.ss._services[a.service]?.price;
			return a;
		});

		this.informationLoaded.apptRows = true;
	}

	@HostListener('window:resize')
	onWindowResize() {
		this.windowWidth = window.innerWidth;
	}

	findIntersection(array1: any[], array2: any[]): number {
		const timeToMinutes = (time: string): number => {
			const [hours, minutes] = time.split(':').map(Number);
			return hours * 60 + minutes;
		}
		let intersectionMinutes = 0;
	
		for (const interval1 of array1) {
			const start1 = timeToMinutes(interval1.from);
			const end1 = timeToMinutes(interval1.to);
		
			for (const interval2 of array2) {
				const start2 = timeToMinutes(interval2.from);
				const end2 = timeToMinutes(interval2.to);
		
				// Calculate the intersection
				const intersectionStart = Math.max(start1, start2);
				const intersectionEnd = Math.min(end1, end2);
		
				// If there's a valid intersection, add it to the total intersection minutes
				if (intersectionStart < intersectionEnd) {
					intersectionMinutes += intersectionEnd - intersectionStart;
				}
			}
		}
	
		return intersectionMinutes;
	}
}