import { Pipe, PipeTransform } from '@angular/core';
import { MongoService } from 'wacom';
@Pipe({
	name: 'appointments'
})
export class AppointmentsPipe implements PipeTransform {
	constructor(private mongo: MongoService) {}
	clear(appointments, location){
		if(!location) return;
		this.mongo.on('service', (data:any)=>{
			if(!data.objservice.location[location]){
				appointments.splice(0, appointments.length);	
			}else{
				for (let j = appointments.length-1; j >= 0; j--){
				    let remove = true;
					for (let i = 0; i < data.objservice.location[location].length; i++){
					    if(appointments[j].service == data.objservice.location[location][i]._id){
					    	remove = false;
					    	break;
					    }
					}
					if(remove){
						appointments.splice(j, 1);
					}
				}
			}
		});
	}
	transform(appointments: any, location:string='', refresh?): unknown {
		if(!appointments) appointments=[];
		if(!location) return appointments;
		appointments = appointments.slice();
		appointments.sort((a, b) => {
			var aD = new Date();
			var bD = new Date();
			aD.setHours(a.start.split(':')[0], a.start.split(':')[1]);
			bD.setHours(b.start.split(':')[0], b.start.split(':')[1]);
			return aD.getTime() - bD.getTime();
		});
		if(location) this.clear(appointments, location);
		return appointments;
	}
}
