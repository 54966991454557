import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, LoaderService } from '@services';
import { AlertService } from 'wacom';

@Component({
    selector: 'app-sign',
    templateUrl: './sign.component.html',
    styleUrls: ['./sign.component.scss']
})
export class SignComponent {
    constructor(private router: Router,
        private http: HttpClient,
        private alert: AlertService,
        private loader: LoaderService,
        public auth: AuthService,
		private route: ActivatedRoute) {
            this.auth.reset();
			this.auth.submitted.firstName = false;
			this.auth.submitted.lastName = false;
			this.auth.submitted.email = false;
			this.auth.submitted.password = false;
			this.auth.submitted.rpassword = false;
			this.route.queryParams.subscribe(params => {
				this.user = JSON.parse(params['profile']);
				if(this.user.email) {
					this.auth.check.email = true;
					this.user.email_verified = true;
				}
				if(this.user.google || this.user.fb) {
					this.user.password = ' ';
				}
			});
        }
    public user:any = {
        email: '',
        password: '',
    };
    sign() {
		this.auth.submitted.firstName = true;
		this.auth.submitted.lastName = true;
		this.auth.submitted.email = true;
		this.auth.submitted.password = true;
		this.auth.submitted.rpassword = true;
        if(!this.user.firstName) {
			this.alert.error({
				text: 'Enter your first name',
			})
			return;
        }
        if(!this.user.lastName) {
			this.alert.error({
				text: 'Enter your last name',
			})
			return;
        }
        if(!this.user.email) {
			this.alert.error({
				text: 'Enter your email',
			})
			return;
        }
		if(!this.user.google&&!this.user.fb) {
			if(!this.user.password) {
				this.alert.error({
					text: 'Enter your password',
				})
				return;
			}
			if(!this.auth.passwordLengthRegex.test(this.user.password)) {
				this.alert.error({
					text: 'Password must contain at least 8 characters',
				})
				return;
			}
			if(!this.auth.passwordRegex.test(this.user.password)) {
				this.alert.error({
					text: 'Password must contain at least 1 capital letter, 1 number and 1 special character',
				})
				return;
			}
			if(!this.user.rpassword) {
				this.alert.error({
					text: 'Confirm the password',
				})
				return;
			}
			if(this.user.password != this.user.rpassword) {
				this.alert.error({
					text: 'Passwords do not match',
				})
				return;
			} 
		}
		this.loader.show({transparent: true});
        this.http.post('/api/user/status', this.user).subscribe((resp:any) => {
			if(resp.email) {
				this.auth.error.email = 'This email already exists';
				this.auth.submitted.email = true;
				this.auth.check.email = false;
				this.loader.remove();
				this.alert.error({
					text: "This email already exists",
				})
			} else {
				this.http.post('/api/user/signup', {
					firstName: this.user.firstName,
					lastName: this.user.lastName,
					username: this.user.email,
					password: this.user.password,
					google: this.user.google,
					google_id: this.user.google_id,
					fb: this.user.fb,
					fb_id: this.user.fb_id
				}).subscribe((res:any) => {
					this.loader.remove();
					localStorage.setItem('waw_user', JSON.stringify(res));
					this.router.navigate(['/profile'])
				})
			}
        });
    }
}
