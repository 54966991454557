import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, HostListener, AfterViewInit, AfterViewChecked } from '@angular/core';
import { Editor, Toolbar } from "ngx-editor";
import { modalAnimation } from 'src/app/common/animation/animation.component';
import { ElementRef } from '@angular/core';
import { UserService } from '@services';
import { toHTML, parseContent } from 'ngx-editor';

@Component({
	selector: 'note-editor',
	templateUrl: './note-editor.component.html',
	styleUrls: ['./note-editor.component.scss'],
	animations: [modalAnimation]
})
export class NoteEditorComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
	@Input('placeholder') placeholder: string;
	@Input('label') label: string;
	@Input('disabled') disabled: boolean = false;
	@Input('notes') notes: any;
	@Input() size: 'small' | 'medium' | 'large' = null;
	@Output('onChange') onChange = new EventEmitter();
	@Output('characterLimit') characterLimitReached = new EventEmitter();

	editor: Editor;
	toolbar: Toolbar = [
		['bold', 'italic', 'underline', 'strike'],
		['text_color', 'background_color'],
		['ordered_list', 'bullet_list', 'align_left', 'align_center', 'align_right', 'align_justify'],
	];
	colorPresets = [
        '#ff6f00', '#b71c1c', '#4a148c', '#1a237e', '#01579b', '#004d40', '#33691e', '#000000',
        '#ffa000', '#d32f2f', '#7b1fa2', '#303f9f', '#0288d1', '#00796b', '#689f38', '#525252', 
        '#ffc107', '#f44336', '#9c27b0', '#3f51b5', '#03a9f4', '#009688', '#8bc34a', '#969696', 
        '#ffd54f', '#e57373', '#ba68c8', '#7986cb', '#4fc3f7', '#4db6ac', '#aed581', '#d9d9d9', 
        '#ffecb3', '#ffcdd2', '#e1bee7', '#c5cae9', '#b3e5fc', '#b2dfdb', '#dcedc8', '#ffffff',
	];
	public emojiOpen: boolean = false;
	public perLine: number = 9;

	public hover: boolean = false;
	public on_focus: boolean = false;

	public characterLimit: number = 250;
	public characterCount: number = 0;

	constructor(private el: ElementRef, public us: UserService) {}

	ngOnInit(): void {
		switch(this.size) {
			case 'small':
				this.characterLimit = 250;
				break;
			case 'medium':
				this.characterLimit = 500;
				break;
			case 'large':
				this.characterLimit = 1000;
				break;
		}
		this.editor = new Editor();
		window.innerWidth <= 575 ? this.perLine = 7 : this.perLine = 9;
	}
	ngAfterViewInit(): void {
		const element = this.el.nativeElement.querySelector('.NgxEditor__Content');
		
		const observer = new MutationObserver((mutationsList, observer) => {
			mutationsList.forEach((mutation) => {
				if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
					if(element.classList.contains('ProseMirror-focused')) this.on_focus = true;
					else this.on_focus = false;
				}
			});
		});
		
		const config = { attributes: true, attributeFilter: ['class'] };
		observer.observe(element, config);
	}
	ngAfterViewChecked(): void {
		const popup = this.el.nativeElement.querySelector('.NgxEditor__Popup');
		if(popup) {
			this.el.nativeElement.querySelector('.backdrop').style.opacity = 1;
			this.el.nativeElement.querySelector('.backdrop').style.pointerEvents = 'all';
		} else {
			this.el.nativeElement.querySelector('.backdrop').style.opacity = 0;
			this.el.nativeElement.querySelector('.backdrop').style.pointerEvents = 'none';
		}
	}
	ngOnDestroy(): void {
		this.editor.destroy();
	}
	select($event): void {
		this.editor.commands.insertText($event.emoji.native).focus().exec();
		this.emojiOpen = false;
	}

	@HostListener('window:resize')
	onWindowResize() {
		window.innerWidth <= 575 ? this.perLine = 7 : this.perLine = 9;
	}

	characterCountCheck(event) {
		if(this.size) {
			this.characterCount = toHTML(event).replace(/<p[^>]*>/g, '\n').replace(/<[^>]*>/g, '').length - 1;
			
			if(this.characterCount > this.characterLimit) {
				this.characterLimitReached.emit(`Must be shorter than ${this.characterLimit + 1} characters`);
			} else {
				this.characterLimitReached.emit(null);
			}
		}
	}

	focus() {
		this.editor.commands.focus().exec();
	}
}