import { Component, OnInit, AfterViewInit, ElementRef} from '@angular/core';
import { UserService, TaxService, LoaderService } from '@services';
import { ModalService, MongoService } from 'wacom';
import { Router,ActivatedRoute } from '@angular/router';


@Component({
	selector: 'app-taxes',
	templateUrl: './taxes.component.html',
	styleUrls: ['./taxes.component.scss', '../setup.component.scss']
})
export class TaxesComponent implements OnInit, AfterViewInit {
	edit(tax){
		if(this.us.is.admin||this.us.is.owner) {
			this.router.navigate([],{ queryParams: { modal: 'open' } });
			this.modal.show({component: 'tax', profile: JSON.parse(JSON.stringify(tax))})
		}
	}
	constructor(public modal: ModalService,
		public ts: TaxService,
		public us: UserService,
		public router: Router,
		private route: ActivatedRoute,
		private loader: LoaderService,
		private mongo: MongoService,
		private eref: ElementRef) {
		this.us.headerTitle = 'Taxes';
		this.us.headerSubtitle = 'Setup';
	}
	ngOnInit() {
		this.loader.show({container: true}, this.eref.nativeElement.querySelector('.containerBox'));
	}
	ngAfterViewInit() {
		this.mongo.on('tax', () => {
			this.loader.remove();
		});
	}
	ngDoCheck(){
		if( this.route.snapshot.queryParams['modal']){
		}
		else{
			this.modal.destroy()
		}
	}
	openTax(profile = null){
		if(this.us.is.admin||this.us.is.owner) {
			this.router.navigate([],{ queryParams: { modal: 'open' } });
			this.modal.show({
				component: 'modal',
				profile: profile ? JSON.parse(JSON.stringify(profile)) : {},
				title: 'Tax',
				components: [
					{
						type: 'INPUT',
						input: 'name',
						label: 'Tax name*',
						placeholder: 'Enter tax name',
						reqText: 'Name is required',
						required: true,
					},
					{
						type: 'NUMBER',
						input: 'amount',
						label: 'Tax rate*',
						placeholder: '%',
						min: 0,
						reqText: 'Tax rate must be 0 or higher',
						required: true,
					},
				],
				alertOpts: {
					title: 'Delete tax',
					body: `Are you sure you want to delete tax ${profile?.name}?`
				},
				onDelete: (profile: any) => {
					this.ts.delete(profile, () => {
						this.modal.destroy();
					});
				},
				onSave: (profile: any) => {
					this.ts.create(profile, () => {
						this.modal.destroy();
					});
				}
			});	
		}
	}
	delete(tax) {
		if(this.us.is.admin||this.us.is.owner) {
			this.router.navigate([],{ queryParams: { modal: 'open' } });
			this.modal.show({
				component: 'deleteAlert',
				title: 'Delete tax',
				body: `Are you sure you want to delete tax ${tax.name}?`,
				on_add: ()=> {
					this.loader.show({container: true, transparent: true}, this.eref.nativeElement.querySelector('.containerBox'));
					this.ts.delete(tax, () => {
						this.loader.remove();
					});
				}
			});
		}
	}
}
