import { Injectable } from '@angular/core';
import { MongoService, AlertService } from 'wacom';

@Injectable({
	providedIn: 'root'
})
export class QuestionService {
	public questions: any = [];
	public _questions: any = {};
	constructor(private mongo: MongoService, private alert: AlertService) { 
		this.questions = mongo.get('question', {
			replace: {
				select: mongo.beObj,
				text: mongo.beObj,
				options: (val, cb)=>{
					if(!val) val=[];
					val.forEach(obj=>{
						if(!obj.select) obj.select={};
					});
					cb(val);
				}
			}
		}, (arr, obj) => {
			this._questions = obj;
		});
	}
	create(question, cb: any = () => {}) {
		if(question._id) return this.save(question, cb);
		this.mongo.create('question', question, created=>{
			if(typeof cb === 'function') cb(created);
			this.alert.show({
				text: 'Question has been created.'
			});
		}); 
	}
	update(question, cb: any = () => {}) {
		this.mongo.afterWhile(question, ()=> {
			this.save(question, cb);
		});
	}
	save(question, cb: any = () => {}){
		this.mongo.update('question', question, (updated)=>{
			if(typeof cb === 'function') cb(updated);
			this.alert.show({
				text: 'Question has been updated.'
			});
		});
	}
	delete(question, cb: any = () => {}) {
		this.mongo.delete('question', question, (deleted)=>{
			if(typeof cb === 'function') cb(deleted);
			this.alert.show({
				text: 'Question has been deleted.'
			});
		});
	}
}
