import { Component, Input, OnInit, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
@Component({
	selector: 'donat-chart',
	templateUrl: './donat-chart.component.html',
	styleUrls: ['./donat-chart.component.scss']
})
export class DonatChartComponent implements OnInit, OnChanges {
	@Input('total') Input_total: number;
	@Input('items') Input_items: any = [];
	@Input() sort: boolean = false;
	@Input() legendPosition: string = 'top'; // top, left

	public colors: any = ['#6AC4B3', '#FFC300', '#E47878', '#B380C4', '#45C2E6'];

	public items: any = [];

	public svg_items: any = [];

	public total;

	constructor(private eref: ElementRef) {}
	
	ngOnInit(): void {
		this.initialize();
	}

	ngOnChanges(): void {
		this.initialize();
	}

	initialize() {
		this.svg_items = [];
		this.total = this.addAbbreviation(this.Input_total);
		const round_r = 5;
		this.items = this.Input_items.slice();
		if(this.sort) this.items.sort((a, b) => b.value - a.value);
		this.items = this.items.map((e, i) => {
			e.color = this.colors[i];
			e.id = Math.floor(Math.random() * 10000);
			return e;
		});
		const buf = this.items.slice();
		buf.reverse();
		for(let i = 0; i < buf.length; i++) {
			let percentage = Number((buf[i].value * 100 / this.Input_total).toFixed(1));
			if(buf[i].value > 0) {
				let next_value = 0;
	
				for(let j = i + 1; j < buf.length; j++) {
					next_value += buf[j].value;
				}
	
	
				let d = 'M 0,0 L ';
	
				let r1 = 89.5 + ((112.5 - 89.5) / buf.length * (i + 1));
				let r2 = r1 - round_r;
	
				let a = Math.asin(round_r / r2);
				
				let start_angle = ((next_value - next_value * 50 / 100) * 360) / this.Input_total;
				let angle = ((buf[i].value + next_value * 50 / 100) * 360) / this.Input_total;

				if(angle < 1) angle = 5;
	
				if(Math.ceil(start_angle + angle) >= 360) {
					angle += 10;
				}
	
				let sector_start = this.getPointOnCircle(r1, start_angle);
				if (start_angle == 0) {
					d += `0,-${r2 * (Math.cos(a))} A ${round_r},${round_r} 0 0 1 ${r1 * Math.sin(a)},-${r1 * Math.cos(a)} `;
				} else {
					d += `${sector_start.x},${sector_start.y} A `;
				}
				d += `${r1},${r1} 0 0 1 `;
	
				if (angle > 180) d += `${this.getPointOnCircle(r1, start_angle + angle / 3).x},${this.getPointOnCircle(r1, start_angle + angle / 3).y} ${r1},${r1} 0 0 1 ${this.getPointOnCircle(r1, start_angle + angle / 3 * 2).x},${this.getPointOnCircle(r1, start_angle + angle / 3 * 2).y} ${r1},${r1} 0 0 1 `;
	
				let arc_end = this.getPointOnCircle(r1, start_angle + angle - a * (180 / Math.PI));
				let round_end = this.getPointOnCircle(r2 * Math.cos(a), start_angle + angle);
				d += `${arc_end.x},${arc_end.y} ${round_r},${round_r} 0 0 1 ${round_end.x},${round_end.y} z`;
	
				if (start_angle == 0) {
					var animate = `M 0,0 L 0,-${(62 - round_r) * (Math.cos(Math.asin(round_r / (62 - round_r))))} A ${round_r},${round_r} 0 0 1 ${62 * Math.sin(Math.asin(round_r / (62 - round_r)))},-${62 * Math.cos(Math.asin(round_r / (62 - round_r)))}`;
				} else {
					var animate = `M 0,0 L ${this.getPointOnCircle(62, start_angle).x},${this.getPointOnCircle(62, start_angle).y} A`;
				}
				if (angle > 180) animate += ` ${62},${62} 0 0 1 ${this.getPointOnCircle(62, start_angle + angle / 3).x},${this.getPointOnCircle(62, start_angle + angle / 3).y} ${62},${62} 0 0 1 ${this.getPointOnCircle(62, start_angle + angle / 3 * 2).x},${this.getPointOnCircle(62, start_angle + angle / 3 * 2).y}`;
				animate += ` 62,62 0 0 1 ${this.getPointOnCircle(62, start_angle + angle - a * (180 / Math.PI)).x},${this.getPointOnCircle(62, start_angle + angle - a * (180 / Math.PI)).y} ${round_r},${round_r} 0 0 1 ${this.getPointOnCircle((62 - round_r) * Math.cos(a), start_angle + angle).x},${this.getPointOnCircle((62 - round_r) * Math.cos(a), start_angle + angle).y} z; ${d};`;

				this.svg_items.push({
					id: buf[i].id, 
					name: buf[i].name,
					percentage: percentage ? percentage + '%' : '0%',
					d: d, 
					color: buf[i].color, 
					animate: animate
				});			
			} else {
				this.svg_items.push({
					id: buf[i].id, 
					name: buf[i].name,
					percentage: percentage ? percentage + '%' : '0%',
				});
			}
		}
		let interval = setInterval(() => {
			if(this.eref.nativeElement.querySelectorAll('[id^="path_"]').length === this.svg_items.filter(i => i.d).length && this.svg_items.filter(i => i.d).length != 0) {
				clearInterval(interval);
				this.startAnimation();
			}
		}, 10);
	}

	getPointOnCircle(radius, angleDegrees) {
		var angleRadians = ((Math.round(angleDegrees) - 90) * Math.PI) / 180;
	  
		var x = radius * Math.cos(angleRadians);
		var y = radius * Math.sin(angleRadians);
		
		return { x: x.toFixed(2), y: y.toFixed(2) };
	}

	showInfo(id) {
		this.eref.nativeElement.querySelector('#hide_total').style.display = 'block';
		this.eref.nativeElement.querySelector('#percentage_'+id).style.display = 'block';
		this.eref.nativeElement.querySelector('#name_'+id).style.display = 'block';
		if (this.eref.nativeElement.querySelector('#path_'+id)) {
			this.eref.nativeElement.querySelector('#path_'+id).classList.add('sector_active');
			// for(let item of this.items) {
			// 	if(item.id != id && document.getElementById('path_'+item.id)) document.getElementById('path_'+item.id).classList.add('sector_not_active');
			// }
		}
	}

	hideInfo(id) {
		this.eref.nativeElement.querySelector('#hide_total').style.display = 'none';
		this.eref.nativeElement.querySelector('#percentage_'+id).style.display = 'none';
		this.eref.nativeElement.querySelector('#name_'+id).style.display = 'none';
		if (this.eref.nativeElement.querySelector('#path_'+id)) {
			this.eref.nativeElement.querySelector('#path_'+id).classList.remove('sector_active');
			// for(let item of this.items) {
			// 	if(item.id != id && document.getElementById('path_'+item.id)) document.getElementById('path_'+item.id).classList.remove('sector_not_active');
			// }
		}
	}

	startAnimation() {
		this.eref.nativeElement.querySelectorAll("animate").forEach((element) => {
			element.beginElement();
		});
	}

	addAbbreviation(amount) {
		const abbreviations = ['', 'K', 'M', 'B', 'T'];
		let index = 0;
	  
		while (amount >= 1000 && index < abbreviations.length - 1) {
			amount /= 1000;
			index++;
		}
	  
		return Number(amount.toFixed(2)) + abbreviations[index];
	}
}
