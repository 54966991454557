import { Component, ElementRef, OnInit } from '@angular/core';
import { AlertService, ModalService } from 'wacom';
import { toHTML } from 'ngx-editor';
import { LoaderService } from '@services';

@Component({
	selector: 'modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit{
	public profile: any = {};
	public close = () => {};
	public onSave = (profile: any) => {};
	public onDelete = (profile: any) => {};
	public closeAutomatically: Boolean = false;
	public possibleToDelete: Boolean = true;
	public saveButton: string = '';
	public submitted: Boolean;
	public title: string;
	public preTitle: Boolean = true;
	public components: any = [];
	private alertOpts: any = {};
	public noteEditor: any;
	public characterLimit: string = null;
	constructor(
		public alert: AlertService,
		public modal: ModalService,
		private loader: LoaderService,
		private eref: ElementRef) {}
	ngOnInit(): void {
		const editor = this.components.find((c: any) => c.type === 'EDITOR' );
		if(editor) {
			if (this.profile[editor.input]) this.noteEditor = JSON.parse(this.profile[editor.input]);	
		}
	}
	save() {
		this.submitted = true; 
		const editor = this.components.find((c: any) => c.type === 'EDITOR' );
		if( this.noteEditor && editor ) {
			this.profile[editor.input] = JSON.stringify(this.noteEditor);
			if( toHTML(this.noteEditor) === '<p></p>' ) this.profile[editor.input] = '';
		}
		if( editor && editor.required && ( !this.profile[editor.input] || toHTML(this.noteEditor) === '<p></p>' ) ) {
			this.profile[editor.input] = '';
			return this.alert.error({
				text: "Please fill required fields",
			});
		}
		for(let component of this.components) {
			if(component.type == "NUMBER" && component.required && ( this.profile[component.input] == null || this.profile[component.input] < component.min )) return this.alert.error({
				text: "Please fill required fields",
			});
			if(component.type != "NUMBER" && component.required && !this.profile[component.input]?.trim()) return this.alert.error({
				text: "Please fill required fields",
			});
		}
		if(this.characterLimit) return this.alert.error({
			text: "Exceeded maximum character limit",
		});

		this.loader.show({ 
			modal: true,
			transparent: true,
			preventClick: true
		}, this.eref.nativeElement.querySelector('.modal-box'));

		if(this.closeAutomatically) this.close();
		this.onSave(this.profile);
	}
	delete() {
		if(this.alertOpts?.title || this.alertOpts?.body) {
			this.modal.show({
				component: 'deleteAlert',
				title: this.alertOpts?.title,
				body: this.alertOpts?.body,
				on_add: ()=> {
					this.loader.show({ 
						modal: true,
						transparent: true,
						preventClick: true
					}, this.eref.nativeElement.querySelector('.modal-box'));
					
					if(this.closeAutomatically) this.close();
					this.onDelete(this.profile);
				}
			});
		} else {
			if(this.closeAutomatically) this.close();
			this.onDelete(this.profile);
		}
	}
}
