<div class="select-container" cdkOverlayOrigin #select="cdkOverlayOrigin">
    <ng-template #defaultSelect>
        <div class="select" [class._open]="open" [class._disabled]="disabled" [class._icon]="icon" [class._req]="required" [ngClass]="[class || '', icon || '']" (click)="!disabled ? (open = true) : open = false">
            <div *ngIf="(!multiple && !value) || (multiple && !getItem()?.length)" class="select__placeholder">{{placeholder}}</div>
            <div *ngIf="(!multiple && value) || (multiple && getItem()?.length)" class="select__value" [class._multiple]="multiple">
                <ng-template #defaultLabel>
                    <ng-template #singleLabel>
                        {{getValue()}}
                    </ng-template>
                    <ng-template #multipleLabel>
                        <ng-template #more>
                            <div class="select__value-first">{{getValue()}}</div>
                            <div class="select__value-more" *ngIf="getItem().length > 1">+ {{getItem().length - 1}}</div>
                        </ng-template>
                        <ng-template #all>
                            <div class="select__value-all">{{getItem().length}}</div>
                        </ng-template>
                        <ng-container
                            *ngIf="width > 200 || getItem().length == 1; then more; else all"
                        ></ng-container>
                    </ng-template>
                    <ng-container
                        *ngIf="multiple; then multipleLabel; else singleLabel"
                    ></ng-container>
                </ng-template>
                <ng-template #customLabel>
                    <ng-container
                        *ngTemplateOutlet="custom_label; context: {$implicit: getItem()}"
                    ></ng-container>
                </ng-template>
                <ng-container
                    *ngIf="custom_label; then customLabel; else defaultLabel"
                ></ng-container>
            </div>   
            <button mat-icon-button color="primary" type="button" [disabled]="disabled" class="btn" (click)="!disabled ? (open = true) : (open = false)">
                <span class="btn-icon icon-arrow_down" style="display: block; transition: 0.15s all ease-in-out;" [ngStyle]="{'transform': open ? 'rotate(-180deg)' : 'rotate(0deg)' }"></span>
            </button>     
        </div>
    </ng-template>
    <ng-template #customSelect>
        <div (click)="!disabled ? (open = true) : open = false">
            <ng-container
                *ngTemplateOutlet="custom_select; context: {$implicit: getItem(), open: open, disabled: disabled, required: required}"
            ></ng-container>
        </div>
    </ng-template>
    <ng-container
        *ngIf="custom_select; then customSelect; else defaultSelect"
    ></ng-container>
</div>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="select"
    [cdkConnectedOverlayOpen]="open && !disabled"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'picker-overlay-backdrop'"
    [cdkConnectedOverlayPanelClass]="'panelClass'"
    [cdkConnectedOverlayFlexibleDimensions]="false"
    [cdkConnectedOverlayMinWidth]="width"
    [cdkConnectedOverlayMinHeight]="height"
    (positionChange)="positionChange($event)"
    (backdropClick)="open=false; $event.stopPropagation()"
    (detach)="open=false; position = 'positionBottom'"
    (attach)="scrollToOption()"
>
    <div class="select-picker" id="selectPicker" [ngStyle]="{'width': !us.mobile ? width + 'px' : ''}" [class._searchable]="searchable" [class._clearable]="clearable" [class._multiple]="multiple" [ngClass]="[pickerClass || '']" [@modal]="us.mobile ? (open ? 'zoom' : open) : (open ? position : open)" (click)="$event.stopPropagation();">
        <div class="select-search" *ngIf="searchable">
            <input class="select-search__input" type="text" placeholder="Search" [(ngModel)]="search">
        </div>
        <div class="select-list">
            <div class="select-list__empty" *ngIf="!getItems()?.length">No items</div>
            <ng-container *ngFor="let item of getItems()">
                <ng-template #defaultOption>
                    <ng-template #singleOption>
                        <div class="select-list__item" [class._active]="isActive(item)" [class._disabled]="item[bindDisabled]" (click)="onSelect(item)">
                            {{bindLabel ? item[bindLabel] : item}}
                        </div>
                    </ng-template>
                    <ng-template #multipleOption>
                        <label class="select-list__item multiple" [class._active]="isActive(item)" [class._disabled]="item[bindDisabled]">
                            <input type="checkbox" [ngModel]="isActive(item)" (ngModelChange)="onSelect(item);" hidden>
                            <span class="checkbox"></span>
                            <span class="select-list__item-name">{{bindLabel? item[bindLabel] : item}}</span>
                        </label>
                    </ng-template>
                    <ng-container
                        *ngIf="multiple; then multipleOption; else singleOption"
                    ></ng-container>
                </ng-template>
                <ng-template #customOption>
                    <div class="select-list__item" [class._active]="isActive(item)" [class._disabled]="item[bindDisabled]" (click)="onSelect(item)">
                        <ng-container
                            *ngTemplateOutlet="custom_option; context: {$implicit: item}"
                        ></ng-container>
                    </div>
                </ng-template>
                <ng-container
                    *ngIf="custom_option; then customOption; else defaultOption"
                ></ng-container>
            </ng-container>
        </div>
        <div class="select-actions" *ngIf="multiple || clearable">
            <button mat-button type="button" *ngIf="select_all && multiple" (click)="selectAll(); $event.stopPropagation()">Select all</button>
            <button mat-button type="button" *ngIf="clearable" (click)="clear(); $event.stopPropagation()">Clear {{multiple ? 'all' : ''}}</button>
            <button mat-flat-button color="primary" type="button" *ngIf="multiple" (click)="open = false; $event.stopPropagation()">Ok</button>
        </div>
    </div>
</ng-template>
