import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';
import { ClosedService } from '../services/closed.service';

@Pipe({
	name: 'workingUsers'
})
export class WorkingusersPipe implements PipeTransform {
    constructor (private cs: ClosedService, private datePipe: DatePipe) {}

    isClosedDate(staff, date) {
		const d = new Date(date);
		d.setHours(0, 0, 0, 0);
		for (let i = this.cs.closeds.length-1; i >= 0; i--){
			for (let location of staff.location) {
				if(
					this.cs.closeds[i].locations.find(l => l == location) && 
					(
						(
							!this.cs.closeds[i].holiday &&
							new Date(this.cs.closeds[i].start?.singleDate?.formatted) <= new Date(d) && 
							new Date(this.cs.closeds[i].end?.singleDate?.formatted) >= new Date(d)
						) ||
						(
							this.cs.closeds[i].holiday &&
							this.cs.isHoliday(new Date(d), this.cs.closeds[i].holiday, this.cs.closeds[i].substitute )
						)
					)
				) {
					return this.cs.closeds[i];
				}
			}
		}
		return false;
	}

	transform(users:any, date, refresh?:any){
        if(!users) users=[];
		if(!date) return users;
		users = users.slice();
		if(date) {
            users = users.filter(user => {
                return !this.isClosedDate(user, date) && user.data.working_hours && ( ( user.data.working_hours.default && user.data.working_hours.default?.[this.datePipe.transform(date, 'EEEE')]?.length && !user.data.working_hours[this.datePipe.transform(date,'M/d/yyyy')]?.vacation ) || ( user.data.working_hours[this.datePipe.transform(date,'M/d/yyyy')]?.hours?.length && !user.data.working_hours[this.datePipe.transform(date,'M/d/yyyy')]?.vacation ) );
            });
        }
		return users;
	}
}