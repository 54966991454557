import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public error: any = {
        email: '',
        password: '',
        rpassword: '',
    };
    public submitted: any = {
        email: false,
        password: false,
        rpassword: false,
    };
    public check: any = {
        email: false,
        password: false,
        rpassword: false,
    };
    public visibility: boolean = false;
    public show_visibility_btn: boolean = false;
    public visibility_repeat: boolean = false;
    public show_visibility_btn_repeat: boolean = false;
    public password_verification: any = {
        uppercase: false,
        number: false,
        special: false,
        minimum: false,
    };
    public show_verification_block: boolean = false;
    public passwordRegex: RegExp = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>?/])[A-Za-z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>?/]{8,}$/;
    public passwordLengthRegex: RegExp = /^.{8,}$/;

    reset() {
        this.error = {
            email: '',
            password: '',
            rpassword: '',
        };
        this.submitted = {
            email: false,
            password: false,
            rpassword: false,
        };
        this.check = {
            email: false,
            password: false,
            rpassword: false,
        };
        this.visibility = false;
        this.show_visibility_btn = false;
        this.visibility_repeat = false;
        this.show_visibility_btn_repeat = false;
        this.password_verification = {
            uppercase: false,
            number: false,
            special: false,
            minimum: false,
        };
        this.show_verification_block = false;
    }
    
    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    
    validateInputEmail(event: Event, submitted: boolean = false) {
        const value = (event.target as HTMLInputElement).value.trim();
        if(submitted) this.submitted.email = submitted;

        if(!!value) {
            if (!this.validateEmail(value)) {
                if(this.submitted.email) {
                    this.error.email = 'Invalid email address';
                    this.check.email = false;
                }
            } else {
                this.error.email = '';
                this.check.email = true;
            }
        } else if(this.submitted.email) {
            this.error.email = 'Enter your email';
            this.check.email = false;
        }
    }

    validateInputPassword(event = null) {
        if(event && event.relatedTarget && event.relatedTarget.nodeName == 'BUTTON' && event.relatedTarget.classList.contains('visibility')) {
            event.target.focus();
        } else {
            this.error.password = '';
            this.submitted.password = true;
        }
    }

    validateNewPassword(password, event = null) {
        if(event && event.relatedTarget && event.relatedTarget.nodeName == 'BUTTON' && event.relatedTarget.classList.contains('visibility')) {
            event.target.focus();
        }

        this.submitted.password = true;

        const allowedChars = /[A-Za-z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>?/]/;
        let invalidChars = [];

        for (let char of password) {
            if (!allowedChars.test(char)) {
                invalidChars.push(char);
            }
        }

        if(this.passwordRegex.test(password)) {
            this.error.password = '';
            this.check.password = true;
        } else {
            this.check.password = false;
            if(!!password.trim()) {
                this.error.password = ' ';
            } else {
                this.error.password = '';
            }
        }
        
        // one uppercase character
        if(/[A-Z]/.test(password)) { 
            this.password_verification.uppercase = true;
        } else {
            this.password_verification.uppercase = false;
        }
        
        // one number;
        if(/\d/.test(password)) { 
            this.password_verification.number = true;
        } else {
            this.password_verification.number = false;
        }
        
        // one special character
        if(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>?/]/.test(password)) { 
            this.password_verification.special = true;
        } else {
            this.password_verification.special = false;
        }
        
        // 8 character minimum
        if(/^.{8,}$/.test(password)) { 
            this.password_verification.minimum = true;
        } else {
            this.password_verification.minimum = false;
        }

        if (invalidChars.length > 0) {
            this.check.password = false;
            if(invalidChars[0] == ' ') {
                this.error.password = `Space cannot be used`;
            } else {
                this.error.password = `'${invalidChars[0]}' is an invalid character`;
            }
        }
    }

    validateConfirmPassword(password, rpassword, submitted = false, event = null) {
        if(event && event.relatedTarget && event.relatedTarget.nodeName == 'BUTTON' && event.relatedTarget.classList.contains('visibility')) {
            event.target.focus();
        }

        if(submitted) this.submitted.rpassword = true;

        if(this.submitted.rpassword) {
            if(!rpassword?.trim()) {
                this.check.rpassword = false;
                this.error.rpassword = '';
                return;
            }

            if(password != rpassword) {
                this.check.rpassword = false;
                this.error.rpassword = 'Passwords do not match';
            } else {
                this.check.rpassword = true;
                this.error.rpassword = '';
            }
        }
    }

    changeVisibility(repeat = false) {
        if(!repeat) {
            this.visibility = !this.visibility; 
            const input = document.querySelector('input[name=password]') as HTMLInputElement;
            const selectionStart = input.selectionStart;
            input.type = this.visibility ? 'text' : 'password';
            if(document.activeElement == input) {
                setTimeout(() => {
                    input.focus();
                    input.setSelectionRange(selectionStart, selectionStart);
                }, 1);
            }
        } else {
            this.visibility_repeat = !this.visibility_repeat; 
            const input = document.querySelector('input[name=rpassword]') as HTMLInputElement;
            const selectionStart = input.selectionStart;
            input.type = this.visibility_repeat ? 'text' : 'password';
            if(document.activeElement == input) {
                setTimeout(() => {
                    input.focus();
                    input.setSelectionRange(selectionStart, selectionStart);
                }, 1);
            }
        }
    }
}
