<div class="modal-box">
	<div class="modal-title t-hide">{{preTitle ? (profile._id&&'Edit'||'New') : ''}} {{title}}</div>
	<button mat-icon-button type="button" class="modal-close" (click)="close()">
		<span class="modal-close__icon"></span>
	</button>
	<form NgForm (ngSubmit)="save()">
		<div class="row">
			<ng-container *ngFor="let component of components">
				<ng-container *ngIf="component.customRef">
					<ng-container *ngTemplateOutlet="component.customRef; 
						context: {
							$implicit: {
								component: component,
								profile: profile,
								submitted: submitted
							}
						}
					"
					></ng-container>
				</ng-container>
	
				<ng-container *ngIf="!component.customRef">
					<ng-container [ngSwitch]="component.type">
						<ng-container *ngSwitchCase="'EDITOR'">
							<div class="waw-textarea" [ngClass]="[ component.class ? component.class : 'col-1' ]">
								<div [class.req]="component.required && submitted && !profile[component.input]?.trim() || characterLimit">
									<note-editor [notes]="noteEditor" [placeholder]="component.placeholder" [label]="component.label" [size]="component.size" (characterLimit)="characterLimit = $event" (onChange)="noteEditor = $event"></note-editor>
									<div *ngIf="component.required && submitted && !profile[component.input]?.trim() || characterLimit" class="req-text">{{ characterLimit ? characterLimit : component.reqText }}</div>
								</div>
							</div>
						</ng-container>
						<ng-container *ngSwitchCase="'NUMBER'">
							<div class="waw-input" [ngClass]="[ component.class ? component.class : 'col-1' ]">
								<label [class.req]="component.required && submitted && (!profile[component.input] || profile[component.input] < component.min)">
									<span class="waw-input__title">{{ component.label }}</span>
									<input type="number" [placeholder]="component.placeholder" [name]="component.input" [(ngModel)]="profile[component.input]">
									<div *ngIf="component.required && submitted && (!profile[component.input] || profile[component.input] < component.min)" class="req-text">{{ component.reqText }}</div>
								</label>
							</div>
						</ng-container>
						<ng-container *ngSwitchCase="'SELECT'">
							<div class="waw-select" [ngClass]="[ component.class ? component.class : 'col-1' ]">
								<span class="waw-select__title">{{ component.label }}</span>
								<app-select 
									[placeholder]="component.placeholder" 
									[(value)]="profile[component.input]"
									[items]="component.items" 
									[clearable]="component.clearable" 
									[searchable]="component.searchable"
									[multiple]="component.multiple"
									[required]="component.required && submitted && (!component.multiple ? !profile[component.input] : !profile[component.input].length)"
									[bindValue]="component.bindValue" 
									[bindLabel]="component.bindLabel"
								></app-select>
								<div *ngIf="component.required && submitted && (!component.multiple ? !profile[component.input] : !profile[component.input].length)" class="req-text">{{ component.reqText }}</div>
							</div>
						</ng-container>
						<ng-container *ngSwitchDefault>
							<div class="waw-input" [ngClass]="[ component.class ? component.class : 'col-1' ]">
								<label [class.req]="component.required && submitted && !profile[component.input]?.trim()">
									<span class="waw-input__title">{{ component.label }}</span>
									<input type="text" [placeholder]="component.placeholder" [name]="component.input" [(ngModel)]="profile[component.input]">
									<div *ngIf="component.required && submitted && !profile[component.input]?.trim()" class="req-text">{{ component.reqText }}</div>
								</label>
							</div>
						</ng-container>
					</ng-container>
				</ng-container>
			</ng-container>
		</div>
		<div class="modal-btn">
			<button mat-button color="primary" type="button" *ngIf="!profile._id || !possibleToDelete" (click)="close()" class="waw-btn _second">Cancel</button>
			<button mat-button color="primary" type="button" *ngIf="profile._id && possibleToDelete" (click)="delete()" class="waw-btn _second _delete">Delete</button>
			<button mat-flat-button color="primary" type="submit" class="waw-btn _primary">{{!saveButton ? (profile._id&&'Save'||'Create') : saveButton}}</button>
		</div>
	</form>
</div>
