<div class="dashboard">
	<div class="dashboard-card" style="grid-area: appointments">
		<div class="fl-sb-c mb15">
			<div class="dashboard-card__title">
				This {{interval}}
			</div>
		</div>
		<div class="dashboard-card__content bookings-total-amount">
			<div class="bookings-total-amount__info">
				<div class="bookings-total-amount__info-current mb20">
					<span class="bookings-total-amount__info__value">{{(aps._appointments.date|appointmentsDate:selector:location:aps.now)?.length}}</span>
					<span class="bookings-total-amount__info__units">appointments</span>
				</div>
				<div class="ai-c">
					<span class="bookings-total-amount__info__difference">{{differenceAppts}} previous</span>
				</div>
			</div>
			<div class="bookings-total-amount__chart">
				<ng-template [ngTemplateOutlet]="lineChart" [ngTemplateOutletContext]="{points: chartAppts}"></ng-template>
			</div>
		</div>
	</div>
	<div class="dashboard-card" style="grid-area: revenue">
		<div class="fl-sb-c mb15">
			<div class="dashboard-card__title">Revenue</div>
		</div>
		<div class="dashboard-card__content revenue">
			<div class="revenue-info">
				<span class="revenue-info__amount">{{revenue}}</span>
				<div class="revenue-info__chart">
					<ng-template [ngTemplateOutlet]="barChart" [ngTemplateOutletContext]="{svg: chartRevenue}"></ng-template>							
				</div>
			</div>
		</div>
	</div>
	<div class="dashboard-card" style="grid-area: occupancy">
		<div class="fl-sb-c mb15">
			<div class="dashboard-card__title">
				Occupancy
			</div>
		</div>
		<div class="dashboard-card__content occupancy">
			<div class="occupancy__info">
				<div class="occupancy__info-value">{{occupancy.value}}</div>
				<div class="occupancy__info-difference">{{occupancy.difference}} previous</div>
			</div>
			<div class="occupancy__chart">
				<ng-template [ngTemplateOutlet]="circularChart" [ngTemplateOutletContext]="{percentage: occupancy.percentage}"></ng-template>
			</div>
		</div>
	</div>
	<div class="dashboard-card" style="grid-area: clients">
		<div class="fl-sb-c mb15">
			<div class="dashboard-card__title">
				Clients
			</div>
		</div>
		<div class="dashboard-card__content clients">
			<div class="clients-info">
				<span class="clients-info__current">{{clients.total}}</span>
				<span class="clients-info__difference">{{clients.difference}} previous</span>
			</div>
			<div class="clients-chart">
				<ng-template [ngTemplateOutlet]="horizontalChart" [ngTemplateOutletContext]="{item_1: {name: 'returning', value: clients.returning}, item_2: {name: 'First Visit', value: clients.new}}"></ng-template>
			</div>
		</div>
	</div>
	<div class="dashboard-card" style="grid-area: bookings">
		<div class="fl-sb-c mb10">
			<div class="dashboard-card__title">Popular Bookings</div>
		</div>
		<div class="dashboard-card__content popular">
			<ng-container *ngFor="let service of top_services; index as i">
				<div class="popular-item">
					<div class="popular-item__name" [ngStyle]="{'white-space': open_service[service.id] ? 'wrap' : 'nowrap'}">{{service.name}}</div>
					<button class="popular-item__show-hide" (click)="open_service[service.id] ? open_service[service.id] = false : open_service[service.id] = true">
						<i *ngIf="!open_service[service.id]" class="material-icons">expand_more</i>
						<i *ngIf="open_service[service.id]" class="material-icons">expand_less</i>
					</button>
					<div class="popular-item__info" [class._open]="open_service[service.id]">
						<div class="popular-item__info-item">
							<span class="popular-item__info-item__value">{{ service.bookings }}</span>
							<span class="popular-item__info-item__units">Bookings</span>
						</div>
						<div class="popular-item__info-item">
							<span class="popular-item__info-item__value">{{ service.price_1 | currency }}{{ service.price_2 ? ' - ' + (service.price_2 | currency) : '' }}</span>
							<span class="popular-item__info-item__units">Price</span>
						</div>
						<div class="popular-item__info-item">
							<span class="popular-item__info-item__value">{{ service.revenue | currency }}</span>
							<span class="popular-item__info-item__units">Revenue</span>
						</div>
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="!top_services?.length"> 
				<ng-container *ngFor="let item of ui.arr(5)">
					<div class="popular-item _empty"></div>
				</ng-container>
			</ng-container>
		</div>
	</div>
	<div class="dashboard-card" style="grid-area: staff">
		<div class="fl-sb-c mb10">
			<div class="dashboard-card__title">Staff Occupancy</div>
		</div>
		<div class="dashboard-card__content">
			<ng-container *ngFor="let staff of topStaff">
				<div class="staff">
					<div class="staff-info ai-c mr10">
						<div class="staff-info__img mr10">
							<picture [user]="staff"></picture>
						</div>
						<div class="staff-info__name">{{ staff.name }}</div>
					</div>
					<div class="staff-occupancy">{{ staff.occupancy }}%</div>
				</div>
			</ng-container>
			<ng-container *ngIf="!topStaff?.length"> 
				<ng-container *ngFor="let item of ui.arr(5)">
					<div class="staff _empty"></div>
				</ng-container>
			</ng-container>
		</div>
	</div>
	<div class="dashboard-card" style="grid-area: new_clients">
		<div class="fl-sb-c mb15">
			<div class="dashboard-card__title">
				New Clients
			</div>
		</div>
		<div class="dashboard-card__content new-clients" [ngStyle]="{'grid-template-columns': new_clients?.length ? 'repeat(' + new_clients_columns + ', 100%)' : '100%'}">
			<ng-container *ngFor="let columns of ui.arr(new_clients_columns); index as i">
				<div class="new-clients-column">
					<ng-container *ngFor="let client of new_clients; index as j">
						<ng-container *ngIf="j >= i * 10 && j < i * 10 + 10">
							<div class="new-clients__client" (click)="openClient(client)">
								<div class="new-clients__client__img mr10">
									<picture [user]="client"></picture>
								</div>
								<div class="new-clients__client__name">{{ client.name }}</div>
							</div>
						</ng-container>
					</ng-container>
				</div>
			</ng-container>
			
			<ng-container *ngIf="!new_clients?.length"> 
				<div class="new-clients-column">
					<ng-container *ngFor="let item of ui.arr(5)">
						<div class="new-clients__client _empty"></div>
					</ng-container>
				</div>
			</ng-container>
		</div>
	</div>
	<div class="dashboard-card" style="grid-area: top_clients">
		<div class="fl-sb-c mb15">
			<div class="dashboard-card__title">
				Top 10 Clients by Revenue
			</div>
		</div>
		<div class="dashboard-card__content">
			<app-table [columns]="topClientsColumns" [rows]="topClientsRows" [pagination]="false">
				<ng-template cell="client" let-element>
					<div class="client">
						<div class="client__img">
							<picture [user]="element?.client"></picture>
						</div>
						<div class="client__name">
							{{element?.client?.name}}
						</div>
					</div>
				</ng-template>
				<ng-template cell="revenue" let-element>
					{{element?.revenue | currency}}
				</ng-template>
			</app-table>
		</div>
	</div>
	<div class="dashboard-card" style="grid-area: products">
		<div class="fl-sb-c mb10">
			<div class="dashboard-card__title">Popular Products</div>
		</div>
		<div class="dashboard-card__content popular">
			<ng-container *ngFor="let product of top_products">
				<div class="popular-item">
					<div class="popular-item__name" [ngStyle]="{'white-space': open_product[product.id] ? 'wrap' : 'nowrap'}">{{product.name}}</div>
					<button class="popular-item__show-hide" (click)="open_product[product.id] ? open_product[product.id] = false : open_product[product.id] = true">
						<i *ngIf="!open_product[product.id]" class="material-icons">expand_more</i>
						<i *ngIf="open_product[product.id]" class="material-icons">expand_less</i>
					</button>
					<div class="popular-item__info" [class._open]="open_product[product.id]">
						<div class="popular-item__info-item">
							<span class="popular-item__info-item__value">{{ product.items }}</span>
							<span class="popular-item__info-item__units">Items Sold</span>
						</div>
						<div class="popular-item__info-item">
							<span class="popular-item__info-item__value">{{ product.price_1 | currency }} {{ product.price_2 ? ' - ' + (product.price_2 | currency) : ''}}</span>
							<span class="popular-item__info-item__units">Price</span>
						</div>
						<div class="popular-item__info-item">
							<span class="popular-item__info-item__value">{{ product.revenue | currency }}</span>
							<span class="popular-item__info-item__units">Revenue</span>
						</div>
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="!top_products?.length"> 
				<ng-container *ngFor="let item of ui.arr(5)">
					<div class="popular-item _empty"></div>
				</ng-container>
			</ng-container>
		</div>
	</div>
</div>

<!-- 
SVG line chart 80 x 70
-->
<ng-template #lineChart let-points="points">
	<svg viewBox="0 0 80 70" class="chart" xmlns="http://www.w3.org/2000/svg">
		<polyline fill="none" stroke-width="2" [attr.points]="points" class="polyline _animation" width="100%" height="100%" />
	</svg>
</ng-template>

<!-- 
SVG bar chart 110 x 70
-->
<ng-template #barChart let-svg="svg">
	<svg [attr.width]="svg.width || '110'" [attr.height]="svg.height || '70'" [attr.viewBox]="'0 0 ' + (svg.width || '110') + ' ' + (svg.height || '70')" fill="none" class="chart" xmlns="http://www.w3.org/2000/svg">
		<line *ngFor="let line of svg.lines" [attr.x1]="line.x1" [attr.y1]="line.y1" [attr.x2]="line.x2" [attr.y2]="line.y2" stroke-width="5" stroke-linecap="round" class="line _animation" />
	</svg>	
</ng-template>

<!-- 
SVG circular chart 70 x 70
-->
<ng-template #circularChart let-percentage="percentage">
	<svg width="70" height="70" viewBox="0 0 36 36" class="circular-chart">
		<path fill="none" stroke="#EEEEEE" stroke-width="3.8" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>
		<path *ngIf="percentage" class="circle _animation" fill="none" stroke="#6AC4B3" stroke-width="3.8" stroke-linecap="round" [attr.stroke-dasharray]="percentage + ', 100'" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>
	</svg>
</ng-template>

<!-- 
	SVG horizontal chart 
-->
<ng-template #horizontalChart let-item_1="item_1" let-item_2="item_2">
	<div class="horizontal-chart">
		<div class="horizontal-chart-legend">
			<div class="horizontal-chart-legend__item">
				<span class="horizontal-chart-legend__item-value">{{item_1.value}}</span>
				<span class="horizontal-chart-legend__item-name">{{item_1.name}}</span>
			</div>
			<div class="horizontal-chart-legend__item">
				<span class="horizontal-chart-legend__item-name">{{item_2.name}}</span>
				<span class="horizontal-chart-legend__item-value">{{item_2.value}}</span>
			</div>
		</div>
		<div class="horizontal-chart-body">
			<div class="horizontal-chart-body__stripe _left _animation" [ngStyle]="{'width': ((item_1.value * 100 / (item_1.value + item_2.value )) || 0) + '%'}"></div>
			<div class="horizontal-chart-body__stripe _right _animation" [ngStyle]="{'width': (((item_1.value + item_2.value) * 100 / (item_1.value + item_2.value )) || 0) + '%'}"></div>
		</div>
	</div>
</ng-template>
