import { Injectable } from '@angular/core';
import { MongoService, AlertService } from 'wacom';

@Injectable({
	providedIn: 'root'
})
export class WebsiteService {
	public websites: any = [];
	public _websites: any = {};
	constructor(private alert: AlertService,private mongo: MongoService) { 
		this.websites = mongo.get('website', (arr, obj) => {
			this._websites = obj;
		});
	}
	create(website) {
		if(website._id) return this.update(website);
		this.mongo.create('website', website, ()=>{
			this.alert.show({
				text: 'Website has been created.'
			});
		}); 
	}
	update(website) {
		this.mongo.afterWhile(website, ()=> {
			this.mongo.update('website', website);
		});
	}
	delete(website) {
		this.mongo.delete('website', website);
	}
}
