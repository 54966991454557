<div class="containerBox">
	<div class="tab">
		<div [class._active]="tab=='edit'" (click)="tab='edit';hash.set('tab', 'edit');" class="tab__item">Edit profile</div>
		<div [class._active]="tab=='email'" (click)="tab='email';hash.set('tab', 'email');" class="tab__item">Email</div>
		<div [class._active]="tab=='notifications'" (click)="tab='notifications';hash.set('tab', 'notifications');" class="tab__item">Notifications</div>
		<!-- tab for mobile devices -->
		<div [class._active]="tab=='edit'" (click)="tab='edit';hash.set('tab', 'edit');" class="tab__item--icon"><span class="material-icons">person</span></div>
		<div [class._active]="tab=='email'" (click)="tab='email';hash.set('tab', 'email');" class="tab__item--icon"><span class="material-icons">email</span></div>
		<div [class._active]="tab=='notifications'" (click)="tab='notifications';hash.set('tab', 'notifications');" class="tab__item--icon"><span class="material-icons">notifications</span></div>
	</div>

	<!-- Edit profile -->
	<ng-container *ngIf="tab=='edit'">
		<div class="us-profile">
			<div class="us-profile__left col-5 fl-column-ai-c">
				<label for="updateAvatar" class="us-profile__img mb15 modal-img">
					<picture class="_fz-46" [user]="us" [big]="true"></picture>
					<span class="img-upload"><span class="icon-pencil"></span></span>
				</label>
				<input type="file" id="updateAvatar" (change)="us.addAvatar($event);" [hidden]="true" accept="image/*" />
				<button class="waw-btn _danger _new" (click)="us.logout();">Log out</button>
			</div>
			<div class="col-23 fl-grow">
				<div class="row">
					<div class="waw-input col-2">
						<label>
							<span class="waw-input__title">First name</span>
							<input type="text" placeholder="First name" name="firstName" [(ngModel)]="us.firstName" (ngModelChange)="us.update();">
						</label>
					</div>
					<div class="waw-input col-2">
						<label>
							<span class="waw-input__title">Last name</span>
							<input type="text" placeholder="Last name" name="lastName" [(ngModel)]="us.lastName" (ngModelChange)="us.update();">
						</label>
					</div>
				</div>
				<div class="row">
					<div class="waw-input col-2">
						<label>
							<span class="waw-input__title">Phone</span>
							<input type="tel" name="number" placeholder="Phone number" [(ngModel)]="us.data.phone" (ngModelChange)="us.update();">
						</label>
					</div>
					<div class="waw-input col-2">
						<label>
							<span class="waw-input__title">Location</span>
							<input ngx-google-places-autocomplete autocomplete="off" [options]="{componentRestrictions: {country:ls.primary&&ls.primary[0]&&(ls.primary[0].country=='Canada'&&'ca'||ls.primary[0].country=='USA'&&'us'||'ca')||'ca'}}" placeholder="Your location" [(ngModel)]="us.data.address" name="address" (onAddressChange)="us.data.address = us.getFormattedAddress($event, true)">
						</label>
					</div>
				</div>
				<div class="row">
					<div class="waw-select col-2">
						<span class="waw-select__title">Gender</span>
						<app-select 
							placeholder="Select gender" 
							[(value)]="us.data.gender"
							(valueChange)="us.update()"
							[items]="['Prefer not to say', 'Female', 'Male', 'Non-binary']" 
							[clearable]="false" 
							[searchable]="false"
						></app-select>
					</div>
					<!-- <div class="col-2">
						<div style="display: block !important;" class="waw-datepicker__title">Date of Birth</div>
						<mat-form-field class="example-full-width "   appearance="fill" >
							<input matInput [matDatepicker]="picker"  placeholder="Choose a date" name="mydate"  [(ngModel)]="us.data.birthday" (ngModelChange)="us.update();">
							<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-datepicker #picker [dateClass]="dateClass"></mat-datepicker>
						</mat-form-field>
					</div> -->
					<div class="waw-datepicker col-2 _arrow">
						<span class="waw-datepicker__title">Date of Birth</span>
						<datepicker [date]="us.data.birthday" (valueChange)="us.data.birthday=$event; us.update();"></datepicker>
					</div>
				</div>
				<div class="row">
					<div class="waw-textarea col-1">
						<label>
							<span class="waw-textarea__title">notes</span>
							<note-editor [notes]="noteEditor" placeholder="Add notes" (onChange)="noteEditor = $event; update()"></note-editor>
						</label>
					</div>
				</div>
				<!-- <div class="row">
					<div class="waw-textarea col-1">
						<div [class.req]="characterLimit">
							<note-editor [notes]="descriptionEditor" label="Biography" placeholder="Your bio" size="medium" (characterLimit)="characterLimit = $event" (onChange)="descriptionEditor = $event; update()"></note-editor>
							<div *ngIf="characterLimit" class="req-text">{{characterLimit}}</div>
						</div>
					</div>
				</div> -->
				<div class="row">
					<div class="waw-input col-1">
						<label>
							<span class="waw-input__title">Email</span>
							<div>{{us.email}}</div>
						</label>
					</div>
				</div>
				<div class="social mt20 mb20" [class.navShown]="!us.sidebarToggle">
					<div class="row">
						<div class="col-2">
							<button class="social-item" (click)="us.fb ? facebookDisconnect() : facebookConnect()">
								<span class="social-item__body">
									<span class="social-item__title">Sign in with Facebook</span>
									<span class="social-item__desc">Sign into Pure Planner with 1-click</span>
									<ng-container *ngIf="!us.fb">
										<span class="social-item__status">You are not signed</span>
									</ng-container>
									<ng-container *ngIf="us.fb">
										<span class="social-item__status _connected">Disconnect</span>
									</ng-container>
								</span>
								<span class="social-item__icon">
									<img src="assets/img/setup/social/facebook.svg" alt="facebook" />
								</span>
							</button>
						</div>
						<div class="col-2">
							<button class="social-item" (click)="us.google ? googleDisconnect() : googleConnect()">
								<span class="social-item__body">
									<span class="social-item__title">Sign in with Google</span>
									<span class="social-item__desc">Sign into Pure Planner with 1-click</span>
									<ng-container *ngIf="!us.google">
										<span class="social-item__status">You are not signed</span>
									</ng-container>
									<ng-container *ngIf="us.google">
										<span class="social-item__status _connected">Disconnect</span>
									</ng-container>
								</span>
								<span class="social-item__icon">
									<img src="assets/img/setup/social/google.svg" alt="google" />
								</span>
							</button>
						</div>
					</div>
					<!-- <div class="row">
						<div class="col-2">
							<div class="social-item">
								<div class="row">
									<div class="col-1">
										<div class="social-item__title h-title">accounting software</div>
									</div>
								</div>
								<div class="row">
									<div class="col-23">
										<div class="social-item__desc">Sign into Pure Planner with 1-click</div>
										<div class="social-item__status">You are not signed.</div>
									</div>
									<div class="col-3 fl-c-c">
										<a href="#" class="social-item__login">
											<div *ngIf="false" class="social-item__logout"><span class="icon icon-exit"></span></div>
											<img *ngIf="true" src="assets/img/setup/social/setup.png" alt="" />
										</a>
									</div>
								</div>
							</div>
						</div>
						<div class="col-2">
							<div class="social-item">
								<div class="row">
									<div class="col-1 fl-sb-c">
										<div class="social-item__title h-title">Sign in with slack</div>
									</div>
								</div>
								<div class="row">
									<div class="col-23">
										<div class="social-item__desc">Sign into Pure Planner with 1-click</div>
										<div class="social-item__status">You are not signed.</div>
									</div>
									<div class="col-3 fl-c-c">
										<a href="#" class="social-item__login">
											<div *ngIf="false" class="social-item__logout"><span class="icon icon-exit"></span></div>
											<img *ngIf="true" src="assets/img/setup/social/slack.png" alt="" />
										</a>
									</div>
								</div>
							</div>
						</div>
					</div> -->
				</div>
				<div class="row">
					<div class="col-1">
						v1.4.1
					</div>
				</div>
			</div>
		</div>
	</ng-container>

	<!-- Change email -->
	<ng-container *ngIf="tab=='email'">
		<div class="us-profile">
			<div class="us-profile__title">
				<div class="us-profile__title-container">
					<h2 class="modal-h2">Change Email Address</h2>
					<p class="modal-p">You can easily update your email address to ensure accurate and efficient communication with our platform.</p>
				</div>
			</div>
			<div class="us-profile__content fl-grow mt15">
				<button class="waw-btn _primary fl-c-c mb20" (click)="onStart()" *ngIf="step[1] == 'next'">Start</button>
				<div class="step" [class._next]="step[1] == 'next'" [class._completed]="step[1] == 'completed'">
					<div class="ai-c mb20">
						<div class="step__number" [class._completed]="step[1] == 'completed'">
							<ng-container *ngIf="step[1] != 'completed'">
								1
							</ng-container>
						</div>
						<div class="step__title">Enter verification code sent to <span class="step__title-email">{{us.email}}</span></div>
					</div>
					<ng-container *ngIf="step[1] == 'current'">
						<div class="waw-input col-1">
							<label>
								<input type="text" [(ngModel)]="user.code" (ngModelChange)="onEnterCode()" placeholder="000000" mask="000000" maxlength="6" inputmode="numeric">
							</label>
						</div>
						<button class="waw-btn _second fl-c-c ml10 mb20" (click)="onStart()">Resend code</button>
					</ng-container>
				</div>
				<div class="step" [class._next]="step[2] == 'next'" [class._completed]="step[2] == 'completed'">
					<div class="ai-c mb20">
						<div class="step__number" [class._completed]="step[2] == 'completed'">
							<ng-container *ngIf="step[2] != 'completed'">
								2
							</ng-container>
						</div>
						<div class="step__title">Enter your new email address</div>
					</div>
					<ng-container *ngIf="step[2] == 'current'">
						<div class="waw-input col-1">
							<label>
								<input type="text" placeholder="Email" inputmode="email" [(ngModel)]="user.email">
							</label>
						</div>
						<button class="waw-btn _primary fl-c-c ml10 mb20" (click)="onEnterEmail()">Continue</button>
					</ng-container>
				</div>
				<div class="step" [class._next]="step[3] == 'next'" [class._completed]="step[3] == 'completed'">
					<div class="ai-c mb20">
						<div class="step__number" [class._completed]="step[3] == 'completed'">
							<ng-container *ngIf="step[3] != 'completed'">
								3
							</ng-container>
						</div>
						<div class="step__title">Verify email address</div>
					</div>
					<ng-container *ngIf="step[3] == 'current'">
						<div class="ml10">
							<p class="modal-p">A confirmation email has been sent to the provided email address. To complete the email address change process, please check your inbox and click on the confirmation link within the email.</p>
							<p class="modal-p">By clicking on the confirmation link, you will verify that the new email address belongs to you, ensuring the security and accuracy of your account information.</p>
							<p class="modal-p">Please note that the link may expire after a certain period, so we recommend completing the confirmation process as soon as possible.</p>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</ng-container>

	<!-- Notifications -->
	<ng-container *ngIf="tab=='notifications'">
		<notificationssettings [modal]="false"></notificationssettings>
	</ng-container>
</div>
