import { Component, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { UserService, RoleService, LoaderService } from '@services'
import { ModalService, MongoService } from 'wacom';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss', '../setup.component.scss']
})
export class UsersComponent implements OnInit, AfterViewInit {
	public search:any='';
	public tab: any = 'users';
	public sort_type:any = {};
	public afterViewInit: boolean = false;
	public tabs: any = [
		'users',
		'permission',
		'role'
	];
	public allRoles: any = [];
	sort(field) {
		if(this.sort_type.title != field) this.sort_type = {};
		this.sort_type = {
			title: field,
			direction: (typeof this.sort_type.direction != 'string'&&'asc')||(this.sort_type.direction == 'asc'&&'desc')||undefined
		}
	}
	constructor(public modal: ModalService, public us: UserService,
		public rs: RoleService,
		public router: Router,
		private route: ActivatedRoute,
		public loader: LoaderService,
		private mongo: MongoService,
		private eref: ElementRef) {
		this.us.headerTitle = 'Users';
		this.us.headerSubtitle = 'Setup';
	}
	ngOnInit() {
		this.loader.show({container: true}, this.eref.nativeElement.querySelector('.containerBox'));
	}
	ngAfterViewInit() {
		this.mongo.on('user role', () => {
			this.allRoles = [...this.rs.roles];
			this.allRoles.unshift({
				_id: 'admin',
				name: 'Admin'
			});
			this.afterViewInit = true;
			this.loader.remove();
		});
	}
	delete(user) {
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({
			component: 'deleteAlert',
			title: 'Delete user',
			body: `Are you sure you want to delete user ${user.firstName} ${user.lastName}?`,
			on_add: ()=> {
				this.loader.show({container: true, transparent: true}, this.eref.nativeElement.querySelector('.containerBox'));
				this.us.delete(user, () => {
					this.loader.remove();
				});
			}
		});
	}
	return(user) {
		this.loader.show({container: true, transparent: true}, this.eref.nativeElement.querySelector('.containerBox'));
		this.us.return(user, () => {
			this.loader.remove();
		});
	}
	edit(user) {
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		if(user.is.admin||user.is.owner||user.is.staff) {
			this.modal.show({component: 'staff', staff: JSON.parse(JSON.stringify(user))});
		} else {
			this.modal.show({component: 'client', profile: JSON.parse(JSON.stringify(user))})		
		}
	}
	updateRole(role) {
		this.loader.show({container: true, transparent: true}, this.eref.nativeElement.querySelector('.containerBox'));
		this.rs.update(role, () => {
			this.loader.remove();
		});
	}
	ngDoCheck(){
		if( this.route.snapshot.queryParams['modal']){
		}
		else{
			this.modal.destroy()
		}
	}
	openCreateuser(){
		this.router.navigate([],{ queryParams: { modal: 'open' }, queryParamsHandling: 'merge' });
		this.router.navigate([],{ queryParams: { modal: 'open' }, queryParamsHandling: 'merge' });
		this.modal.show({ component: 'createuser'})		
	}
	openRole(profile = null){
		this.router.navigate([],{ queryParams: { modal: 'open' }, queryParamsHandling: 'merge' });
		this.modal.show({
			component: 'modal',
			profile: profile ? JSON.parse(JSON.stringify(profile)) : {},
			title: 'Role',
			components: [{
				type: 'INPUT',
				input: 'name',
				label: 'Role name*',
				placeholder: 'Enter role name',
				reqText: 'Name is required',
				required: true,
			}],
			alertOpts: {
				title: 'Delete role',
				body: `Are you sure you want to delete role ${profile?.name}?`
			},
			onDelete: (profile: any) => {
				this.rs.delete(profile, () => {
					this.modal.destroy();
				});
			},
			onSave: (profile: any) => {
				this.rs.create(profile, () => {
					this.modal.destroy();
				});
			}
		});	
	}
	deleteRole(role) {
		this.router.navigate([],{ queryParams: { modal: 'open' }, queryParamsHandling: 'merge' });
		this.modal.show({
			component: 'deleteAlert',
			title: 'Delete role',
			body: `Are you sure you want to delete role ${role.name}?`,
			on_add: ()=> {
				this.loader.show({container: true, transparent: true}, this.eref.nativeElement.querySelector('.containerBox'));
				this.rs.delete(role, () => {
					this.loader.remove();
				});
			}
		});
	}
	roleChange(user, event) {
		switch (event) {
			case 'admin':
				user.role = null;
				user.is.admin = true;
				break;
			case null:
				user.role = null;
				user.is.admin = false;
				break;
			default: 
				user.role = event;
				user.is.admin = false;
		}
		this.us.save(user);
	}
}
