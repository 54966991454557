import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '@services';
import * as  moment  from 'moment-timezone'
@Pipe({
	name: 'lastModified'
})
export class LastModifiedPipe implements PipeTransform {
	constructor(public us: UserService) {}
	transform(arr, now) {
		if(!arr || !arr.length) return;
		arr = arr.slice();		
		let result_date = moment('1995-12-25').format('M/DD/yyyy HH:mm');
		let result_author;
		for (let i = 0; i < arr.length; i++) {	
			if(arr[i].select[this.us.user._id] && moment(arr[i].select[this.us.user._id].date).diff(result_date||'') > 0) {
				result_date = moment(arr[i].select[this.us.user._id].date).format('M/DD/yyyy HH:mm');
				result_author = arr[i].select[this.us.user._id].author;
			}
		}
		if(result_author == this.us.user._id || !this.us._users[result_author]) {
			return '';
		} else {
			return  `${ this.us._users[result_author].name||''} ${result_date||''}`;
		}
	}
}
