import { Component, ViewChild } from '@angular/core';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { NgxCsvParser } from 'ngx-csv-parser';
import { AlertService } from 'wacom';
import { UserService, LoaderService } from "@services";
import { toDoc, toHTML } from 'ngx-editor';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'importexport',
	templateUrl: './importexport.component.html',
	styleUrls: ['./importexport.component.scss']
})
export class ImportexportComponent {
	constructor(private ngxCsvParser: NgxCsvParser, private loader: LoaderService, public us: UserService, private alert: AlertService, private datePipe: DatePipe) { }
	public close;
	public file;
	public headers: any = ["First Name", "Last Name", "Email", "Phone", "Address", "Gender", "Date of Birth", "Note"];
	private number_new_clients: number = 0;

	fileChangeListener($event: any): void {
		this.loader.show({ 
			modal: true,
			transparent: true,
			preventClick: true
		}, document.querySelector('.modal-box'));

		const files = $event.srcElement.files;

		var allowedExtensions = /(csv)$/i;

		if (!allowedExtensions.exec(files[0].type)) {
			this.loader.remove();
			this.alert.error({text: 'Select a file in .csv format'});
			delete this.file;
			return;
		}

		this.number_new_clients = 0;

		this.ngxCsvParser.parse(files[0], { header: true, delimiter: ',' }).pipe().subscribe({
			next: (result): void => {
				if(!Object.keys(result[0]).find(k => k === 'First Name') || !Object.keys(result[0]).find(k => k === 'Last Name') || !Object.keys(result[0]).find(k => k === 'Email')) {
					this.loader.remove();
					delete this.file;
					return this.alert.error({
						text: 'Columns required: First Name, Last Name, Email'
					});
				} else {
					this.createClient(result, 0);
				}
			},
			error: (error): void => {
				this.loader.remove();
				delete this.file;
			  	console.error(error);
			}
		});
	}
	private createClient(result, i) {
		try {
			if (result.length && result[i]) {
				if(!!result[i]['First Name'] && result[i]['First Name']?.trim() != '' && !!result[i]['Last Name'] && result[i]['Last Name']?.trim() != '' && !!result[i]['Email'] && result[i]['Email']?.trim() != '') {
					this.us.create({
						firstName: result[i]['First Name'],
						lastName: result[i]['Last Name'],
						name: result[i]['First Name'] + ' ' + result[i]['Last Name'],
						email: result[i]['Email'],
						data: {
							phone: result[i]['Phone'],
							address: result[i]['Address'],
							note: result[i]['Note'] ? JSON.stringify(toDoc("<p>" + result[i]['Note'].split('\n').join("</p><p>") + "</p>")) : null,
							gender: result[i]['Gender'],
							birthday: result[i]['Date of Birth'] ? this.dateFormatting(result[i]['Date of Birth']) : null
						}
					}, () => {
						this.number_new_clients++;
						this.respClient(result, i);
					}, () => {
						this.respClient(result, i);
					}, false);
				} else {
					this.respClient(result, i);
				}
			} else {
				this.loader.remove();
			}
		} catch(e) {
			console.error(e);
			this.loader.remove();
			delete this.file;
			this.alert.error({
				text: 'Import failed due to an unexpected error'
			});
		}
	}
	private respClient(result, i) {
		if (result.length == i + 1) {
			this.loader.remove();
			delete this.file;
			this.alert.show({
				text: `Import Successful! ${this.number_new_clients} new clients`
			});
			this.close();
		} else {
			this.createClient(result, i + 1)
		}
	}
	export() {
		try {
			this.loader.show({ 
				modal: true,
				transparent: true,
				preventClick: true
			}, document.querySelector('.modal-box'));

			let clients = [];
			for (let i = 0; i < this.us.clients.length; i++) {
				clients.push({
					firstName: this.us.clients[i].firstName.trim() || '',
					lastName: this.us.clients[i].lastName.trim() || '',
					email: this.us.clients[i].email || '',
					phone: this.us.clients[i].data.phone || '',
					address: this.us.clients[i].data.address || '',
					gender: this.us.clients[i].data.gender || '',
					birthday: this.us.clients[i].data.birthday?.singleDate?.formatted || '',
					note: this.us.clients[i].data.note ? toHTML(JSON.parse(this.us.clients[i].data.note)).replace(/<p[^>]*>/g, '\n').replace(/<[^>]*>/g, '').trim() : ''
				});
			}

			const date = new Date();
			const filename = `PurePlannerClients_${date.getFullYear()}-${(date.getMonth()+1).toString().padStart(2, '0')}-${(date.getDate()).toString().padStart(2, '0')}_${(date.getHours()).toString().padStart(2, '0')}-${(date.getMinutes()).toString().padStart(2, '0')}-${(date.getSeconds()).toString().padStart(2, '0')}`;
			new ngxCsv(clients, filename, {
				fieldSeparator: ',',
				quoteStrings: '"',
				decimalseparator: '.',
				showLabels: true,
				useBom: true,
				headers: this.headers
			});

			this.loader.remove();
			this.alert.show({
				text: 'Export Successful!'
			});
			this.close();
		} catch(e) {
			console.error(e);
			this.loader.remove();
			this.alert.error({
				text: 'Export failed due to an unexpected error'
			});
		}
	}
	private dateFormatting(formatted) {
		var res: any = {"isRange": false, "singleDate": { "date": {} }, "dateRange": null};
		const date = new Date(formatted);
		res.singleDate.date = {
			"year": date.getFullYear(),
			"month": date.getMonth() + 1,
			"day": date.getDate()
		};
		res.singleDate.jsDate = date;
		res.singleDate.formatted = this.datePipe.transform(date, 'M/d/yyyy');
		res.singleDate.epoc = date.getTime();
		return res;
	}
}
