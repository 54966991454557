<div class="modal-box">
	<form (ngSubmit)="save()">
		<div class="modal-title">Add user</div>
		<button mat-icon-button type="button" class="modal-close" (click)="close()">
			<span class="modal-close__icon"></span>
		</button>
		<div class="row">
			<div class="waw-input col-2">
				<label [class.req]="submitted&&!profile.firstName">
					<span class="waw-input__title">Name *</span>
					<input type="text" placeholder="Name" name="firstName" [(ngModel)]="profile.firstName">
					<div *ngIf="submitted&&!profile.firstName" class="req-text">First name is required</div>
				</label>
			</div>
			<div class="waw-input col-2">
				<label [class.req]="submitted&&!profile.lastName">
					<span class="waw-input__title">Last Name *</span>
					<input type="text" placeholder="Last Name" name="lastName" [(ngModel)]="profile.lastName">
					<div *ngIf="submitted&&!profile.lastName" class="req-text">Last name is required</div>
				</label>
			</div>
		</div>
		<div class="row">
			<div class="waw-input col-2">
				<label [class.req]="submitted&&!profile.email">
					<span class="waw-input__title">Email *</span>
					<input type="text" placeholder="Email" name="Email" [(ngModel)]="profile.email">
					<div *ngIf="submitted&&!profile.email" class="req-text">Email is required</div>
				</label>
			</div>
			<div class="waw-select col-2" *ngIf="rs.roles.length">
				<span class="waw-select__title">Role *</span>
				<app-select 
					placeholder="No Role" 
					[(value)]="profile.role"
					[items]="rs.roles" 
					[clearable]="false" 
					[searchable]="false"
					[required]="submitted&&!profile.role"
					bindLabel="name" 
					bindValue="_id"
				></app-select>
				<div *ngIf="submitted&&!profile.role" class="req-text">Role is required</div>
			</div>
		</div>
		<div class="modal-btn">
			<button mat-button color="primary" type="button" class="waw-btn _second" (click)="close()">Cancel</button>
			<button mat-flat-button color="primary" type="submit" class="waw-btn _primary">Create</button>
		</div>
	</form>
</div>

