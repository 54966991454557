import { Component, ElementRef, QueryList, ViewChildren, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService, LoaderService } from '@services';
import { AlertService } from 'wacom';

@Component({
	selector: 'app-reset',
	templateUrl: './reset.component.html',
	styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements AfterViewInit {
	constructor(private router: Router,
		private http: HttpClient,
		private alert: AlertService,
		private loader: LoaderService,
		public auth: AuthService) {
			this.auth.reset();		
			if(localStorage.getItem('waw_reset_email')){
				this.user.email = localStorage.getItem('waw_reset_email');
			}
			if(!this.user.email){
				this.router.navigate(['/forgot']);
			}
		}
	public user:any = {
		email: '',
		code: ''
	};
	public error: string = '';
	public submitted: boolean = false;
	@ViewChildren('InputCode') inputCode: QueryList<ElementRef>;

	ngAfterViewInit() {
		if(this.inputCode) {
			this.inputCode.forEach((input, index, array) => {
				input.nativeElement.addEventListener('keydown',(e)=>{
					// if the keycode is backspace & the current field is empty
					// focus the input before the current. Then the event happens
					// which will clear the "before" input box.
					if(e.keyCode === 8 && e.target.value==='') array[Math.max(0,index-1)].nativeElement.focus();
				});
				input.nativeElement.addEventListener('input',(e)=>{
					this.submitted = false;
					// take the first character of the input
					const [first,...rest] = e.target.value;
					e.target.value = first ?? ''; // first will be undefined when backspace was entered, so set the input to ""
					const lastInputBox = index===array.length-1;
					const didInsertContent = first!==undefined;
					if(didInsertContent && !lastInputBox && rest.length) {
						// continue to input the rest of the string
						array[index+1].nativeElement.focus();
						array[index+1].nativeElement.value = rest.join('');
						array[index+1].nativeElement.dispatchEvent(new Event('input'));
					} else if(!lastInputBox) {
						array[index+1].nativeElement.focus();
						array[index+1].nativeElement.setSelectionRange(0, 1);
					}
				});
			});
		}
	}

	reset() {
		this.user.code = '';
		if(this.inputCode) {
			this.inputCode.forEach((input) => {
				this.user.code += input.nativeElement.value;
			});
		}
		this.submitted = true;
		if(!this.user.code || this.user.code.length != 6) {
			this.alert.error({
				text: 'Enter verification code',
			})
			return;
		}
		this.loader.show({transparent: true});
		this.http.post('/api/user/resetPassword', {
			email: this.user.email,
			pin: this.user.code
		}).subscribe((resp:any) => {
			this.loader.remove();
			if(resp == true) {
				localStorage.setItem('waw_reset_code', this.user.code);
				this.router.navigate(['/save']);
			} else if(resp == false) {
				this.router.navigate(['/forgot']);
			} else {
				this.error = resp;
				this.alert.error({
					text: resp
				});
			}	
		});
	}

	resend() {
		this.loader.show({transparent: true});
		this.http.post('/api/user/request', {
			email: this.user.email
		}).subscribe((resp:any) => {
			this.loader.remove();
			this.alert.info({
				text: "Mail sent to your email"
			});
		});
	}
}