import { Component, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { UserService, ClosedService, LocationService, LoaderService } from '@services'
import { ModalService, MongoService } from 'wacom';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-closed-dates',
	templateUrl: './closed-dates.component.html',
	styleUrls: ['./closed-dates.component.scss', '../setup.component.scss']
})
export class ClosedDatesComponent implements OnInit, AfterViewInit {
	public tab: any = 1;
	public pastOpen: any;
	public comingOpen: any;
	public profileBarPopup: any;
	public questionShow: any;
	public selectStatus: any = {};
	public link: any = 0;

	
	edit(profile){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({component: 'closed', profile: JSON.parse(JSON.stringify(profile))})
	}
	constructor(public modal: ModalService,
		public cs: ClosedService,
		public us: UserService,
		public ls: LocationService,
		public router: Router,
		private route: ActivatedRoute,
		private loader: LoaderService,
		private mongo: MongoService,
		private eref: ElementRef) 
	{
		this.us.headerTitle = 'Closed Dates';
		this.us.headerSubtitle = 'Setup';
	}
	ngOnInit() {
		this.loader.show({container: true}, this.eref.nativeElement.querySelector('.containerBox'));
	}
	ngAfterViewInit() {
		this.mongo.on('closed location', () => {
			this.loader.remove();
		});
	}
	ngDoCheck(){
		if( this.route.snapshot.queryParams['modal']){
		}
		else{
			this.modal.destroy()
		}
	}
	openModal(){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({ component: 'closed'})		
	}
	delete(profile) {
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({
			component: 'deleteAlert',
			title: 'Delete closed date',
			body: `Are you sure you want to delete closed date ${profile.name}`,
			on_add: ()=> {
				this.loader.show({container: true, transparent: true}, this.eref.nativeElement.querySelector('.containerBox'));
				this.cs.delete(profile, () => {
					this.loader.remove();
				});
			}
		});
	}
}
