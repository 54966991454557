<div [class.modal-box]="!compon">
	<form (ngSubmit)="save()">
		<div class="modal-title" *ngIf="!compon">
			<span>{{profile._id&&'Edit'||'New'}} Location</span>
			<span class="modal-title__step">Step 1</span>
		</div>
		<button mat-icon-button type="button" *ngIf="!compon" class="modal-close" (click)="close()">
			<span class="modal-close__icon"></span>
		</button>
		<div class="row">
			<div class="waw-input col-2">
				<label [class.req]="submitted&&!profile.name">
					<span class="waw-input__title">Business name *</span>
					<input type="text" placeholder="Business name" name="businessName" [(ngModel)]="profile.name" (ngModelChange)="onChange.emit(profile)">
					<div *ngIf="submitted&&!profile.name" class="req-text">Name is required</div>
				</label>
			</div>
			<div class="waw-input col-2">
				<label [class.req]="submitted&&!profile.number">
					<span class="waw-input__title">Contact number *</span>
					<input type="tel" placeholder="Contact number" name="contactNumber" [(ngModel)]="profile.number" (ngModelChange)="onChange.emit(profile)">
					<div *ngIf="submitted&&!profile.number" class="req-text">Phone is required</div>
				</label>
			</div>
		</div>
		<div class="row">
			<div class="waw-input col-2">
				<label [class.req]="submitted&&!profile.email">
					<span class="waw-input__title">Email *</span>
					<input type="email" placeholder="E-mail" name="email" [(ngModel)]="profile.email" (ngModelChange)="onChange.emit(profile)">
					<div *ngIf="submitted&&!profile.email" class="req-text">Email is required</div>
				</label>
			</div>
			<div class="waw-select col-2">
				<span class="waw-select__title">Country *</span>
				<app-select 
					placeholder="Select location" 
					[(value)]="profile.country"
					(valueChange)="selectedCountry(); onChange.emit(profile)"
					[items]="countries" 
					[clearable]="false" 
					[searchable]="false"
					[required]="submitted&&!profile.country"
				></app-select>
				<div *ngIf="submitted&&!profile.country" class="req-text">Country is required</div>
			</div>
		</div>
		<div class="row" *ngIf="profile.country&&autocomplete">
			<div class="waw-input col-1">
				<label [class.req]="submitted&&!profile.address">
					<span class="waw-input__title">Street address *</span>
					<input ngx-google-places-autocomplete autocomplete="off" [options]="{componentRestrictions: {country:profile.country=='Canada'&&'ca'||profile.country=='USA'&&'us'||'ca'}}" placeholder="Street address" [(ngModel)]="profile.address" (ngModelChange)="profile.address = $event; onChange.emit(profile)" name="address" (onAddressChange)="selectAddress($event)">
					<div *ngIf="submitted&&!profile.address" class="req-text">Address is required</div>
				</label>
			</div>
		</div>
		<div class="row" *ngIf="profile.country&&autocomplete">
			<div class="waw-input col-1">
				<label [class.req]="submitted&&!profile.city">
					<span class="waw-input__title">City *</span>
					<input type="text" placeholder="Select a city" name="city" [(ngModel)]="profile.city" (ngModelChange)="onChange.emit(profile)">
					<div *ngIf="submitted&&!profile.city" class="req-text">City is required</div>
				</label>
			</div>
			<div class="waw-input col-2">
				<label [class.req]="submitted&&!profile.state">
					<span class="waw-input__title">State *</span>
					<input type="text" placeholder="State" name="State" [(ngModel)]="profile.state" (ngModelChange)="onChange.emit(profile)">
					<div *ngIf="submitted&&!profile.state" class="req-text">City is required</div>
				</label>
			</div>
			<div class="waw-input col-2">
				<label [class.req]="submitted&&!profile.zip">
					<span class="waw-input__title">Zip code *</span>
					<input type="text" placeholder="Zip code" name="Zip code" [(ngModel)]="profile.zip" (ngModelChange)="onChange.emit(profile)">
					<div *ngIf="submitted&&!profile.zip" class="req-text">Zip is required</div>
				</label>
			</div>
		</div>
		<div class="row" *ngIf="profile.country&&autocomplete">
			<div class="waw-select col-1">
				<span class="waw-select__title">Time zone *</span>
				<app-select 
					placeholder="Select time zone" 
					[(value)]="profile.timezone"
					(valueChange)="onChange.emit(profile)"
					[items]="timeZones" 
					[clearable]="false" 
					[searchable]="true"
					[required]="submitted&&!profile.timezone"
					bindLabel="name" 
					bindValue="_id"
				>
					<ng-template option let-item>
						<div class="select-option" [title]="item.name">
							<span class="select-option__name">{{item._id}}</span>
							<span class="select-option__offset">{{item.offset}}</span>
						</div>
					</ng-template>
				</app-select>
				<div *ngIf="submitted&&!profile.timezone" class="req-text">Time zone is required</div>
			</div>
		</div>
		<div class="modal-switch" *ngIf="!compon">
			<label class="waw-switch">
				<input type="checkbox" name="notifications" [(ngModel)]="profile.primary">
				<span class="track">
					<span class="handle"></span>
				</span>
				<div class="waw-switch-text">Primary</div>
			</label>
		</div>
		<div class="modal-btn" *ngIf="!compon">
			<button mat-button color="primary" type="button" *ngIf="!profile._id" (click)="close()" class="waw-btn _second">Cancel</button>
			<button mat-button color="primary" type="button" *ngIf="profile._id" (click)="delete()" class="waw-btn _second _delete">Delete</button>
			<button mat-flat-button color="primary" type="submit" class="waw-btn _primary">Next</button>
		</div>
	</form>
</div>
