import { Pipe, PipeTransform } from '@angular/core';
import { MongoService } from 'wacom';
@Pipe({
	name: 'staffById'
})
export class StaffByIdPipe implements PipeTransform {
	constructor(private mongo: MongoService) {}
	transform(staff, allowedId=[], allowedCategories=[], allowedLocation, now) {
		if(!staff) return [];
		if(!allowedId.length && !allowedCategories.length && !allowedLocation) return staff;
		staff = staff.slice();
		if(allowedId.length) {
			for (let i = staff.length-1; i >= 0; i--){
				let bool = false;
				for (let j = allowedId.length-1; j >= 0; j--){
			        if(allowedId[j] == staff[i]._id) bool = true;
			    }
				if(!bool) staff.splice(i, 1);
			}
		}
		if(allowedCategories.length) {
			for (let i = staff.length-1; i >= 0; i--){
				let bool = false;
				for (let j = allowedCategories.length-1; j >= 0; j--){
			        if(allowedCategories[j] == staff[i].category) bool = true;
			    }
				if(!bool) staff.splice(i, 1);
			}
		}
		if(allowedLocation) {
			for (let i = staff.length-1; i >= 0; i--){
				let bool = false;
				if(staff[i].location) {
					for (let j = 0; j < staff[i].location.length; j++) {
						if(staff[i].location[j] == allowedLocation) bool = true;
						
					}
				}
				if(!bool) staff.splice(i, 1);
			}
		}
		staff.sort((a, b)=> { 
			if (a.order < b.order) {
				return -1;
			}
			if (a.order > b.order) {
				return 1;
			}
			return 0;
		});
		return staff;
	}
}
