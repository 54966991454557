import { Pipe, PipeTransform } from '@angular/core';
import { MongoService } from 'wacom';
@Pipe({
	name: 'checkoutsFilter'
})
export class CheckoutsFilterPipe implements PipeTransform {
	constructor(private mongo: MongoService) {}
	transform(arr, date, type) {
		if(!arr) return [];
		if(!date && !type) return arr;
		arr = arr.slice();
		for (let i = arr.length-1; i >= 0; i--){
			if( (date && arr[i].creation_date?.singleDate?.formatted != date.singleDate.formatted) || (type && arr[i].type != type) ) {
				arr.splice(i ,1);
			}
		}
	    
		return arr;
	}
}
