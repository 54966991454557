import { Component, HostListener, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { UserService, HashService, ServiceService, LoaderService } from '@services';
import { ModalService } from 'wacom';
@Component({
	selector: 'app-clients',
	templateUrl: './clients.component.html',
	styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
	public searching = '';
	public services:any;
	public date: any;
	public tab = 'list';
	public tabs: any = [
		{
			label: 'Clients list',
			title: 'list'
		}, {
			label: 'Service history',
			title: 'history'
		},
		'notes',
	];
	constructor(
		public hash: HashService, 
		public us: UserService,
		public modal: ModalService,
		private router: Router,
		public ss: ServiceService,
		private loader: LoaderService,
		private eref: ElementRef
	) {
		this.us.headerTitle = 'Clients';
		this.us.headerSubtitle = '';
		this.hash.on('tab', tab => this.tab = tab || 'list');
	}
	ngOnInit(): void {
		this.loader.show({container: true}, this.eref.nativeElement.querySelector('.containerBox'));
	}

	@HostListener('window:popstate', ['$event'])
    onPopState(event: PopStateEvent) {
		this.tab = this.hash.get('tab') || 'list';
    }

	openClient(){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({component: 'client'});	
	}

	openNote(){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({component: 'note'});
	}
}


