import { Injectable } from '@angular/core';
import { MongoService, AlertService } from 'wacom';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment-timezone';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class TimeService {
	public by_author: any = {};
	public current:any = [];
	public now = new Date().getTime();
	private loadedSubject = new BehaviorSubject <boolean>(false);
	refresh(){
		this.now = new Date().getTime();
	}
	// NOT FINISHED
	// add sockets for tab sync
	constructor(private mongo: MongoService, private alert: AlertService, private http: HttpClient) { 
		this.mongo.config('time', {});
		this.http.post('/api/time/current', {}).subscribe({
			next: (resp: any) => {
				this.current = resp.map(time => {
					return {
						...time,
						start: new Date(time.start),
						end: !time.end ? null : new Date(time.end)
					};
				});this.refresh();
			},
			error: (error) => {
				console.error(error);
			},
		});
	}
	toggle(){		
		if(this.current.length){
			this.update(this.current[0]);
		}else this.create();
	}
	create() {
		this.mongo.create('time', { utcOffset: moment().format('Z') }, created=>{
			// return error if there is already a started time
			created.end = !created.end ? null : new Date(created.end);			
			created.start = new Date(created.start),
			this.current.push(created);
			this.alert.show({
				text: 'Time has been added.'
			});
		}); 
	}
	update(time) {
		this.http.post('/api/time/end', { ...time, utcOffset: moment().format('Z') }).subscribe(
			(resp) => {
				this.current.splice(this.current.findIndex(t => t._id == time._id), 1);
				this.alert.show({
					text: 'Time has been updated.'
				});
			},
			(error) => {
				// return error if this time has already ended
				console.error(error);
				this.alert.error({
					text: 'Something went wrong'
				});
			}
		);
	}
	get(start, end, user = null) {
		this.loadedSubject.next(false);

		this.http.post('/api/time/get_by_user', { start: start, end: end, user: user }).subscribe({
			next: (resp: any) => {								
				resp.forEach((author) => {
					author.times.forEach(time => {
						if(!this.by_author[author._id]?.length) this.by_author[author._id] = [];
						if (!this.by_author[author._id].some(t => t._id === time._id)) {
							this.by_author[author._id].push({ 
								...time,
								start: new Date(time.start),
								end: !time.end ? null : new Date(time.end)
							});
						}
					});
				});
				this.loadedSubject.next(true);
				this.refresh();
			},
			error: (error) => {
				console.error(error);
			}
		});
	}
	loaded(cb:any=event=>{}) {
		if(this.loadedSubject.getValue() === true) {			
			if(typeof cb === 'function') cb(true);
		} else {
			this.loadedSubject.subscribe((state: boolean) => {
				if(state === true) {
					if(typeof cb === 'function') cb(true);
				}
			});
		}
	}
}
