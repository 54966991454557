<div class="containerBox setup">
	<div class="setup-container" *ngIf="us.setupTab==0">
		<div class="setup setup-box br14">
			<div class="setup-title">account setup</div>
			<div class="setup-desc">Manage the general settings for your business</div>
			<div class="setup-link">
				<a routerLink="/setup/locations" class="setup-link__item">Locations</a>
				<!-- <a routerLink="/setup/resources" class="setup-link__item">Resources</a> -->
				<a routerLink="/setup/closed" class="setup-link__item">Closed Dates</a>
				<!-- <a class="setup-link__item" (click)="this.modal.getModal('langSett').open()">Language Settings</a> -->
				<!-- <a routerLink="/setup/social" class="setup-link__item">Connected services</a> -->
				<!-- <a routerLink="/setup/online-link" class="setup-link__item">Online Links</a> -->
				<a (click)="openModal()" class="setup-link__item">Import / Export</a>
				<a (click)="openBooking()" class="setup-link__item">Booking</a>
				<a *ngIf="us.is.admin||us.is.owner" routerLink="/setup/users" class="setup-link__item">Users</a>
				<a *ngIf="us.is.admin||us.is.owner" routerLink="/mails" class="setup-link__item">Mail Templates [Admin]</a>
				<a *ngIf="us.is.admin||us.is.owner" routerLink="/notifications" class="setup-link__item">Notification Templates [Admin]</a>
			</div>
		</div>
		<div class="setup setup-box br14">
			<div class="setup-title">Point of sale</div>
			<div class="setup-desc">Manage point of sale settings</div>
			<div class="setup-link">
				<!-- <a routerLink="/setup/payment" class="setup-link__item">Payment Types</a> -->
				<a routerLink="/setup/discount" class="setup-link__item">Discount Types</a>
				<a routerLink="/setup/taxes" class="setup-link__item">Taxes</a>
				<a *ngIf="us.is.admin||us.is.owner" routerLink="/sales" class="setup-link__item">Sales [Admin]</a>
				<!-- <a class="setup-link__item" (click)="this.modal.getModal('salesSett').open()">Sales Settings</a> -->
				<a *ngIf="us.is.admin||us.is.owner" class="setup-link__item" (click)="openInvoice()">Invoice Template [Admin]</a>
				<!-- <a routerLink="/setup/invoice" class="setup-link__item">Invoice Sequencing</a> -->
			</div>
		</div>
		<div class="setup setup-box br14">
			<div class="setup-title">Client settings</div>
			<div class="setup-desc">Manage your client settings</div>
			<div class="setup-link">
				<a routerLink="/setup/cancel-reason" class="setup-link__item">Cancellation Reasons</a>
				<a routerLink="/setup/questionnaire" class="setup-link__item">Questionnaire</a>
				<a routerLink="/setup/alerts" class="setup-link__item">Alerts</a>
				<!-- <a routerLink="/setup/user_list" class="setup-link__item">User list</a> -->
				<!-- <a *ngIf="us.is.admin||us.is.owner" routerLink="/users" class="setup-link__item">Users [Admin]</a> -->
			</div>
		</div>
		
		<!-- <div class="setup setup-box br14">
			<div class="col-2">
				<div class="setup-title">Pro Features</div>
				<div class="setup-desc">Enhanced features</div>
				<div class="setup-link">
					<a href="#" class="setup-link__item">Enhanced features</a>
					<a href="#" class="setup-link__item">Online Booking</a>
					<a href="#" class="setup-link__item">Marketing</a>
					<a href="#" class="setup-link__item">Card Processing</a>
					<a href="#" class="setup-link__item">Client Notifications</a>
					<a href="#" class="setup-link__item">Client Messages</a>
					<a href="#" class="setup-link__item">Billing Activity</a>
					<a href="#" class="setup-link__item">Billing Details</a>
				</div>
			</div>
			<div class="col-2 setup-img">
				<div><img src="assets/img/setup-pro.png" alt="proFeaturesImg" /></div>
			</div>
		</div> -->
	
	</div>
</div>
<!-- Time and calendar settings -->


<!-- language settings -->
<!-- <ngx-smart-modal #md2 identifier="langSett">
	<div class="langSett p20">
		<div class="modal-title">language settings</div>
		<div class="row">
			<div class="waw-select col-1">
				<label>
					<span class="waw-select__title">Client notification languages</span>
					<ng-select placeholder="Select Client notification languages" name="Client notification languages" [items]="[]" [clearable]="false" [searchable]="false"></ng-select>
				</label>
			</div>
		</div>
		<div class="row">
			<div class="waw-select col-1">
				<label>
					<span class="waw-select__title">default language for your staff</span>
					<ng-select placeholder="Select default language for your staff" name="default language for your staff" [items]="[]" [clearable]="false" [searchable]="false"></ng-select>
				</label>
			</div>
		</div>
		<div class="modal-btn">
			<button class="waw-btn _second _second--mob" (click)="md2.close()">Cancel</button>
			<button class="waw-btn _primary _primary--mob">Save</button>
		</div>
	</div>
</ngx-smart-modal> -->

<!-- Sales settings -->
<!-- <ngx-smart-modal #md3 identifier="salesSett">
	<div class="salesSett p20">
		<div class="modal-title">Sales settings</div>
		<div class="salesSett--header">Vouchers</div>
		<div class="row">
			<div class="waw-select col-1">
				<label>
					<span class="waw-select__title">default expiry period</span>
					<ng-select placeholder="Select default expiry period" name="default expiry period" [items]="[]" [clearable]="false" [searchable]="false"></ng-select>
				</label>
			</div>
		</div>
		<div class="modal-switch">
			<div class="salesSett--header">staff commissions</div>
			<div class="waw-switch-wrap">
				<label class="waw-switch mb20">
					<input type="checkbox" name="calculate by item sale price before discount">
					<span class="slider round"></span>
				</label>
				<div class="waw-switch-text">calculate by item sale price before discount</div>
			</div>
			<div class="waw-switch-wrap">
				<label class="waw-switch">
					<input type="checkbox" name="calculate by item sale price including tax">
					<span class="slider round"></span>
				</label>
				<div class="waw-switch-text">calculate by item sale price including tax</div>
			</div>
		</div>
		<div class="modal-btn">
			<button class="waw-btn _second _second--mob" (click)="md3.close()">Cancel</button>
			<button class="waw-btn _primary _primary--mob">Save</button>
		</div>
	</div>
</ngx-smart-modal> -->

<!-- Invoice template -->
<!-- <ngx-smart-modal #md4 identifier="invoiceSett">
	<div class="invoiceSett p20">
		<div class="modal-title">Invoice template</div>
		<div class="row">
			<div class="waw-input col-2">
				<label>
					<span class="waw-input__title">INVOICE TITLE</span>
					<input type="text" placeholder="INVOICE TITLE" name="INVOICE TITLE">
				</label>
			</div>
			<div class="waw-input col-2">
				<label>
					<span class="waw-input__title">Receipt custom line 1</span>
					<input type="text" placeholder="Receipt custom line 1" name="Receipt custom line 1">
				</label>
			</div>
		</div>
		<div class="row">
			<div class="waw-input col-2">
				<label>
					<span class="waw-input__title">Receipt custom line 2</span>
					<input type="text" placeholder="Receipt custom line 2" name="Receipt custom line 2">
				</label>
			</div>
			<div class="waw-input col-2">
				<label>
					<span class="waw-input__title">Receipt footer</span>
					<input type="text" placeholder="Receipt footer" name="Receipt footer">
				</label>
			</div>
		</div>
		<div class="modal-switch">
			<div class="waw-switch-wrap">
				<label class="waw-switch mb20">
					<input type="checkbox" name="automatically print receipts upon sale completion">
					<span class="slider round"></span>
				</label>
				<div class="waw-switch-text">automatically print receipts upon sale completion</div>
			</div>
			<div class="waw-switch-wrap mb20">
				<label class="waw-switch">
					<input type="checkbox" name="Show client mobile and email on invoices">
					<span class="slider round"></span>
				</label>
				<div class="waw-switch-text">Show client mobile and email on invoices</div>
			</div>
			<div class="waw-switch-wrap">
				<label class="waw-switch">
					<input type="checkbox" name="Show client address on invoices">
					<span class="slider round"></span>
				</label>
				<div class="waw-switch-text">Show client address on invoices</div>
			</div>
		</div>
		<div class="modal-btn">
			<button class="waw-btn _second _second--mob" (click)="md4.close()">Cancel</button>
			<button class="waw-btn _primary _primary--mob">Save</button>
		</div>
	</div>
</ngx-smart-modal> -->
