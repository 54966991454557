import { Component, HostListener, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService, HashService, LoaderService, LocationService, CategoryService, BrandService } from '@services';
import { ModalService } from 'wacom';

@Component({
	selector: 'app-resources',
	templateUrl: './resources.component.html',
	styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
	public tab = 'products';
	public tabs: any = [
		'products',
		'rooms',
		'categories',
		'brands'
	];
	constructor(
		public us: UserService, 
		public hash: HashService, 
		private router: Router, 
		private modal: ModalService,
		private loader: LoaderService,
		private eref: ElementRef,
		public ls: LocationService,
		public cs: CategoryService,
		public bs: BrandService
	) {
		this.us.headerTitle = 'Resources';
		this.us.headerSubtitle = '';
		hash.on('tab', tab=>{
			this.tab = tab || 'products';
		});
	}
	ngOnInit() {
		this.loader.show({container: true}, this.eref.nativeElement.querySelector('.containerBox'));
	}
	@HostListener('window:popstate', ['$event'])
    onPopState(event: PopStateEvent) {
		this.tab = this.hash.get('tab') || 'products';
    }
	openModal(){
		switch(this.tab) {
			case 'products':
				this.router.navigate([],{ queryParams: { modal: 'open' } });
				this.modal.show({component: 'product'});
				break;
			case 'rooms':
				this.router.navigate([],{ queryParams: { modal: 'open' } });
				this.modal.show({component: 'room', profile:{location: this.ls._locations.primary[0]?._id||'', availability:{Monday: [], Tuesday: [], Wednesday: [], Thursday: [], Friday: [], Saturday: [], Sunday: []}}});
				break;		
			case 'categories':
				if(this.us.is.admin||this.us.is.owner) {
					this.router.navigate([],{ queryParams: { modal: 'open' } });
					this.modal.show({
						component: 'modal',
						title: 'Category',
						components: [{
							type: 'INPUT',
							input: 'name',
							label: 'Category name*',
							placeholder: 'e.g. Fitness equipment',
							reqText: 'Name is required',
							required: true,
						}],
						onSave: (profile: any) => {
							this.cs.create(profile, () => {
								this.modal.destroy();
							});
						}
					});	
				}
				break;
			case 'brands':
				if(this.us.is.admin||this.us.is.owner) {
					this.router.navigate([],{ queryParams: { modal: 'open' } });
					this.modal.show({
						component: 'modal',
						title: 'Brand',
						components: [{
							type: 'INPUT',
							input: 'name',
							label: 'Brand name*',
							placeholder: 'e.g. Sport Life',
							reqText: 'Name is required',
							required: true,
						}],
						onSave: (profile: any) => {
							this.bs.create(profile, () => {
								this.modal.destroy();
							});
						}
					});	
				}	
				break;
		}
	}
}
