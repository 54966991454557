import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { UserService, HourService, LocationService, LoaderService } from '@services';
import { ModalService } from 'wacom';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-setup',
	templateUrl: './setup.component.html',
	styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit, AfterViewInit {
	constructor(public hs: HourService,
		public us: UserService,
		public ls: LocationService,
		public modal: ModalService,
		public router: Router,
		private route: ActivatedRoute,
		private loader: LoaderService,
		private eref: ElementRef
		) {
		this.us.headerTitle = 'Setup';
		this.us.headerSubtitle = '';
	}
	ngOnInit() {
		this.loader.show({container: true}, this.eref.nativeElement.querySelector('.containerBox'));
	}
	ngAfterViewInit() {
		this.loader.remove();
	}
	ngDoCheck(){
		if( this.route.snapshot.queryParams['modal']){
		}
		else{
			this.modal.destroy()
		}
	}
	openModal(){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({component: 'importexport', class: 'importexport'})		
	}	
	openInvoice() {
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({component: 'invoicetemplate'})
	}
	openBooking() {
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({component: 'booking'})
	}
}
