import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';
import { toHTML } from 'ngx-editor';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
	name: 'note'
})
export class NotePipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}

	transform(note, refresh?:any) {
		try {
			return this.sanitizer.bypassSecurityTrustHtml(toHTML(JSON.parse(note)));
		} catch (e) {
			return note;
		}
	}
}
