import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ClosedService } from '@services';
import { MongoService } from 'wacom';

@Injectable({
	providedIn: 'root'
})
export class LocationService {
	public locations: any = [];
	public _locations: any = {};
	public primary;
	constructor(private mongo: MongoService, private cs: ClosedService, private datePipe: DatePipe) { 
		this.locations = mongo.get('location', {
			replace: {
				data: mongo.beObj
			},
			query: {
				primary: function(doc){
					return doc.primary;
				}
			}
		}, (arr, obj) => {
			this.primary = obj.primary;
			this._locations = obj;
		});
	}
	create(location:any, cb: any = () => {}) {
		if(location._id) return this.update(location, cb);
		if(!this.locations.length) location.primary=true;
		else if(location.primary){
			for (let i = 0; i < this.locations.length; i++){
				if(this.locations[i].primary){
					this.locations[i].primary = false;
					this.save(this.locations[i], cb);
				}
			}
		}
		this.mongo.create('location', location, (created) => {
			this.cs.getHolidays(created.country, created.state, created.timezone, 'public').forEach((holiday: any) => {
				if( this.cs.closeds.find((h: any) => {
					return h.holiday?.name === holiday.name && h.holiday?.date == holiday.date && ( ( holiday.possible_substitute && h.substitute ) || !holiday.possible_substitute );
				}) ) {
					const closed = this.cs.closeds.find((h: any) => {
						return h.holiday?.name === holiday.name && h.holiday?.date == holiday.date && ( ( holiday.possible_substitute && h.substitute ) || !holiday.possible_substitute );
					});

					closed.locations.push(created._id);

					this.cs.update(closed);
				} else {
					const closed = {
						locations: [created._id],
						name: holiday.name,
						holiday: holiday,
						substitute: holiday.possible_substitute ? true : false,
						start: {
							isRange: false,
							dateRange: null,
							singleDate: {
								date: {
									year: new Date(holiday.start).getFullYear(),
									month: new Date(holiday.start).getMonth() + 1,
									day: new Date(holiday.start).getDate()
								},
								jsDate: new Date(holiday.start),
								formatted: this.datePipe.transform(new Date(holiday.start), 'M/d/yyyy'),
								epoc: new Date(holiday.start).getTime()
							}
						},
						end: {
							isRange: false,
							dateRange: null,
							singleDate: {
								date: {
									year: new Date(holiday.end).getFullYear(),
									month: new Date(holiday.end).getMonth() + 1,
									day: new Date(holiday.end).getDate()
								},
								jsDate: new Date(holiday.end),
								formatted: this.datePipe.transform(new Date(holiday.end), 'M/d/yyyy'),
								epoc: new Date(holiday.end).getTime()
							}
						},
					};
	
					this.cs.create(closed, false);
				}
			});

			if(typeof cb === 'function') cb(created);
		}); 
	}
	update(location, cb: any = () => {}) {
		this.mongo.afterWhile(location, ()=> {
			if(location.primary) this.set_primary(location);
			this.save(location, cb);
		});
	}
	save(location, cb: any = () => {}) {
		this.mongo.update('location', location, (updated) => {
			if(typeof cb === 'function') cb(updated);
		});
	}
	delete(location, cb: any = () => {}) {
		this.mongo.delete('location', location, (deleted) => {
			if(typeof cb === 'function') cb(deleted);
		});
	}
	set_primary(location){
		for (let i = 0; i < this.locations.length; i++){
			if(this.locations[i]._id == location._id){
				this.locations[i].primary = true;
				this.save(this.locations[i]);
			}else if(this.locations[i].primary){
				this.locations[i].primary = false;
				this.save(this.locations[i]);
			}
		}
	}
}
