<div class="rs-container">
	<div class="setup-empty" *ngIf="false">
		<div class="setup-empty__img"><img src="assets/img/setup/resources.png" alt="resources" draggable="false"/></div>
		<div class="rs-empty-right">
			<div class="setup-empty__title">
				<div class="rs-empty-title">Setup bookable resources</div> <br>Rooms, booths, equipment - you name it! Here you can set up resources used for booking availability. You'll be able to see your resources in the calendar and track usage in reports.
			</div>
			<div (click)="openResource()" class="setup-empty__btn"><button class="waw-btn _second _second--mob">new resource</button></div>
		</div>
	</div>
	<div class="rs-list">
		<div class="page-nav">
			<div class="rs-nav-left">
				<div class="rs-nav-select matSelect">
					<!-- <ng-select [searchable]="false" [typeahead]="false" placeholder="Select location" [multiple]="true">
						<ng-option [value]="[]"></ng-option>
					</ng-select> -->
				</div>
				<div class="rs-nav-select matSelect">
					<!-- <ng-select [searchable]="false" [typeahead]="false" placeholder="Services" [multiple]="true">
						<ng-option [value]="[]"></ng-option>
					</ng-select> -->
				</div>
			</div>
			<button (click)="openResource()" class="btn-icon page-nav__btn"><span class="icon-plus"></span></button>
		</div>
		<div class="rs-list-table">
			<!-- ===  Clients Service history Table === -->
			<div class="table-wrapp cl-table">
				<table class="table" cellpadding="0" cellspacing="0">
					<thead>
						<tr>
							<th scope="col">resource name</th>
							<th scope="col">service</th>
							<th scope="col">location</th>
							<th scope="col">quantity</th>
							<th scope="col">description</th>
							<th scope="col">Services assigned</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let resource of rs.resources" (click)="edit(resource);">
							<td data-label="name">{{resource.name}}</td>
							<td data-label="service">Cold Yoga</td>
							<td data-label="location">Yoga room</td>
							<td data-label="quantity">{{resource.quantity}}</td>
							<td data-label="description">{{resource.description}}</td>
							<td data-label="assigned">0</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>