import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { UserService, ServiceService, AppointmentService } from '@services';
@Pipe({
  name: 'searchByClient'
})
export class SearchByClientPipe implements PipeTransform {

	transform(arr, name, type='1') {
		if(!name) return arr;
		if(!arr || !arr.length) return [];
		arr = arr.slice();
		if(type == '1') {
			for (let i = arr.length-1; i >= 0; i--){
			    if(!this.ss._services[arr[i].service]||!this.us._users[arr[i].client]||!this.us._users[arr[i].client].name.toLowerCase().includes(name.toLowerCase()) && !this.ss._services[arr[i].service].name.toLowerCase().includes(name.toLowerCase()) && !arr[i].day.singleDate.formatted.toLowerCase().includes(name.toLowerCase())&& !this.us._users[arr[i].client].inc.toString().includes(name.toLowerCase())&& !this.timeFormatting(arr[i].start).toLowerCase().includes(name.toLowerCase())&&!arr[i].price.toString().includes(name.toLowerCase())) {
			    	arr.splice(i, 1);
			    }
			}
		} else if(type == '2') {
			for (let i = arr.length-1; i >= 0; i--){
			    if(!this.us._users[arr[i].client] || !this.us._users[arr[i].client]?.name.toLowerCase().includes(name.toLowerCase()) && !this.ss._services[arr[i].appointment?.service]?.name.toLowerCase().includes(name.toLowerCase())) {
			    	arr.splice(i, 1);
			    }
			}
		}
		return arr;
	}
	constructor(public us: UserService, public ss: ServiceService, public ap: AppointmentService, private datePipe: DatePipe) {}

	timeFormatting(time) {
		var d = new Date();
		d.setHours(time.split(':')[0]);
		d.setMinutes(time.split(':')[1]);
		return this.datePipe.transform(d, 'shortTime');
	}
}
