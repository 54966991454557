<div class="modal-box" [class.compon]="compon">
	<form (ngSubmit)="save()">
		<div class="modal-title" *ngIf="!compon">{{selected_user.firstName}}'s Working Hours</div>
		<button mat-icon-button type="button" class="modal-close" *ngIf="!compon" (click)="close()">
			<span class="modal-close__icon"></span>
		</button>

		<div class="hours">
			<div class="hours__day" *ngFor="let day of us.days_of_week" [class._empty]="!selected_user.data?.working_hours?.default?.[day]?.length">
				<div class="hours__day__name">{{day}}</div>
				<div class="hours__day__body">
					<ng-container *ngFor="let hour of selected_user.data?.working_hours?.default[day]; index as i; last as last">
						<div class="hours__day__body__item">
							<div class="hours__day__body__item-time">
								<timepicker 
									label="Start*" 
									[value]="hour.from" 
									(valueChange)="hour.from = $event; Change.emit(selected_user)" 
									[requiredText]="submitted&&!hour.from&&'Start is required' || i>0&&convertTimeToMinutes(hour.from)<=convertTimeToMinutes(selected_user.data?.working_hours?.default[day][i-1].to)&&'Time is overlapping'"
									[required]="submitted&&!hour.from || i>0&&convertTimeToMinutes(hour.from)<=convertTimeToMinutes(selected_user.data?.working_hours?.default[day][i-1].to)" 
								></timepicker>
								<timepicker 
									label="End*" 
									[value]="hour.to" 
									(valueChange)="hour.to = $event; Change.emit(selected_user)" 
									[requiredText]="submitted&&!hour.to&&'End is required' || !!selected_user.data?.working_hours?.default[day]?.[i+1]&&convertTimeToMinutes(hour.to)>=convertTimeToMinutes(selected_user.data?.working_hours?.default[day][i+1].from)&&'Time is overlapping' || convertTimeToMinutes(hour.to)<=convertTimeToMinutes(hour.from)&&'The end time must be after the start time'"
									[required]="submitted&&!hour.to || !!selected_user.data?.working_hours?.default[day]?.[i+1]&&convertTimeToMinutes(hour.to)>=convertTimeToMinutes(selected_user.data?.working_hours?.default[day][i+1].from) || convertTimeToMinutes(hour.to)<=convertTimeToMinutes(hour.from)" 
								></timepicker>
							</div>
							<div class="hours__day__body__item-actions" [class._last]="last">
								<button mat-icon-button color="warn" class="waw-btn__icon warn" type="button" (click)="selected_user.data.working_hours.default[day].splice(i, 1); Change.emit(selected_user)"><span class="material-icons-round notranslate">remove</span></button>
								<button *ngIf="last && selected_user.data?.working_hours?.default?.[day]?.length < 10" mat-icon-button color="primary" class="waw-btn__icon primary" type="button" (click)="addHour(day); Change.emit(selected_user)"><span class="material-icons-round notranslate">add</span></button>
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="!selected_user.data?.working_hours?.default[day]?.length">
						<button mat-icon-button color="primary" class="waw-btn__icon primary" type="button" (click)="addHour(day); Change.emit(selected_user)"><span class="material-icons-round notranslate">add</span></button>
					</ng-container>
				</div>
			</div>
		</div>

		<div class="modal-btn" *ngIf="!compon">
			<button mat-button color="primary" type="button" class="waw-btn _second" (click)="close()">Cancel</button>
			<button mat-flat-button color="primary" type="submit" class="waw-btn _primary">Save</button>
		</div>
	</form>
</div>
