import { Directive, TemplateRef } from '@angular/core';

@Directive({
	selector: 'ng-template[option]'
})
export class OptionDirective {
	constructor(public template: TemplateRef<any>) {}
}

@Directive({
	selector: 'ng-template[label]'
})
export class LabelDirective {
	constructor(public template: TemplateRef<any>) {}
}

@Directive({
	selector: 'ng-template[select]'
})
export class SelectDirective {
	constructor(public template: TemplateRef<any>) {}
}