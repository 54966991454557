import { Component } from '@angular/core';
import { UserService } from '@services';
@Component({
	selector: 'email',
	templateUrl: './email.component.html',
	styleUrls: ['./email.component.scss']
})
export class EmailComponent{
	public email:any = {};
	public close;
	constructor(public us: UserService) {}
}
