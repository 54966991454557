import { Injectable } from '@angular/core';
import { MongoService, AlertService } from 'wacom';

@Injectable({
	providedIn: 'root'
})
export class PlanService {
	public plans: any = [];
	public _plans: any = {};
	constructor(private alert: AlertService, private mongo: MongoService) { 
		this.plans = mongo.get('plan', (arr, obj) => {
			this._plans = obj;
		});
	}
	create(plan) {
		if(plan._id) return this.update(plan);
		this.mongo.create('plan', plan, ()=>{
			this.alert.show({
				text: 'Plan has been created.'
			});
		});
	}
	update(plan) {
		this.mongo.afterWhile(plan, ()=> {
			this.mongo.update('plan', plan);
		});
	}
	delete(plan) {
		this.mongo.delete('plan', plan);
	}
}
