import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { RoomService, LocationService, HourService, UserService, LoaderService } from '@services';
import { ModalService, MongoService, UiService } from 'wacom';
import { Router,ActivatedRoute } from '@angular/router';
import { RoomComponent } from 'src/app/modals/room/room.component';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-res-rooms',
	templateUrl: './res-rooms.component.html',
	styleUrls: ['./res-rooms.component.scss']
})
export class ResRoomsComponent implements OnInit, AfterViewInit {
	public selected:any = 'Monday';
	constructor(
		public modal: ModalService, 
		public rs: RoomService, 
		public hs: HourService,
		public ls: LocationService, 
		public ui: UiService, 
		public us:UserService,
		private route: ActivatedRoute,
		private router: Router,
		private datePipe: DatePipe,
		private loader: LoaderService,
		private mongo: MongoService,
		private eref: ElementRef) {}
	ngOnInit() {
		if (!this.loader.isLoaderShowing) this.loader.show({container: true}, this.eref.nativeElement.closest('.containerTab'));
	}
	ngAfterViewInit() {
		this.mongo.on('room location', () => {
			this.loader.remove();
		});
	}
	edit(profile){
		if(this.us.is.admin||this.us.is.owner) {
			this.router.navigate([],{ queryParams: { modal: 'open' } });
			this.modal.show({component: 'room', profile:JSON.parse(JSON.stringify(profile))})
		}
	}
	ngDoCheck(){
		if( this.route.snapshot.queryParams['modal']){
		}
		else{
			this.modal.destroy()
		}
	}
	delete(profile) {
		if(this.us.is.admin||this.us.is.owner) {
			this.router.navigate([],{ queryParams: { modal: 'open' } });
			this.modal.show({
				component: 'deleteAlert',
				title: 'Delete room',
				body: `Are you sure you want to delete room ${profile.name}`,
				on_add: ()=> {
					this.loader.show({container: true, transparent: true}, this.eref.nativeElement.closest('.containerBox'));
					this.rs.delete(profile, () => {
						this.loader.remove();
					});
				}
			});
		}
	}
	getBusinessHours(hours) {
		if( hours?.length ) {
			const res = [];
			for(const [index, hour] of hours.entries()) {
				const from = new Date();
				const to = new Date();
				from.setHours(hour.from.split(':')[0], hour.from.split(':')[1]);
				to.setHours(hour.to.split(':')[0], hour.to.split(':')[1]);
				res.push(this.datePipe.transform(from, 'shortTime') + ' - ' + this.datePipe.transform(to, 'shortTime') + ((index != hours.length - 1) ? ' / ' : ''));
			}
			return res;
		} else return ['Day off'];
	}
}
