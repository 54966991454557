<div class="containerBox">
    <div class="navigation display-sm">
		<div class="navigation__topbar display-sm">
            <button mat-button color="primary" type="button" class="topbar-user-btn waw-btn _second display-sm" (click)="openModal()">
                <span class="icon-plus"></span>
                <span>New group</span>
            </button>
        </div>
    </div>

    <ng-container *ngFor="let group of gs.groups">
        <div class="setup-item setup-box">
            <div class="setup-item__title">{{group.name}}</div>
            <div class="setup-item__actions">
				<button mat-icon-button color="primary" class="action-edit" (click)="openModal(group)"><span class="icon-pencil"></span></button>
				<button mat-icon-button color="warn" class="action-delete" (click)="delete(group)"><span class="icon-delete"></span></button>
			</div>
            <ng-container *ngIf="group.description">
                <hr>
                <div class="setup-item__note">
                    <div class="note _limit" #Note [innerHtml]="group.description | note" [class._more]="Note.scrollHeight > Note.clientHeight" (click)="Note.scrollHeight > Note.clientHeight ? Note.classList.add('_all') : !Note.classList.contains('_all') && Note.classList.remove('_all')"></div>
                    <button class="note-btn" *ngIf="Note.classList.contains('_all') || Note.scrollHeight > Note.clientHeight" (click)="Note.classList.toggle('_all')">{{Note.scrollHeight > Note.clientHeight ? 'More' : 'Less'}}</button>
                </div>
            </ng-container>
            <button mat-button color="primary" class="service-btn waw-btn _primary" [class._no_description]="!group.description" (click)="create(group._id)">
                <span class="icon-plus"></span>
                <span>New service</span>
            </button>
        </div>
        
        <div class="table-container tablet-col3 mobile-col3">
            <button mat-flat-button color="primary" class="service-btn waw-btn _primary" (click)="create(group._id)">
                <span class="icon-plus"></span>
                <span>New service</span>
            </button>
            <table class="table" [class.navShown]="!us.sidebarToggle" cellpadding="0" cellspacing="0">
                <thead>
                    <tr>
                        <th scope="col" class="sort" (click)="sort('name')">
                            <div class="ai-c">
                                <span>Name</span> 
                                <span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'name'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'name'">
                                    <span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
                                    <span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
                                </span>
                            </div>
                        </th>
                        <th scope="col" class="sort" (click)="sort('time')">
                            <div class="ai-c">
                                <span>Duration</span> 
                                <span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'time'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'time'">
                                    <span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
                                    <span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
                                </span>
                            </div>
                        </th>
                        <th scope="col" class="sort" (click)="sort('price')">
                            <div class="ai-c">
                                <span>Price</span> 
                                <span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'price'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'price'">
                                    <span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
                                    <span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
                                </span>
                            </div>
                        </th>
                        <th scope="col" class="sort" (click)="sort('capacity')">
                            <div class="ai-c">
                                <span>Capacity</span> 
                                <span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'capacity'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'capacity'">
                                    <span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
                                    <span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
                                </span>
                            </div>
                        </th>
                        <th scope="col">
                            <span>Location</span>
                        </th>
                        <th scope="col">
                            <span>Room</span>
                        </th>
                        <th scope="col">
                            <span>Staff</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let service of ss.group[group._id]|sort:sort_type:ss.now" style="cursor: pointer;" (click)="editService(service);">
                        <td data-label="Name" class="ovf-hidden">
                            <div class="service ai-c">
                                <div class="service__color" [ngStyle]="{ 'background-color': service.color }"></div>
                                <div class="service__name t-hide">{{service.name}}</div>
                            </div>
                        </td>
                        <td data-label="Duration" class="text-elipsis">{{service.time}}</td>
                        <td data-label="Price" class="text-elipsis">{{ service.price | currency }}</td>
                        <td data-label="Capacity" class="text-elipsis">{{ service.capacity ? ( service.capacity + ' people') : '' }}</td>
                        <td data-label="Location" class="ovf-hidden">
                            <div class="list">
                                <ng-container *ngFor="let location of getLocations(service.locations); index as i">
                                    <div class="list__item text-elipsis" *ngIf="i < 1 || ( i == 1 && getLocations(service.locations)?.length == 2 )">{{ls._locations[location]?.name}}</div>
                                </ng-container>
                                <ng-container *ngIf="getLocations(service.locations)?.length > 2">
                                    <div class="list__more text-elipsis">+ {{getLocations(service.locations)?.length - 1}} more</div>
                                </ng-container>
                            </div>
                        </td>
                        <td data-label="Room" class="text-elipsis">{{rs._rooms[service.room]?.name}}</td>
                        <td data-label="Staff" class="ovf-hidden">
                            <div class="list staff">
                                <ng-container *ngFor="let staff of getStaff(service.staffs); index as i">
                                    <div class="list__item text-elipsis" *ngIf="i < 2 || ( i == 2 && getStaff(service.staffs)?.length == 3 )">{{us._users[staff]?.name}}</div>
                                </ng-container>
                                <ng-container *ngIf="getStaff(service.staffs)?.length > 3">
                                    <div class="list__more text-elipsis">+ {{getStaff(service.staffs)?.length - 2}} more</div>
                                </ng-container>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>
</div>