<div class="modal-box">
	<form (ngSubmit)="save()">
		<div class="modal-title">{{profile._id&&'Edit'||'Add'}} Alert</div>
		<button mat-icon-button type="button" class="modal-close" (click)="close()">
			<span class="modal-close__icon"></span>
		</button>
		<div class="row">
			<div class="waw-select col-1" *ngIf="us.clients.length">
				<span class="waw-select__title">Select client *</span>
				<app-select 
					placeholder="Select client" 
					[(value)]="profile.client"
					[items]="clients || []" 
					[clearable]="false" 
					[searchable]="true"
					bindLabel="name" 
					bindValue="_id"
					[required]="submitted&&!profile.client"
				>
					<ng-template label let-item>
						<div class="select-option">
							<span class="select-option__name">{{item?.name}}</span>
							<span *ngIf="item?.deleted" class="select-option__status"> (Deleted)</span>
						</div>
					</ng-template>
					<ng-template option let-item>
						<div class="select-option">
							<span class="select-option__name">{{item.name}}</span>
							<div *ngIf="!item.deleted" class="select-option__email">{{item.email}}</div>
							<span *ngIf="item.deleted" class="select-option__status"> (Deleted)</span>
						</div>
					</ng-template>
				</app-select>
				<div *ngIf="submitted&&!profile.client" class="req-text">Client is required</div>
			</div>
		</div>
		<div class="row">
			<div class="waw-textarea col-1">
				<div [class.req]="submitted&&!profile.description || characterLimit">
					<note-editor [notes]="noteEditor" placeholder="Please add your alert here..." label="Alert *" size="small" (characterLimit)="characterLimit=$event" (onChange)="noteEditor = $event"></note-editor>
					<div *ngIf="submitted&&!profile.description || characterLimit" class="req-text">{{characterLimit ? characterLimit : 'Message is required'}}</div>
				</div>
			</div>
		</div>
		<div class="modal-btn">
			<button mat-button color="primary" type="button" *ngIf="!profile._id" (click)="close()" class="waw-btn _second">Cancel</button>
			<button mat-button color="primary" type="button" *ngIf="profile._id" (click)="delete()" class="waw-btn _second _delete">Delete</button>
			<button mat-flat-button color="primary" type="submit" class="waw-btn _primary">{{profile._id&&'Save'||'Create'}}</button>
		</div>
	</form>
</div>
