import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from '@services';
import { HttpService } from 'wacom';
@Component({
  selector: 'app-confirmappointment',
  templateUrl: './confirmappointment.component.html',
  styleUrls: ['./confirmappointment.component.scss']
})
export class ConfirmappointmentComponent implements OnInit {
	public data:any = this.router.url.toLowerCase().replace('/confirm_appointment/', '').split("/");
	public confirmed = {title: '', text: ''};
	constructor(public router: Router, public http: HttpService, private loader: LoaderService) { }

	ngOnInit(): void {
		this.loader.show({});
		this.http.post('/api/appointment/confirm_appointment', {_id: this.data[1]}, (resp)=> {
			this.loader.remove();
			this.confirmed = resp;
		});	
	}
}
