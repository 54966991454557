import { Component, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { UserService, LocationService, LoaderService } from '@services';
import { ModalService, MongoService } from 'wacom';
import { Router,ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-locations',
	templateUrl: './locations.component.html',
	styleUrls: ['./locations.component.scss', '../setup.component.scss']
})
export class LocationsComponent implements OnInit, AfterViewInit {
	
	edit(profile){
		if(this.us.is.admin||this.us.is.owner) {
			this.router.navigate([],{ queryParams: { modal: 'open' } });
			this.modal.show({component: 'locationprofile', profile: JSON.parse(JSON.stringify(profile))}); 
		}
	}
	
	constructor(public modal: ModalService,
		public ls: LocationService,
		public us: UserService,
		public router: Router,
		private route: ActivatedRoute,
		public datePipe: DatePipe,
		private loader: LoaderService,
		private mongo: MongoService,
		private eref: ElementRef) {
		this.us.headerTitle = 'Locations';
		this.us.headerSubtitle = 'Setup';
	}
	ngOnInit() {
		this.loader.show({container: true}, this.eref.nativeElement.querySelector('.containerBox'));
	}
	ngAfterViewInit() {
		this.mongo.on('location', () => {
			this.loader.remove();
		});
	}
	ngDoCheck(){
		if( this.route.snapshot.queryParams['modal']){
		}
		else{
			this.modal.destroy()
		}
	}
	openModal(){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({ component: 'locationprofile'})		
	}

	getBusinessHours(hours) {
		if( hours?.length ) {
			const res = [];
			for(const [index, hour] of hours.entries()) {
				const from = new Date();
				const to = new Date();
				from.setHours(hour.from.split(':')[0], hour.from.split(':')[1]);
				to.setHours(hour.to.split(':')[0], hour.to.split(':')[1]);
				res.push(this.datePipe.transform(from, 'shortTime') + ' - ' + this.datePipe.transform(to, 'shortTime') + ((index != hours.length - 1) ? ' / ' : ''));
			}
			return res;
		} else return ['Day off'];
	}

	delete(location) {
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({
			component: 'deleteAlert',
			title: 'Delete location',
			body: `Are you sure you want to delete location ${location.name}`,
			on_add: ()=> {
				this.loader.show({container: true, transparent: true}, this.eref.nativeElement.querySelector('.containerBox'));
				this.ls.delete(location, () => {
					this.loader.remove();
				});
			}
		});
	}
}
