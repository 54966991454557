<!-- ===  Clients List Table === -->
<div class="table-container tablet-col3 mobile-col3">
	<table class="table" [class.navShown]="!us.sidebarToggle" cellpadding="0" cellspacing="0">
		<thead>
			<tr>
                <th scope="col" class="sort" (click)="sort('name')">
					<div class="ai-c">
						<span>Staff</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'name'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'name'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
                <th scope="col" class="sort" (click)="sort('data.address')">
					<div class="ai-c">
						<span>Address</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'data.address'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'data.address'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
                <th scope="col" class="sort" (click)="sort('email')">
					<div class="ai-c">
						<span>Email</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'email'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'email'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
                <th scope="col" class="sort" (click)="sort('phone')">
					<div class="ai-c">
						<span>Phone</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'phone'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'phone'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
                <th scope="col" class="sort" (click)="sort('data.birthday.singleDate.epoc')">
					<div class="ai-c">
						<span>Date of Birth</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'data.birthday.singleDate.epoc'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'data.birthday.singleDate.epoc'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
                <th scope="col">Appoint</th>
                <th scope="col">Notes</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let user of us.staff|search: searching: 'name email inc data.address data.phone data.additionalPhone data.gender.label data.birthday.singleDate.formatted data.note'|sort:sort_type:us.now">
                <td data-label="Name" class="ovf-hidden" [tooltip]="user.name" tooltipClass="tableTooltip" tooltipShowDelay="200">
					<div class="table__user">
						<div class="table__user__img" [class._letter]="!user.avatarUrl">
							<picture [user]="user"></picture>
						</div>
						<div class="table__user__info">
							<span class="table__user__info-name">{{user.firstName}} <wbr> {{user.lastName}}</span>
							<div class="table__user__info-desc">{{user.inc}}</div>
						</div>
					</div>
				</td>
				<td data-label="Address" class="text-elipsis" [tooltip]="user.data.address" tooltipClass="tableTooltip" tooltipShowDelay="200">{{user.data.address}}</td>
				<td data-label="Email" class="text-elipsis" [tooltip]="user.email" tooltipClass="tableTooltip" tooltipShowDelay="200">{{user.email}}</td>
				<td data-label="Phone" class="text-elipsis" [tooltip]="user.data.phone" tooltipClass="tableTooltip" tooltipShowDelay="200">{{user.data.phone}}</td>
				<td data-label="Date of Birth" class="text-elipsis" [tooltip]="user.data.birthday?.singleDate?.formatted||''" tooltipClass="tableTooltip" tooltipShowDelay="200">{{user.data.birthday?.singleDate?.formatted||''}}</td>
				<td data-label="Appoint">
                    <label class="waw-checkbox mb0">
                        <input type="checkbox" [(ngModel)]="user.appointment" (ngModelChange)="us.save(user)" [disabled]="!(us.is.admin||us.is.owner)&&user._id != us._id">
                        <span class="checkmark"></span>
                    </label>
                </td>
				<td data-label="Note" style="grid-column: span 2;">
					<div class="note _limit" #Note [innerHtml]="user.data.note | note" [class._more]="Note.scrollHeight > Note.clientHeight" (click)="Note.scrollHeight > Note.clientHeight ? Note.classList.add('_all') : !Note.classList.contains('_all') && Note.classList.remove('_all')"></div>
					<button class="note-btn" *ngIf="Note.classList.contains('_all') || Note.scrollHeight > Note.clientHeight" (click)="Note.classList.toggle('_all')">{{Note.scrollHeight > Note.clientHeight ? 'More' : 'Less'}}</button>
				</td>
                <td>
                    <div class="actions">
                        <button *ngIf="us.is.admin||us.is.owner||user._id == us._id" mat-icon-button color="primary" tooltip="Edit" tooltipMobileDisabled="true" aria-label="Edit" (click)="edit(user);" class="waw-btn__icon primary mr10 _tablet">
                            <span class="icon-pencil"></span>
                        </button>
                        <button *ngIf="us.is.admin||us.is.owner" mat-icon-button color="warn" tooltip="Delete" tooltipMobileDisabled="true" aria-label="Delete" (click)="delete(user);" class="waw-btn__icon warn _tablet">
                            <span class="icon-delete"></span>
                        </button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
