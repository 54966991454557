<div class="containerBox flex-column">
	<div class="navigation" [class.navShown]="!us.sidebarToggle">
		<div class="navigation__topbar display-sm">
			<button mat-button color="primary" type="button" class="topbar-user-btn waw-btn _second display-sm" (click)="us.userPopup = !us.userPopup;">
				<span class="icon-plus"></span>
				<span>New client</span>
			</button>
		</div>
		
		<div class="navigation__search">
			<datepicker type="dashboard" [date]="selector" [interval]="interval" [readonly]="true" (valueChange)="periodChange($event)"></datepicker>
		</div>

		<div class="navigation__tab">
			<tab [(value)]="tab" [tabs]="tabs"></tab>
		</div>

		<div class="navigation__right _filter">
			<div class="navigation__filter">
				<div class="navigation__filter-location">
					<app-select 
						placeholder="Location" 
						[(value)]="location" 
						(valueChange)="locationChange($event)" 
						[items]="loc.locations" 
						bindLabel="name" 
						bindValue="_id" 
						icon="location" 
						class="_filter"
					></app-select>
				</div>
			</div>
		</div>
	</div>

	<div class="containerTab fl-grow">
		<!-- Overview Tab -->
		<ng-container *ngIf="tab == 'overview'">
			<app-dashboard-overview
				[interval]="interval"
				[selector]="selector"
				[location]="location"
			></app-dashboard-overview>
		</ng-container>
	
		<!-- Appointments Tab -->
		<ng-container *ngIf="tab == 'appointments'">
			<app-dashboard-appointments
				[interval]="interval"
				[selector]="selector"
				[location]="location"
			></app-dashboard-appointments>
		</ng-container>
	
		<!-- Sales Tab -->
		<ng-container *ngIf="tab == 'sales'">
			<app-dashboard-sales
				[interval]="interval"
				[selector]="selector"
				[location]="location"
			></app-dashboard-sales>
		</ng-container>
	
		<!-- Clients Tab -->
		<ng-container *ngIf="tab == 'clients'">
			<app-dashboard-clients
				[interval]="interval"
				[selector]="selector"
				[location]="location"
			></app-dashboard-clients>
		</ng-container>
	
		<!-- Staff Tab -->
		<ng-container *ngIf="tab == 'staff'">
			<app-dashboard-staff
				[interval]="interval"
				[selector]="selector"
				[location]="location"
			></app-dashboard-staff>
		</ng-container>
	</div>
</div>
