import { Injectable } from '@angular/core';
import { CoreService } from 'wacom';

@Injectable({
	providedIn: 'root'
})
export class HashService {
    private replaces = [{
		from: '%20',
		to: ' '
	}];
    public hash:any = {};
    private done: boolean = false;
	constructor(private core: CoreService) {
		if(!this.core.window.location.hash && !sessionStorage.getItem(this.core.window.location.pathname)){
			this.done = true;
			return;
		}
		this.load();
		this.done = true;
	}
    load(){
		this.hash = {};
        let hash:any;
        if(this.core.window.location.hash) {
            hash = this.core.window.location.hash.replace('#!#', '').replace('#', '').split('&');
            for(let i = 0; i < hash?.length; i++){
                if(hash[i].indexOf('=') != -1) {
                    let holder = hash[i].split('=')[0];
                    let value = hash[i].split('=')[1];
                    for(let j = 0; j < this.replaces.length; j++){
                        holder = holder.split(this.replaces[j].from).join(this.replaces[j].to);
                        value = value.split(this.replaces[j].from).join(this.replaces[j].to);
                    }
                    this.hash[holder] = value;
                } else {
                    let holder = hash[i];
                    for(let j = 0; j < this.replaces.length; j++){
                        holder = holder.split(this.replaces[j].from).join(this.replaces[j].to);
                    }
                    this.hash[holder] = true;
                }
            }
        }
	}
    set(field:any, value?:any) {
        this.load();
        this.hash[field] = value ? value : true;
        this.save();
    }
    save(){
        let hash = '';
		for(let each in this.hash){
            if(hash) hash += '&';
            if(this.hash[each] !== true) hash += each + '=' + this.hash[each];
            else hash += each;
		}
		this.core.window.location.hash = hash;
	}
    on(field:any, cb = (resp:any)=>{}):any{
        this.load();
        if(!this.done) return setTimeout(()=>{
			this.on(field, cb);
		}, 100);
		cb(this.hash[field]);
        this.save();
	}
    get(field:any){
        this.load();
		return this.hash[field];
	}
    remove(field:any) {
        this.load();
        delete this.hash[field];
        this.save();
    }
}