import { Component, Input } from '@angular/core';
@Component({
	selector: 'picture',
	templateUrl: './picture.component.html',
	styleUrls: ['./picture.component.scss']
})
export class PictureComponent{
	@Input() user:any = {};
	@Input() big:boolean = false;
	constructor() {}
}
