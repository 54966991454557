import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
	selector: 'app-redirect',
	templateUrl: './redirect.component.html',
	styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {
	constructor(private router: Router) { }

	ngOnInit(): void {
		const text = new URL(window.location.href).searchParams.get('text');
    	const social_media = new URL(window.location.href).searchParams.get('social_media');
    	const error = new URL(window.location.href).searchParams.get('error');

		if (text) {
			window.opener.postMessage({ text, social_media }, window.location.origin);
			window.close();
		} else if (error) {
			window.opener.postMessage({ error }, window.location.origin);
			window.close();
		} else {
			// Handle cases where token or error is not present
			this.router.navigate(['/']);
		}
	}
}
