import { Component, OnInit } from '@angular/core';
import { ClosedService, LoaderService, LocationService, UserService } from '@services';
import { AlertService, ModalService } from 'wacom';
import DateHolidays from 'date-holidays';
import { DatePipe } from '@angular/common';
import * as  moment  from 'moment-timezone'

@Component({
	selector: 'closed',
	templateUrl: './closed.component.html',
	styleUrls: ['./closed.component.scss']
})
export class ClosedComponent implements OnInit{
	public profile:any = {
		substitute: true
	};
	public descriptionEditor: any;
	public close;
	public submitted:Boolean;
	public holidays: any = [];
	public characterLimit: string = null;

	constructor(public cs: ClosedService,
		public us: UserService,
		public ls: LocationService,
		public alert: AlertService,
		public modal: ModalService,
		private datePipe: DatePipe,
		private loader: LoaderService) {}

	ngOnInit(): void {
		if (this.profile.description) this.descriptionEditor = JSON.parse(this.profile.description);
		this.initializeHolidays();
	}
	save() {
		this.submitted = true;
		if(!this.profile.name||!this.profile.locations||this.profile.locations&&!this.profile.locations.length||!this.profile.start||!this.profile.end) return this.alert.error({
			text: "Please fill required fields",
		});
		if(this.characterLimit) return this.alert.error({
			text: "Exceeded maximum character limit",
		});

		this.loader.show({ 
			modal: true,
			transparent: true,
			preventClick: true
		}, document.querySelector('.modal-box'));

		if (this.descriptionEditor) this.profile.description = JSON.stringify(this.descriptionEditor);
		this.cs.create(this.profile, true, () => {
			this.loader.remove();
			this.close();
		});
	}
	delete() {
		this.modal.show({
			component: 'deleteAlert',
			title: 'Delete closed date',
			body: `Are you sure you want to delete closed date ${this.profile.name}`,
			on_add: ()=> {
				this.loader.show({ 
					modal: true,
					transparent: true,
					preventClick: true
				}, document.querySelector('.modal-box'));

				this.cs.delete(this.profile, () => {
					this.loader.remove();
					this.close();
				});
			}
		});
	}

	initializeHolidays() {
		this.holidays = [];

		if( this.profile.locations?.length ) {
			this.profile.locations.forEach((l: any) => {
				this.cs.getHolidays(this.ls._locations[l]?.country, this.ls._locations[l]?.state, this.ls._locations[l]?.timezone).forEach((holiday: any) => {
					if( !this.holidays.find((h) => { return h.name === holiday.name && h.date === holiday.date }) ) {
						this.holidays.push(holiday);
					}
				});
			});
		}
	}

	holidaySelected(event) {
		if(event) {
			const holiday = this.holidays.find(h => h.name === event);
			this.profile.holiday = holiday;

			this.profile.start = {
				isRange: false,
				dateRange: null,
				singleDate: {
					date: {
						year: new Date(holiday.start).getFullYear(),
						month: new Date(holiday.start).getMonth() + 1,
						day: new Date(holiday.start).getDate()
					},
					jsDate: new Date(holiday.start),
					formatted: this.datePipe.transform(new Date(holiday.start), 'M/d/yyyy'),
					epoc: new Date(holiday.start).getTime()
				}
			};
			this.profile.end = {
				isRange: false,
				dateRange: null,
				singleDate: {
					date: {
						year: new Date(holiday.end).getFullYear(),
						month: new Date(holiday.end).getMonth() + 1,
						day: new Date(holiday.end).getDate()
					},
					jsDate: new Date(holiday.end),
					formatted: this.datePipe.transform(new Date(holiday.end), 'M/d/yyyy'),
					epoc: new Date(holiday.end).getTime()
				}
			};

			holiday.possible_substitute ? this.profile.substitute = true : this.profile.substitute = false;
		} else {
			this.profile.substitute = false;
		}
	}
}
