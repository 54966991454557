import { Component, OnInit } from '@angular/core';
import { BrandService, CategoryService, DiscountService, LoaderService, ProductService, TaxService } from '@services';
import { AlertService, FileService, ModalService } from 'wacom';
@Component({
	selector: 'product',
	templateUrl: './product.component.html',
	styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit{
	public noteEditor: any;
	public new_product:any = {};
	public close;
	public submitted:Boolean;
	public characterLimit: string = null;
	constructor(public ps: ProductService, 
		public ds: DiscountService, 
		public cs: CategoryService, 
		public bs: BrandService, 
		public ts: TaxService,
		public alert: AlertService,
		public modal: ModalService,
		public fs: FileService,
		private loader: LoaderService
		) {
		}

	ngOnInit(): void {
		if (this.new_product.description) this.noteEditor = JSON.parse(this.new_product.description);
	}
	save() {
		this.submitted=true; 
		if(!this.new_product.name) return this.alert.error({
			text: "Please fill required fields",
		});
		if(this.characterLimit) return this.alert.error({
			text: "Exceeded maximum character limit",
		});

		this.loader.show({ 
			modal: true,
			transparent: true,
			preventClick: true
		}, document.querySelector('.modal-box'));

		if (this.noteEditor) this.new_product.description = JSON.stringify(this.noteEditor);
		this.ps.create(this.new_product, () => {
			this.loader.remove();
			this.close();
		}); 
	}
	fileChangeEvent(event: any): void {
		const file = event.target.files[0];
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = ()=> {
			this.new_product.thumb = reader.result;
		};
	}
	delete() {
		this.modal.show({
			component: 'deleteAlert',
			title: 'Delete product',
			body: `Are you sure you want to delete product ${this.new_product.name}`,
			on_add: ()=> {
				this.loader.show({ 
					modal: true,
					transparent: true,
					preventClick: true
				}, document.querySelector('.modal-box'));

				this.ps.delete(this.new_product, () => {
					this.loader.remove();
					this.close();
				});
			}
		});
	}
}
