import { Component, OnInit, AfterViewChecked, ElementRef, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { UserService, MessageService, LoaderService } from '@services';
import { MongoService } from 'wacom';
import { modalAnimation } from 'src/app/common/animation/animation.component';

@Component({
	selector: 'app-messanger',
	templateUrl: './messanger.component.html',
	styleUrls: ['./messanger.component.scss'],
	animations: [modalAnimation]
})
export class MessangerComponent implements OnInit, AfterViewChecked, AfterViewInit {
	constructor(public us: UserService, public ms: MessageService,
		private mongo: MongoService,
		private loader: LoaderService,
		private eref: ElementRef) {
		this.mongo.on('message user', this.scrollToBottom.bind(this));
		this.us.headerTitle = 'customer messages';
		this.us.headerSubtitle = '';
	}
	public searching = '';
	public chat_id: any;
	public user_id: any;
	public text: any = '';
	public emojiOpen: boolean = false;
	public perLine: number = 9;
	public scroll: boolean = true;
	set_user(user_id) {
		this.user_id = user_id;
		let chat_id = user_id + this.us._id;
		if (this.us._id > user_id) chat_id = this.us._id + user_id;
		if (this.chat_id == chat_id) this.chat_id = null;
		else this.chat_id = chat_id;
		this.ms.view_all(chat_id);
		this.scroll = true;
	}
	ngOnInit() {
		this.loader.show({container: true}, this.eref.nativeElement.querySelector('.containerBox'));
		this.scrollToBottom();
		window.innerWidth <= 575 ? this.perLine = 7 : this.perLine = 9;
	}
	ngAfterViewInit() {
		this.mongo.on('message user', () => {
			this.loader.remove();
		});
	}
	ngAfterViewChecked() {
		this.scrollToBottom();
	}
	@ViewChild('messages') private container: ElementRef;
	scrollToBottom(smooth = false): void {
		if(this.scroll) {
			try {
				if(smooth) this.container.nativeElement.scrollTo({top: this.container.nativeElement.scrollHeight, behavior: "smooth"});
				else this.container.nativeElement.scrollTop = this.container.nativeElement.scrollHeight;
				this.scroll = false;
			} catch (err) { }
		}
	}
	create(message) {
		if (this.text.length >= 1 && this.text.replace(/^\s+|\s+$/g, '')) {
			this.ms.create(message, () => {
				setTimeout(() => {
					this.scroll = true;
					this.scrollToBottom(true);
				}, 10);
			});
		}
	}
	emojiSelect($event): void {
		this.text += $event.emoji.native;
		this.emojiOpen = false;
	}
	isEmoji(text) {
		var emojiPattern = /^[\uD83C-\uDBFF\uDC00-\uDFFF\uD800-\uDBFF\uDC00-\uDFFF\u2600-\u26FF\u2700-\u27BF🏴‍☠️]+$/;
  		return emojiPattern.test(text);
	}

	@HostListener('window:resize')
	onWindowResize() {
		window.innerWidth <= 575 ? this.perLine = 7 : this.perLine = 9;
	}
}
