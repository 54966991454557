import { Injectable } from '@angular/core';
import { MongoService, AlertService } from 'wacom';

@Injectable({
	providedIn: 'root'
})
export class ReasonService {
	public reasons: any = [];
	public _reasons: any = {};
	constructor(private alert: AlertService,private mongo: MongoService) { 
		this.reasons = mongo.get('reason', (arr, obj) => {
			this._reasons = obj;
		});
	}
	create(reason, cb: any = () => {}) {
		if(reason._id) return this.update(reason, cb);
		this.mongo.create('reason', reason, (created)=>{
			if(typeof cb === 'function') cb(created);
			this.alert.show({
				text: 'Reason has been created.'
			});
		});
	}
	update(reason, cb: any = () => {}) {
		this.mongo.afterWhile(reason, ()=> {
			this.mongo.update('reason', reason, (updated) => {
				if(typeof cb === 'function') cb(updated);
			});
		});
	}
	delete(reason, cb: any = () => {}) {
		this.mongo.delete('reason', reason, (deleted) => {
			if(typeof cb === 'function') cb(deleted);
		});
	}
}
