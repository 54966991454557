import { Component } from '@angular/core';
import { CategoryService, UserService, WebsiteService } from '@services';
@Component({
	selector: 'websiteprofile',
	templateUrl: './websiteprofile.component.html',
	styleUrls: ['./websiteprofile.component.scss']
})
export class WebsiteprofileComponent{
	public profile:any = {};
	public close;
	public icons:any = ['Instagram','Facebook','Website'];
	constructor(public cs: CategoryService,
		public ws: WebsiteService,
		public us: UserService) {}
}
