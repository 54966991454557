import { Component, HostListener, OnInit, AfterViewInit, ElementRef, EventEmitter } from '@angular/core';
import { UserService, LocationService, ServiceService, HashService, LoaderService } from '@services';
import { MongoService, HttpService, AlertService, ModalService } from 'wacom';
import { ActivatedRoute , NavigationEnd, Router  } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SocialAuthService } from './social.service';
@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss'],
	providers: [SocialAuthService]
})
export class ProfileComponent implements OnInit, AfterViewInit {
	public tab = 'edit';
	public noteEditor: any;
	public descriptionEditor: any;
	public model: any = null;
	public email;
	public characterLimit: string = null;
	constructor(public us: UserService,
		public ls: LocationService,
		private mongo: MongoService,
		public hash: HashService,
		private http: HttpService,
		private alert: AlertService,
		private route: ActivatedRoute,
		private router: Router,
		private modal: ModalService,
		public ss: ServiceService,
		private loader: LoaderService,
		private eref: ElementRef,
		private social: SocialAuthService ) {
		this.us.headerTitle = 'My Profile'
		this.us.headerSubtitle = '';
		this.hash.on('tab', tab => this.tab = tab || 'edit');
		this.route.queryParams.subscribe(params => {
			if (params.confirm_email) {
				sessionStorage.setItem('confirm_email', params.confirm_email);
				this.router.navigate([], { fragment: 'tab=email' });
			} else {
				this.router.events
				.pipe(
					filter(event => event instanceof NavigationEnd)
					)
					.subscribe((event: NavigationEnd) => {
						if(event.urlAfterRedirects.split('#')[1]) {
							const hash = event.urlAfterRedirects.split('#')[1].split('=')[0];
							const value = event.urlAfterRedirects.split('#')[1].split('=')[1];
							if (hash == 'tab') this.tab = value || 'edit';
						}
					});
				if(sessionStorage.getItem('confirm_email')) {
					if (this.us.data.verify) this.step[1] = 'completed';
					if (this.us.data.new_email) this.step[2] = 'completed';
					this.http.post('/api/user/email/confirm', {pin: sessionStorage.getItem('confirm_email')}, (resp: any) => {
						if (resp == true) {
							sessionStorage.removeItem('confirm_email')
							this.alert.show({
								text: "Email address has been successfully changed"
							});
							this.us.getUsers(() => {
								this.us.email = this.us._users[this.us._id].email;
							});
						} else {
							this.alert.error({
								text: resp
							});
						}
					});
				}
			}
		});
		this.route.queryParams.subscribe(params => {
			this.mongo.on('location user service', () => {
				if(params.registration) {
					if( (this.us.is.admin || this.us.is.owner) && (!this.ss.services.length || !this.us.staff.length || !this.ls.locations.length) ) {
						this.modal.show({
							component: 'registration',
							closable: false,
							onClickOutside: () => {}
						});
					} else {
						this.router.navigate(['/profile']);					
					}
				}
			});
		});
	}

	ngOnInit(): void {
		this.loader.show({container: true}, this.eref.nativeElement.querySelector('.containerBox'));
		this.mongo.on('user', () => {
			if (this.us.data.note) this.noteEditor = JSON.parse(this.us.data.note);
			if (this.us.data.description) this.descriptionEditor = JSON.parse(this.us.data.description);
		});
	}
	ngAfterViewInit() {
		this.mongo.on('user location', () => {
			this.loader.remove();
		});
	}
	update(): void {
		// if (this.noteEditor) this.us.data.note = JSON.stringify(this.noteEditor);
		if(this.characterLimit) return this.alert.error({
			text: "Exceeded maximum character limit",
		});
		if (this.descriptionEditor) this.us.data.description = JSON.stringify(this.descriptionEditor);
		this.us.update();
	}

	public step: any = {
		1: 'next',
		2: 'next',
		3: 'next'
	}
	// next, current, completed
	public user: any = {
		code: '',
		email: ''
	}
	onStart() {
		this.http.post('/api/user/email/request', {}, (resp: any) => {
			if (resp == true) {
				this.step[1] = 'current';
			}
		});
	}
	onEnterCode() {
		if (this.user.code.length == 6) {
			this.http.post('/api/user/email/verify', {pin: this.user.code}, (resp: any) => {
				if(resp == true) {
					this.step[1] = 'completed';
					this.step[2] = 'current';
				} else {
					this.alert.error({
						text: resp
					});
				}
			});
		}
	}
	onEnterEmail() {
		this.http.post('/api/user/email/new', {email: this.user.email}, (resp: any) => {
			if(resp == true) {
				this.step[2] = 'completed';
				this.step[3] = 'current';
			} else {
				this.alert.error({
					text: resp
				});
			}
		});
	}

	@HostListener('window:popstate', ['$event'])
    onPopState(event: PopStateEvent) {
		this.tab = this.hash.get('tab') || 'edit';
    }

	googleConnect() {
		this.social.googleAuthenticate().then((res: any) => {
			if(res.text) this.alert.show({text: res.text});
			if(res.social_media === 'google') this.us.google = true;
		}).catch((error) => {
			this.alert.error({text: error});
		});
	}

	googleDisconnect() {
		this.modal.show({
			component: 'deleteAlert',
			title: 'Disconnect your Google account?',
			body: `You will no longer be able to use this Google account to sign in`,
			primary_btn: 'Disconnect',
			on_add: ()=> {
				this.loader.show({container: true, transparent: true}, this.eref.nativeElement.querySelector('.containerBox'));
				this.social.googleDisconnect((resp) => {
					this.loader.remove();
					if(resp) {
						this.us.google = false;
						this.alert.show({text: 'Successfully disconnected account'});
					} else {
						this.alert.error({text: 'Failed to disconnect account'});
					}
				});
			}
		});
	}

	facebookConnect() {
		this.social.facebookAuthenticate().then((res: any) => {
			if(res.text) this.alert.show({text: res.text});
			if(res.social_media === 'facebook') this.us.fb = true;
		}).catch((error) => {
			this.alert.error({text: error});
		});
	}

	facebookDisconnect() {
		this.modal.show({
			component: 'deleteAlert',
			title: 'Disconnect your Facebook account?',
			body: `You will no longer be able to use this Facebook account to sign in`,
			primary_btn: 'Disconnect',
			on_add: ()=> {
				this.loader.show({container: true, transparent: true}, this.eref.nativeElement.querySelector('.containerBox'));
				this.social.facebookDisconnect((resp) => {
					this.loader.remove();
					if(resp) {
						this.us.fb = false;
						this.alert.show({text: 'Successfully disconnected account'});
					} else {
						this.alert.error({text: 'Failed to disconnect account'});
					}
				});
			}
		});
	}
}
