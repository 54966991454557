<div class="modal-box">
	<div class="modal-title">New Edit Resource</div>
	<button mat-icon-button type="button" class="modal-close" (click)="close()">
		<span class="modal-close__icon"></span>
	</button>
	<div class="row">
		<div class="waw-input col-2">
			<label>
				<span class="waw-input__title">Resource name</span>
				<input type="text" placeholder="Resource name" name="Resource name" [(ngModel)]="profile.name">
			</label>
		</div>
		<div class="waw-select col-2">
			<span class="waw-select__title">Service</span>
			<app-select 
				placeholder="Select Service" 
				[(value)]="profile.service"
				[items]="[]" 
				[clearable]="false" 
				[searchable]="false"
			></app-select>
		</div>
	</div>
	<div class="row">
		<div class="waw-input col-2">
			<label>
				<span class="waw-input__title">quantity</span>
				<input type="number" placeholder="Quantity" name="quantity" [(ngModel)]="profile.quantity">
			</label>
		</div>
		<div class="waw-select col-2">
			<span class="waw-select__title">location</span>
			<app-select 
				placeholder="Select location" 
				[(value)]="profile.room"
				[items]="[]" 
				[clearable]="false" 
				[searchable]="false"
			></app-select>
		</div>
	</div>
	<div class="row">
		<div class="waw-textarea col-1">
			<label>
				<span class="waw-textarea__title">Description</span>
				<textarea placeholder="Add Description" [(ngModel)]="profile.description"></textarea>
			</label>
		</div>
	</div>
	<div class="modal-btn">
		<button mat-button color="primary" type="button" *ngIf="!profile._id" (click)="close()" class="waw-btn _second">Cancel</button>
		<button mat-button color="primary" type="button" *ngIf="profile._id" (click)="delete()" class="waw-btn _second _delete">Delete</button>
		<button mat-flat-button color="primary" type="submit" class="waw-btn _primary" (click)="rs.create(profile); close();">{{profile._id&&'Save'||'Create'}}</button>
	</div>
</div>
