<div class="month" [class._open]="mp.opened">
    <button mat-icon-button color="primary" type="button" class="btn left" (click)="changeMonth(-1)">
        <span class="btn-icon icon-arrow_left"></span>
    </button>
    <mat-form-field>
        <input (click)="mp.open(); open.emit(true)" readonly matInput [matDatepicker]="mp" [formControl]="monthpicker">
        <mat-datepicker #mp
                        startView="year"
                        (monthSelected)="setMonthAndYear($event, mp)"
                        panelClass="monthpicker"
                        [touchUi]="touchUi">
        </mat-datepicker>
    </mat-form-field>
    <button mat-icon-button color="primary" type="button" class="btn right" (click)="changeMonth(1)">
        <span class="btn-icon icon-arrow_right"></span>
    </button>
</div>