import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MongoService, AlertService } from 'wacom';

@Injectable({
	providedIn: 'root'
})
export class ProductService {
	public products: any = [];
	public _products: any = {};
	public now;
	constructor(private alert: AlertService, private mongo: MongoService, private http: HttpClient) {
		this.load();
	}
	load() {
		this.products = this.mongo.get('product', (arr, obj) => {
			this._products = obj;
			this.now = Date.now()
		});
	}
	create(product, cb: any = () => {}) {
		if(product._id) return this.update(product, cb);
		this.mongo.create('product', product, (createdProduct)=>{
			if(product.thumb && /^data:image\/[a-zA-Z]+;base64,/.test(product.thumb)){
				createdProduct.thumb = product.thumb;
				this.uploadFile(createdProduct, ()=> {
					this.load();
				});
				this.mongo.update('product', createdProduct, ()=> {
					this.now = Date.now()
				});
			}
			if(typeof cb === 'function') cb(createdProduct);
			this.now = Date.now()
			this.alert.show({
				text: 'Product has been created.'
			});
		});
	}
	update(product, cb: any = () => {}) {
		this.mongo.afterWhile(product, ()=> {
			if(product.thumb && /^data:image\/[a-zA-Z]+;base64,/.test(product.thumb)){
				this.uploadFile(product, ()=> {
					this.load();
				});
			}
			this.mongo.update('product', product, (updated)=> {
				if(typeof cb === 'function') cb(updated);
				this.now = Date.now()
			});
		});
	}
	delete(product, cb: any = () => {}) {
		this.mongo.delete('product', product, (deleted)=> {
			if(typeof cb === 'function') cb(deleted);
			this.now = Date.now()
		});
	}
	uploadFile(product, cb=()=>{}) {
		this.http.post < any > ('/api/product/file', {
			dataUrl: product.thumb,
			_id: product._id
		}).subscribe(resp=>{
			product.thumb = resp;
			cb();
		});
	}
}
