<div class="containerBox setup">
	<div class="setup-empty setup-box" *ngIf="!cs.closeds.length">
        <div class="setup-empty__img" style="max-height: 335px;"><img src="assets/img/setup/closed-date-preview.png" alt="closed-date-preview" draggable="false"/></div>
        <div class="setup-empty__title">List the dates your business is closed for public holidays, maintenance or any other reason.<br>Clients will not be able to place online bookings during these days.</div>
        <div class="setup-empty__btn">
			<button mat-flat-button color="primary" class="waw-btn _primary" (click)="openModal()">
				<span class="icon-plus"></span>
				<span>Add closed date</span>
			</button>
		</div>
    </div>
    <div class="setup-container" *ngIf="cs.closeds.length">
        <div class="setup-item setup-box dates-item" *ngFor="let closed of cs.closeds">
            <div class="setup-item__title jc-s">
				<span class="t-hide">{{closed.name}}</span>
				<span *ngIf="closed.holiday && closed.holiday.possible_repeat" class="repeat-icon material-icons-round" title="The holiday automatically repeats">event_repeat</span>
			</div>
			<div class="setup-item__description">
				<div class="note _limit" #Note [innerHtml]="closed.description | note" [class._more]="Note.scrollHeight > Note.clientHeight" (click)="Note.scrollHeight > Note.clientHeight ? Note.classList.add('_all') : !Note.classList.contains('_all') && Note.classList.remove('_all')"></div>
				<button class="note-btn" *ngIf="Note.classList.contains('_all') || Note.scrollHeight > Note.clientHeight" (click)="Note.classList.toggle('_all')">{{Note.scrollHeight > Note.clientHeight ? 'More' : 'Less'}}</button>
			</div>
			<hr>
            <div class="setup-item__actions">
				<button mat-icon-button color="primary" class="action-edit" (click)="edit(closed);"><span class="icon-pencil"></span></button>
				<button mat-icon-button color="warn" class="action-delete" (click)="delete(closed);"><span class="icon-delete"></span></button>
			</div>
			<div class="setup-item__property">
				<div class="setup-item__property__name">Start</div>
				<span class="setup-item__property__value text-elipsis">{{ closed.start?.singleDate?.formatted }}</span>
			</div>
            <div class="setup-item__property">
				<div class="setup-item__property__name">End</div>
				<span class="setup-item__property__value text-elipsis">{{ closed.end?.singleDate?.formatted }}</span>
			</div>
			<ng-container *ngIf="closed.substitute && closed.substitute_start && closed.substitute_end">
				<div class="setup-item__description" style="margin-top: 20px;">Replace</div>
				<hr>
				<div class="setup-item__property">
					<div class="setup-item__property__name">Start</div>
					<span class="setup-item__property__value text-elipsis">{{ closed.substitute_start?.singleDate?.formatted }}</span>
				</div>
				<div class="setup-item__property">
					<div class="setup-item__property__name">End</div>
					<span class="setup-item__property__value text-elipsis">{{ closed.substitute_end?.singleDate?.formatted }}</span>
				</div>
			</ng-container>
        </div>
        <div class="setup-add">
			<button mat-flat-button color="primary" class="waw-btn _primary" (click)="openModal()">
				<span class="icon-plus"></span>
				<span>Add closed date</span>
			</button>
		</div>
    </div>
</div>
