import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { IdleService } from '@services'
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'app';
  private time: any;
  private lastActiveTime: any;
  constructor(private platform: Platform, private idleService: IdleService, private http: HttpClient) {
    this.time = Date.now();
    this.lastActiveTime = Date.now();

    window.addEventListener("storage", (event) => {
      // When local storage 'waw_user' changes, the page is reloaded
      if( event.key == 'waw_user' ) {
        window.location.reload();
      }
    });

    // Checking user authorization for automatic logout
    const authenticated = () => {
      this.http.post('/api/user/check_authorization', {}).subscribe((resp:any) => {
        console.log(resp);
        this.lastActiveTime = Date.now();
        if(!resp) {
          window.location.reload();
        }
      })
    };
    const timeout = 900000; // 15 minutes in milliseconds
    idleService.idle$.subscribe(s => {
      this.lastActiveTime = Date.now() - idleService.idleAfterMilliseconds;
      console.log('im idle, zzz', new Date(this.lastActiveTime).toTimeString());
    });
    idleService.wake$.subscribe(s => {
      console.log('im awake!', new Date(this.lastActiveTime).toTimeString());
      console.log('Date.now()', Date.now());
      console.log('lastActiveTime', this.lastActiveTime);
      console.log('Date.now() - this.lastActiveTime', Date.now() - this.lastActiveTime);
      console.log('Date.now() - this.lastActiveTime > timeout', Date.now() - this.lastActiveTime > timeout);
      if(localStorage.getItem('waw_user') && this.lastActiveTime && Date.now() - this.lastActiveTime > timeout) {
        authenticated();
      }
    });
    document.addEventListener("visibilitychange", () => {
      console.log(document.visibilityState, new Date(this.lastActiveTime).toTimeString());
      if(localStorage.getItem('waw_user') && this.lastActiveTime && document.visibilityState == 'visible' && Date.now() - this.lastActiveTime > timeout) {   
        authenticated();
      }
    });
  }
  ngOnInit() {
    // do not use custom scrolling if the device is mobile
    if(this.platform.ANDROID || this.platform.IOS || navigator.maxTouchPoints > 1) {
      document.querySelector('body').classList.add('_mobile-scrollbar');
    }
  }
  ngAfterViewInit() {
    this.removePreloader();
  }
  removePreloader() {
    if(document.querySelector('#preloader')) {
      setTimeout(() => {
        if (!document.querySelector('#preloader').classList.contains('_close')) document.querySelector('#preloader').classList.add('_close');
        setTimeout(() => {
          if(document.querySelector('#preloader')) document.querySelector('#preloader').remove();
        }, 350);
      }, 1000 - (Date.now() - this.time));
    }
  }
  @HostListener('window:dragover', ['$event'])
  onWindowDragOver(event: DragEvent) {
    event.preventDefault();
    event.dataTransfer!.dropEffect = 'none';
  }
}

// import { Component, OnInit } from '@angular/core';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import {
//   SocialAuthService,
//   GoogleLoginProvider,
//   SocialUser,
// } from 'angularx-social-login';
// @Component({
//   selector: 'app-root',
//   templateUrl: './app.component.html',
//   styleUrls: ['./app.component.scss'],
// })
// export class AppComponent implements OnInit {
//   loginForm!: FormGroup;
//   socialUser!: SocialUser;
//   isLoggedin?: boolean;
//   constructor(
//     private formBuilder: FormBuilder,
//     private socialAuthService: SocialAuthService
//   ) {}
//   ngOnInit() {
//     this.loginForm = this.formBuilder.group({
//       email: ['', Validators.required],
//       password: ['', Validators.required],
//     });
//     this.socialAuthService.authState.subscribe((user) => {
//       this.socialUser = user;
//       this.isLoggedin = user != null;
//       console.log(this.socialUser);
//     });
//   }
//   loginWithGoogle(): void {
//     this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
//   }
//   logOut(): void {
//     this.socialAuthService.signOut();
//   }
// }