import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LoaderService, PlatformService } from '@services';
import { MongoService } from 'wacom';
import { SafeResourceUrl  } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import * as es6printJS from "print-js";
import { saveAs } from "file-saver"

@Component({
	selector: 'invoicetemplate',
	templateUrl: './invoicetemplate.component.html',
	styleUrls: ['./invoicetemplate.component.scss']
})
export class InvoicetemplateComponent implements OnInit{
	public invoice: any = {margin:{}};
	public close;
	@ViewChild('logoInput') logoInput: any;
	constructor(public ps: PlatformService, private mongo: MongoService, private http: HttpClient, private loader: LoaderService) {}
	ngOnInit() {
		this.loader.show({ 
			modal: true,
		}, document.querySelector('.modal-box'));
		this.mongo.on('platform', () => {
			this.loader.remove();
			this.invoice = JSON.parse(JSON.stringify(this.ps.platform.data.invoice));
		});
	}
	save() {
		this.ps.platform.data.invoice = this.invoice;
		this.ps.save();
		this.close();
	}
	public preview: any = false;
	public previewUrl: SafeResourceUrl;
	generatePreview() {
		this.loader.show({ 
			modal: true,
			transparent: true,
			preventClick: true
		}, document.querySelector('.modal-box'));
		this.http.post('/api/invoice/preview', this.invoice, { responseType: 'blob'}).subscribe(resp=> {
			resp.text().then(value => {
				const fileReader = new FileReader();
				fileReader.onload = (event: any) => {
					const arrayBuffer = event.target.result;
					this.previewUrl = new Uint8Array(arrayBuffer);
					this.preview = resp;
				};
				fileReader.readAsArrayBuffer(resp);
			});
			this.loader.remove();
		});
	}

	@ViewChild('pdfViewer', { static: false }) pdfViewer: ElementRef;
	public formats: any = {
		Letter: {
			width: 8.5,
			height: 11 
		},
		Legal: {
			width: 8.5,
			height: 14 
		},
		Tabloid: {
			width: 11,
			height: 17
		},
		Ledger: {
			width: 17,
			height: 11 
		},
		A0: {
			width: 33.1,
			height: 46.8 
		},
		A1: {
			width: 23.4,
			height: 33.1 
		},
		A2: {
			width: 16.54,
			height: 23.4 
		},
		A3: {
			width: 11.7,
			height: 16.54 
		},
		A4: {
			width: 8.27,
			height: 11.7 
		},
		A5: {
			width: 5.83,
			height: 8.27
		},
		A6: {
			width: 4.13,
			height: 5.83
		}
	}
	getHeight() {
		if (this.pdfViewer) {
			const width = this.pdfViewer.nativeElement.clientWidth;
			const format = this.formats[this.invoice.format];
			const coefficient = format.width / format.height;
			return width / coefficient + 10 + 'px';
		} else return '500px';
	}
	onLogoSelected(event) {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = () => {
				this.invoice.logo = reader.result as string;
			};
			reader.readAsDataURL(file);
		}
	}
	public logo_sizes = [
		{
			name: 'Small',
			size: 60
		}, {
			name: 'Medium',
			size: 90
		}, {
			name: 'Large',
			size: 120
		}
	];
	public fonts: any = [
		'Times New Roman',
		'Open Sans',
		'Roboto',
		'Lato',
		'Montserrat',
		'Raleway',
		'Nunito',
		'Source Sans Pro',
		'PT Sans',
		'Playfair Display',
		'Quicksand',
		'Geologica',
		'Poppins',
		'Roboto Slab',
		'Barlow'
	];
	public templates: any = [
		{
			name: 'Professional',
			id: 'professional'
		}, {
			name: 'Classic',
			id: 'classic'
		}, {
			name: 'Modern',
			id: 'modern'
		}, {
			name: 'Minimalist',
			id: 'minimalist'
		}
	];
	onTemplateSelected(event) {
		this.http.post('/api/invoice/template', {template: event}).subscribe(resp=> {
			this.invoice = resp;
		});
	}
	printPreview() {
		es6printJS(URL.createObjectURL(this.preview), 'pdf');
	}
	downloadPreview() {
		saveAs(this.preview, 'Invoice preview.pdf');
	}
	uploadLogo() {
		this.logoInput.nativeElement.click();
	}
	deleteLogo() {
		this.logoInput.nativeElement.value = '';
		this.invoice.logo = '';
	}
}
