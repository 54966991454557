import { Directive, Input, HostBinding, OnInit, ElementRef } from '@angular/core';
import { fromEvent } from 'rxjs';

@Directive({
	selector: '[hideOnScroll]',
})
export class HideOnScrollDirective implements OnInit {
	private lastScrollPosition: number = 0;
	constructor(private eref: ElementRef) {}
	ngOnInit() {
		this.onScroll().subscribe((event: Event)=> {
			const currentScrollPosition = (event.target as HTMLElement).scrollTop;

			if (currentScrollPosition > this.lastScrollPosition) {
				// Scrolling down
				document.querySelectorAll('.navigation__btn, .hideOnScroll').forEach(element => {
					element.classList.add('_hide');
				});
			} else {
				// Scrolling up
				document.querySelectorAll('.navigation__btn, .hideOnScroll').forEach(element => {
					element.classList.remove('_hide');
				});
			}

			this.lastScrollPosition = currentScrollPosition;
		});
	}
	@HostBinding('window:scroll') onScroll() {
		return fromEvent(this.eref.nativeElement, 'scroll');
	}
}