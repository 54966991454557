<div class="containerBox flex-column">
	<div class="navigation without_searching without_filter" [class.navShown]="!us.sidebarToggle" [class.products]="tab == 'products'">

		<div class="navigation__tab">
			<tab [(value)]="tab" [tabs]="tabs"></tab>
		</div>

		<div class="navigation__right">
			<div class="navigation__btn">
				<button mat-flat-button color="primary" class="waw-btn _primary" (click)="openModal()">
					<span class="icon-plus"></span>
					<span [ngSwitch]="tab">
						<ng-container *ngSwitchCase="'products'">New product</ng-container>
						<ng-container *ngSwitchCase="'rooms'">New room</ng-container>
						<ng-container *ngSwitchCase="'categories'">New category</ng-container>
						<ng-container *ngSwitchCase="'brands'">New brand</ng-container>
					</span>
				</button>
			</div>
		</div>
	</div>
	<div class="containerTab fl-grow">
		<app-res-products *ngIf="tab=='products'"></app-res-products>
		<app-res-rooms *ngIf="tab=='rooms'"></app-res-rooms>
		<app-res-categories *ngIf="tab=='categories'"></app-res-categories>
		<app-res-brands *ngIf="tab=='brands'"></app-res-brands>
	</div>
</div>