<div class="auth">
    <div class="auth-container">
        <form class="auth-left" (ngSubmit)="login()">
            <div class="flex-column">
                <h1 class="auth__title">Sign in</h1>
                <div class="auth__buttons">
                    <a class="auth__btn waw-btn _second _not_change_on_mobile" href="api/user/google">
                        <img src="assets/sign/google.svg" alt="Google">
                        <span>Sign in with Google</span>
                    </a>
                    <a class="auth__btn waw-btn _second _not_change_on_mobile" href="api/user/facebook">
                        <img src="assets/sign/facebook.svg" alt="Facebook">
                        <span>Sign in with Facebook</span>
                    </a>
                </div>
                <div class="auth__separator">
                    <hr>
                    <span>or Sign in with Email</span>
                </div>
                <div class="waw-input _not_change_on_mobile">
                    <label [class.req]="auth.submitted.email&&!user.email || auth.error.email" [class.check]="!auth.error.email && user.email && auth.check.email">
                        <div class="fl-sb-c">
                            <span class="waw-input__title">Email *</span>
                            <span *ngIf="auth.submitted.email&&!user.email || auth.error.email" class="req-text">{{auth.error.email ? auth.error.email : 'Enter your email'}}</span>
                        </div>
                        <input type="email" placeholder="Email" name="email" [(ngModel)]="user.email" (input)="auth.validateInputEmail($event);" (focusout)="auth.validateInputEmail($event, true)" autocomplete="email">
                    </label>
                </div>
                <div class="waw-input _not_change_on_mobile">
                    <label [class.req]="auth.submitted.password&&!user.password?.trim() || auth.error.password">
                        <div class="fl-sb-c">
                            <span class="waw-input__title">Password *</span>
                            <span *ngIf="auth.submitted.password&&!user.password?.trim() || auth.error.password" class="req-text">{{auth.error.password ? auth.error.password : 'Enter the password'}}</span>
                        </div>
                        <input type="password" [(ngModel)]="user.password" (ngModelChange)="auth.validateInputPassword()" (focus)="auth.show_visibility_btn = true" (focusout)="auth.validateInputPassword($event); auth.show_visibility_btn = false" placeholder="Password" name="password" autocomplete="current-password">
                    </label>
                    <button mat-icon-button class="visibility" tabindex="-1" [class._show]="auth.show_visibility_btn" type="button" (click)="auth.changeVisibility()">
                        <span [class.icon-visibility]="!auth.visibility" [class.icon-visibility_off]="auth.visibility"></span>
                    </button>
                </div>
                <div class="auth__note">
                    <span></span>
                    <!-- <mat-checkbox color="primary">Remember me</mat-checkbox> -->
                    <a class="auth__note-link" routerLink="/forgot">Forgot password?</a>
                </div>
            </div>
            <div>
                <button mat-flat-button color="primary" type="submit" class="waw-btn _primary _not_change_on_mobile">Login</button>
                <div class="auth__note">
                    <span class="auth__note-text">Not registered yet?</span>
                    <a class="auth__note-link" routerLink="/sign">Create an account</a>
                </div>
            </div>
        </form>
    
        <div class="auth-right">
            <div class="auth__logo">
                <img class="auth__logo-img" width="80" height="80" src="assets/sign/logo.svg" alt="Logo">
                <span class="auth__logo-text notranslate">Pure Planner</span>
            </div>
            <div class="auth__preview-1"><img width="305" height="159" src="assets/sign/preview_1.png"></div>
            <div class="auth__preview-2"><img width="305" height="159" src="assets/sign/preview_2.png"></div>
            <div class="auth__preview-3"><img width="305" height="159" src="assets/sign/preview_3.png"></div>
        </div>
    </div>
</div>