import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { Authenticated, Guest, Admins } from '@services';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@pipes';
import { OverlayModule } from '@angular/cdk/overlay';
import { DatePipe } from '@angular/common';
import { CurrencyPipe } from '@angular/common';
// plugin
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { NgxMaskModule } from 'ngx-mask';
import { NgxEditorModule } from 'ngx-editor';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { NgxCurrencyModule } from "ngx-currency";
import { AngularFireAuthModule } from '@angular/fire/compat/auth'
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging'
import { AngularFireModule } from '@angular/fire/compat'
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
// Common
import { AppComponent } from './app.component';
import { GuestComponent } from './common/guest/guest.component';
import { UserComponent } from './common/user/user.component';
// Pages
import { CheckoutsComponent } from './pages/admin/checkouts/checkouts.component';
import { MailsComponent } from './pages/admin/mails/mails.component';
import { ProfileComponent } from './pages/user/profile/profile.component';
import { RedirectComponent } from './pages/guest/redirect/redirect.component';
import { LoginComponent } from './pages/guest/login/login.component';
import { ForgotComponent } from './pages/guest/forgot/forgot.component';
import { ResetComponent } from './pages/guest/reset/reset.component';
import { SaveComponent } from './pages/guest/save/save.component';
import { SignComponent } from './pages/guest/sign/sign.component';
import { ClientsComponent } from './pages/user/clients/clients.component';
import { ServiceHistoryComponent } from './pages/user/clients/service-history/service-history.component';
import { NotesComponent } from './pages/user/clients/notes/notes.component';
import { ClientsListComponent } from './pages/user/clients/clients-list/clients-list.component';
import { ClientsProfileComponent } from './pages/user/clients/clients-profile/clients-profile.component';
import { StaffComponent } from './pages/user/staff/staff.component';
import { WorkingHoursComponent } from './pages/user/staff/working-hours/working-hours.component';
import { StaffInformationComponent } from './pages/user/staff/staff-information/staff-information.component';
import { UsersComponent } from './pages/user/setup/users/users.component';
import { ClosedDatesComponent } from './pages/user/setup/closed-dates/closed-dates.component';
import { ServicesComponent } from './pages/user/services/services.component';
import { ResourcesComponent } from './pages/user/resources/resources.component';
import { ResProductsComponent } from './pages/user/resources/res-products/res-products.component';
import { ResRoomsComponent } from './pages/user/resources/res-rooms/res-rooms.component';
import { ResCategoriesComponent } from './pages/user/resources/res-categories/res-categories.component';
import { ResBrandsComponent } from './pages/user/resources/res-brands/res-brands.component';
import { SetupComponent } from './pages/user/setup/setup.component';
import { DiscountComponent } from './pages/user/setup/discount/discount.component';
import { TaxesComponent } from './pages/user/setup/taxes/taxes.component';
import { PaymentComponent } from './pages/user/setup/payment/payment.component';
import { CancelReasonComponent } from './pages/user/setup/cancel-reason/cancel-reason.component';
import { LocationsComponent } from './pages/user/setup/locations/locations.component';
import { SocialComponent } from './pages/user/setup/social/social.component';
import { AlertsComponent } from './pages/user/setup/alerts/alerts.component';
import { QuestionnaireComponent } from './pages/user/setup/questionnaire/questionnaire.component';
import { ResourcesSetupComponent } from './pages/user/setup/resources-setup/resources-setup.component';
import { OnlineLinkComponent } from './pages/user/setup/online-link/online-link.component';
import { CalendarComponent } from './pages/user/calendar/calendar.component';
import { DashboardComponent } from './pages/admin/dashboard/dashboard.component';
import { NotificationsComponent } from './pages/admin/notifications/notifications.component';
import { DashboardOverviewComponent } from './pages/admin/dashboard/dashboard-overview/dashboard-overview.component';
import { DashboardAppointmentsComponent } from './pages/admin/dashboard/dashboard-appointments/dashboard-appointments.component';
import { DashboardSalesComponent } from './pages/admin/dashboard/dashboard-sales/dashboard-sales.component';
import { DashboardClientsComponent } from './pages/admin/dashboard/dashboard-clients/dashboard-clients.component';
import { DashboardStaffComponent } from './pages/admin/dashboard/dashboard-staff/dashboard-staff.component';
// config
import { WacomModule, MetaGuard, Config } from 'wacom';
import { AppointmentComponent } from './modals/appointment/appointment.component';
import { AppointmentComponent as AppointmentAlertComponent } from './alerts/appointment/appointment.component';
import { LunchComponent } from './modals/lunch/lunch.component';
import { ClientComponent } from './modals/client/client.component';
import { ServiceComponent } from './modals/service/service.component';
import { MessangerComponent } from './pages/user/messanger/messanger.component';
import { PictureComponent } from './common/picture/picture.component';
import { NoteComponent } from './modals/note/note.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { WorkingDaysComponent } from './modals/working-days/working-days.component';
import { WorkingHoursComponent as WorkingHours } from './modals/working-hours/working-hours.component';
import { BusinessHoursComponent } from './modals/business-hours/business-hours.component';
import { ImportexportComponent } from './modals/importexport/importexport.component';
import { CheckoutComponent } from './modals/checkout/checkout.component';
import { MailComponent } from './modals/mail/mail.component';
import { ConfirmappointmentComponent } from './pages/guest/confirmappointment/confirmappointment.component';
import { CancelappointmentComponent } from './pages/guest/cancelappointment/cancelappointment.component';
import { RescheduleComponent } from './modals/reschedule/reschedule.component';
import { RebookComponent } from './modals/rebook/rebook.component';
import { DeleteAlertComponent } from './modals/delete-alert/delete-alert.component';
import { StaffComponent as ModalStaffComponent } from './modals/staff/staff.component';
import { EmailComponent } from './modals/email/email.component';
import { ModalComponent } from './modals/modal/modal.component';
import { RoomComponent } from './modals/room/room.component';
import { ProductComponent } from './modals/product/product.component';
import { FilesComponent } from './pages/user/clients/clients-profile/files/files.component';
import { NotesComponent as ClientNotesComponent } from './pages/user/clients/clients-profile/notes/notes.component';
import { QuestionnaireComponent as ClientQuestionnaireComponent } from './pages/user/clients/clients-profile/questionnaire/questionnaire.component';
import { AlertprofileComponent } from './modals/alertprofile/alertprofile.component';
import { DiscountprofileComponent } from './modals/discountprofile/discountprofile.component';
import { LocationprofileComponent } from './modals/locationprofile/locationprofile.component';
import { WebsiteprofileComponent } from './modals/websiteprofile/websiteprofile.component';
import { PaymentComponent as PaymentModal } from './modals/payment/payment.component';
import { CreateuserComponent } from './modals/createuser/createuser.component';
import { QuestionComponent } from './modals/question/question.component';
import { ResourceComponent } from './modals/resource/resource.component';
import { ClosedComponent } from './modals/closed/closed.component';
import { ClockComponent } from './modals/clock/clock.component';
import { AppointmentPopupComponent } from './common/appointment-popup/appointment-popup.component';
import { DurationinputComponent } from './common/durationinput/durationinput.component';
import { TimepickerComponent } from './common/timepicker/timepicker.component';
import { ClientbookingsComponent } from './modals/clientbookings/clientbookings.component';
import { NoteEditorComponent } from './common/note-editor/note-editor.component';
import { CurrencyInputComponent } from './common/currency-input/currency-input.component';
import { DatepickerComponent } from './common/datepicker/datepicker.component';
import { DatepickerweekComponent } from './common/datepicker/datepicker-week/datepicker-week.component';
import { DatepickermonthComponent } from './common/datepicker/datepicker-month/datepicker-month.component';
import { DatepickerdashboardComponent } from './common/datepicker/datepicker-dashboard/datepicker-dashboard.component';
import { ColorpickerComponent } from './common/colorpicker/colorpicker.component';
import { InvoicetemplateComponent } from './modals/invoicetemplate/invoicetemplate.component';
import { WorkingusersPipe } from '@pipes';
import { BookingComponent } from './modals/booking/booking.component';
import { NotificationComponent } from './common/notification/notification.component';
import { NotificationtemplateComponent } from './modals/notificationtemplate/notificationtemplate.component';
import { NotificationssettingsComponent } from './modals/notificationssettings/notificationssettings.component';
import { environment } from 'src/environments/environment';
import { RegistrationComponent } from './modals/registration/registration.component';
import { AppointmentsDatePipe } from '@pipes';
import { DonatChartComponent } from './common/donat-chart/donat-chart.component';
import { MongodatePipe } from '@pipes';
import { SelectComponent } from './common/select/select.component';
import { TableComponent } from './common/table/table.component';
import { CellDirective } from './common/table/table.directive'
import { SortDirective } from './common/table/table.directive'
import { TabComponent } from './common/tab/tab.component'
import { OptionDirective } from './common/select/select.directive'
import { LabelDirective } from './common/select/select.directive'
import { SelectDirective } from './common/select/select.directive'
import { TooltipDirective } from './directives/tooltip.directive'
import { HideOnScrollDirective } from './directives/hide-on-scroll.directive'
import { FormatTimePipe } from '@pipes';
import { NotePipe } from '@pipes';
import { LoaderComponent } from './common/loader/loader.component';

const config: Config = {
	socket: false,
	meta: {
		useTitleSuffix: true,
		defaults: {
			title: 'Pure Planner',
			titleSuffix: ' | Pure Planner',
			// 'og:image': 'https://webart.work/api/user/cdn/waw-logo.png'
		}
	},
	modal: {
		class: "pureplanner",
		modals: { /* modals */
			modal:ModalComponent,
			registration: RegistrationComponent,
			notificationssettings: NotificationssettingsComponent,
			notificationtemplate: NotificationtemplateComponent,
			booking: BookingComponent,
			invoicetemplate: InvoicetemplateComponent,
			clientbookings: ClientbookingsComponent,
			clock: ClockComponent,
			closed: ClosedComponent,
			resource: ResourceComponent,
			question: QuestionComponent,
			createuser: CreateuserComponent,
			payment: PaymentModal,
			websiteprofile: WebsiteprofileComponent,
			locationprofile: LocationprofileComponent,
			discountprofile: DiscountprofileComponent,
			alertprofile: AlertprofileComponent,
			product: ProductComponent,
			room: RoomComponent,
			email: EmailComponent,
			importexport: ImportexportComponent,
			note: NoteComponent,
			service: ServiceComponent,
			client: ClientComponent,
			lunch: LunchComponent,
			appointment: AppointmentComponent,
			workingDays: WorkingDaysComponent,
			workingHours: WorkingHours,
			businessHours: BusinessHoursComponent,
			checkout: CheckoutComponent,
			mail: MailComponent,
			reschedule: RescheduleComponent,
			rebook: RebookComponent,
			deleteAlert: DeleteAlertComponent,
			staff: ModalStaffComponent
		}
	},
	alert: {
		class: "pureplanner",
		position: "bottomCenter",
		alerts: { /* alerts */
			appointment: AppointmentAlertComponent
		}
	}
};
const routes: Routes = [{
	path: '', redirectTo: 'calendar', pathMatch: 'full'
}, {
	path: '',
	canActivate: [Authenticated],
	component: UserComponent,
	children: [{
		path: 'profile',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'My Profile'
			}
		},
		component: ProfileComponent
	},
	{
		path: 'dashboard',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Dashboard'
			}
		},
		component: DashboardComponent
	},
	{
		path: 'clients',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Clients'
			}
		},
		component: ClientsComponent
	},
	{
		path: 'calendar',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Calendar'
			}
		},
		component: CalendarComponent
	},
	{
		path: 'schedule',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Schedule'
			}
		},
		component: CalendarComponent
	},
	{
		path: 'c/:_id',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Profile'
			}
		},
		component: ClientsProfileComponent
	},
	{
		path: 'staff',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Staff'
			}
		},
		component: StaffComponent
	},
	{
		path: 'staff/:staff_id',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Staff'
			}
		},
		component: StaffComponent
	},
	{
		path: 'services',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Services'
			}
		},
		component: ServicesComponent
	},
	{
		path: 'resources',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Resources'
			}
		},
		component: ResourcesComponent
	},
	{
		path: 'setup',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Setup'
			}
		},
		component: SetupComponent
	},
	{
		path: 'messanger',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Messanger'
			}
		},
		component: MessangerComponent
	},
	{
		path: 'setup/discount',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Discount'
			}
		},
		component: DiscountComponent
	},
	{
		path: 'setup/users',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Users'
			}
		},
		component: UsersComponent
	},
	{
		path: 'setup/payment',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Payment'
			}
		},
		component: PaymentComponent
	},
	{
		path: 'setup/closed',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Closed Dates'
			}
		},
		component: ClosedDatesComponent
	},
	{
		path: 'setup/taxes',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Taxes'
			}
		},
		component: TaxesComponent
	},
	{
		path: 'setup/cancel-reason',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Cancel reason'
			}
		},
		component: CancelReasonComponent
	},
	{
		path: 'setup/locations',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Location'
			}
		},
		component: LocationsComponent
	},
	{
		path: 'setup/social',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Social'
			}
		},
		component: SocialComponent
	},
	{
		path: 'setup/alerts',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Alerts'
			}
		},
		component: AlertsComponent
	},
	{
		path: 'setup/questionnaire',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Questionnaire'
			}
		},
		component: QuestionnaireComponent
	},
	{
		path: 'setup/resources',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Resources'
			}
		},
		component: ResourcesSetupComponent
	},
	{
		path: 'setup/online-link',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Online link'
			}
		},
		component: OnlineLinkComponent
	},]
}, {
	path: '',
	canActivate: [Admins],
	component: UserComponent,
	children: [{
		path: 'sales',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Sales'
			}
		},
		component: CheckoutsComponent
	}, {
		path: 'mails',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Mails'
			}
		},
		component: MailsComponent
	}, {
		path: 'notifications',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Notifications'
			}
		},
		component: NotificationsComponent
	}]
}, {
	path: '',
	canActivate: [Guest],
	component: GuestComponent,
	children: [{
		path: 'login',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Login'
			}
		},
		component: LoginComponent
	}, {
		path: 'sign',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Sign Up'
			}
		},
		component: SignComponent
	}, {
		path: 'auth/:user/:token',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Login'
			}
		},
		component: LoginComponent
	}, {
		path: 'forgot',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Forgot Password'
			}
		},
		component: ForgotComponent
	}, {
		path: 'reset',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'Reset Password'
			}
		},
		component: ResetComponent
	}, {
		path: 'save',
		canActivate: [MetaGuard],
		data: {
			meta: {
				title: 'New Password'
			}
		},
		component: SaveComponent
	}]
}, {
	path: 'confirm_appointment/:_id/:_appointment',
	canActivate: [MetaGuard],
	data: {
		meta: {
			title: 'Confirmation'
		}
	},
	component: ConfirmappointmentComponent
}, {
	path: 'cancel_appointment/:_id/:_appointment',
	canActivate: [MetaGuard],
	data: {
		meta: {
			title: 'Cancellation'
		}
	},
	component: CancelappointmentComponent
}, {
	path: 'redirect',
	canActivate: [MetaGuard],
	data: {
		meta: {
			title: 'Loading'
		}
	},
	component: RedirectComponent
}, {
	path: '**', redirectTo: 'profile', pathMatch: 'full'
}];

@NgModule({
    declarations: [
        AppComponent,
        GuestComponent,
        UserComponent,
        CheckoutsComponent,
        ProfileComponent,
		RedirectComponent,
        LoginComponent,
		ForgotComponent,
        ResetComponent,
        SaveComponent,
        ConfirmappointmentComponent,
		CancelappointmentComponent,
        SignComponent,
        ClientsComponent,
        ServiceHistoryComponent,
        NotesComponent,
        ClientsListComponent,
        ClientsProfileComponent,
        StaffComponent,
        WorkingHoursComponent,
        StaffInformationComponent,
        ClosedDatesComponent,
        ServicesComponent,
        ResourcesComponent,
        ResProductsComponent,
        ResRoomsComponent,
        ResCategoriesComponent,
        ResBrandsComponent,
        SetupComponent,
        DiscountComponent,
        TaxesComponent,
        PaymentComponent,
        CancelReasonComponent,
        LocationsComponent,
        SocialComponent,
        AlertsComponent,
        QuestionnaireComponent,
        ResourcesSetupComponent,
        OnlineLinkComponent,
        CalendarComponent,
        DashboardComponent,
        AppointmentComponent,
        AppointmentAlertComponent,
        LunchComponent,
        ClientComponent,
        ServiceComponent,
        MessangerComponent,
        PictureComponent,
        UsersComponent,
        NoteComponent,
        WorkingDaysComponent,
        WorkingHours,
        ModalStaffComponent,
        DeleteAlertComponent,
        BusinessHoursComponent,
        CheckoutComponent,
        MailComponent,
        RescheduleComponent,
        RebookComponent,
        ImportexportComponent,
        MailsComponent,
        EmailComponent,
		ModalComponent,
        RoomComponent,
        ProductComponent,
        FilesComponent,
        ClientNotesComponent,
        ClientQuestionnaireComponent,
        AlertprofileComponent,
        DiscountprofileComponent,
        LocationprofileComponent,
        WebsiteprofileComponent,
        PaymentModal,
        CreateuserComponent,
        QuestionComponent,
        ResourceComponent,
        ClosedComponent,
        ClockComponent,
        AppointmentPopupComponent,
        DurationinputComponent,
        TimepickerComponent,
		ClientbookingsComponent,
		NoteEditorComponent,
		CurrencyInputComponent,
  		InvoicetemplateComponent,
    	BookingComponent,
		DatepickerComponent,
		DatepickerweekComponent,
		DatepickermonthComponent,
		DatepickerdashboardComponent,
		NotificationComponent,
		NotificationsComponent,
		NotificationtemplateComponent,
		NotificationssettingsComponent,
		ColorpickerComponent,
		RegistrationComponent,
		DonatChartComponent,
		SelectComponent,
		TableComponent,
		CellDirective,
		SortDirective,
		DashboardOverviewComponent,
		DashboardAppointmentsComponent,
		DashboardSalesComponent,
		TabComponent,
		DashboardClientsComponent,
		DashboardStaffComponent,
		OptionDirective,
		LabelDirective,
		SelectDirective,
		TooltipDirective,
		HideOnScrollDirective,
		LoaderComponent,
    ],
    imports: [
        NgxMaskModule.forRoot(),
        NgxChartsModule,
        DragAndDropModule,
		DragDropModule,
		NgxEditorModule,
		PickerModule,
		OverlayModule,
        PipesModule,
        WacomModule.forRoot(config),
        CommonModule,
        FormsModule,
		ReactiveFormsModule,
        BrowserModule,
		MatSlideToggleModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatFormFieldModule,
		MatInputModule,
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            relativeLinkResolution: 'legacy'
        }),
        HttpClientModule,
        AngularMyDatePickerModule,
        BrowserAnimationsModule,
        GooglePlaceModule,
		NgxCurrencyModule,
		AngularFireAuthModule,
		AngularFireMessagingModule,
		AngularFireModule.initializeApp(environment.firebase),
		PdfViewerModule,
		MatButtonModule,
		MatPaginatorModule,
		MatTooltipModule,
		MatRippleModule,
		MatMenuModule,
		MatTreeModule,
		MatCheckboxModule   ],
    providers: [Authenticated, Guest, Admins, DatePipe, WorkingusersPipe, AppointmentsDatePipe, MongodatePipe, CurrencyPipe, FormatTimePipe, NotePipe,
	],
    bootstrap: [AppComponent]
})
export class AppModule { }
