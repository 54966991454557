<div class="table-container tablet-col3 mobile-col3">
    <table class="table" [class.navShown]="!us.sidebarToggle" cellpadding="0" cellspacing="0">
        <thead>
            <tr>
                <th scope="col" class="sort" (click)="sort('name')">
                    <div class="ai-c">
                        <span>Product name</span> 
                        <span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'name'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'name'">
                            <span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
                            <span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
                        </span>
                    </div>
                </th>
                <th scope="col" class="sort" (click)="sort('barcode')">
                    <div class="ai-c">
                        <span>Barcode</span> 
                        <span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'barcode'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'barcode'">
                            <span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
                            <span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
                        </span>
                    </div>
                </th>
                <th scope="col" class="sort" (click)="sort('price')">
                    <div class="ai-c">
                        <span>Retail price</span> 
                        <span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'price'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'price'">
                            <span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
                            <span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
                        </span>
                    </div>
                </th>
                <th scope="col" class="sort hidden-2" (click)="sort('brandRe')">
                    <div class="ai-c">
                        <span>Brand</span> 
                        <span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'brandRe'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'brandRe'">
                            <span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
                            <span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
                        </span>
                    </div>
                </th>
                <th scope="col" class="sort hidden-2" (click)="sort('categoryRe')">
                    <div class="ai-c">
                        <span>Category</span> 
                        <span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'categoryRe'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'categoryRe'">
                            <span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
                            <span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
                        </span>
                    </div>
                </th>
                <th scope="col" class="sort hidden-1" (click)="sort('discountRe')">
                    <div class="ai-c">
                        <span>Discount</span>
                        <span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'discountRe'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'discountRe'">
                            <span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
                            <span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
                        </span>
                    </div>
                </th>
                <th scope="col" class="hidden-1" style="grid-column: span 2;">
                    <span>Tax</span>
                </th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let product of ps.products|sort:sort_type:ps.now">
                <td data-label="Name" class="ovf-hidden">
                    <div class="table__user">
                        <div class="table__user__img">
                            <img *ngIf="product.thumb" [src]="product.thumb" alt="product" draggable="false"/>
                            <span *ngIf="!product.thumb" class="icon-photo"></span>
                        </div>
                        <div class="table__user__info">
                            <span class="table__user__info-name">{{product.name}}</span>
                            <span class="table__user__info-name" *ngIf="product.quantity"> x {{product.quantity}}</span>
                        </div>
                    </div>
                </td>
                <td data-label="Barcode" class="text-elipsis">{{product.barcode}}</td>
                <td data-label="Price" class="text-elipsis">{{ product.price | currency }}</td>
                <td data-label="Brand" class="text-elipsis hidden-2">{{bs._brands[product.brand]?.name}}</td>
                <td data-label="Category" class="text-elipsis hidden-2">{{cs._categories[product.category]?.name}}</td>
                <td data-label="Discount" class="text-elipsis hidden-1">{{ ds._discounts[product.discount]?.name }} {{ ds._discounts[product.discount]?.kind!='perc' ? ( ds._discounts[product.discount]?.amount | currency ) : ( ds._discounts[product.discount]?.amount + '%' ) }}</td>
                <td data-label="Tax" class="text-elipsis hidden-1" style="grid-column: span 2;">
                    <div class="tax">
                        <ng-container *ngFor="let tax of product.tax; index as i">
                            <div class="tax__item" *ngIf="i < 2 || ( i == 2 && product.tax?.length == 3 )">{{ts._taxes[tax]?.name}}</div>
                        </ng-container>
                        <ng-container *ngIf="product.tax?.length > 3">
                            <div class="tax__more">+ {{product.tax?.length - 2}} more</div>
                        </ng-container>
                    </div>
                </td>
                <td>
                    <div class="actions">
                        <button mat-icon-button color="primary" (click)="edit(product);" class="waw-btn__icon primary _tablet">
                            <span class="icon-pencil"></span>
                        </button>
                        <button mat-icon-button color="warn" class="waw-btn__icon warn _tablet" (click)="delete(product)">
                            <span class="icon-delete"></span>
                        </button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
