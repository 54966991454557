import { Component, Input, ContentChild, EventEmitter, Output, AfterContentInit, ViewChild, AfterContentChecked } from '@angular/core';
import { modalAnimation } from 'src/app/common/animation/animation.component';
import { OptionDirective, LabelDirective, SelectDirective } from './select.directive'
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { UserService } from '@services';

@Component({
	selector: 'app-select',
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss'],
	animations: [modalAnimation]
})
export class SelectComponent implements AfterContentInit, AfterContentChecked {
	@Input() value: any;

	@Input() items: any = [];

	@Input() bindLabel: string = "";

	@Input() bindValue: string = "";

	@Input() bindDisabled: string = "";

	@Input() placeholder: string = "Select";

	@Input() icon: string;

	@Input() clearable: boolean = true;

	@Input() searchable: boolean = false;

	@Input() multiple: boolean = false;

	@Input() disabled: boolean = false;

	@Input() class: string = '';

	@Input() pickerClass: string = '';
	
	@Input() required: boolean = false;

	@Input() select_all: boolean = false;

	@Input('width') width_custom: number = 0;
	
	@Output() valueChange = new EventEmitter<any>();

	@ContentChild(OptionDirective) option: OptionDirective;

	@ContentChild(LabelDirective) label: LabelDirective;

	@ContentChild(SelectDirective) select: SelectDirective;

	@ViewChild('select', { static: false }) select_container: CdkOverlayOrigin;

	public open: boolean = false;

    public position: string = 'positionBottom';

    public search: string = '';

	public custom_option: any = {};

	public custom_label: any = {};

	public custom_select: any = {};

	public width: number = 250;

	public height: number = 50;
	
	constructor(public us: UserService) {}
	
	ngAfterContentInit(): void {
		if(this.option) {
			this.custom_option = this.option.template;
		} else {
			this.custom_option = null;
		}
		if(this.label) {
			this.custom_label = this.label.template;
		} else {
			this.custom_label = null;
		}
		if(this.select) {
			this.custom_select = this.select.template;
		} else {
			this.custom_select = null;
		}
	}

	ngAfterContentChecked() {
		if(this.select_container) this.width = this.select_container.elementRef.nativeElement.offsetWidth;
		if(this.select && this.width_custom) this.width = this.width_custom;
		this.height = 0;
		if(this.items?.length) {
			!this.multiple ? this.height += 40 * this.items.length : this.height += 42 * this.items.length;
			if(this.height > 200) this.height = 200;
		} else {
			this.height += 50;
		}
		if(this.clearable) this.height += 50;
		else this.height += 6;
		if(this.searchable) this.height += 42;
		else this.height += 6;
		this.height += 20;
	}

	getValue() {
		if(!this.multiple) {
			const item = this.items.find(i => {
				return this.bindValue ? ( i[this.bindValue] === this.value ) : ( JSON.stringify(i) === JSON.stringify(this.value) );
			});
			return this.bindLabel ? item?.[this.bindLabel] : item;
		} else {
			const item = this.items.find(i => {
				return this.bindValue ? ( i[this.bindValue] === this.value[0] ) : ( JSON.stringify(i) === JSON.stringify(this.value[0]) );
			});
			return this.bindLabel ? item?.[this.bindLabel] : item;
		}
	}

	getItem() {
		if(!this.multiple) {
			return this.items.find(i => {
				return this.bindValue ? ( i[this.bindValue] === this.value ) : ( JSON.stringify(i) === JSON.stringify(this.value) );
			});
		} else {
			return this.value?.map(v => {
				return this.items.find(i => this.bindValue ? ( i[this.bindValue] === v ) : ( JSON.stringify(i) === JSON.stringify(v) ));
			}).filter(v => v);
		}
	}

	positionChange(event) {
        if(event.connectionPair.originY === 'top') this.position = 'positionTop';
        if(event.connectionPair.originY === 'bottom') this.position = 'positionBottom';
    }

	clear() {
		if(this.clearable) {
			if(!this.multiple) {
				this.open = false;
				this.search = '';
				this.valueChange.emit(null); 
			} else {
				this.search = '';
				this.valueChange.emit([]); 
			}
		}
	}

	getItems() {
		if(this.items) {
			return this.items.filter(item => {
				return this.bindLabel ? item[this.bindLabel].toLowerCase().includes(this.search.toLowerCase()) : item.toLowerCase().includes(this.search.toLowerCase());
			});
		} else return [];
	}

	onSelect(item) {
		if(!item[this.bindDisabled]) {
			if(!this.multiple) {
				this.valueChange.emit(this.bindValue ? item[this.bindValue] : item); 
				this.open = false;
			} else {
				this.value = this.value?.filter(element => {
					if( !this.items.find(i => {
						return this.bindValue ? ( i[this.bindValue] === element ) : ( JSON.stringify(i) === JSON.stringify(element) )
					}) ) {
						return false;
					} else return true;
				});
				let value = this.value;
				if(!value?.length) value = [];
				if(!value.find(v => this.bindValue ? ( item[this.bindValue] === v ) : ( JSON.stringify(item) === JSON.stringify(v) ))) {
					value.push(this.bindValue ? item[this.bindValue] : item);
					this.valueChange.emit(value); 
				} else {
					value.splice(value.findIndex(v => this.bindValue ? ( item[this.bindValue] === v ) : ( JSON.stringify(item) === JSON.stringify(v) )), 1);
					this.valueChange.emit(value); 
				}
			}
		}
	}

	isActive(item) {
		if(!this.multiple) {
			return (this.bindValue ? item[this.bindValue] : item) === this.value && this.value;
		} else {
			return this.value ? this.value.find(v => v === (this.bindValue ? item[this.bindValue] : item)) : false;
		}
	}

	scrollToOption() {
		if(document.querySelector('#selectPicker .select-list__item._active')) document.querySelector('#selectPicker .select-list__item._active').scrollIntoView({ block: "center" });
	}

	selectAll() {
		const value = [];
		for(let item of this.items) {
			value.push(this.bindValue ? item[this.bindValue] : item);
		}
		this.valueChange.emit(value); 
	}
}