<div class="week" [class._open]="picker.opened">
    <button mat-icon-button color="primary" type="button" class="btn left" (click)="changeWeek(-1)">
        <span class="btn-icon icon-arrow_left"></span>
    </button>
    <mat-form-field>
        <input type="text" (click)="picker.open(); open.emit(true)" readonly [ngModel]="value">
        <mat-date-range-input [rangePicker]="picker" style="display: none;">
            <input #dateRangeStart matStartDate readonly [formControl]="start" (dateChange)="dateRangeChange()">
            <input #dateRangeEnd matEndDate readonly [formControl]="end" (dateChange)="dateRangeChange()">
        </mat-date-range-input>
        <mat-date-range-picker #picker panelClass="weekpicker" [touchUi]="touchUi"></mat-date-range-picker>
    </mat-form-field>
    <button mat-icon-button color="primary" type="button" class="btn right" (click)="changeWeek(1)">
        <span class="btn-icon icon-arrow_right"></span>
    </button>
</div>