import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
	parse: {
	  	dateInput: 'MMMM, YYYY',
	},
	display: {
	  	dateInput: 'MMMM, YYYY',
	  	monthYearLabel: 'MMM YYYY',
	  	dateA11yLabel: 'LL',
	  	monthYearA11yLabel: 'MMMM YYYY',
	},
};

@Component({
	selector: 'datepicker-month',
	templateUrl: './datepicker-month.component.html',
	styleUrls: ['./datepicker-month.component.scss'],
    providers: [
		{
		  	provide: DateAdapter,
		  	useClass: MomentDateAdapter,
		  	deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
		},
	
		{provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
	],
})
export class DatepickermonthComponent{
    @Input() month: number = new Date().getMonth();

    @Input() year: number = new Date().getFullYear();

    @Input() touchUi: boolean = false;
	
    @Output() valueChange = new EventEmitter();

    @Output() open = new EventEmitter();

	constructor() {}

    changeMonth(variation) {
		let D = new Date(this.year, this.month);
		D.setMonth(D.getMonth()+variation);
		this.month = D.getMonth();
		this.year = D.getFullYear();
		const ctrlValue = this.monthpicker.value!;
		ctrlValue.month(D.getMonth());
		ctrlValue.year(D.getFullYear());
		this.monthpicker.setValue(ctrlValue);
        this.valueChange.emit({month: this.month, year: this.year});
	}
	public monthpicker = new FormControl(moment());
	setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
		const ctrlValue = this.monthpicker.value!;
		this.month = normalizedMonthAndYear.month();
		this.year = normalizedMonthAndYear.year();
		ctrlValue.month(normalizedMonthAndYear.month());
		ctrlValue.year(normalizedMonthAndYear.year());
		this.monthpicker.setValue(ctrlValue);
		datepicker.close();
        this.valueChange.emit({month: this.month, year: this.year});
	}
}