<div class="OnLinkModal p20">
	<div class="modal-box">
		<div class="modal-title">{{profile._id&&'Edit'||'Add'}} Online Link</div>
		<button mat-icon-button type="button" class="modal-close" (click)="close()">
			<span class="modal-close__icon"></span>
		</button>
    	<div class="row">
	        <div class="waw-select col-2" *ngIf="cs.categories.length">
				<span class="waw-select__title">Category</span>
				<app-select 
					placeholder="Select category" 
					[(value)]="profile.category"
					[items]="cs.categories" 
					[clearable]="false" 
					[searchable]="false"
					bindLabel="name" 
					bindValue="_id"
				></app-select>
	        </div>
	        <div class="waw-select col-2">
				<span class="waw-select__title">Icon</span>
				<app-select 
					placeholder="Select Icon" 
					[(value)]="profile.icon"
					[items]="icons" 
					[clearable]="false" 
					[searchable]="false"
				></app-select>
	        </div>
	    </div>
	    <div class="row pos-rel">
	        <div class="waw-input col-1">
	            <label>
	                <span class="waw-input__title">Link</span>
	                <input type="text" placeholder="Link" name="Link" [(ngModel)]="profile.name">
	            </label>
	        </div>
	        <span *ngIf="profile.icon=='instagram'" class="onLinkModal-icon icon-instagram"></span>
	        <span *ngIf="profile.icon=='facebook'" class="onLinkModal-icon icon-facebook"></span>
	        <span *ngIf="!profile.icon" class="onLinkModal-icon icon-website"></span>
	    </div>
	    <div class="modal-btn">
	        <button mat-button color="primary" type="button" *ngIf="!profile._id" (click)="close()" class="waw-btn _second">Cancel</button>
	        <button mat-button color="primary" type="button" *ngIf="profile._id" (click)="ws.delete(profile); close();" class="waw-btn _second _delete">Delete</button>
	        <button mat-flat-button color="primary" type="submit" class="waw-btn _primary" (click)="ws.create(profile); close();">{{profile._id&&'Save'||'Create'}}</button>
	    </div>
    </div>
</div>
