import { Component } from '@angular/core';
import { AppointmentService } from '@services';
@Component({
	selector: 'appointment',
	templateUrl: './appointment.component.html',
	styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent{
	public undo:any
	constructor(public aps: AppointmentService) {}
}
