<div class="modal-box">
    <form (ngSubmit)="save()">
        <div class="modal-title">{{ preview ? 'Preview Invoice Template' : 'Edit Invoice Template'}}</div>
        <button mat-icon-button type="button" class="modal-close" (click)="close()">
            <span class="modal-close__icon"></span>
        </button>
        <ng-container *ngIf="!preview">
            <div class="modal-rowImg">
                <div class="modal-rowImg__left">
                    <div class="modal-img" [class._logoUploaded]="invoice.logo">
                        <img *ngIf="invoice.logo" [src]="invoice.logo" alt="">
                        <label for="logo">
                            <button mat-icon-button color="primary" type="button" class="img-upload waw-btn__icon primary" (click)="uploadLogo()"><span class="icon-pencil"></span></button>
                        </label>
                        <input type="file" name="logo" id="logo" #logoInput [hidden]="true" accept="image/*" (change)="onLogoSelected($event)">
                        <button mat-icon-button color="warn" type="button" class="img-delete waw-btn__icon warn" *ngIf="invoice.logo" (click)="deleteLogo()">
                            <span class="icon-delete"></span>
                        </button>
                    </div>
                </div>
                <div class="modal-rowImg__right">
                    <div class="waw-select col-1">
                        <span class="waw-select__title">Template</span>
                        <app-select 
                            placeholder="Select template" 
                            [(value)]="invoice.template"
                            (valueChange)="onTemplateSelected($event)"
                            [items]="templates" 
                            [clearable]="false" 
                            [searchable]="false"
                            pickerClass="templatePicker"
                            bindLabel="name" 
                            bindValue="id"
                        >
                            <ng-template option let-item>
                                <div class="select-option-template">
                                    <div class="select-option-template__img">
                                        <img [src]="'assets/invoice/'+item.id+'_preview.png'" [alt]="item.name + ' preview'" draggable="false">
                                    </div>
                                    <span class="select-option-template__name">{{item.name}}</span>
                                </div>
                            </ng-template>
                        </app-select>
                    </div>
                    <div class="waw-select col-1">
                        <span class="waw-select__title">Logo Size</span>
                        <app-select 
                            placeholder="Select size" 
                            [(value)]="invoice.logo_size"
                            [items]="logo_sizes" 
                            [clearable]="false" 
                            [searchable]="false"
                            bindLabel="name" 
                            bindValue="size"
                        ></app-select>
                    </div>
                </div>
            </div>
            <h2 class="modal-h2">Colors</h2>
            <p class="modal-p">Pick the colors that match your style! Choose a palette for your invoices that shows off your brand.</p>
            <div class="row colors">
                <ng-container *ngFor="let color of invoice.colors; index as i">
                    <div class="color">
                        <colorpicker [title]="color.name" [value]="color.code" (valueChange)="invoice.colors[i].code = $event"></colorpicker>
                    </div>
                </ng-container>
            </div>
            <h2 class="modal-h2">Text</h2>
            <p class="modal-p">Make words clear and professional by easily changing the font style and size.</p>
            <div class="row">
                <div class="waw-select col-1">
                    <span class="waw-select__title">Font</span>
                    <app-select 
                        placeholder="Select font" 
                        [(value)]="invoice.font"
                        [items]="fonts" 
                        [clearable]="false" 
                        [searchable]="false"
                    >
                        <ng-template label let-item>
                            <span [ngStyle]="{'font-family': item}">
                                {{item}}
                            </span>
                        </ng-template>
                        <ng-template option let-item>
                            <span [ngStyle]="{'font-family': item}">
                                {{item}}
                            </span>
                        </ng-template>
                    </app-select>
                </div>
                <div class="waw-input col-1">
                    <label>
                        <span class="waw-input__title">Size</span>
                        <input type="text" inputmode="decimal" mask="0*" placeholder="px" name="font_size" [(ngModel)]="invoice.font_size">
                    </label>
                </div>
            </div>
            <div class="modal-section">
                <h2 class="modal-h2">Page Setup</h2>
                <p class="modal-p">Arrange your invoices the way you want! Adjust how they look on the page with format and margin settings.</p>
                <div class="row">
                    <div class="waw-select col-1">
                        <span class="waw-select__title">Format</span>
                        <app-select 
                            placeholder="Select format" 
                            [(value)]="invoice.format"
                            [items]="['Letter','Legal','Tabloid','Ledger','A0','A1','A2','A3','A4','A5','A6']" 
                            [clearable]="false" 
                            [searchable]="false"
                        ></app-select>
                    </div>
                    <div class="waw-input col-2">
                        <label>
                            <span class="waw-input__title">Margin Top</span>
                            <input type="text" inputmode="decimal" mask="0*" placeholder="px" name="top" [(ngModel)]="invoice.margin.top">
                        </label>
                    </div>
                    <div class="waw-input col-2">
                        <label>
                            <span class="waw-input__title">Margin Bottom</span>
                            <input type="text" inputmode="decimal" mask="0*" placeholder="px" name="bottom" [(ngModel)]="invoice.margin.bottom">
                        </label>
                    </div>
                    <div class="waw-input col-2 pb0">
                        <label>
                            <span class="waw-input__title">Margin Right</span>
                            <input class="mb0" type="text" inputmode="decimal" mask="0*" placeholder="px" name="right" [(ngModel)]="invoice.margin.right">
                        </label>
                    </div>
                    <div class="waw-input col-2 pb0">
                        <label>
                            <span class="waw-input__title">Margin Left</span>
                            <input class="mb0" type="text" inputmode="decimal" mask="0*" placeholder="px" name="left" [(ngModel)]="invoice.margin.left">
                        </label>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="preview">
            <div class="modal-btn mb10" *ngIf="preview">
                <button mat-button color="primary" type="button" style="border: none;" class="waw-btn _second mb0" (click)="downloadPreview()">
                    <span class="icon-download mr5"></span>
                    <span>Download</span>
                </button>
                <button mat-button color="primary" type="button" style="border: none;" class="waw-btn _second mb0" (click)="printPreview()">
                    <span class="icon-print mr5"></span>
                    <span>Print</span>
                </button>
            </div>
            <div class="preview" #pdfViewer>
                <pdf-viewer [src]="previewUrl"
                [render-text]="false"
                [original-size]="false"
                [show-all]="true"
                style="width: 100%; height: 500px"
                [ngStyle]="{'height': getHeight()}"
                ></pdf-viewer>
            </div>
        </ng-container>
        <div class="modal-btn">
            <button mat-button color="primary" type="button" *ngIf="!preview" class="waw-btn _second" (click)="generatePreview()">Preview</button>
            <button mat-button color="primary" type="button" *ngIf="preview" class="waw-btn _second" (click)="preview = false">Edit</button>
            <button mat-flat-button color="primary" type="submit" class="waw-btn _primary">Save</button>
        </div>
    </form>
</div>