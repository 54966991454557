<div class="modal-box" [class.compon]="compon">
	<form (ngSubmit)="save()">
		<div class="modal-title" *ngIf="!compon">{{profile._id&&'Edit'||'New'}} Room</div>
		<button mat-icon-button type="button" *ngIf="!compon" class="modal-close" (click)="close()">
			<span class="modal-close__icon"></span>
		</button>
		<div class="row">
			<div class="waw-input col-1">
				<label [class.req]="submitted&&!profile.name">
					<span class="waw-input__title">Room name *</span>
					<input type="text" placeholder="Room name" name="roomName" [(ngModel)]="profile.name" (ngModelChange)="onChange.emit(profile)">
					<div *ngIf="submitted&&!profile.name" class="req-text">Name is required</div>
				</label>
			</div>
			<div class="waw-select col-1" *ngIf="ls.locations.length && !compon">
				<span class="waw-select__title">location *</span>
				<app-select 
					placeholder="Select Location" 
					[(value)]="profile.location"
					[items]="ls.locations" 
					[clearable]="false" 
					[searchable]="false"
					[required]="submitted&&!profile.location"
					bindLabel="name" 
					bindValue="_id"
				></app-select>
				<div *ngIf="submitted&&!profile.location" class="req-text">Location is required</div>
			</div>
		</div>

		<div class="hours">
			<div class="hours__day" *ngFor="let day of us.days_of_week" [class._empty]="!profile.availability[day]?.length">
				<div class="hours__day__name">{{day}}</div>
				<div class="hours__day__body">
					<ng-container *ngFor="let hour of profile.availability[day]; index as i; last as last">
						<div class="hours__day__body__item">
							<div class="hours__day__body__item-time">
								<timepicker 
									label="Start*" 
									[value]="hour.from" 
									(valueChange)="hour.from = $event; onChange.emit(profile)" 
									[requiredText]="submitted&&!hour.from&&'Start is required' || i>0&&convertTimeToMinutes(hour.from)<=convertTimeToMinutes(profile.availability[day][i-1].to)&&'Time is overlapping'"
									[required]="submitted&&!hour.from || i>0&&convertTimeToMinutes(hour.from)<=convertTimeToMinutes(profile.availability[day][i-1].to)" 
								></timepicker>
								<timepicker 
									label="End*" 
									[value]="hour.to" 
									(valueChange)="hour.to = $event; onChange.emit(profile)" 
									[requiredText]="submitted&&!hour.to&&'End is required' || !!profile.availability[day]?.[i+1]&&convertTimeToMinutes(hour.to)>=convertTimeToMinutes(profile.availability[day][i+1].from)&&'Time is overlapping' || convertTimeToMinutes(hour.to)<=convertTimeToMinutes(hour.from)&&'The end time must be after the start time'"
									[required]="submitted&&!hour.to || !!profile.availability[day]?.[i+1]&&convertTimeToMinutes(hour.to)>=convertTimeToMinutes(profile.availability[day][i+1].from) || convertTimeToMinutes(hour.to)<=convertTimeToMinutes(hour.from)" 
								></timepicker>
							</div>
							<div class="hours__day__body__item-actions" [class._last]="last">
								<button mat-icon-button color="warn" class="waw-btn__icon warn" type="button" (click)="profile.availability[day].splice(i, 1); onChange.emit(profile)"><span class="material-icons-round notranslate">remove</span></button>
								<button *ngIf="last && profile.availability[day]?.length < 10" mat-icon-button color="primary" class="waw-btn__icon primary" type="button" (click)="addHour(day); onChange.emit(profile)"><span class="material-icons-round notranslate">add</span></button>
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="!profile.availability[day]?.length">
						<button mat-icon-button color="primary" class="waw-btn__icon primary" type="button" (click)="addHour(day); onChange.emit(profile)"><span class="material-icons-round notranslate">add</span></button>
					</ng-container>
				</div>
			</div>
		</div>
	
		<div class="modal-btn" *ngIf="!compon">
			<button mat-button color="primary" type="button" *ngIf="!profile._id" (click)="close()" class="waw-btn _second">Cancel</button>
			<button mat-button color="primary" type="button" *ngIf="profile._id" (click)="delete()" class="waw-btn _second _delete">Delete</button>
			<button mat-flat-button color="primary" type="submit" class="waw-btn _primary">{{profile._id&&'Save'||'Create'}}</button>
		</div>
	</form>
</div>