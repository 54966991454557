import { Injectable } from '@angular/core';
import { MongoService, AlertService } from 'wacom';

@Injectable({
	providedIn: 'root'
})
export class BrandService {
	public brands: any = [];
	public _brands: any = {};
	constructor(private alert: AlertService, private mongo: MongoService) { 
		this.brands = mongo.get('brand', (arr, obj) => {
			this._brands = obj;
		});
	}
	create(brand, cb: any = () => {}) {
		if(brand._id) return this.update(brand, cb);
		this.mongo.create('brand', brand, (created)=>{
			if(typeof cb === 'function') cb(created);
			this.alert.show({
				text: 'Brand has been created.'
			});
		});
	}
	update(brand, cb: any = () => {}) {
		this.mongo.afterWhile(brand, ()=> {
			this.mongo.update('brand', brand, (updated) => {
				if(typeof cb === 'function') cb(updated);
			});
		});
	}
	delete(brand, cb: any = () => {}) {
		this.mongo.delete('brand', brand, (deleted) => {
			if(typeof cb === 'function') cb(deleted);
		});
	}
}
