<div class="modal-box">
	<form (ngSubmit)="save()">
		<div class="modal-title">{{profile._id&&'Edit'||'New'}} Closed Date</div>
		<button mat-icon-button type="button" class="modal-close" (click)="close()">
			<span class="modal-close__icon"></span>
		</button>
		<div class="row">
			<div class="waw-input col-1">
				<label [class.req]="submitted&&!profile.name">
					<span class="waw-input__title">Name*</span>
					<input type="text" placeholder="Name" [(ngModel)]="profile.name" name="name">
					<div *ngIf="submitted&&!profile.name" class="req-text">Name is required</div>
				</label>
			</div>
			<div class="waw-select col-1">
				<div class="waw-select__title">Location*</div>
				<app-select 
					placeholder="Locations" 
					[(value)]="profile.locations"
					(valueChange)="initializeHolidays()"
					[items]="ls.locations" 
					[clearable]="true" 
					[searchable]="true" 
					[multiple]="true"
					[required]="submitted&&(!profile.locations||profile.locations&&!profile.locations.length)"
					bindLabel="name" 
					bindValue="_id" 
				></app-select>
				<div *ngIf="submitted&&(!profile.locations||profile.locations&&!profile.locations.length)" class="req-text">Location is required</div>
			</div>
		</div>
		<div class="row">
			<div class="waw-datepicker col-2 _arrow" [class.req]="submitted&&!profile.start">
				<span class="waw-datepicker__title">Start date*</span>
				<datepicker [date]="profile.start" (valueChange)="profile.start=$event" [required]="submitted&&!profile.start" [disabled]="profile.holiday"></datepicker>
				<div *ngIf="submitted&&!profile.start" class="req-text">Start is required</div>
			</div>
			<div class="waw-datepicker col-2 _arrow" [class.req]="submitted&&!profile.end">
				<span class="waw-datepicker__title">End date*</span>
				<datepicker [date]="profile.end" (valueChange)="profile.end=$event" [required]="submitted&&!profile.end" [disabled]="profile.holiday"></datepicker>
				<div *ngIf="submitted&&!profile.end" class="req-text">End is required</div>
			</div>
		</div>
		<div class="row">
			<div class="waw-textarea col-1">
				<div [class.req]="characterLimit">
					<note-editor [notes]="descriptionEditor" placeholder="Add Description" label="Description" size="medium" (characterLimit)="characterLimit = $event" (onChange)="descriptionEditor = $event"></note-editor>
					<div *ngIf="characterLimit" class="req-text mb10">{{characterLimit}}</div>
				</div>
			</div>
		</div>
		<div class="modal-section">
			<h2 class="modal-h2">Holiday</h2>
			<p class="modal-p">The selected holiday will be automatically repeated in the calendar</p>
			<div class="row">
				<div class="waw-select col-1" [ngStyle]="{'padding-bottom': !profile.holiday?.possible_substitute ? '0' : '', 'margin-bottom': !profile.holiday?.possible_substitute ? '-10px' : ''}">
					<div class="waw-select__title">Holiday</div>
					<app-select 
						placeholder="Holiday" 
						[value]="profile.holiday?.name"
						(valueChange)="holidaySelected($event)"
						[items]="holidays" 
						[clearable]="true" 
						[searchable]="true" 
						[multiple]="false"
						bindLabel="name" 
						bindValue="name" 
					>
						<ng-template option let-item>
							<div class="select-option">
								<span class="select-option__name">{{ item.name }}</span>
								<span class="select-option__date">{{ item.date | date : 'shortDate' }}</span>
							</div>
						</ng-template>
					</app-select>
				</div>
				<div class="col-1 pb0" *ngIf="profile.holiday && profile.holiday.possible_substitute">
					<label class="waw-checkbox mb0">
						<input type="checkbox" [(ngModel)]="profile.substitute" name="substitute">
						<span class="checkmark"></span>
						<span class="waw-checkbox__text">Replace the day off if the holiday falls on the weekend</span>
					</label>
				</div>
			</div>
		</div>
		<!-- <div class="modal-switch">
			<div class="waw-switch-wrap">
				<label class="waw-switch">
					<input type="checkbox" name="repeat" [(ngModel)]="profile.repeat">
					<span class="track">
						<span class="handle"></span>
					</span>
				</label>
				<div class="waw-switch-text">repeat at the same time</div>
			</div>
		</div>  -->
		<div class="modal-btn">
			<button mat-button color="primary" type="button" class="waw-btn _second" (click)="close()" *ngIf="!profile._id">Cancel</button>
	
			<button mat-button color="primary" type="button" class="waw-btn _second _delete" (click)="delete()" *ngIf="profile._id">Delete</button>
	
			<button mat-flat-button color="primary" type="submit" class="waw-btn _primary">{{profile._id&&'Save'||'Create'}}</button>
		</div>
	</form>
</div>
