<div class="auth">
    <div class="auth-container">
        <form class="auth-left" (ngSubmit)="changePass()">
            <div class="flex-column">
                <h1 class="auth__title">Create new password</h1>
                <div class="waw-input _not_change_on_mobile">
                    <label [class.req]="auth.submitted.password&&!user.password?.trim() || auth.error.password" [class.check]="auth.check.password">
                        <div class="fl-sb-c">
                            <span class="waw-input__title">New Password</span>
                            <span *ngIf="auth.submitted.password&&!user.password?.trim() || auth.error.password" class="req-text">{{auth.error.password ? auth.error.password : 'Enter the password'}}</span>
                        </div>
                        <input type="password" [(ngModel)]="user.password" (ngModelChange)="auth.validateNewPassword(user.password); auth.validateConfirmPassword(user.password, user.rpassword)" (focus)="auth.show_visibility_btn = true; auth.show_verification_block = true" (focusout)="auth.validateNewPassword(user.password, $event); auth.show_visibility_btn = false" placeholder="New password" name="password" autocomplete="new-password">
                    </label>
                    <button mat-icon-button class="visibility" tabindex="-1" [class._show]="auth.show_visibility_btn" type="button" (click)="auth.changeVisibility()">
                        <span [class.icon-visibility]="!auth.visibility" [class.icon-visibility_off]="auth.visibility"></span>
                    </button>
                </div>
                <div class="password-verification" id="verification" [ngStyle]="{ 'display' : (auth.password_verification.uppercase && auth.password_verification.number && auth.password_verification.special && auth.password_verification.minimum) || !auth.show_verification_block ? 'none' : 'block' }">
                    <div class="password-verification__item" id="uppercase" [class.check]="auth.password_verification.uppercase">
                        <i class="material-icons notranslate">{{!auth.password_verification.uppercase ? 'cancel' : 'check_circle'}}</i>
                        <span>one uppercase character</span>
                    </div>
                    <div class="password-verification__item" id="number" [class.check]="auth.password_verification.number">
                        <i class="material-icons notranslate">{{!auth.password_verification.number ? 'cancel' : 'check_circle'}}</i>
                        <span>one number</span>
                    </div>
                    <div class="password-verification__item" id="special" [class.check]="auth.password_verification.special">
                        <i class="material-icons notranslate">{{!auth.password_verification.special ? 'cancel' : 'check_circle'}}</i>
                        <span>one special character</span>
                    </div>
                    <div class="password-verification__item" id="minimum" [class.check]="auth.password_verification.minimum">
                        <i class="material-icons notranslate">{{!auth.password_verification.minimum ? 'cancel' : 'check_circle'}}</i>
                        <span>8 character minimum</span>
                    </div>
                </div>
                <div class="waw-input _not_change_on_mobile">
                    <label [class.req]="auth.submitted.rpassword&&!user.rpassword?.trim() || auth.error.rpassword" [class.check]="auth.check.rpassword">
                        <div class="fl-sb-c">
                            <span class="waw-input__title">Confirm Password</span>
                            <span *ngIf="auth.submitted.rpassword&&!user.rpassword?.trim() || auth.error.rpassword" class="req-text">{{auth.error.rpassword ? auth.error.rpassword : 'Confirm the password'}}</span>
                        </div>
                        <input type="password" [(ngModel)]="user.rpassword" (ngModelChange)="auth.validateConfirmPassword(user.password, user.rpassword)" (focus)="auth.show_visibility_btn_repeat = true;" (focusout)="auth.validateConfirmPassword(user.password, user.rpassword, true, $event); auth.show_visibility_btn_repeat = false" placeholder="Confirm password" name="rpassword" autocomplete="off">
                    </label>
                    <button mat-icon-button class="visibility" tabindex="-1" [class._show]="auth.show_visibility_btn_repeat" type="button" (click)="auth.changeVisibility(true)">
                        <span [class.icon-visibility]="!auth.visibility_repeat" [class.icon-visibility_off]="auth.visibility_repeat"></span>
                    </button>
                </div>
            </div>
            <div>
                <button mat-flat-button color="primary" type="submit" class="waw-btn _primary _not_change_on_mobile">Create</button>
            </div>
        </form>

        <div class="auth-right">
            <div class="auth__logo">
                <img class="auth__logo-img" width="80" height="80" src="assets/sign/logo.svg" alt="Logo">
                <span class="auth__logo-text notranslate">Pure Planner</span>
            </div>
            <div class="auth__preview-1"><img width="305" height="159" src="assets/sign/preview_1.png"></div>
            <div class="auth__preview-2"><img width="305" height="159" src="assets/sign/preview_2.png"></div>
            <div class="auth__preview-3"><img width="305" height="159" src="assets/sign/preview_3.png"></div>
        </div>
    </div>
</div>
