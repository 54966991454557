import { Component, ContentChildren, Input, OnInit, QueryList, AfterContentInit, TemplateRef, ContentChild, ElementRef, OnChanges } from '@angular/core';
import { CellDirective, SortDirective } from './table.directive';
@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, AfterContentInit, OnChanges {
	@Input() config: any = {};
	
	@Input() columns: any = [];
	
	@Input() rows: any = [];

	@Input() pagination: boolean = true;

	@ContentChildren(CellDirective) cell: QueryList<CellDirective>;

	@ContentChildren(SortDirective) sortHeaders: QueryList<SortDirective>;

	custom_cell: any = {};

	sortable: any = {};

	sort_type: any = {};

	ngOnInit(): void {
		this.default_config();

		for (let i = 0; i < this.columns.length; i++) {
			if (typeof this.columns[i] === 'string') {
				this.columns[i] = {
					title: this.columns[i],
					field: this.columns[i]
				};
			}
		}
	}

	ngAfterContentInit(): void {
		for (let i = 0; i < this.sortHeaders.toArray().length; i++) {
			this.sortable[this.sortHeaders.toArray()[i].cell] = true;
		}

		for (let i = 0; i < this.cell.toArray().length; i++) {
			const cell = this.cell.toArray()[i];

			this.custom_cell[cell.cell] = cell.template;
		}
	}

	ngOnChanges(): void {
		this.config.page = 1;
	}

	default_config(): void {
		if (!this.config.pageSizeOptions) {
			this.config.pageSizeOptions = [5, 10, 25, 50];
		}

		if (!this.config.perPage) {
			this.config.perPage = 10;
		}

		if (!this.config.page) {
			this.config.page = 1;
		}
	}

	next(): void {
		if (this.config.page * this.config.perPage < this.rows.length) {
			this.config.page += 1;
		}
	}

	previous(): void {
		if (this.config.page > 1) {
			this.config.page -= 1;
		}
	}

	lastPage(): void {
		this.config.page = Math.ceil(this.rows.length / this.config.perPage);
	}

	sort(column: any): void {
		if (this.sort_type.title != column.field) {
			this.sort_type = {};
		}

		if (this.sortable[column.field]) {
			this.sort_type = {
				title: column.field,
				direction:
					(typeof this.sort_type.direction != 'string' && 'asc') ||
					(this.sort_type.direction == 'asc' && 'desc') ||
					undefined
			};
		}
	}
}
