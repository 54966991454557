import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AlertService } from 'wacom';
import { AuthService, LoaderService } from '@services';

@Component({
    selector: 'app-save',
    templateUrl: './save.component.html',
    styleUrls: ['./save.component.scss']
})
export class SaveComponent {
    constructor(private router: Router,
        private http: HttpClient,
        private alert: AlertService,
        public auth: AuthService,
        private loader: LoaderService) {
        this.auth.reset();
        if(localStorage.getItem('waw_reset_email')){
            this.user.email = localStorage.getItem('waw_reset_email');
        }
        if(localStorage.getItem('waw_reset_code')){
            this.user.code = localStorage.getItem('waw_reset_code');
        }
        if(!this.user.email || !this.user.code){
            this.router.navigate(['/forgot']);
        }
    }
    public user:any = {
        email: '',
        password: '',
        rpassword: '',
        code: ''
    };
    changePass() {
        this.auth.submitted.password = true;
        this.auth.submitted.rpassword = true;
        if(!this.user.password) {
            this.alert.error({
                text: 'Enter your password',
            })
            return;
        }
        if(!this.auth.passwordLengthRegex.test(this.user.password)) {
            this.alert.error({
                text: 'Password must contain at least 8 characters',
            })
            return;
        }
        if(!this.auth.passwordRegex.test(this.user.password)) {
            this.alert.error({
                text: 'Password must contain at least 1 capital letter, 1 number and 1 special character',
            })
            return;
        }
        if(!this.user.rpassword) {
            this.alert.error({
                text: 'Confirm the password',
            })
            return;
        }
        if(this.user.password != this.user.rpassword) {
            this.alert.error({
                text: 'Passwords do not match',
            })
            return;
        } 
        this.loader.show({transparent: true});
        this.http.post('/api/user/change', {
            password: this.user.password,
            email: this.user.email,
            pin: this.user.code
        }).subscribe((resp:any) => {
            if(resp == true) {
                localStorage.removeItem('waw_reset_email');
                localStorage.removeItem('waw_reset_code');
                this.http.post('/api/user/login', {
                    password: this.user.password,
                    username: this.user.email
                }).subscribe((res:any) => {
                    this.loader.remove();
                    localStorage.setItem('waw_user', JSON.stringify(res));
                    this.router.navigate(['/profile']);
                });     
            } else {
                this.loader.remove();
                if(resp != false) this.alert.error({
                    text: resp
                });
                this.router.navigate(['/reset']);
            }   
        });
    }
}
