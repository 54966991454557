import { Injectable } from '@angular/core';
import { MongoService, AlertService } from 'wacom';

@Injectable({
	providedIn: 'root'
})
export class PlatformService {
	public platform: any = {data:{}};
	constructor(private mongo: MongoService, private alert: AlertService) { 
		mongo.get('platform', {
			replace: {
				data: mongo.beObj
			}
		}, (arr, obj)=>{
			this.platform = arr[0];
		});
	}
	has(what){
		return this.platform.data[what];
	}
	update() {
		this.mongo.afterWhile(this, ()=> {
			this.save();
		});
	}
	save(){
		console.log('save', this.platform);
		this.mongo.update('platform', this.platform, ()=>{
			this.mongo.get('platform', {
				replace: {
					data: this.mongo.beObj
				}
			}, (arr, obj)=>{
				this.platform = arr[0];
			});
		});
	}
}
