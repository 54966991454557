<div class="modal-box">
    <div class="modal-title">Booking</div>
    <button mat-icon-button type="button" class="modal-close" (click)="close()">
		<span class="modal-close__icon"></span>
	</button>
    <h2 class="modal-h2">Appointment scheduling conflict</h2>
    <div class="row">
        <div class="col-1">
            <label class="waw-radio">
                <input type="radio" name="booking" [value]="false" [(ngModel)]="block_booking">
                <span class="circle"></span>
                <span class="waw-radio__text">
                    Allow booking with time conflict
                </span>
            </label>
            <label class="waw-radio">
                <input type="radio" name="booking" [value]="true" [(ngModel)]="block_booking">
                <span class="circle"></span>
                <span class="waw-radio__text">
                    Prevent booking with time conflict
                </span>
            </label>
        </div>
    </div>
    <div class="modal-btn">
        <button mat-button color="primary" type="button" class="waw-btn _second" (click)="close()">Cancel</button>
        <button mat-flat-button color="primary" type="submit" class="waw-btn _primary" (click)="save()">Save</button>
    </div>
</div>