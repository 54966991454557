import { Injectable } from '@angular/core';
import { MongoService, AlertService } from 'wacom';

@Injectable({
	providedIn: 'root'
})
export class RoomService {
	public rooms: any = [];
	public _rooms: any = {};
	public by_locations: any = {};
	public days:any = [{
		name: "Monday",
	}, {
		name: "Tuesday",
	}, {
		name: "Wednesday",
	}, {
		name: "Thursday",
	}, {
		name: "Friday",
	}, {
		name: "Saturday",
	}, {
		name: "Sunday"
	}];
	private WeekDay:any = {
		"0": "Monday",
		"1": "Tuesday",
		"2": "Wednesday",
		"3": "Thursday",
		"4": "Friday",
		"5": "Saturday",
		"6": "Sunday"
	};
	constructor(private alert: AlertService, private mongo: MongoService) {
		this.rooms = mongo.get('room', {
			groups: 'location'
		}, (arr, obj) => {
			this.by_locations = obj.location;
			this._rooms = obj;
		});
	}
	create(room, cb: any = (created) => {}) {
		if(room._id) return this.update(room, cb);
		this.mongo.create('room', room, (created)=>{
			if(typeof cb === 'function') cb(created);
			this.alert.show({
				text: 'Room has been created.'
			});
		}); 
	}
	update(room, cb: any = () => {}) {
		this.mongo.afterWhile(room, ()=> {
			this.mongo.update('room', room, (updated) => {
				if(typeof cb === 'function') cb(updated);
			});
		});
	}
	delete(room, cb: any = () => {}) {
		this.mongo.delete('room', room, (deleted) => {
			if(typeof cb === 'function') cb(deleted);
		});
	}
}

/*
We need a 
schedule and availability for rooms.
Which days the rooms are available 
from what time to what time.
*/