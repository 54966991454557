import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';

@Pipe({
	name: 'time'
})
export class TimePipe implements PipeTransform {
	transform(timelogs:any, from, to, refresh?:any, type = 1){
		if(!timelogs ) return type == 1 ? '0h' : '0:00';
		let minutes = 0;
		for (let i = 0; i < timelogs.length; i++){
			if(!timelogs[i].start || isNaN(timelogs[i].start?.getTime())) continue;
			if(timelogs[i].end && isNaN(timelogs[i].end?.getTime())) continue;
			if(timelogs[i].start.getTime()>=from && timelogs[i].start.getTime()<to && !timelogs[i].end) {
		    	minutes += (new Date().getTime()-timelogs[i].start.getTime()) / 1000 / 60;
				continue;
			} else if(!timelogs[i].end) continue;
		    if(timelogs[i].start.getTime()>=from && timelogs[i].end.getTime()<to) {
				minutes += (timelogs[i].end.getTime()-timelogs[i].start.getTime()) / 1000 / 60;
				continue;
		    }
			if(timelogs[i].start.getTime()>=from && timelogs[i].start.getTime()<to && timelogs[i].end) {
				minutes += (to-timelogs[i].start.getTime()) / 1000 / 60;
				continue;
			}
			if(timelogs[i].end.getTime()>=from && timelogs[i].end.getTime()<to) {				
		    	minutes += (timelogs[i].end.getTime()-from) / 1000 / 60;
				continue;
			}
		}
		return type == 1 ? ( Math.floor( Math.round(minutes) / 60 ) + 'h ' + Math.floor( Math.round(minutes) % 60 ) + 'm' ) : ( Math.floor( Math.round(minutes) / 60 ) + ':' + Math.floor( Math.round(minutes) % 60 ).toString().padStart(2, '0') );
	}
}
