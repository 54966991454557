<div class="currency-container">
    <input 
        #input
        inputmode="decimal"
        currencyMask 
        [ngModel]="value"
        (ngModelChange)="valueChange.emit($event)" 
        [disabled] = "disabled" 
        [options]="options"
        [ngClass] = "class"
        placeholder="0.00"
    >
</div>