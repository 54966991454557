import { Component, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import {
	GroupService,
	ServiceService,
	LocationService,
	UserService,
	RoomService,
	LoaderService
} from '@services';
import { ModalService, MongoService } from 'wacom';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-services',
	templateUrl: './services.component.html',
	styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit, AfterViewInit {
	editService(new_service){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({component: 'service', new_service: JSON.parse(JSON.stringify(new_service))})
	}
	create(groupId){
		let new_service:any = {
			color: this.us.randomColor
		};
		if(this.ls._locations.primary && this.ls._locations.primary.length){
			new_service.locations = [this.ls._locations.primary[0]._id];
		}
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({component: 'service', new_service: new_service, groupId: groupId});
	}
	constructor(public us: UserService,
		public ls: LocationService,
		public rs: RoomService,
		public gs: GroupService,
		public ss: ServiceService,
		public modal: ModalService,
		private route: ActivatedRoute,
		private router: Router,
		private mongo: MongoService,
		private loader: LoaderService,
		private eref: ElementRef) {
		this.us.headerTitle = 'Services';
		this.us.headerSubtitle = '';
	}
	ngOnInit() {
		this.loader.show({container: true}, this.eref.nativeElement.querySelector('.containerBox'));
	}
	ngAfterViewInit() {
		this.mongo.on('user location room group service', () => {
			this.loader.remove();
		});
	}
	public sort_type:any = {};
	sort(field) {
		if(this.sort_type.title != field) this.sort_type = {};
		this.sort_type = {
			title: field,
			direction: (typeof this.sort_type.direction != 'string'&&'asc')||(this.sort_type.direction == 'asc'&&'desc')||undefined
		}
	}
	ngDoCheck(){
		if( this.route.snapshot.queryParams['modal']){
		}
		else{
			this.modal.destroy()
		}
	}
	openModal(profile = null){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({
			component: 'modal',
			profile: profile ? JSON.parse(JSON.stringify(profile)) : {},
			title: 'Service Group',
			components: [
				{
					type: 'INPUT',
					input: 'name',
					label: 'Group name*',
					placeholder: 'Enter group name',
					reqText: 'Name is required',
					required: true,
				}, {
					type: 'EDITOR',
					input: 'description',
					label: 'Notes',
					placeholder: 'Add privates notes',
					size: 'large'
				}
			],
			alertOpts: {
				title: 'Delete group',
				body: `Are you sure you want to delete group ${profile?.name}?`
			},
			onDelete: (profile: any) => {
				this.gs.delete(profile, () => {
					this.modal.destroy();
				});
			},
			onSave: (profile: any) => {
				this.gs.create(profile, () => {
					this.modal.destroy();
				});
			}
		});	
	}	
	delete(group) {
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({
			component: 'deleteAlert',
			title: 'Delete group',
			body: `Are you sure you want to delete group ${group.name}?`,
			on_add: ()=> {
				this.loader.show({container: true, transparent: true}, this.eref.nativeElement.querySelector('.containerBox'));
				this.gs.delete(group, () => {
					this.loader.remove();
				});
			}
		});
	}
	getLocations(locations) {
		this.mongo.on('location', () => {
			for(let i = 0; i < locations?.length; i++) {
				if(!this.ls._locations[locations[i]]) locations.splice(i, 1);
			}
		});
		return locations;
	}
	getStaff(staff) {
		this.mongo.on('user', () => {
			for(let i = 0; i < staff?.length; i++) {
				if(!this.us._users[staff[i]] || this.us._users[staff[i]].deleted) staff.splice(i, 1);
			}
		});
		return staff;
	}
}