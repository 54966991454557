<form (ngSubmit)="create();">
	<div class="modal-box">
		<div class="modal-title">{{staff._id&&'Edit'||'New'}} Staff</div>
		<button mat-icon-button type="button" class="modal-close" (click)="close()">
			<span class="modal-close__icon"></span>
		</button>
		<div class="modal-rowImg">
			<div class="modal-rowImg__left">
				<div class="modal-img">
					<picture class="_fz-46" style="height: 158px;font-size: 35px;" [user]="staff" [big]="true"></picture>
					<ng-container *ngIf="us.is.admin||us.is.owner||us._id == staff._id">
						<label for="newClientCrop">
							<button mat-icon-button color="primary" type="button" class="img-upload waw-btn__icon primary" (click)="input.click()"><span class="icon-pencil"></span></button>
						</label>
						<input type="file" id="newClientCrop" #input (change)="fileChangeEvent($event);" [hidden]="true" accept="image/*" />
					</ng-container>
				</div>
				<div class="waw-select">
					<span class="waw-select__title">Gender</span>
					<app-select 
						placeholder="Select gender" 
						[(value)]="staff.data.gender"
						[items]="['Prefer not to say', 'Female', 'Male', 'Non-binary']" 
						[clearable]="false" 
						[searchable]="false"
						[disabled]="!(us.is.admin||us.is.owner)&&us._id != staff._id"
					></app-select>
				</div>
			</div>
			<div class="modal-rowImg__right">
				<div class="waw-input col-1">
					<label [class.req]="submitted&&!staff.firstName">
						<span class="waw-input__title">First Name *</span>
						<input type="text" placeholder="First Name" name="firstName" [(ngModel)]="staff.firstName" [disabled]="!(us.is.admin||us.is.owner)&&us._id != staff._id">
						<div *ngIf="submitted&&!staff.firstName" class="req-text">First name is required</div>
					</label>
				</div>
				<div class="waw-input col-1">
					<label [class.req]="submitted&&!staff.lastName">
						<span class="waw-input__title">Last Name *</span>
						<input type="text" placeholder="Last Name" name="lastName" [(ngModel)]="staff.lastName" [disabled]="!(us.is.admin||us.is.owner)&&us._id != staff._id">
						<div *ngIf="submitted&&!staff.lastName" class="req-text">Last name is required</div>
					</label>
				</div>
				<div class="waw-datepicker _icon col-1 _arrow">
					<span class="waw-datepicker__title">Date of Birth</span>
					<datepicker [date]="staff.data.birthday" (valueChange)="staff.data.birthday=$event" [icon]="true" [disabled]="!(us.is.admin||us.is.owner)&&us._id != staff._id"></datepicker>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="waw-textarea col-1">
				<div [class.req]="characterLimit">
					<note-editor [notes]="noteEditor" [disabled]="!(us.is.admin||us.is.owner)&&us._id != staff._id" placeholder="Add notes" label="notes" size="medium" (characterLimit)="characterLimit = $event" (onChange)="noteEditor = $event"></note-editor>
					<div *ngIf="characterLimit" class="req-text mb10">{{characterLimit}}</div>
				</div>
			</div>
		</div>
		<div class="modal-section">
			<h2 class="modal-h2">Additional Info</h2>
			<p class="modal-p">Staff contact details are confidential and will not be shared with your customers.</p>
			<div class="row">
				<div class="waw-input col-1">
					<label>
						<span class="waw-input__title">address</span>
						<input ngx-google-places-autocomplete autocomplete="off" [options]="{componentRestrictions: {country:ls.primary&&ls.primary[0]&&(ls.primary[0].country=='Canada'&&'ca'||ls.primary[0].country=='USA'&&'us'||'ca')||'ca'}}" placeholder="Street" [(ngModel)]="staff.data.address" name="address" (onAddressChange)="staff.data.address = us.getFormattedAddress($event, true)" [disabled]="!(us.is.admin||us.is.owner)&&us._id != staff._id">
					</label>
				</div>
				<div class="waw-input col-1">
					<label [class.req]="submitted&&!staff.email">
						<span class="waw-input__title">Email *</span>
						<input type="text" placeholder="Email" name="email" [(ngModel)]="staff.email" [disabled]="staff._id" [disabled]="!(us.is.admin||us.is.owner)&&us._id != staff._id">
						<div *ngIf="submitted&&!staff.email" class="req-text">Email is required</div>
					</label>
				</div>
				<div class="waw-input col-2">
					<label>
						<span class="waw-input__title">Phone</span>
						<input type="text" placeholder="Phone" name="phone" [(ngModel)]="staff.data.phone" [disabled]="!(us.is.admin||us.is.owner)&&us._id != staff._id">
					</label>
				</div>
				<div class="waw-input col-2">
					<label>
						<span class="waw-input__title">Additional phone</span>
						<input type="text" placeholder="Additional Phone" name="additionalPhone" [(ngModel)]="staff.data.additionalPhone" [disabled]="!(us.is.admin||us.is.owner)&&us._id != staff._id">
					</label>
				</div>
				<div class="waw-select col-1">
					<span class="waw-select__title">Location office *</span>
					<app-select 
						placeholder="Location office" 
						[(value)]="staff.location"
						[items]="ls.locations||[]" 
						[clearable]="false" 
						[searchable]="true"
						[disabled]="!(us.is.admin||us.is.owner)&&us._id != staff._id"
						[required]="submitted&&!staff.location||submitted&&staff.location&&!staff.location.length"
						[multiple]="true"
						bindLabel="name" 
						bindValue="_id"
					>
						<ng-template select let-items let-open="open" let-required="required">
							<div class="modal-selectMultiple" [class._open]="open" [class._req]="required" #select>
								<ng-container *ngIf="items?.length">
									<div class="modal-selectMultiple__item _delete text-elipsis" *ngFor="let item of items; index as i">
										{{item.name}}
										<button mat-icon-button color="primary" type="button" class="modal-selectMultiple__item__delete" (click)="staff.location.splice(i, 1); $event.stopPropagation()"><span class="material-icons">close</span></button>
									</div>
								</ng-container>
								<ng-container *ngIf="!items?.length">
									<span class="modal-selectMultiple__placeholder">Select location</span>
								</ng-container>
								<button mat-icon-button color="primary" type="button" class="modal-selectMultiple__btn">
									<span class="modal-selectMultiple__btn-icon icon-arrow_down" style="display: block; transition: 0.15s all ease-in-out;" [ngStyle]="{'transform': open ? 'rotate(-180deg)' : 'rotate(0deg)' }"></span>
								</button>
							</div>
						</ng-template>
					</app-select>
					<div *ngIf="submitted&&!staff.location||submitted&&staff.location&&!staff.location.length" class="req-text">Location is required</div>
				</div>
	
				<div class="waw-select col-1 pb0" style="margin-bottom: -10px;">
					<span class="waw-select__title">Category</span>
					<app-select 
						placeholder="Select category" 
						[(value)]="staff.category"
						[items]="cs.categories" 
						[clearable]="false" 
						[searchable]="false"
						[disabled]="!(us.is.admin||us.is.owner)&&us._id != staff._id"
						bindLabel="name" 
						bindValue="_id" 
					></app-select>
				</div>
			</div>
		</div>
		<h2 class="modal-h2">Working Hours</h2>
		<p class="modal-p">Choose working hours of current staff</p>
		<app-working-days [component]="true" [submit]="submitted" [staff]="staff" (onChange)="staff = $event"></app-working-days>

		<div class="modal-btn">
			<button mat-button color="primary" type="button" class="waw-btn _second" (click)="close()" *ngIf="!staff._id||!(us.is.admin||us.is.owner)">Cancel</button>
			<button mat-button color="primary" type="button" class="waw-btn _second _delete" (click)="delete();" *ngIf="staff._id&&(us.is.admin||us.is.owner)">Delete</button>
			<button mat-flat-button color="primary" type="submit" *ngIf="us.is.admin||us.is.owner||us._id == staff._id" class="waw-btn _primary">{{staff._id&&'Save'||'Create'}}</button>
		</div>
	</div>
</form>
