import { Injectable } from '@angular/core';
import { MongoService, AlertService } from 'wacom';

@Injectable({
	providedIn: 'root'
})
export class HourService {
	public hours: any = [];
	public store: any = [];
	public _hours: any = {};
	public room: any = {};
	private WeekDay:any = {
		"0": "Monday",
		"1": "Tuesday",
		"2": "Wednesday",
		"3": "Thursday",
		"4": "Friday",
		"5": "Saturday",
		"6": "Sunday"
	};
	load(){
		this.hours = this.mongo.get('hour', {
			groups: 'room',
			query: {
				store: function(doc){ return !doc.room }
			}
		}, (arr, obj) => {
			this._hours = obj;
			this.room = obj.room || {};
			this.store = obj.store;
		});
	}
	constructor(private alert: AlertService, private mongo: MongoService) {
		this.load();
	}
	update_all(){
		for (let i = 0; i < this.hours.length; i++){
		    this.save(this.hours[i]);
		}
	}
	create(hour) {
		if(hour._id) return this.update(hour);
		console.log(hour);
		this.mongo.create('hour', hour, ()=>{
			this.alert.show({
				text: 'Hours has been added.'
			});
		});
	}
	update(hour) {
		this.mongo.afterWhile(hour, ()=> {
			this.save(hour);
		});
	}
	save(hour) {
		this.mongo.update('hour', hour);
	}
	delete(hour) {
		this.mongo.delete('hour', hour);
	}
}
