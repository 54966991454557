<div class="containerBox setup">
	<div class="setup-empty setup-box" *ngIf="!ls.locations?.length">
		<div class="setup-empty__img">
			<img src="assets/img/setup/location.png" alt="location" draggable="false"/>
		</div>
		<div class="setup-empty__title">Add your business locations here</div>
		<div class="setup-empty__btn">
			<button mat-flat-button color="primary" class="waw-btn _primary" (click)="openModal()">
				<span class="icon-plus"></span>
				<span>Add location</span>
			</button>
		</div>
	</div>
	<div class="setup-container" *ngIf="ls.locations?.length">
		<div class="setup-item setup-box" *ngFor="let location of ls.locations" style="grid-row: span 2;">
			<div class="setup-item__title">{{location.name}}</div>
			<div class="setup-item__actions">
				<button mat-icon-button color="primary" class="action-edit" (click)="edit(location);"><span class="icon-pencil"></span></button>
				<button mat-icon-button color="warn" class="action-delete" (click)="delete(location);"><span class="icon-delete"></span></button>
			</div>
			<hr>
			<div class="setup-item__property">
				<div class="setup-item__property__name">Email:</div>
				<span class="setup-item__property__value text-elipsis">{{location.email}}</span>
			</div>
			<div class="setup-item__property">
				<div class="setup-item__property__name">Country:</div>
				<span class="setup-item__property__value text-elipsis">{{location.country}}</span>
			</div>
			<div class="setup-item__property">
				<div class="setup-item__property__name">City:</div>
				<span class="setup-item__property__value text-elipsis">{{location.city}}</span>
			</div>
			<div class="setup-item__property">
				<div class="setup-item__property__name">Contact number:</div>
				<span class="setup-item__property__value text-elipsis">{{location.number}}</span>
			</div>
			<div class="setup-item__property">
				<div class="setup-item__property__name">State:</div>
				<span class="setup-item__property__value text-elipsis">{{location.state}}</span>
			</div>
			<div class="setup-item__property">
				<div class="setup-item__property__name">Zip code:</div>
				<span class="setup-item__property__value text-elipsis">{{location.zip}}</span>
			</div>
			<div class="setup-item__property">
				<div class="setup-item__property__name">Street:</div>
				<span class="setup-item__property__value text-elipsis">{{location.address}}</span>
			</div>
			<div class="setup-item__description" style="margin-top: 20px;">Business Hours</div>
			<hr>
			<div class="setup-item__property" *ngFor="let day of us.days_of_week" style="align-items: flex-start;">
				<div class="setup-item__property__name">{{ day }}:</div>
				<span class="setup-item__property__value text-elipsis">
					<ng-container *ngFor="let hour of getBusinessHours(location.data.business_hours?.[day]); last as last">
						<span>{{hour}}</span>
						<wbr *ngIf="!last">
					</ng-container>
				</span>
			</div>
		</div>
		<div class="setup-add">
			<button mat-flat-button color="primary" class="waw-btn _primary" (click)="openModal()">
				<span class="icon-plus"></span>
				<span>Add location</span>
			</button>
		</div>
	</div>
</div>
