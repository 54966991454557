import { Component, Input, OnDestroy } from '@angular/core';
import { NotificationService, UserService } from '@services';

@Component({
	selector: 'notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnDestroy {
    @Input('notif') notif: any = {};
	public now = Date.now();
	private interval;
	constructor(public us: UserService, public ns: NotificationService){
		this.interval = setInterval(() => {
			this.now = Date.now();
		}, 30000);
	}
	ngOnDestroy() {
		clearInterval(this.interval);
	}
}
