import { Component } from '@angular/core';
import { UserService, PlanService } from '@services';
import { ModalService } from 'wacom';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-payment',
	templateUrl: './payment.component.html',
	styleUrls: ['./payment.component.scss']
})
export class PaymentComponent {
	
	edit(plan){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({component: 'payment', profile: JSON.parse(JSON.stringify(plan))})
	}
	constructor(public modal: ModalService,
		public ps: PlanService,
		public us: UserService,
		public router: Router,
		private route: ActivatedRoute) {
		this.us.headerTitle = 'payment types'
		this.us.headerSubtitle = 'Setup';
	}
	ngDoCheck(){
		if( this.route.snapshot.queryParams['modal']){
		}
		else{
			this.modal.destroy()
		}
	}
	openModal(){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({ component: 'payment'})		
	}
}
