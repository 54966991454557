import { Component, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService, PlatformService, LoaderService} from '@services';
import { ModalService, MongoService } from 'wacom';
@Component({
	selector: 'app-mails',
	templateUrl: './mails.component.html',
	styleUrls: ['./mails.component.scss']
})
export class MailsComponent implements OnInit, AfterViewInit {
	constructor(
		public us: UserService, 
		public modal: ModalService, 
		public ps: PlatformService, 
		public router:Router,
		private loader: LoaderService,
		private mongo: MongoService,
		private eref: ElementRef
	) {
		this.us.headerTitle = 'Mail Templates';
		this.us.headerSubtitle = 'Admin';
	}
	ngOnInit() {
		this.loader.show({container: true}, this.eref.nativeElement.querySelector('.containerBox'));
	}
	ngAfterViewInit() {
		this.mongo.on('user platform', () => {
			this.loader.remove();
		});
	}
	edit(mail) {
		this.router.navigate([],{ queryParams: { filter: 'open' } });
		this.modal.show({component: 'mail', mail: JSON.parse(JSON.stringify(mail.value)), edit: mail.holder})
	}
}