import { Component } from '@angular/core';
import { UserService, WebsiteService, CategoryService } from '@services';
import { ModalService } from 'wacom';
import { Router,ActivatedRoute } from '@angular/router';


@Component({
	selector: 'app-online-link',
	templateUrl: './online-link.component.html',
	styleUrls: ['./online-link.component.scss']
})
export class OnlineLinkComponent {
	edit(profile){
		if(!profile.icon) profile.icon='';
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({component: 'websiteprofile', profile: JSON.parse(JSON.stringify(profile))})
	}
	constructor(public modal: ModalService,
		public cs: CategoryService,
		public ws: WebsiteService,
		public us: UserService,
		public router: Router,
		private route: ActivatedRoute) {
		this.us.headerTitle = 'Online links'
		this.us.headerSubtitle = 'Setup';
	}
	ngDoCheck(){
		if( this.route.snapshot.queryParams['modal']){
		}
		else{
			this.modal.destroy()
		}
	}
	openModal(){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({ component: 'websiteprofile'})		
	}
}
