import { trigger, state, style, animate, transition, } from '@angular/animations';
export const flyAnimation = [
	trigger('flyInOut', [
        state('in', style({ transform: 'translateX(0)' })),
        transition('void => *', [
            style({
                transform: 'translateY(-20px)',
                opacity: 0,
            }),
            animate(300)
        ]),
        transition('* => void', [
            animate(300, style({
                opacity: 0,
                // height: '50px'
                transform: 'translateY(-20px)',
            }))
        ])
    ]),
    trigger('tabInOut', [
        state('in', style({ transform: 'translateX(0)' })),
        transition('void => *', [
            style({
                transform: 'translateX(20px)',
                opacity: 0,
            }),
            animate(300)
        ]),
        transition('* => void', [
            animate(300, style({
                opacity: 0,
                // height: '50px'
                transform: 'translateX(20px)',
            }))
        ])
    ])
];
export const modalAnimation = [
    trigger('modal', [
        state('positionTop', style({ opacity: 1, height: '*', marginBottom: '10px' })),
        transition('* => positionTop', [
            style({ 
                opacity: 0,
                height: 0,
                marginTop: '100%',
            }),
            animate(100)
        ]),
        transition('positionTop => *', [ animate(0, style({ opacity: 0, height: 0, marginTop: '10%' })) ]),
        state('positionBottom', style({ opacity: 1, height: '*', marginTop: '*' })),
        transition('* => positionBottom', [
            style({ 
                opacity: 0,
                height: 0
            }),
            animate(100)
        ]),
        transition('positionBottom => *', [ animate(0, style({ opacity: 0, height: 0, marginTop: '-10%' })) ]),
        state('zoom', style({ opacity: 1, transform: 'scale(1)' })),
        transition('* => zoom', [
            style({ 
                opacity: 0,
                transform: 'scale(0.6)'
            }),
            animate('.15s ease-in-out')
        ]),
        transition('zoom => *', [ animate('0s ease-in-out', style({ opacity: 0, transform: 'scale(0.6)' })) ]),
    ]),
];
​
// Readme 
//  === html directive  ===
// (click)="variable"
// *ngIf="!variable"
// [@flyInOut]="variable"
​
// === import to component  ===
// import { flyAnimation } from "./animation"
// @Component({
// 	animations: [ flyAnimation ]
// })