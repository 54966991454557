import { Component, EventEmitter, Input, Output, HostBinding, OnChanges, ViewChild, AfterViewInit, HostListener, OnInit, ElementRef } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { fromEvent } from 'rxjs';
import { modalAnimation } from 'src/app/common/animation/animation.component';
import { DatePipe } from '@angular/common';
import { UserService } from '@services';

@Component({
	selector: 'datepicker',
	templateUrl: './datepicker.component.html',
	styleUrls: ['./datepicker.component.scss'],
    animations: [modalAnimation]
})
export class DatepickerComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() type: string = 'date';

    @Input() month: number;

    @Input() year: number;
    
    @Input() date: any;

    @Input() disabled: boolean = false;

    @Input() readonly: boolean = false;

    @Input() icon: boolean = false;

    @Input() darkIcon: boolean = false;

	@Input() class: string = '';
    
    @Input() weekend_red: boolean = true;

	@Input() placeholder: string = 'Select date';

	@Input() required: boolean = false;

	@Input('open') openPicker: boolean = false;

	@Input() interval: string = 'day';

	@Input() minDate: Date;

    @Input() dateFilter: (d: Date) => boolean;
    
    @Output() valueChange = new EventEmitter();

    @Output() close = new EventEmitter();

    @ViewChild('calDpUi', { static: false }) datepickerUi: MatDatepicker<Date>;

    @ViewChild('calDp', { static: false }) datepicker: MatDatepicker<Date>;

    @ViewChild('input', { static: false }) input: ElementRef;

    public isOpen: boolean = false;
    
    public position: string = 'positionBottom';

    public formatted: string = '';

    constructor(private datePipe: DatePipe, public us: UserService) {}
    
    @HostBinding('window:click') onClick() {
        return fromEvent(document.querySelector('.cdk-overlay-container'), 'click');
	}

	@HostListener('window:resize')
	onWindowResize() {
        if (this.openPicker) {
            if(this.touchUi) {
                if(!this.datepickerUi.opened) {
                    this.datepicker.close();
                    this.datepickerUi.open();
                }
            } else {
                if(!this.datepicker.opened) {
                    this.datepickerUi.close();
                    this.datepicker.open();
                }
            }
        }
	}
    
    ngOnInit() {
        this.formatted = this.value ? this.datePipe.transform(this.value, 'M/d/yyyy') : '';
    }

    open() {
        this.onClick().subscribe(event => {
            console.log(event);
            event.stopPropagation();
        });
    }

    get value() {
        if (this.date == null) return null;
        if (Object.keys(this.date).length !== 0) return this.date?.singleDate?.jsDate;
        else return this.date;
    }

    selectedChange(event) {
        if (event == null) {
            this.dateChange({ value: null });
            this.formatted = '';
        } else {
            this.dateChange({ value: new Date(event) });
            this.formatted = this.datePipe.transform(new Date(event), 'M/d/yyyy');
        }
    }
 
    dateChange(event) {
        if (event.value == null) {
            this.valueChange.emit(null);
            this.isOpen = false;
        } else {
            var res: any = {"isRange": false, "singleDate": { "date": {} }, "dateRange": null};
            const date = new Date(event.value);
            res.singleDate.date = {
                "year": date.getFullYear(),
                "month": date.getMonth() + 1,
                "day": date.getDate()
            };
            res.singleDate.jsDate = date;
            res.singleDate.formatted = this.datePipe.transform(date, 'M/d/yyyy');
            res.singleDate.epoc = date.getTime();
            this.isOpen = false;
            this.valueChange.emit(res);
        }
    }

    get touchUi() {
        if(window.innerWidth < 1024) return true;
        else return false;
    }

    ngAfterViewInit() {
        if(this.datepickerUi) {
            this.datepickerUi.closedStream.subscribe(() => {
                this.close.emit(true);
            });
        }
        if(this.datepicker) {
            this.datepicker.closedStream.subscribe(() => {
                this.close.emit(true);
            });
            this.datepicker.openedStream.subscribe((event) => {
                setTimeout(() => {
                    this.datepicker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
                        this.dateChange(event);
                        this.datepicker.close();
                        this.close.emit(true);
                    })
                },0)
            });
        }
    }

    ngOnChanges() {
        if(this.input) {
            if(this.input.nativeElement !== document.activeElement) this.refreshInput();
        }
        if(this.openPicker) {
            if(this.touchUi && this.datepickerUi) {
                this.datepickerUi.open();
            } else if(this.datepicker) {
                this.datepicker.open();
            }
        }
    }

    get week() {
        if(this.datepicker) {
            return this.datepicker.opened;
        } else return false;
    }

    addWeeks(number=1){
		let date = new Date(new Date(this.date.singleDate.jsDate).getTime()+(number*604800000));
		let value = {
            isRange: false,
			singleDate: {
                jsDate: date,
				formatted: this.datePipe.transform(date, 'M/d/yyyy')
			}
		};
        this.datepicker.close();
        this.close.emit(true);
        this.valueChange.emit(value);
	}
    
    positionChange(event) {
        if(event.connectionPair.originY === 'top') this.position = 'positionTop';
        if(event.connectionPair.originY === 'bottom') this.position = 'positionBottom';
    }

    attach() {
        this.onClick().subscribe(event => {
            if((event.target as HTMLElement).getAttribute('aria-label') == this.datePipe.transform(new Date(this.value), 'MMMM d, y') || (event.target as HTMLElement).parentElement.getAttribute('aria-label') == this.datePipe.transform(new Date(this.value), 'MMMM d, y')) {
                this.isOpen = false;
            } 
            event.stopPropagation();
        });
    }

    clear() {
        this.selectedChange(null);
        this.formatted = '';
    }

    inputChange(event) {
        const value = event.target.value;
        if(new Date(value).toString() !== 'Invalid Date' && new Date(value).toString() !== '') {
            this.dateChange({ value: new Date(value) });
        } else {
            this.dateChange({ value: null });
        }
    }

    refreshInput() {
        this.formatted = this.value ? this.datePipe.transform(this.value, 'M/d/yyyy') : '';
    }

    filter = (d: Date): boolean => {
        if (this.dateFilter) {
            return this.dateFilter(d);
        } else return true;
    }
}