import { Component, OnInit } from '@angular/core';
import { PlatformService, UserService } from '@services';
@Component({
  selector: 'mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.scss']
})
export class MailComponent implements OnInit {
	public mail;
	public close;
	public edit;
	constructor(public us: UserService, public ps: PlatformService) { }

	ngOnInit(): void {}
	create() {
		this.ps.platform.data.mails[this.edit] = this.mail;
		this.ps.save();
	}
}
