<form (ngSubmit)="create(); close();">
	<div class="modal-box">
		<div class="modal-title">Edit template</div>
		<button mat-icon-button type="button" class="modal-close" (click)="close()">
			<span class="modal-close__icon"></span>
		</button>
		<div class="row">
			<div class="waw-input col-1">
				<label>
					<span class="waw-input__title">Subject</span>
					<input type="text" placeholder="Subject" name="subject" [(ngModel)]="mail.subject">
				</label>
			</div>
			<div class="waw-input col-1">
				<label>
					<span class="waw-input__title">Title</span>
					<input type="text" placeholder="Title" name="title" [(ngModel)]="mail.title">
				</label>
			</div>
		</div>
		<div class="row">
			<div class="waw-input col-1">
				<label>
					<span class="waw-input__title">From</span>
					<input type="text" placeholder="From" name="from" [(ngModel)]="mail.from">
				</label>
			</div>
		</div>
		<div class="row">
			<div class="waw-textarea col-1">
				<label>
					<span class="waw-textarea__title">Body</span>
					<textarea type="text" placeholder="Body" name="body" [(ngModel)]="mail.body"></textarea>
				</label>
			</div>
		</div>
		<div class="modal-btn">
			<div></div>
			<button mat-flat-button color="primary" type="submit" class="waw-btn _primary">Save</button>
		</div>
	</div>
</form>
