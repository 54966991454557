import { Component } from '@angular/core';
@Component({
	selector: 'delete-alert',
	templateUrl: './delete-alert.component.html',
	styleUrls: ['./delete-alert.component.scss']
})
export class DeleteAlertComponent{
	public close:any;
	public title:string;
	public body:string;
	public second_btn: string = 'Cancel';
	public primary_btn: string = 'Delete';
	public on_add;
	public checkbox;
	public repeat;
	constructor() {}
}
