import { Component, ViewChild } from '@angular/core';
import { LoaderService, QuestionService, UserService } from '@services';
import { AlertService, ModalService } from 'wacom';
@Component({
	selector: 'question',
	templateUrl: './question.component.html',
	styleUrls: ['./question.component.scss']
})
export class QuestionComponent{
	public profile: any = { kind: 'text', options: [] };
	public close;
	public kinds = [
		{ value: 'select', name: 'Choose one option' },
		{ value: 'multi', name: 'Choose a couple of options' },
		{ value: 'text', name: 'Give full and constructive answer' }
	];
	public submitted: Boolean;
	constructor(public us: UserService,
		public qs: QuestionService,
		public alert: AlertService,
		public modal: ModalService,
		private loader: LoaderService) { }
	save() {
		this.submitted = true;
		if (!this.profile.title || !this.profile.kind) return this.alert.error({
			text: "Please fill required fields",
		});
		if (this.profile.kind == 'select' || this.profile.kind == 'multi') {
			if (!this.profile.options.length) {
				return this.alert.error({
					text: "Please fill options",
				});
			}
			for (let i = 0; i < this.profile.options.length; i++) {
				if (!this.profile.options[i].answer) return this.alert.error({
					text: "Please fill required fields",
				});

			}
		}

		this.loader.show({ 
			modal: true,
			transparent: true,
			preventClick: true
		}, document.querySelector('.modal-box'));

		this.qs.create(this.profile, () => {
			this.loader.remove();
			this.close();
		});
	}
	delete() {
		this.modal.show({
			component: 'deleteAlert',
			title: 'Delete question',
			body: `Are you sure you want to delete question ${this.profile.name}`,
			on_add: ()=> {
				this.loader.show({ 
					modal: true,
					transparent: true,
					preventClick: true
				}, document.querySelector('.modal-box'));

				this.qs.delete(this.profile, () => {
					this.loader.remove();
					this.close();
				});
			}
		});
	}
	removeLineBreaks(text: string): string {
		return text.replace(/\n/g, '');
	}
}
