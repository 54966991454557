<div class="wrapper">
	<!-- when small devices, toogle hidden -->
	<button mat-flat-button color="primary" class="nav__toggle" [class._hidden]="us.sidebarToggle" [class._setup]="finishRegistration" (click)="toggle();">
		<svg class="nav__toggle__icon" width="15" height="25" viewBox="0 0 15 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M13.5 23L8.25 17.75L3 12.5L13.5 2" stroke="white" stroke-width="3" stroke-linecap="round"/>
		</svg>
	</button>
	<!-- main nav -->
	<nav class="nav dark-scrollbar" [class.navHidden]="us.sidebarToggle" [class.sidebarOn]="us.sidebarOn" [class._setup]="finishRegistration">

		<a class="nav-logo" routerLink="/"><img src="assets/img/sidebar/logo.svg" alt="sidebarLogo" draggable="false"/></a>

		<!-- nav profile user for small devices from topbar profile -->
		<div class="nav-profile">
			<div class="nav-profile__img" routerLink="/profile" (click)="sidebarOff()">
				<picture [user]="us"></picture>
			</div>
			<div class="nav-profile__info" routerLink="/profile" (click)="sidebarOff()">
				<div class="nav-profile__info-name text-elipsis ">{{us.firstName}}</div>
				<div class="nav-profile__info-role text-elipsis ">{{us.is.owner&&'Owner'||us.is.admin&&'Admin'||us.is.staff&&us.role&&rs._roles[us.role]?.name||us.is.staff&&!us.role&&'Staff'||!us.is.staff&&'Client'}}</div>
			</div>
			<button mat-icon-button [color]="ts.current.length ? 'warn' : ( us.mobile ? 'primary' : '' )" type="button" class="nav-profile__btn" [class._warn]="ts.current.length" (click)="ts.toggle();">
				<span class="nav-profile__btn__icon material-icons-round notranslate">{{ ts.current.length ? 'pause' : 'play_arrow' }}</span>
			</button>
		</div>
		<!-- main nav link -->
		<div class="nav-link" [class.tooltip-disabled]="!us.sidebarToggle">
			<a class="nav-link__item mobile" (click)="sidebarOff()" routerLink="/profile" routerLinkActive="_active" title="My profile">
				<div class="nav-icon"><span class="icon-profile"></span></div>
				<div class="nav-text">My Profile</div>
			</a>
			<a class="nav-link__item mobile" (click)="ntfOpen()" title="Notifications" [class._new-ntf]="ns.unread_count">
				<div class="nav-icon"><span class="material-icons-round notranslate">notifications</span></div>
				<div class="nav-text">Notifications</div>
			</a>
			<a class="nav-link__item tooltip" (click)="sidebarOff()" routerLink="/dashboard" routerLinkActive="_active" *ngIf="us.is.owner || us.is.admin" title="Dashboard">
				<div class="nav-icon"><span class="icon-analytics"></span></div>
				<div class="nav-text">Dashboard</div>
				<span class="tooltip__nav">Dashboard</span>
			</a>
			<a class="nav-link__item tooltip" (click)="sidebarOff()" routerLink="/schedule" routerLinkActive="_active" *ngIf="us.is.owner || us.is.admin || rs._roles[us.role]?.config['acccess_schedule'] || rs._sections['acccess_schedule'].client" title="My Schedule">
				<div class="nav-icon"><span class="icon-schedule"></span></div>
				<div class="nav-text">My Schedule</div>
				<span class="tooltip__nav">My Schedule</span>
			</a>
			<a class="nav-link__item tooltip" (click)="sidebarOff()" routerLink="/calendar" routerLinkActive="_active" *ngIf="us.is.owner || us.is.admin || rs._roles[us.role]?.config['acccess_calendar'] || rs._sections['acccess_calendar'].client" title="Calendar">
				<div class="nav-icon"><span class="icon-calendar"></span></div>
				<div class="nav-text">Calendar</div>
				<span class="tooltip__nav">Calendar</span>
			</a>
			<a class="nav-link__item tooltip" (click)="sidebarOff()" routerLink="/clients" routerLinkActive="_active" *ngIf="us.is.owner || us.is.admin || rs._roles[us.role]?.config['acccess_clients'] || rs._sections['acccess_clients'].client" title="Clients">
				<div class="nav-icon"><span class="icon-clients"></span></div>
				<div class="nav-text">Clients</div>
				<span class="tooltip__nav">Clients</span>
			</a>
			<a class="nav-link__item tooltip" (click)="sidebarOff()" routerLink="/staff" routerLinkActive="_active" *ngIf="us.is.owner || us.is.admin || rs._roles[us.role]?.config['acccess_staff'] || rs._sections['acccess_staff'].client" title="Staff">
				<div class="nav-icon"><span class="icon-staff"></span></div>
				<div class="nav-text">Staff</div>
				<span class="tooltip__nav">Staff</span>
			</a>
			<a class="nav-link__item tooltip" (click)="sidebarOff()" routerLink="/services" routerLinkActive="_active" *ngIf="us.is.owner || us.is.admin || rs._roles[us.role]?.config['acccess_services'] || rs._sections['acccess_services'].client" title="Services">
				<div class="nav-icon"><span class="icon-services"></span></div>
				<div class="nav-text">Services</div>
				<span class="tooltip__nav">Services</span>
			</a>
			<a class="nav-link__item tooltip" (click)="sidebarOff()" routerLink="/resources" routerLinkActive="_active" *ngIf="us.is.owner || us.is.admin || rs._roles[us.role]?.config['acccess_resources'] || rs._sections['acccess_resources'].client" title="Resources">
				<div class="nav-icon"><span class="icon-resources"></span></div>
				<div class="nav-text">Resources</div>
				<span class="tooltip__nav">Resources</span>
			</a>
			<!-- <a class="nav-link__item tooltip">
				<div class="nav-icon"><span class="icon-directory"></span></div>
				<div class="nav-text">Directory</div>
			</a> -->
			<a class="nav-link__item tooltip" (click)="sidebarOff()" routerLink="/setup" routerLinkActive="_active" *ngIf="us.is.owner || us.is.admin || rs._roles[us.role]?.config['acccess_setup'] || rs._sections['acccess_setup'].client" title="Setup">
				<div class="nav-icon"><span class="icon-setup"></span></div>
				<div class="nav-text">Setup</div>
				<span class="tooltip__nav">Setup</span>
			</a>
			<!-- <a class="nav-link__item tooltip">
				<div class="nav-icon"><span class="icon-sales"></span></div>
				<div class="nav-text">Sales</div>
			</a> -->
			<a class="nav-link__item tooltip" (click)="sidebarOff()" routerLink="/messanger" routerLinkActive="_active" *ngIf="us.is.owner || us.is.admin || rs._roles[us.role]?.config['acccess_customer_messages'] || rs._sections['acccess_customer_messages'].client" title="Messages">
				<div class="nav-icon"><span class="material-icons-round notranslate">message</span></div>
				<!-- <div class="nav-icon"><span class="icon-messages"></span></div> -->
				<div class="nav-text">Customer Messages</div>
				<span class="tooltip__nav">Messages</span>
			</a>
		</div>

		<!-- finalize registration -->
		<div class="nav-registration" *ngIf="finishRegistration">
			<div class="nav-registration__title">{{!us.sidebarToggle || us.mobile ? 'Let’s continue your setup!' : 'Setup'}}</div>
			<div class="nav-registration__progress">
				<div class="nav-registration__progress-text">
					<span>Your Progress</span>
					<span>{{registrationStep}}/{{steps.length}}</span>
				</div>
				<div class="nav-registration__progress-steps">
					<div *ngFor="let step of steps" class="nav-registration__progress-steps__item" [class._completed]="step < registrationStep"></div>
				</div>
			</div>
			<button mat-button color="primary" type="button" class="nav-registration__btn waw-btn _second" (click)="sidebarOff(); finishRegistrationModal()">Continue</button>
		</div>

		<a class="nav-link__item mobile" (click)="us.logout();" title="Log out">
			<div class="nav-icon"><span class="material-icons-round notranslate">logout</span></div>
			<div class="nav-text">Log out</div>
		</a>
	</nav>
	<div class="main" [class._active]="!us.sidebarToggle" [class._setup]="finishRegistration">
		<div class="topbar" #topbar [class.navShown]="!us.sidebarToggle" [class._setup]="finishRegistration">
			<div class="topbar-left">
				<div class="topbar-left__back" *ngIf="us.headerSubtitle" [@inOutBackBtn] [@.disabled]="us.mobile">
					<button mat-icon-button [color]="us.mobile ? 'accent' : 'primary'" class="topbar-left__back-btn" (click)="backClicked()">
						<span *ngIf="!us.mobile" class="material-icons notranslate">arrow_back</span>
						<span *ngIf="us.mobile" class="icon-arrow_left"></span>
					</button>
				</div>
				<button mat-icon-button *ngIf="!us.headerSubtitle" color="accent" class="topbar-left__menu" [class._close]="this.us.sidebarOn" [class._timer]="ts.current.length" [class._ntf]="ns.unread_count" (click)="sidebarOn()">
					<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M4 8.00134 L28 8.00134" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
							<animate #openAnimate attributeName="d" values="M4 8.00134 L28 8.00134; M8 8.00134 L24 24.0013" dur="0.2s" fill="freeze" begin="indefinite" />
							<animate #closeAnimate attributeName="d" values="M8 8.00134 L24 24.0013; M4 8.00134 L28 8.00134" dur="0.2s" fill="freeze" begin="indefinite" />
						</path>
						<path d="M4 16.0013 L28 16.0013" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
							<animate #openAnimate attributeName="stroke-width" from="2" to="0" dur="0.1s" fill="freeze" begin="indefinite" />
							<animate #closeAnimate attributeName="stroke-width" from="0" to="2" dur="0.2s" fill="freeze" begin="indefinite" />
						</path>
						<path d="M4 24.0013 L28 24.0013" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
							<animate #openAnimate attributeName="d" values="M4 24.0013 L28 24.0013; M24 8.00134L8 24.0013" dur="0.2s" fill="freeze" begin="indefinite" />
							<animate #closeAnimate attributeName="d" values="M24 8.00134L8 24.0013; M4 24.0013 L28 24.0013" dur="0.2s" fill="freeze" begin="indefinite" />
						</path>
					</svg>
				</button>
				<div class="topbar-left__page">
					<span class="topbar-left__page__subtitle t-hide" *ngIf="us.headerSubtitle">{{us.headerSubtitle}}</span>
					<span class="topbar-left__page__title t-hide">{{us.headerTitle}}</span>
				</div>

				<ng-container *ngIf="( us.is.admin || us.is.owner || us.role ) && !service">
					<button mat-stroked-button color="primary" type="button" id="newUser" class="topbar-user-btn waw-btn _second hidden-sm mr30" (click)="us.userPopup = !us.userPopup;" cdkOverlayOrigin #newUser="cdkOverlayOrigin">
						<span class="icon-plus"></span>
						<span>{{ !staff ? 'New client' : 'New staff' }}</span>
					</button>
					<ng-template
						cdkConnectedOverlay
						[cdkConnectedOverlayOrigin]="newUser"
						[cdkConnectedOverlayOpen]="us.userPopup && ( us.is.owner || us.is.admin || us.role )"
						[cdkConnectedOverlayHasBackdrop]="us.mobile ? true : false"
						[cdkConnectedOverlayBackdropClass]="'picker-overlay-backdrop'"
						[cdkConnectedOverlayPanelClass]="'panelClass'"
						[cdkConnectedOverlayFlexibleDimensions]="false"
						(backdropClick)="us.userPopup=false;"
						(positionChange)="positionChange($event)"
						(attach)="new_user_submitted = false;"
						(detach)="us.userPopup=false; position = 'positionBottom'"
					>
						<div class="user-popup popup" [@modal]="us.mobile ? (us.userPopup ? 'zoom' : us.userPopup) : (us.userPopup ? position : us.userPopup)" (clickOutside)="closePopup($event, 'newUser')">
							<div class="waw-input col-1">
								<label [class.req]="new_user_submitted && !new_user.firstName">
									<span class="waw-input__title">Name *</span>
									<input type="text" placeholder="Name" name="firstName" [(ngModel)]="new_user.firstName">
									<div *ngIf="new_user_submitted && !new_user.firstName" class="req-text">First name is required</div>
								</label>
							</div>
							<div class="waw-input col-1">
								<label [class.req]="new_user_submitted && !new_user.lastName">
									<span class="waw-input__title">Last name *</span>
									<input type="text" placeholder="Last name" name="lastName" [(ngModel)]="new_user.lastName">
									<div *ngIf="new_user_submitted && !new_user.lastName" class="req-text">Last name is required</div>
								</label>
							</div>
							<div class="waw-input col-1">
								<label [class.req]="new_user_submitted && !new_user.email">
									<span class="waw-input__title">Email *</span>
									<input type="text" placeholder="Email" inputmode="email" name="email" [(ngModel)]="new_user.email">
									<div *ngIf="new_user_submitted && !new_user.email" class="req-text">Email is required</div>
								</label>
							</div>
							<div class="waw-input col-1">
								<label>
									<span class="waw-input__title">Phone</span>
									<input type="text" placeholder="Phone" inputmode="tel" name="phone" [(ngModel)]="new_user.data.phone">
								</label>
							</div>
							<div class="col-1" style="padding-bottom: 15px;">
								<button mat-flat-button color="primary" class="waw-btn _primary" (click)="addUser()" type="button" style="width: 100%;">{{ !staff ? 'Add client' : 'Add staff' }}</button>
							</div>
						</div>
					</ng-template>
				</ng-container>
				<ng-container *ngIf="( us.is.admin || us.is.owner ) && service">
					<button mat-stroked-button color="primary" type="button" class="topbar-user-btn waw-btn _second hidden-sm mr30" (click)="newGroup()">
						<span class="icon-plus"></span>
						<span>New group</span>
					</button>
				</ng-container>
			</div>
			<div class="topbar-right">
				<div class="topbar-date_and_time hidden-md" [class._hide]="hideTime" [attr.data-date]="date | date: 'mediumDate'">
					<span class="topbar-date" [attr.data-date]="date | date: 'mediumDate'">{{date | date: 'fullDate'}}</span>
					<span class="topbar-time">{{date | date: 'shortTime'}}</span>
				</div>
				<button mat-icon-button [color]="ns.unread_count ? 'primary' : ( us.mobile ? 'accent' : '' )" id="ntf" (click)="ntfOpen()" type="button" class="topbar-btn hidden-md" [class._primary]="ns.unread_count" cdkOverlayOrigin #ntf="cdkOverlayOrigin" tooltip="Notifications" aria-label="Notifications">
					<span class="topbar-btn__icon material-icons-round notranslate">{{ ns.unread_count ? 'notifications_active' : 'notifications' }}</span>
				</button>
				<ng-template
					cdkConnectedOverlay
					[cdkConnectedOverlayOrigin]="ntf"
					[cdkConnectedOverlayOpen]="ntfPopup"
					[cdkConnectedOverlayHasBackdrop]="us.mobile ? true : false"
					[cdkConnectedOverlayBackdropClass]="'picker-overlay-backdrop'"
					[cdkConnectedOverlayPanelClass]="'panelClass'"
					[cdkConnectedOverlayFlexibleDimensions]="false"
					[cdkConnectedOverlayPositions]="connectedPosition"
					(backdropClick)="ntfPopup=false;"
					(positionChange)="positionChange($event)"
					(detach)="ntfClose(); position = 'positionBottom'"
				>
					<div class="ntf-popup popup" [@modal]="us.mobile ? (ntfPopup ? 'zoom' : ntfPopup) : (ntfPopup ? position : ntfPopup)" (clickOutside)="closePopup($event, 'ntf')">
						<div class="ntf-popup__head">
							<div class="ntf-popup__head__title">Notifications</div>
							<button mat-icon-button type="button" class="ntf-popup__head__stgs topbar-btn" (click)="openNtfStgs()">
								<span class="ntf-popup__head__stgs-icon icon-setup"></span>
							</button>
						</div>
						<div class="ntf-popup__body" (scroll)="isScrolledNtfsToBottom($event); checkNtfsInView()" [class.empty-list]="!ns.notifications?.length" #ntfsContainer>
							<!-- when list is empty  -->
							<ng-container *ngIf="!ns.notifications?.length">
								<span class="empty-list__icon material-icons-round notranslate">notifications_none</span>
								<div class="empty-list__text">You don't have notifications</div>
							</ng-container>
							<!-- ntf item -->
							<ng-container *ngFor="let notif of ns.notifications; index as i">
								<div class="ntf-popup__item" #ntfItem [attr.data-id]="notif._id" [attr.data-index]="i" [class._read]="notif.read">
									<notification [notif]="notif"></notification>
								</div>
							</ng-container>
						</div>
					</div>
				</ng-template>
				<button mat-icon-button [color]="ts.current.length ? 'warn' : ( us.mobile ? 'accent' : '' )" type="button" class="topbar-btn hidden-md" [class._warn]="ts.current.length" (click)="ts.toggle();" [tooltip]="ts.current.length ? 'Stop tracking' : 'Start tracking'" [attr.aria-label]="ts.current.length ? 'Stop tracking' : 'Start tracking'">
					<span class="topbar-btn__icon material-icons-round notranslate">{{ ts.current.length ? 'pause' : 'play_arrow' }}</span>
				</button>
				<div class="topbar-profile" id="profile" [matMenuTriggerFor]="account" #accountTrigger="matMenuTrigger" (clickOutside)="accountTrigger.closeMenu()">
					<div class="topbar-profile__img">
						<picture [user]="us"></picture>
					</div>
					<div class="topbar-profile__info">
						<div class="topbar-profile__info-name text-elipsis ">{{us.firstName}}</div>
						<div class="topbar-profile__info-role text-elipsis ">{{us.is.owner&&'Owner'||us.is.admin&&'Admin'||us.is.staff&&us.role&&rs._roles[us.role]?.name||us.is.staff&&!us.role&&'Staff'||!us.is.staff&&'Client'}}</div>
					</div>
					<button mat-icon-button color="primary" class="topbar-profile__btn" style="transition: 0.15s all ease-in-out;" [ngStyle]="{'transform': accountTrigger.menuOpen ? 'rotate(-180deg)' : 'rotate(0deg)' }" aria-label="Account menu">
						<span class="topbar-profile__btn__icon icon-arrow_down"></span>
					</button>
				</div>
				<mat-menu #account="matMenu" xPosition="before" yPosition="below" hasBackdrop="false">
					<a class="matMenu__item" routerLink="/profile" mat-menu-item disableRipple matRipple matRippleColor="#0000000f" aria-label="My Profile">
						<span class="matMenu__item-icon icon-profile"></span>
						<span>My Profile</span>
					</a>
					<a class="matMenu__item" routerLink="/schedule" mat-menu-item disableRipple matRipple matRippleColor="#0000000f" aria-label="My Schedule">
						<span class="matMenu__item-icon icon-schedule"></span>
						<span>My Schedule</span>
					</a>
					<button class="matMenu__item" mat-menu-item disableRipple matRipple matRippleColor="#0000000f" (click)="us.logout();" aria-label="Logout">
						<span class="matMenu__item-icon material-icons-round notranslate">logout</span>
						<span>Log out</span>
					</button>
				</mat-menu>
				<a class="topbar-logo" routerLink="/"><img src="assets/img/sidebar/logo.svg" alt="topbarLogo" (click)="sidebarOff()" draggable="false"/></a>
			</div>
		</div>
		<!-- nav mobile fade for nav burger for small devices -->
		<div class="nav__fade" [class._active]="us.sidebarOn" (click)="sidebarOff()"></div>
		<router-outlet></router-outlet>
	</div>
</div>
