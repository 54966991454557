<div class="containerBox">
	<div class="mails-title">
		<span class="mails-title__text">Time when the appointment mail will be sent</span>
		<hr>
		<span class="mails-title__value">24h</span>
	</div>
	<div class="table-container tablet-col2">
		<table class="table" cellpadding="0" cellspacing="0">
			<thead>
				<tr>
					<th scope="col">Subject</th>
					<th scope="col">Email</th>
                    <th scope="col">Actions</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let mail of ps.platform.data.mails|each:2; index as i">
					<td data-label="Subject">{{mail.value.subject}}</td>
					<td data-label="Email" class="text-elipsis">{{mail.value.from}}</td>
					<td data-label="Action">
						<button mat-icon-button color="primary" (click)="edit(mail)" class="waw-btn _second _edit"></button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
