import { Pipe, PipeTransform } from '@angular/core';
import { ServiceService } from '@services';
@Pipe({
  name: 'priceByStatus'
})
export class PriceByStatusPipe implements PipeTransform {
	constructor(public ss: ServiceService) {}
	transform(arr:any, status:any, refresh?, count?) {
		let value = 0;
		if(arr) {
			for (let i = arr.length-1; i >= 0; i--){
			    if(status == arr[i].status) {
			    	if(count) {
			    		value += 1;
			    	} else {
			    		value += this.ss._services[arr[i]?.service]?.price;
			    	}
			    }
			}
		}
    	return value;
	}
}