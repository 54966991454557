<div class="modal-box">
	<form (ngSubmit)="save()">
		<div class="modal-title">Working Hours</div>
		<button mat-icon-button type="button" class="modal-close" (click)="close()">
			<span class="modal-close__icon"></span>
		</button>
		<div class="row">
			<div class="waw-select col-1">
				<span class="waw-select__title">Select staff*</span>
				<app-select 
					placeholder="Staff" 
					[(value)]="new_time.staff"
					[items]="us.staff" 
					[clearable]="false" 
					[searchable]="true"
					[disabled]="edit"
					[required]="submitted&&!new_time.staff"
					bindLabel="name" 
					class="_zero_padding"
				>
					<ng-template label let-item>
						<div class="select-option-user">
							<span class="select-option-user__img">
								<picture [user]="item"></picture>
							</span>
							<span class="select-option-user__name">{{item.name}}</span>
						</div>
					</ng-template>
					<ng-template option let-item>
						<div class="select-option-user" style="margin: 0px;">
							<span class="select-option-user__img">
								<picture [user]="item"></picture>
							</span>
							<span class="select-option-user__name">{{item.name}}</span>
						</div>
					</ng-template>
				</app-select>
				<div *ngIf="submitted&&!new_time.staff" class="req-text">Staff is required</div>
			</div>
			<div class="waw-datepicker col-1 _arrow" [class.req]="submitted&&!new_time.date">
				<span class="waw-datepicker__title">Day*</span>
				<datepicker [date]="new_time.date" (valueChange)="new_time.date=$event" [required]="submitted&&!new_time.date" [disabled]="edit" [darkIcon]="true"></datepicker>
				<div *ngIf="submitted&&!new_time.date" class="req-text">Day is required</div>
			</div>
			<ng-container *ngFor="let hour of new_time.hours; index as i; first as first; last as last">
				<div class="hour" [class._first]="first" [class._last]="last">
					<div class="hour__time">
						<timepicker 
							[label]="first ? 'Start*' : ''" 
							[value]="hour.from" 
							(valueChange)="hour.from = $event" 
							[requiredText]="submitted&&!hour.from&&'Start is required' || i>0&&convertTimeToMinutes(hour.from)<=convertTimeToMinutes(new_time.hours[i-1].to)&&'Time is overlapping'"
							[required]="submitted&&!hour.from || i>0&&convertTimeToMinutes(hour.from)<=convertTimeToMinutes(new_time.hours[i-1].to)" 
						></timepicker>
						<timepicker 
							[label]="first ? 'End*' : ''" 
							requiredText="End time is required"
							[value]="hour.to" 
							(valueChange)="hour.to = $event" 
							[requiredText]="submitted&&!hour.to&&'End is required' || !!new_time.hours?.[i+1]&&convertTimeToMinutes(hour.to)>=convertTimeToMinutes(new_time.hours[i+1].from)&&'Time is overlapping' || convertTimeToMinutes(hour.to)<=convertTimeToMinutes(hour.from)&&'The end time must be after the start time'"
							[required]="submitted&&!hour.to || !!new_time.hours[i+1]&&convertTimeToMinutes(hour.to)>=convertTimeToMinutes(new_time.hours[i+1].from) || convertTimeToMinutes(hour.to)<=convertTimeToMinutes(hour.from)" 
						></timepicker>
					</div>
					<div class="hour__actions">
						<button mat-icon-button color="warn" class="waw-btn__icon warn" type="button" [disabled]="new_time.hours?.length == 1" (click)="new_time.hours?.length > 1 ? new_time.hours.splice(i, 1) : ''"><span class="material-icons-round notranslate">remove</span></button>
						<button *ngIf="last && new_time.hours?.length < 10" mat-icon-button color="primary" class="waw-btn__icon primary" type="button" (click)="addHour()"><span class="material-icons-round notranslate">add</span></button>
					</div>
				</div>
			</ng-container>
			<div class="col-1 modal-switch" *ngIf="add">
				<label class="waw-switch">
					<input type="checkbox" name="Repeats" [(ngModel)]="repeat.enable">
					<span class="track">
						<span class="handle"></span>
					</span>
					<div class="waw-switch-text">Repeats</div>
				</label>
			</div>
		</div>
		<ng-container *ngIf="add && repeat.enable">
			<div class="modal-section mt0">
				<div class="row">
					<div class="waw-select col-2 pb0" style="margin-bottom: -10px;">
						<span class="waw-select__title">Repeat*</span>
						<app-select 
							placeholder="Repeat" 
							[(value)]="repeat.kind"
							[items]="kinds" 
							[clearable]="false" 
							[searchable]="false"
							[required]="submitted&&!repeat.kind"
							bindLabel="name" 
						></app-select>
						<div *ngIf="submitted&&!repeat.kind" class="req-text">Type is required</div>
					</div>
					<div class="waw-input col-2 pb0">
						<label [class.req]="submitted&&!repeat.count">
							<span class="waw-input__title">Count*</span>
							<input class="mb0" type="number" placeholder="Count" name="counter" [(ngModel)]="repeat.count">
							<div *ngIf="submitted&&!repeat.count" class="req-text">Count is required</div>
						</label>
					</div>
				</div>
			</div>
		</ng-container>
		<div class="modal-btn">
			<button mat-button color="primary" type="button" class="waw-btn _second" *ngIf="!edit" (click)="close()">Cancel</button>
			<button mat-button color="primary" type="button" class="waw-btn _second _delete" *ngIf="edit" (click)="remove()">Delete</button>
			<button mat-flat-button color="primary" type="submit" class="waw-btn _primary">{{!edit&&'Create'||'Save'}}</button>
		</div>
	</form>
</div>
