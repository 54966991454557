import { Component, Output, EventEmitter, ElementRef, ViewChild, HostListener, OnInit, OnDestroy } from '@angular/core';
import { HashService, LoaderService, UserService } from '@services';
import { AlertService, HttpService, ModalService } from 'wacom';
import { flyAnimation } from 'src/app/common/animation/animation.component';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from "file-saver"
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { HttpClient } from '@angular/common/http'; 

/** Folder data with nested structure. */
interface FolderNode {
	name: string;
	_id: string;
	children?: FolderNode[];
}

/** Flat node with expandable and level information */
interface FlatNode {
	expandable: boolean;
	name: string;
	_id: string;
	level: number;
}

@Component({
	selector: 'files',
	templateUrl: './files.component.html',
	styleUrls: ['./files.component.scss'],
	animations: [flyAnimation]
})
export class FilesComponent implements OnInit, OnDestroy{
	@ViewChild('moveRef', { static: true }) moveRef: ElementRef;
	@Output('linkEvent') linkEvent = new EventEmitter();
	@ViewChild('Image', { static: false }) image: ElementRef;
	public filePreview: any = null;
	public selectedFolder: any = {
		name: 'Root', 
		_id: null
	};
	public path: any = [{ name: 'Root', _id: null }];
	public drag: boolean = false;

	public zoom: number = 1;

	private _transformer = (node: FolderNode, level: number) => {
		return {
			expandable: !!node.children && node.children.length > 0,
			name: node.name,
			_id: node._id,
			level: level,
		};
	};
	
	treeControl = new FlatTreeControl<FlatNode>(
		node => node.level,
		node => node.expandable,
	);
	
	treeFlattener = new MatTreeFlattener(
		this._transformer,
		node => node.level,
		node => node.expandable,
		node => node.children,
	);

	dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

	hasChild = (_: number, node: FlatNode) => node.expandable;

	constructor(public us: UserService,
		public alert: AlertService,
		public http: HttpService,
		public modal: ModalService,
		private router: Router,
		private loader: LoaderService,
		private route: ActivatedRoute,
		public hash: HashService,
		private httpClient: HttpClient) {
			if(this.hash.get('files') && this.hash.get('files') !== 'root') {
				this.selectedFolder = this.us.user._files[this.hash.get('files')]; 
				this.initializePath();
			}

			this.dataSource.data = [
				{
					name: 'Root',
					_id: null,
					children: []
				}
			];
		}
	ngOnInit(): void {
		this.us.backRoute = () => {
			if(this.selectedFolder._id == null) {
				this.hash.remove('files'); 
				this.linkEvent.emit(true);
			} else if(this.selectedFolder.parent) {
				this.selectedFolder = this.us.user._files[this.selectedFolder.parent]; 
				this.initializePath();
			} else {
				this.selectedFolder = { name: 'Root', _id: null }; 
				this.initializePath();
			}
		};
	}
	ngOnDestroy(): void {
		this.us.backRoute = () => {
			this.router.navigate(['/clients']);
		};
	}
	deleteFile(file) {
		this.router.navigate([],{ queryParams: { modal: 'open' }, fragment: this.route.snapshot.fragment });
		this.modal.show({
			class: 'foreground',
			component: 'deleteAlert',
			title: 'Delete file',
			body: `Are you sure you want to delete file "${file.name}"?`,
			on_add: ()=> {
				this.loader.show({
					whiteBackground: true,
					transparent: true,
				}, document.querySelector('.profile-files'));
				this.http.post('/api/user/delete-file', {
					user: this.us.user._id,
					url: file.url.split('/')[4],
					_id: file._id
				}, () => {
					this.us.user?.files.splice(this.us.user?.files.findIndex(f => f._id === file._id), 1); 
					this.loader.remove();
				});
			}
		});
	}
	addFile(event) {
		this.loader.show({
			whiteBackground: true,
			transparent: true,
		}, document.querySelector('.profile-files'));
		this.us.addFiles(event, this.selectedFolder._id, () => {
			this.loader.remove();
		}); 
	}
	downloadFile(file){
		saveAs(file.url, file.name);
	}
	fileModal(file = null) {
		const name = file.name.split('.');
		const extension = name.pop();
		file.name_without_extension = name.join('.');

		this.router.navigate([],{ queryParams: { modal: 'open' }, fragment: this.route.snapshot.fragment });
		this.modal.show({
			class: 'foreground',
			component: 'modal',
			profile: file ? JSON.parse(JSON.stringify(file)) : {},
			title: 'File',
			components: [
				{
					type: 'INPUT',
					input: 'name_without_extension',
					label: 'Name*',
					placeholder: 'Enter name',
					reqText: 'Name is required',
					required: true,
				}
			],
			possibleToDelete: false,
			onSave: (profile: any) => {
				if(profile._id) {
					profile.name = profile.name_without_extension + '.' + extension;
					this.http.post('/api/user/rename-file-folder', {
						user: this.us.user._id,
						_id: profile._id,
						name: profile.name
					}, () => {
						this.modal.destroy();
						const url = profile.url.split('/');
						url.pop();
						url.push(profile.name);
						profile.url = url.join('/');
						this.us.user.files.find(f => f._id === profile._id).name = profile.name; 
						this.us.user._files[profile._id].name = profile.name;
					});
				} 
			}
		});	
	}
	folderModal(folder = null) {
		this.router.navigate([],{ queryParams: { modal: 'open' }, fragment: this.route.snapshot.fragment });
		this.modal.show({
			class: 'foreground',
			component: 'modal',
			profile: folder ? JSON.parse(JSON.stringify(folder)) : {},
			title: 'Folder',
			components: [
				{
					type: 'INPUT',
					input: 'name',
					label: 'Name*',
					placeholder: 'Enter name',
					reqText: 'Name is required',
					required: true,
				}
			],
			possibleToDelete: false,
			onSave: (profile: any) => {
				if(!profile._id) {
					this.us.addFolder(this.selectedFolder._id, profile.name, () => {
						this.modal.destroy();
					});
				} else {
					this.http.post('/api/user/rename-file-folder', {
						user: this.us.user._id,
						_id: profile._id,
						name: profile.name
					}, () => {
						this.modal.destroy();
						this.us.user.files.find(f => f._id === profile._id).name = profile.name; 
						this.us.user._files[profile._id].name = profile.name;
					});
				}
			}
		});	
	}
	deleteFolder(folder) {
		this.router.navigate([],{ queryParams: { modal: 'open' }, fragment: this.route.snapshot.fragment });
		this.modal.show({
			class: 'foreground',
			component: 'deleteAlert',
			title: 'Delete folder',
			body: `Are you sure you want to delete folder "${folder.name}"?`,
			on_add: ()=> {
				this.loader.show({
					whiteBackground: true,
					transparent: true,
				}, document.querySelector('.profile-files'));
				this.http.post('/api/user/delete-folder', {
					user: this.us.user._id,
					_id: folder._id
				}, () => {
					this.us.user?.files.splice(this.us.user?.files.findIndex(f => f._id === folder._id), 1); 
					this.loader.remove();
				});
			}
		});
	}

	moveModal(file) {
		this.buildFolderTree();
		file.parent_new = file.parent;

		this.router.navigate([],{ queryParams: { modal: 'open' }, fragment: this.route.snapshot.fragment });
		this.modal.show({
			component: 'modal',
			profile: file,
			title: `Move "${file.name}"`,
			components: [
				{
					customRef: this.moveRef,
					input: 'parent',
					newValue: 'parent_new'
				}
			],
			possibleToDelete: false,
			saveButton: 'Move',
			preTitle: false,
			onSave: (profile: any) => {
				if (profile.parent_new === profile.parent) {
					this.loader.remove();
					return this.alert.error({
						text: 'Choose a new location'
					});
				}

				this.http.post('/api/user/move-file-folder', {
					user: this.us.user._id,
					_id: profile._id,
					parent: profile.parent_new
				}, () => {
					this.us.user.files.find(f => f._id === profile._id).parent = profile.parent_new; 
					this.us.user._files[profile._id].parent = profile.parent_new;
					this.modal.destroy();
					this.alert.show({
						text: `Moved to "${profile.parent_new ? this.us.user._files[profile.parent_new].name : 'Root'}" folder`
					});
				});

			}
		});	
	}

	buildFolderTree() {
		this.insertChildrenFolderTree(this.dataSource.data[0]);
		this.dataSource.data = this.dataSource.data;
		this.treeControl.expand(this.treeControl.dataNodes[0]);
	}

	insertChildrenFolderTree(parent: FolderNode) {
		parent.children = [];
		for (let folder of this.us.user.files.filter((f: any) => {
				return f.directory && f.parent === parent._id;
			})
		) {
			if(!parent.children.find(c => c._id === folder._id)) {
				parent.children.push({name: folder.name, _id: folder._id});
				this.insertChildrenFolderTree(parent.children.find(c => c._id === folder._id));
			}
		}
	}

	onDragenter(event: DragEvent) {
		if(!this.drag && event.dataTransfer!.types.includes('Files')) {
			event.dataTransfer!.dropEffect = 'copy';
			event.dataTransfer!.effectAllowed = 'copy';
			this.drag = true;
			this.alert.show({
				text: 'Drop files to upload',
				class: 'drop',
				type: 'info',
				timeout: 0,
				closable: false
			});
		}
	}
	onDragover(event: DragEvent) {
		this.drag = true;
		event.stopPropagation();
		event.preventDefault();
	}
	onDragleave(event: DragEvent) {
		this.drag = false;
		this.closeAlert();
	}
	onDrop(event: DragEvent, Input) {
		this.loader.show({
			whiteBackground: true,
			transparent: true,
		}, document.querySelector('.profile-files'));
		this.drag = false;
		event.preventDefault();
		Input.files = event.dataTransfer.files;
		Input.dispatchEvent(new Event('change'));
		this.closeAlert();
	}
	closeAlert() {
		const alerts = document.querySelectorAll('alert .drop');
		for(let i = 0; i < alerts?.length; i++) {
			alerts[i].parentElement.remove();
		}
	}

	get folders() {
		return this.us.user?.files.filter((f: any) => {
			return f.directory && f.parent === this.selectedFolder._id;
		});
	}

	get files() {
		return this.us.user?.files.filter((f: any) => {
			return !f.directory && f.parent === this.selectedFolder._id;
		});
	}

	initializePath() {
		if(this.selectedFolder._id) this.hash.set('files', this.selectedFolder._id);
		else this.hash.set('files', 'root');
		this.path = [this.selectedFolder];
		var folder = this.selectedFolder;
		while (folder.parent && folder.parent != null) {
			folder = this.us.user._files[folder.parent];
			this.path.push(folder);
		}
		if(this.selectedFolder._id) this.path.push({ name: 'Root', _id: null });
		this.path.reverse();
	}

	getFolder(id) {
		if(id === null) {
			return {
				name: 'Root', 
				_id: null
			}
		}

		return this.us.user._files[id];
	}

	expandFolder(id) {
		this.treeControl.expand(this.treeControl.dataNodes[0]);
		var folder = this.us.user._files[id];

		while (folder != null) {
			this.treeControl.expand(this.treeControl.dataNodes.find(e => e._id === folder._id));
			folder = folder.parent ? this.us.user._files[folder.parent] : null;
		}		
	}

	@HostListener('window:popstate', ['$event'])
    onPopState(event: PopStateEvent) {
		if(this.hash.get('files') && this.hash.get('files') !== 'root') {
			this.selectedFolder = this.us.user._files[this.hash.get('files')]; 
			this.initializePath();
		} else if(this.hash.get('files') === 'root') {
			this.selectedFolder = {
				name: 'Root', 
				_id: null
			}; 
			this.initializePath();
		}
    }
	
	correctionImageWidth() {
		if(this.image) {
			const image: HTMLImageElement = this.image.nativeElement;
			image.onload = () => {
				const container = image.parentElement;
				container.style.maxWidth = 'none';
				const ratioImg = image.getBoundingClientRect().width / image.getBoundingClientRect().height;
				container.style.maxWidth = ratioImg * container.getBoundingClientRect().height + 'px';
				container.style.visibility = 'visible';
			};
		}
	}

	zoomOut() {
		this.zoom = Number((this.zoom - 0.1).toFixed(1));
	}

	zoomIn() {
		this.zoom = Number((this.zoom + 0.1).toFixed(1));
	}

	closeFilePreview(event) {
		if(event.target.classList.contains('pdfViewer') || event.target.classList.contains('ng2-pdf-viewer-container')) this.filePreview = null;
	}

	getTextFileContent() {
		if(this.filePreview.extension == 'txt') {
			this.httpClient.get(this.filePreview.url, { responseType: 'text' }).subscribe(data => { 
				this.filePreview.content = data;
			});
		}
	}

	addPDFScrollListener() {
		if(document.querySelector('pdf-viewer .ng2-pdf-viewer-container')) {
			document.querySelector('pdf-viewer .ng2-pdf-viewer-container').addEventListener('scroll', this.addBackgroundForHeadOnScroll);
		}
	}

	addBackgroundForHeadOnScroll(event: Event) {
		if((event.target as HTMLElement).scrollTop > 0) {
			document.querySelector('.file-preview__head').classList.add('background');
		} else {
			document.querySelector('.file-preview__head').classList.remove('background');
		}
	}

	sizeCorrectionForFile() {
		if(this.filePreview.extension === 'pdf' || this.filePreview.extension === 'txt') {
			(document.querySelector('.file-preview-panel') as HTMLElement).style.width = '100%';
			(document.querySelector('.file-preview-panel') as HTMLElement).style.height = '100%';
		}
	}
}
