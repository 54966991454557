import { Injectable } from '@angular/core';
import { MongoService, AlertService } from 'wacom';
import { UserService } from './user.service';
@Injectable({
	providedIn: 'root'
})
export class MessageService {
	public messages: any = [];
	public _messages: any = {};
	public chat_id: any = {};
	public chat_id_view: any = {};
	constructor(private mongo: MongoService, private alert: AlertService,
		private us: UserService) { 
		this.messages = mongo.get('message', {
			replace: {
				chat_id: function(val, cb, doc){
					if(doc.author>doc.receiver) cb(doc.author+doc.receiver);
					else cb(doc.receiver+doc.author);
				}
			},
			groups: {
				chat_id: {
					field: function(doc){
						return doc.chat_id;
					}
				},
				chat_id_view: {
					field: function(doc){
						return doc.chat_id;
					},
					allow: function(doc){
						return !doc.viewed;
					}
				}
			}
		}, (arr, obj) => {
			this.chat_id = obj.chat_id;
			this.chat_id_view = obj.chat_id_view;
			this._messages = obj;
		});
	}
	create(message, cb=created=>{}) {
		if(message._id) return this.save(message);
		this.mongo.create('message', message, cb); 
	}
	view_all(chat_id){
		if(!Array.isArray(this.chat_id[chat_id])) return;
		for (let i = 0; i < this.chat_id[chat_id].length; i++){
		    this.view(this.chat_id[chat_id][i]);
		}
	}
	view(message) {
		if(message.author == this.us._id) return;
		message.viewed = true;
		this.mongo.update('message', message, {
			name: 'receiver'
		});
	}
	update(message) {
		this.mongo.afterWhile(message, ()=> {
			this.save(message);
		});
	}
	save(message){
		this.mongo.update('message', message, ()=>{
			this.alert.show({
				text: 'Message has been updated.'
			});
		});
	}
	delete(message) {
		this.mongo.delete('message', message, ()=>{
			this.alert.show({
				text: 'Message has been deleted.'
			});
		});
	}
}
