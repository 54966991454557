<div class="modal-box">
	<form (ngSubmit)="save()">
		<div class="modal-title">{{new_product._id&&'Edit'||'New'}} Product</div>
		<button mat-icon-button type="button" class="modal-close" (click)="close()">
			<span class="modal-close__icon"></span>
		</button>
		<div class="modal-rowImg">
			<div class="modal-rowImg__left">
				<div class="modal-img">
					<img *ngIf="new_product.thumb" [src]="new_product.thumb" alt="Product Avatar" draggable="false"/>
					<span *ngIf="!new_product.thumb" class="placeholder-icon icon-photo"></span>
					<label for="productAddThumbs">
						<button mat-icon-button color="primary" type="button" class="img-upload waw-btn__icon primary" (click)="input.click()"><span class="icon-pencil"></span></button>
					</label>
					<input type="file" id="productAddThumbs" #input (change)="fileChangeEvent($event);" [hidden]="true" accept="image/*" />
				</div>
				<div class="waw-input">
					<label>
						<span class="waw-input__title">Quantity</span>
						<input type="number" placeholder="Quantity" name="quantity" [(ngModel)]="new_product.quantity">
					</label>
				</div>
			</div>
			<div class="modal-rowImg__right">
				<div class="waw-input col-1">
					<label [class.req]="submitted&&!new_product.name">
						<span class="waw-input__title">Product name *</span>
						<input type="text" placeholder="Name" name="name" [(ngModel)]="new_product.name">
						<div *ngIf="submitted&&!new_product.name" class="req-text">Name is required</div>
					</label>
				</div>
				<div class="waw-input col-1">
					<label>
						<span class="waw-input__title">Product Barcode</span>
						<input type="text" placeholder="Barcode" name="barcode" [(ngModel)]="new_product.barcode">
					</label>
				</div>
				<div class="waw-input col-1">
					<label>
						<span class="waw-input__title">Measure</span>
						<input type="text" placeholder="Milliliters (ml)" name="barcode" [(ngModel)]="new_product.measure">
					</label>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="waw-textarea col-1">
				<div [class.req]="characterLimit">
					<note-editor [notes]="noteEditor" placeholder="Add description" label="Description" size="medium" (characterLimit)="characterLimit = $event" (onChange)="noteEditor = $event"></note-editor>
					<div *ngIf="characterLimit" class="req-text">{{characterLimit}}</div>
				</div>
			</div>
		</div>
		<div>
			<h2 class="modal-h2 mt10">Pricing and Brand</h2>
			<p class="modal-p mb30">Add the pricing of product</p>
		</div>
		<div class="row">
			<div class="waw-input col-2">
				<label>
					<span class="waw-input__title">Retail price</span>
					<currency-input [value]="new_product.price" (valueChange)="new_product.price = $event"></currency-input>
				</label>
			</div>
			<!-- <div class="waw-input col-2">
				<label>
					<span class="waw-input__title">Markup</span>
					<input type="number" placeholder="%" name="markup">
				</label>
			</div> -->
			<div class="waw-select col-2">
				<span class="waw-select__title">Discount</span>
				<app-select 
					placeholder="Select Discount" 
					[(value)]="new_product.discount"
					[items]="ds.discounts" 
					[clearable]="true" 
					[searchable]="false"
					bindLabel="name" 
					bindValue="_id" 
				></app-select>
			</div>
			<div class="waw-select col-1">
				<span class="waw-select__title">Tax</span>
				<app-select 
					placeholder="Select Tax" 
					[(value)]="new_product.tax"
					[items]="ts.taxes" 
					[clearable]="true" 
					[searchable]="false"
					[multiple]="true"
					bindLabel="name" 
					bindValue="_id" 
				>
				<ng-template select let-items let-open="open" let-required="required">
					<div class="modal-selectMultiple" [class._open]="open" [class._req]="required" #select>
						<ng-container *ngIf="items?.length">
							<div class="modal-selectMultiple__item _delete text-elipsis" *ngFor="let item of items; index as i">
								{{item.name}}
								<button mat-icon-button color="primary" type="button" class="modal-selectMultiple__item__delete" (click)="new_product.tax.splice(i, 1); $event.stopPropagation()"><span class="material-icons">close</span></button>
							</div>
						</ng-container>
						<ng-container *ngIf="!items?.length">
							<span class="modal-selectMultiple__placeholder">Select Tax</span>
						</ng-container>
						<button mat-icon-button color="primary" type="button" class="modal-selectMultiple__btn">
							<span class="modal-selectMultiple__btn-icon icon-arrow_down" style="display: block; transition: 0.15s all ease-in-out;" [ngStyle]="{'transform': open ? 'rotate(-180deg)' : 'rotate(0deg)' }"></span>
						</button>
					</div>
				</ng-template>
				</app-select>
			</div>
			<div class="waw-select col-2">
				<span class="waw-select__title">Brand</span>
				<app-select 
					placeholder="Select Brand" 
					[(value)]="new_product.brand"
					[items]="bs.brands" 
					[clearable]="true" 
					[searchable]="false"
					bindLabel="name" 
					bindValue="_id" 
				></app-select>
			</div>
			<div class="waw-select col-2">
				<span class="waw-select__title">Category</span>
				<app-select 
					placeholder="Select category" 
					[(value)]="new_product.category"
					[items]="cs.categories" 
					[clearable]="true" 
					[searchable]="false"
					bindLabel="name" 
					bindValue="_id" 
				></app-select>
			</div>
		</div>
	
		<!-- <div class="modal-switch">
					<div class="waw-switch-wrap">
						<label class="waw-switch">
							<input type="checkbox" name="notifications" [(ngModel)]="new_product.notifications">
							<span class="track">
								<span class="handle"></span>
							</span>
						</label>
						<div class="waw-switch-text">retails sales</div>
					</div>
				</div> -->
		<div class="modal-btn">
			<button mat-button color="primary" type="button" *ngIf="!new_product._id" class="waw-btn _second" (click)="close();">Cancel</button>
			<button mat-button color="primary" type="button" *ngIf="new_product._id" (click)="delete()" class="waw-btn _second _delete">Delete</button>
			<button mat-flat-button color="primary" type="submit" class="waw-btn _primary">{{new_product._id&&'Save'||'Create'}}</button>
		</div>
	</form>
</div>
