<div class="modal-box mb10">
    <div class="head">
        <div class="head__title modal-title">Finalize your registration</div>
        <div class="head__step">
            <span>Step: </span>
            <span>{{step}} of {{steps.length}}</span>
        </div>
    </div>
    <div class="steps">
        <div class="steps__line">
            <div class="steps__line-progress" [ngStyle]="{'width': ((step - 1) * 100 / (steps.length - 1)) + '%'}"></div>
        </div>
        <div *ngFor="let s of steps" class="steps__item" [class._current]="s <= step" [class._verified]="s < step">
            <div *ngIf="s < step" class="steps__item-verified fl-c-c">
                <span class="steps__item-verified__icon icon-check"></span>
            </div>
        </div>
    </div>
</div>
<div class="modal-box" id="modal">
    <ng-container *ngIf="step == 1">
        <div class="modal-title">Add your location</div>
        <locationprofile [component]="true" [submit]="submitted" [location]="location" (onChange)="location = $event"></locationprofile>
    </ng-container>

    <ng-container *ngIf="step == 2">
        <div class="modal-title">Add your bussiness hours</div>
        <app-business-hours [component]="true" [submit]="submitted" [location]="location" (onChange)="location = $event"></app-business-hours>
    </ng-container>
    
    <ng-container *ngIf="step == 3">
        <div class="modal-title">Add your room</div>
        <room [component]="true" [submit]="submitted" [room]="room" (onChange)="room = $event"></room>
    </ng-container>
    
    <ng-container *ngIf="step == 4">
        <div class="modal-title">Add staff information</div>
        <div>
            <div class="row">
                <div class="waw-input col-2">
                    <label [class.req]="submitted&&!staff.firstName">
                        <span class="waw-input__title">Name*</span>
                        <input type="text" placeholder="Name" name="firstName" [(ngModel)]="staff.firstName">
                        <div *ngIf="submitted&&!staff.firstName" class="req-text">First name is required</div>
                    </label>
                </div>
                <div class="waw-input col-2">
                    <label [class.req]="submitted&&!staff.lastName">
                        <span class="waw-input__title">Last Name*</span>
                        <input type="text" placeholder="Last Name" name="lastName" [(ngModel)]="staff.lastName">
                        <div *ngIf="submitted&&!staff.lastName" class="req-text">Last name is required</div>
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="waw-input col-2">
                    <label [class.req]="submitted&&!staff.email">
                        <span class="waw-input__title">E-mail*</span>
                        <div class="field-wrapper">
                            <input type="text" placeholder="E-mail" name="email" [(ngModel)]="staff.email">
                        </div>
                        <div *ngIf="submitted&&!staff.email" class="req-text">Email is required</div>
                    </label>
                </div>
                <div class="waw-input col-2">
                    <label>
                        <span class="waw-input__title">Phone</span>
                        <div class="field-wrapper">
                            <input type="text" placeholder="Phone" name="phone" [(ngModel)]="staff.data.phone">
                        </div>
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="waw-input col-1">
                    <label>
                        <span class="waw-input__title">address</span>
                        <div class="field-wrapper">
                            <input ngx-google-places-autocomplete autocomplete="off" [options]="{componentRestrictions: {country:ls.primary&&ls.primary[0]&&(ls.primary[0].country=='Canada'&&'ca'||ls.primary[0].country=='USA'&&'us'||'ca')||'ca'}}" placeholder="Street" [(ngModel)]="staff.data.address" name="address" (onAddressChange)="staff.data.address = us.getFormattedAddress($event, true)" [disabled]="!(us.is.admin||us.is.owner)">
                        </div>
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="waw-select col-2">
                    <span class="waw-select__title">Gender</span>
                    <app-select 
                        placeholder="Select gender" 
                        [(value)]="staff.data.gender"
                        [items]="['Prefer not to say', 'Female', 'Male', 'Non-binary']" 
                        [clearable]="false" 
                        [searchable]="false"
                    ></app-select>
                </div>
                <div class="waw-datepicker col-2 _arrow">
                    <span class="waw-datepicker__title">Date of Birth</span>
                    <datepicker [date]="staff.data.birthday" (valueChange)="staff.data.birthday=$event"></datepicker>
                </div>
            </div>
        </div>
    </ng-container>
    
    <ng-container *ngIf="step == 5">
        <div class="modal-title">Add staff working hours</div>
		<app-working-days [component]="true" [submit]="submitted" [staff]="staff" (onChange)="staff = $event"></app-working-days>
    </ng-container>

    <ng-container *ngIf="step == 6">
        <div class="modal-title">Add services</div>
        <div class="row">
            <div class="waw-input col-1">
                <label [class.req]="submitted&&!group.name">
                    <span class="waw-input__title">Group name*</span>
                    <input type="text" name="groupName" placeholder="Enter group name" [(ngModel)]="group.name">
                    <div *ngIf="submitted&&!group.name" class="req-text">Group name is required</div>
                </label>
            </div>
        </div>
        <div class="service" *ngFor="let s of services; index as i">
            <div class="waw-input service__input">
                <span class="waw-input__title">Service #{{i+1}}*</span>
                <input type="text" readonly [ngModel]="s.name">
            </div>
            <button mat-icon-button color="primary" (click)="service(i);" class="service__btn">
                <span class="icon-pencil"></span>
            </button>
        </div>
        <div class="mb30">
            <button mat-icon-button color="primary" type="button" id="add-button" class="waw-btn__add" (click)="service()"></button>
            <label for="add-button" class="waw-btn__add-label">Add service</label>
        </div>
    </ng-container>
    
    <div class="modal-btn">
        <button mat-button color="primary" *ngIf="step > 1" type="button" class="waw-btn _second _back" (click)="previousStep()">
            <span class="icon icon-arrow_left"></span>
            <span>go back</span>
        </button>
        <button mat-button color="primary" *ngIf="step == 1" type="button" class="waw-btn _second" (click)="cancel()">Cancel</button>
        <button mat-flat-button color="primary" type="button" class="waw-btn _primary" (click)="nextStep()">{{step < steps.length ? 'Next' : 'Complete'}}</button>
    </div>
</div>

<ng-template #servicePriceRef let-service>
    <div class="waw-input col-1">
        <label [class.req]="service.submitted&&!service.profile.price">
            <span class="waw-input__title">Price *</span>
            <currency-input [value]="service.profile.price" (valueChange)="service.profile.price = $event" class=""></currency-input>
            <div *ngIf="service.submitted&&!service.profile.price" class="req-text">Price is required</div>
        </label>
    </div>
</ng-template>

<ng-template #serviceDurationRef let-service>
    <div class="waw-input col-1">
        <div [class.req]="service.submitted&&!service.profile.time">
            <span class="waw-input__title">Duration *</span>
            <durationinput [(data)]="service.profile.time" (onChange)="service.profile.time = $event" name="time"></durationinput>
            <div *ngIf="service.submitted&&!service.profile.time" class="req-text">Duration is required</div>
        </div>
    </div>
</ng-template>