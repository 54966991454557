<div class="containerBox">
	<div class="navigation">
		<div class="navigation__search search-input">
			<input type="text" placeholder="Search staff" name="search" [(ngModel)]="search" (ngModelChange)="refreshChecks()">
		</div>
		<div class="navigation__right _filter">
			<div class="navigation__filter">
				<div class="navigation__filter-type waw-select">
					<app-select 
						placeholder="Payment" 
						[(value)]="type"
						(valueChange)="refreshChecks()"
						[items]="cs.types" 
						[clearable]="true" 
						[searchable]="false"
						icon="money" 
						class="_filter"
					></app-select>
				</div>
				<div class="navigation__filter-date waw-datepicker _arrow">
					<datepicker [date]="day" (valueChange)="day=$event; refreshChecks()" class="_filter" [icon]="true"></datepicker>
				</div>
			</div>
		</div>
	</div>
	<div class="table-container tablet-col3 mobile-col3">
		<table class="table" [class.navShown]="!us.sidebarToggle" cellpadding="0" cellspacing="0">
			<thead>
				<tr>
					<th scope="col" class="sort" (click)="sort('staff.name')">
						<div class="ai-c">
							<span>Staff</span> 
							<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'staff.name'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'staff.name'">
								<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
								<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
							</span>
						</div>
					</th>
					<th scope="col" class="sort" (click)="sort('type')">
						<div class="ai-c">
							<span>Form of payment</span> 
							<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'type'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'type'">
								<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
								<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
							</span>
						</div>
					</th>
					<th scope="col" class="sort" (click)="sort('creation_date.singleDate.jsDate')">
						<div class="ai-c">
							<span>Date</span> 
							<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'creation_date.singleDate.jsDate'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'creation_date.singleDate.jsDate'">
								<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
								<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
							</span>
						</div>
					</th>
					<th scope="col" class="sort hidden-2" (click)="sort('tax')">
						<div class="ai-c">
							<span>Tax</span> 
							<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'tax'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'tax'">
								<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
								<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
							</span>
						</div>
					</th>
					<th scope="col" class="sort hidden-2" (click)="sort('tips')">
						<div class="ai-c">
							<span>Tips</span> 
							<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'tips'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'tips'">
								<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
								<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
							</span>
						</div>
					</th>
					<th scope="col" class="sort" (click)="sort('total')">
						<div class="ai-c">
							<span>Total</span> 
							<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'total'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'total'">
								<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
								<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
							</span>
						</div>
					</th>
					<th scope="col" class="hidden-1">
						<span>Note</span>
					</th>
					<th scope="col"></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let check of cs.checks; index as i">
					<td data-label="Staff" class="ovf-hidden">
						<div class="table__user">
							<div class="table__user__img" [class._letter]="!check.staff?.avatarUrl">
								<picture [user]="check.staff"></picture>
							</div>
							<div class="table__user__info">
								<span class="table__user__info-name">{{check.staff?.firstName}} <wbr> {{check.staff?.lastName}}</span>
								<small *ngIf="check.staff?.deleted" class="table__user__info-deleted"> (Deleted)</small>
							</div>
						</div>
					</td>
					<td data-label="Form" class="text-elipsis">{{check.type}}</td>
					<td data-label="Date" class="text-elipsis">{{check.creation_date?.singleDate?.formatted}}</td>
					<td data-label="Tax" class="text-elipsis hidden-2">{{check.tax | currency}}</td>
					<td data-label="Tips" class="text-elipsis hidden-2">{{check.tips | currency}}</td>
					<td data-label="Total" class="text-elipsis">{{check.total | currency}}</td>
					<td data-label="Notes" class="hidden-1" style="grid-column: span 2;">
						<div class="note _limit" #Note [innerHtml]="check.notes | note" [class._more]="Note.scrollHeight > Note.clientHeight" (click)="Note.scrollHeight > Note.clientHeight ? Note.classList.add('_all') : !Note.classList.contains('_all') && Note.classList.remove('_all')"></div>
						<button class="note-btn" *ngIf="Note.classList.contains('_all') || Note.scrollHeight > Note.clientHeight" (click)="Note.classList.toggle('_all')">{{Note.scrollHeight > Note.clientHeight ? 'More' : 'Less'}}</button>
					</td>
					<td>
						<div class="actions">
							<button mat-button color="primary" tooltip="Download" tooltipMobileDisabled="true" aria-label="Download" (click)="downloadInvoice(check)" class="waw-btn__icon primary _tablet">
								<span class="icon-download"></span>
							</button>
							<button mat-icon-button color="primary" tooltip="Print" tooltipMobileDisabled="true" aria-label="Print" (click)="printInvoice(check)" class="waw-btn__icon primary _tablet">
								<span class="icon-print"></span>
							</button>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<mat-paginator [length]="cs.count" [pageSize]="config.perPage" [hidePageSize]="true" [showFirstLastButtons]="true" (page)="handlePageEvent($event)" aria-label="Select page"></mat-paginator>
</div>
