import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MongoService, AlertService } from 'wacom';

@Injectable({
	providedIn: 'root'
})
export class LunchService {
	public lunchs: any = [];
	public _lunchs: any = {
		calendar: {}
	};
	private loadedSubject = new BehaviorSubject <boolean>(false);
	timeToPixel(time){
		return time / 3600000 * 80;
	}
	timeToRange(time){
		time = time / 60000;
		let h = Math.floor(time / 60);
		let m = Math.floor(time % 60);
		return (h<10&&'0'||'')+h+':'+(m<10&&'0'||'')+m;
	}
	rangeToTime(start, end){
		if(!start||!end) return 0;
		start = start.split(':');
		start[0] = Number(start[0]);
		start[1] = Number(start[1]);
		end = end.split(':');
		end[0] = Number(end[0]);
		end[1] = Number(end[1]);
		if(end[1]<start[1]){
			end[0]--;
			end[1]+=60;
		}
		if(end[0]<start[0]) return 0;
		return ( (end[0]-start[0])*60 + end[1]-start[1] ) * 60000;
	}
	private replace = {
		end: (val, cb, doc)=>{
			if(doc.duration && doc.start){
				cb(this.timeToRange(this.rangeToTime('00:00', doc.start)+(doc.duration*60000)));
			}else cb(val);
		},
		top: (val, cb, doc)=>{
			cb(this.timeToPixel(this.rangeToTime('00:00', doc.start)));
		},
		height: (val, cb, doc)=>{
			cb(this.timeToPixel(this.rangeToTime(doc.start, doc.end)));
		}
	};
	constructor(private alert: AlertService, private mongo: MongoService, private http: HttpClient) {
		this.mongo.config('lunch', {});
	}
	create(lunch, cb: any = () => {}) {
		if(lunch._id) return this.update(lunch, cb);
		this.replace.end(lunch.end, (end)=>{
			lunch.end = end;
		}, lunch);
		this.mongo.create('lunch', lunch, (created)=>{
			this.replace.top(created.top, top=>{
				created.top = top;
			}, created);
			this.replace.height(created.height, height=>{
				created.height = height;
			}, created);
			this.lunchs.push(created);
			if (!this._lunchs.calendar[created.user+created.day.singleDate.formatted]) {
				this._lunchs.calendar[created.user+created.day.singleDate.formatted] = [];
			}
			this._lunchs.calendar[created.user+created.day.singleDate.formatted].push(created);

			if(typeof cb === 'function') cb(created);
			this.alert.show({
				text: 'Lunch has been created.'
			});
		}); 
	}
	update(lunch, cb: any = () => {}) {
		this.save(lunch, cb);
	}
	save(lunch, cb: any = () => {}) {
		this.replace.end(lunch.end, end=>{
			lunch.end = end;
		}, lunch);
		this.replace.top(lunch.top, top=>{
			lunch.top = top;
		}, lunch);
		this.replace.height(lunch.height, height=>{
			lunch.height = height;
		}, lunch);
		this.refreshCalendarItem(lunch);
		
		this.mongo.update('lunch', lunch, {
			replace: this.replace
		}, (updated) => {
			for(const key in updated) {
				if( ( (typeof updated[key] != 'object' || typeof lunch[key] != 'object') && updated[key] !== lunch[key] ) || ( (typeof updated[key] == 'object' || typeof lunch[key] == 'object') && JSON.stringify(updated[key]) !== JSON.stringify(lunch[key]) ) ) {
					this.refreshCalendarItem(updated);
					break;
				}
			}

			if(typeof cb === 'function') cb(updated);
		});
	}
	delete(lunch, cb: any = () => {}) {
		this.mongo.delete('lunch', lunch, (deleted) => {
			if(deleted) {
				this.lunchs.splice(this.lunchs.findIndex(l => l._id == lunch._id), 1);
				Object.keys(this._lunchs.calendar).forEach(key => {
					this._lunchs.calendar[key] = this._lunchs.calendar[key].filter(item => item._id !== lunch._id);
			
					if (this._lunchs.calendar[key].length === 0) {
						delete this._lunchs.calendar[key];
					}
				});
			}
			if(typeof cb === 'function') cb(deleted);
		});
	}
	get(dates_formatted: string[], user = null, cb: any = () => {}) {
		this.loadedSubject.next(false);
		this.lunchs.length = 0;
		this._lunchs.calendar = null;

		this.http.post('/api/lunch/get', {
			dates: dates_formatted, 
			user: user,
		}).subscribe({
			next: (resp: any) => {
				if(!resp) {
					this.loadedSubject.next(true);
					return cb(false);
				}
				
				const obj = { calendar: {} };

				resp.forEach(item => {
					this.replace.end(item.end, end=>{
						item.end = end;
					}, item);
					this.replace.top(item.top, top=>{
						item.top = top;
					}, item);
					this.replace.height(item.height, height=>{
						item.height = height;
					}, item);

					if (!obj.calendar[item.user+item.day.singleDate.formatted]) {
						obj.calendar[item.user+item.day.singleDate.formatted] = [];
					}
					obj.calendar[item.user+item.day.singleDate.formatted].push(item);
				});		

				this.lunchs = resp;
				this._lunchs = obj;				

				this.loadedSubject.next(true);
				
				if(typeof cb == 'function') cb(resp);
			},
			error: (error) => {
				console.error(error);
				return cb(false);
			}
		});
	}
	loaded(cb:any=event=>{}) {
		if(this.loadedSubject.getValue() === true) {			
			if(typeof cb === 'function') cb(true);
		} else {
			this.loadedSubject.subscribe((state: boolean) => {
				if(state === true) {
					if(typeof cb === 'function') cb(true);
				}
			});
		}
	}
	private refreshCalendarItem(newItem) {
		for (const key in this._lunchs.calendar) {
			if (this._lunchs.calendar.hasOwnProperty(key)) {
				const index = this._lunchs.calendar[key].findIndex(item => item._id === newItem._id);
				if (index !== -1) {
					if(newItem.user+newItem.day.singleDate.formatted === key) {
						if(!newItem.top) {
							this.replace.top(newItem.top, top=>{
								newItem.top = top;
							}, newItem);
						}
						if(!newItem.height) {
							this.replace.height(newItem.height, height=>{
								newItem.height = height;
							}, newItem);
						}
						this._lunchs.calendar[key][index] = newItem;
						break;
					} else {
						this._lunchs.calendar[key].splice(index, 1);
						if (this._lunchs.calendar[key].length === 0) delete this._lunchs.calendar[key];
						if (!this._lunchs.calendar[newItem.user+newItem.day.singleDate.formatted]) this._lunchs.calendar[newItem.user+newItem.day.singleDate.formatted] = [];
						this._lunchs.calendar[newItem.user+newItem.day.singleDate.formatted].push(newItem);
						break;
					}
				}
			}
		}
	}
}
