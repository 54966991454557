import { Component, EventEmitter, Input, Output, Injectable, OnChanges } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDateRangeSelectionStrategy, DateRange, MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Injectable()
export class FiveDayRangeSelectionStrategy implements MatDateRangeSelectionStrategy<string> {
    constructor(private _dateAdapter: DateAdapter<string>) {}

    selectionFinished(date: string | null): DateRange<string> {
        return this._createFiveDayRange(date);
    }

    createPreview(activeDate: string | null): DateRange<string> {
        return this._createFiveDayRange(activeDate);
    }

    private _createFiveDayRange(date: string | null): DateRange<any> {
        if (date) {
            const d = new Date(date)
            const day = d.getDay();
            const diff = day === 0 ? -6 : 1 - day;
            const start = new Date(d.setDate(d.getDate() + diff));
            const end = new Date(d.setDate(d.getDate() + 6));
            return new DateRange<any>(start, end);
        }

        return new DateRange<string>(null, null);
    }
}

@Component({
	selector: 'datepicker-week',
	templateUrl: './datepicker-week.component.html',
	styleUrls: ['./datepicker-week.component.scss'],
    providers: [
        {
            provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
            useClass: FiveDayRangeSelectionStrategy
        },
    ]
})
export class DatepickerweekComponent implements OnChanges {
    @Input() date: any = new Date().getTime();

    @Input() touchUi: boolean = false;

    @Output() valueChange = new EventEmitter();
    
    @Output() open = new EventEmitter();

	constructor(private datePipe: DatePipe) {}

    public start = new FormControl();
    public end = new FormControl();

    public value = '';

    ngOnChanges() {
        const d = new Date(this.date);
        const day = d.getDay();
        const diff = day === 0 ? -6 : 1 - day;
        const start = new Date(d.setDate(d.getDate() + diff));
        const end = new Date(d.setDate(d.getDate() + 6));
		this.start.setValue(start);
		this.end.setValue(end);
        this.dateRangeChange();
    }

    dateRangeChange() {
        const s = this.start.value!;
        const e = this.end.value!;
        if (new Date(s).getMonth() == new Date(e).getMonth()) { 
            var start = this.datePipe.transform(new Date(s), 'd');
            var end = this.datePipe.transform(new Date(e), 'd MMM, y');
        } else {
            var start = this.datePipe.transform(new Date(s), 'd MMM');
            var end = this.datePipe.transform(new Date(e), 'd MMM, y');
        }
        this.value = start + ' - ' + end;
        this.valueChange.emit({start: s, end: e});
    }

    changeWeek(variation) {
		const s = new Date(this.start.value);
		s.setDate(s.getDate()+(variation*7));
		const e = new Date(this.end.value);
		e.setDate(e.getDate()+(variation*7));
        this.start.setValue(s);
		this.end.setValue(e);
        this.dateRangeChange();
	}
}