import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HashService, QuestionService, UserService } from '@services';
import { flyAnimation } from 'src/app/common/animation/animation.component';
@Component({
	selector: 'questionnaire',
	templateUrl: './questionnaire.component.html',
	styleUrls: ['./questionnaire.component.scss'],
	animations: [flyAnimation]
})
export class QuestionnaireComponent{
	public questionBuffer:any = {};
	public questionShow: any;
	public now;
	@Output('linkEvent') linkEvent = new EventEmitter();
	constructor(public qs: QuestionService,
		public us: UserService,
		private router: Router,
		public hash: HashService) {}
	ngOnInit(): void {
		this.us.backRoute = () => {
			this.hash.remove('questionnaire'); 
			this.linkEvent.emit(true);
		};
	}
	ngOnDestroy(): void {
		this.us.backRoute = () => {
			this.router.navigate(['/clients']);
		};
	}
	show(question) {
		if(this.questionBuffer[question._id]) {
			delete this.questionBuffer[question._id]
		} else {
			this.questionBuffer[question._id] = JSON.parse(JSON.stringify(question));
			if(question.kind == 'text') {
				if(!this.questionBuffer[question._id].text[this.us.user._id]) this.questionBuffer[question._id].text[this.us.user._id] = {};
			} else if(question.kind == 'multi'||question.kind == 'select') {
				if(question.options.length) {
					for (let i = 0; i < question.options.length; i++) {			
						if(!question.options[i].select[this.us.user._id]) question.options[i].select[this.us.user._id] = {};
					}
					console.log(question.options);
				}
			}
		}
	}
	save(question) {
		this.questionBuffer[question._id].text[this.us.user._id].date = new Date();
		this.questionBuffer[question._id].text[this.us.user._id].author = this.us._id;
		this.qs.update(this.questionBuffer[question._id]);
		this.show(question);
	}
	saveOptions(question, j) {
		if(question.kind == 'select') {
			for (let i = 0; i < question.options.length; i++) {			
				if(i != j) question.options[i].select[this.us.user._id].value = false;
			}
		}
		console.log(question.options[j].select[this.us.user._id]);
		question.options[j].select[this.us.user._id].date = new Date();
		question.options[j].select[this.us.user._id].author = this.us._id;
		this.now = Date.now();
		this.qs.update(question)
	}
}
