<div class="chart-container" [class.legend-left]="legendPosition == 'left'">
    <div class="legend" [class.left]="legendPosition == 'left'">
        <div class="legend-item" *ngFor="let item of items" (mouseenter)="showInfo(item.id)" (mouseleave)="hideInfo(item.id)">
            <div class="legend-item__color" [ngStyle]="{'background': item.color}"></div>
            <span class="legend-item__name">{{item.name}} <span *ngIf="item.extra_name" class="legend-item__name-extra">{{item.extra_name}}</span></span>
        </div>
    </div>
    <svg width="225" height="225" viewBox="-112.5 -112.5 225 225" xmlns="http://www.w3.org/2000/svg" style="user-select: none;">
        <circle opacity="0.1" cx="0" cy="0" r="89.5" fill="#B6B6B6"/>
        <ng-container *ngFor="let item of svg_items;">
            <ng-container *ngIf="item.d">
                <path [id]="'path_'+item.id" [attr.fill]="item.color" style="cursor:pointer;" (mouseenter)="showInfo(item.id)" (mouseleave)="hideInfo(item.id)">
                    <animate attributeName="d" dur="0.3s" repeatCount="0" [attr.values]="item.animate" fill="freeze" begin="indefinite" />
                </path>
            </ng-container>
        </ng-container>
        <g filter="url(#filter_center)">
            <circle cx="0" cy="0" r="62.5" fill="white" />
        </g>
        <text x="0" y="0" dy="0.35em" text-anchor="middle" font-size="25px" font-family="Gilroy, sans-serif" font-weight="500" fill="#343434" >{{total}}</text>
        <circle id="hide_total" cx="0" cy="0" r="62.5" fill="white" style="display:none"/>
        <ng-container *ngFor="let item of svg_items;">
            <text [id]="'percentage_'+item.id" x="0" y="0" dy="-0em" text-anchor="middle" font-size="25px" font-family="Gilroy, sans-serif" font-weight="500" fill="#343434" style="display:none">{{item.percentage}}</text>
            <text [id]="'name_'+item.id" x="0" y="0" dy="1.5em" text-anchor="middle" font-size="15px" font-family="Gilroy, sans-serif" fill="#343434" style="display:none">{{item.name}}</text>
        </ng-container>
        <defs>
            <filter id="filter_center" x="-77.5" y="-77.5" width="155" height="155" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_2620_19331"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="6"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2620_19331"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2620_19331" result="shape"/>
            </filter>
        </defs>
    </svg>     
</div>

