import { Component } from '@angular/core';

@Component({
	selector: 'app-guest',
	templateUrl: './guest.component.html',
	styleUrls: ['./guest.component.scss']
})
export class GuestComponent {
	constructor() {}
}
