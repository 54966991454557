<div class="discount-container">
	<div class="setup-empty" *ngIf="false">
		<div class="setup-empty__img"><img src="assets/img/setup/payment.svg" alt="discount" draggable="false"/></div>
		<div class="setup-empty__title">Set up manual payment types for use during checkout</div>
		<div class="setup-empty__btn"><button class="waw-btn _second _second--mob" (click)="openModal()">go over</button></div>
	</div>
	<div class="discount-list" *ngIf="true">
		<div class="page-nav">
			<div class="page-nav__left">
				<a routerLink="/setup"><span class="icon icon-chevron setup-arrow-back"></span></a>
			</div>
			<button (click)="openModal()" class="btn-icon page-nav__btn"><span class="icon-plus"></span></button>
		</div>
		<div class="discount-list__body">
			<div class="discount-list__item" *ngFor="let plan of ps.plans" (click)="edit(plan);">
				<div class="discount-list__title">
					<div>{{plan.name}}</div>
					<div><span class="icon-lock"></span></div>
				</div>
			</div>
		</div>
	</div>
</div>