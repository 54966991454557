// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  SENDGRID_API_KEY: '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',
  firebase: {
    apiKey: "AIzaSyBmkTX1hUfwdvrB5-4UmmyoOkkXWXn1UZA",
    authDomain: "pureplanner.firebaseapp.com",
    projectId: "pureplanner",
    storageBucket: "pureplanner.appspot.com",
    messagingSenderId: "786370122363",
    appId: "1:786370122363:web:ff13267a8c248a9f90c8de",
    measurementId: "G-W7SY1DR57R",
    vapidKey: "BDkIhoLgAwbBLjxSMMAQRyXBA4fU7uiHdQgbYksDU0QLw0SLO9ZS1kNNZQIv5vWDfQYtjnG9nn5upp6reE7IP4M"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
