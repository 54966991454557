<div class="modal-box">
	<form (ngSubmit)="submit();">
		<div class="modal-title">{{profile._id&&'Edit'||'Add'}} Appointment</div>
		<button mat-icon-button type="button" class="modal-close" (click)="close()">
			<span class="modal-close__icon"></span>
		</button>
		<div class="newAppoint-form__field">
			<div class="row">
				<div class="waw-select col-2 pos-rel">
					<span class="waw-select__title">Staff *</span>
					<span class="material-icons lockStaff" *ngIf="profile.user && !profile.lockStaff" (click)="profile.lockStaff = true">lock</span>
					<span class="material-icons lockStaff" *ngIf="profile.user && profile.lockStaff" (click)="profile.lockStaff = false">no_encryption</span>
					<app-select 
						placeholder="Staff" 
						[(value)]="profile.user" 
						(valueChange)="aps.setLocation(profile); locationChange(); dayValidation(profile.day);" 
						[items]="allowed_appointments" 
						[clearable]="false" 
						[searchable]="true" 
						bindLabel="name" 
						bindValue="_id" 
						bindDisabled="disabled"
						[disabled]="profile.lockStaff||profile.expired"
						[required]="submitted&&!profile.user"
					>
						<ng-template label let-item>
							<div class="select-option">
								<span class="select-option__name">{{item.name}}</span>
								<span *ngIf="item.deleted" class="select-option__status"> (Deleted)</span>
							</div>
						</ng-template>
						<ng-template option let-item>
							<div class="select-option">
								<span class="select-option__name">{{item.name}}</span>
								<span *ngIf="item.deleted" class="select-option__status"> (Deleted)</span>
							</div>
						</ng-template>
					</app-select>
					<div *ngIf="submitted&&!profile.user" class="req-text">Staff is required</div>
				</div>
				<div class="waw-select col-2">
					<div style="display: flex;justify-content: space-between;">
						<span class="waw-select__title">Client *</span>
						<div class="add-client" *ngIf="!profile.expired"> 
							<button class="add-client__button" (click)="openAddPopup();" type="button">Add</button>
							<div class="modal-popup" *ngIf="add_popup" [class.left]="left_popup" (click)="$event.stopPropagation();">
								<div class="waw-input col-1">
									<label [class.req]="new_client_submitted && !new_client.firstName">
										<span class="waw-input__title">Name *</span>
										<input type="text" placeholder="Name" name="firstName" [(ngModel)]="new_client.firstName">
										<div *ngIf="new_client_submitted && !new_client.firstName" class="req-text">First name is required</div>
									</label>
								</div>
								<div class="waw-input col-1">
									<label [class.req]="new_client_submitted && !new_client.lastName">
										<span class="waw-input__title">Last name *</span>
										<input type="text" placeholder="Last name" name="lastName" [(ngModel)]="new_client.lastName">
										<div *ngIf="new_client_submitted && !new_client.lastName" class="req-text">Last name is required</div>
									</label>
								</div>
								<div class="waw-input col-1">
									<label [class.req]="new_client_submitted && !new_client.email">
										<span class="waw-input__title">Email *</span>
										<input type="text" placeholder="Email" inputmode="email" name="email" [(ngModel)]="new_client.email">
										<div *ngIf="new_client_submitted && !new_client.email" class="req-text">Email is required</div>
									</label>
								</div>
								<div class="waw-input col-1">
									<label>
										<span class="waw-input__title">Phone</span>
										<input type="text" placeholder="Phone" inputmode="tel" name="phone" [(ngModel)]="new_client.data.phone">
									</label>
								</div>
								<div class="modal-btn">
									<div></div>
									<button mat-flat-button color="primary" class="waw-btn _primary" (click)="addClient()" type="button">Add client</button>
								</div>
							</div>
						</div>
					</div>
					<app-select 
						placeholder="Client" 
						[(value)]="profile.client"
						[items]="clients" 
						[clearable]="false" 
						[searchable]="true" 
						bindLabel="name" 
						bindValue="_id" 
						[disabled]="profile.expired"
						[required]="submitted&&!profile.client"
					>
						<ng-template label let-item>
							<div class="select-option">
								<span class="select-option__name">{{item.name}}</span>
								<span *ngIf="item.deleted" class="select-option__status"> (Deleted)</span>
							</div>
						</ng-template>
						<ng-template option let-item>
							<div class="select-option">
								<span class="select-option__name">{{item.name}}</span>
								<div *ngIf="!item.deleted" class="select-option__email">{{item.email}}</div>
								<span *ngIf="item.deleted" class="select-option__status"> (Deleted)</span>
							</div>
						</ng-template>
					</app-select>
					<div *ngIf="submitted&&!profile.client" class="req-text">Client is required</div>
				</div>
				<div class="col-2">
					<timepicker 
						label="Start time *" 
						requiredText="Time is required"
						[value]="profile.start" 
						[required]="submitted&&!profile.start" 
						[disabled]="profile.expired"
						(valueChange)="profile.start = $event" 
					></timepicker>
				</div>
				<div class="waw-select col-2 ">
					<span class="waw-select__title">Service *</span>
					<app-select 
						placeholder="Service" 
						[(value)]="profile.service"
						(valueChange)="update_duration()"
						[items]="ss.by_location[profile.location]" 
						[clearable]="false" 
						[searchable]="true" 
						bindLabel="name" 
						bindValue="_id" 
						[disabled]="profile.expired"
						[required]="submitted&&!profile.service"
					>
						<ng-template option let-item>
							<div class="select-option">
								<span class="select-option__name">{{item.name}}</span>
								<span class="select-option__time">{{item.time | formatTime}}</span>
							</div>
						</ng-template>
					</app-select>
					<div *ngIf="submitted&&!profile.service" class="req-text">Service is required</div>
				</div>
				<div class="waw-textarea col-1">
					<div [class.req]="characterLimit">
						<note-editor [notes]="noteEditor" placeholder="Add privates notes" label="Appointment notes" size="medium" (characterLimit)="characterLimit = $event" (onChange)="noteEditor = $event"></note-editor>
						<div *ngIf="characterLimit" class="req-text">{{characterLimit}}</div>
					</div>
				</div>
			</div>
			<hr>
			<div class="row">
				<div class="waw-datepicker col-2 _arrow" [class.req]="(submitted&&!profile.day) || errorDay">
					<span class="waw-datepicker__title">Select day *</span>	
					<datepicker 
						[date]="profile.day" 
						(valueChange)="profile.day=$event; dayValidation($event); locationsRefresh(); allowedRefresh()" 
						[required]="(submitted&&!profile.day) || errorDay" 
						[disabled]="profile.expired" 
						[minDate]="minDate"
						[dateFilter]="dateFilter"
					></datepicker>
					<div *ngIf="submitted&&!profile.day" class="req-text">Day is required</div>
					<div *ngIf="errorDay" class="req-text">{{errorDay}}</div>
				</div>
				<div class="waw-input col-2">
					<span class="waw-input__title">Duration *</span>
					<div [class.req]="submitted&&(!profile.duration)">
						<durationinput [(data)]="profile.duration" (onChange)="profile.duration = $event" [disabled]="profile.expired" id="appt" name="Appointment end"></durationinput>
						<div *ngIf="submitted&&!profile.duration" class="req-text">Duration is required</div>
					</div>
				</div>
				<div class="waw-select col-2">
					<span class="waw-select__title">status *</span>
					<app-select 
						placeholder="Select status" 
						[(value)]="profile.status"
						[items]="selectStatuses" 
						[clearable]="false" 
						[searchable]="false" 
						[disabled]="profile.expired"
						[required]="submitted&&!profile.status"
					></app-select>
					<div *ngIf="submitted&&!profile.status" class="req-text">Status is required</div>
				</div>
				<!-- if we chooose status to canceled, we will show this field -->
				<div class="waw-select col-2" *ngIf="profile.status=='Canceled'">
					<span class="waw-select__title">Cancellation reasons *</span>
					<app-select 
						placeholder="Select reason" 
						[(value)]="profile.reason"
						[items]="rs.reasons" 
						[clearable]="false" 
						[searchable]="false" 
						bindLabel="name" 
						bindValue="_id" 
						[disabled]="profile.expired"
						[required]="submitted&&!profile.reason"
					></app-select>
					<div *ngIf="submitted&&!profile.reason" class="req-text">Reason is required</div>
				</div>
				<div class="waw-select col-2">
					<span class="waw-select__title">Add location *</span>
					<app-select 
						placeholder="Location" 
						[(value)]="profile.location"
						(valueChange)="profile.service = undefined; locationChange(); dayValidation(profile.day);"
						[items]="locations" 
						bindLabel="name" 
						bindValue="_id" 
						bindDisabled="disabled"
						[clearable]="false" 
						[searchable]="false"
						[disabled]="profile.expired"
						[required]="submitted&&!profile.location"
					></app-select>
					<div *ngIf="submitted&&!profile.location" class="req-text">Location is required</div>
				</div>
			</div>
		</div>
		<div class="appoint-history" *ngIf="profile._id && profile.history && profile.history.length">
			<div class="fl-sb-c appoint-history-row" (click)="appointHistory=!appointHistory">
				<span>History</span>
				<span class="icon icon-calendar"></span>
			</div>
			<div *ngIf="!appointHistory" [@flyInOut]="appointHistory" class="pb10">
				<ul>
					<ng-container *ngFor="let hist of profile.history; index as count">
						<ng-container *ngIf="moreHistory || count <= 4">
							<li class="appoint-history__item" [@flyInOut]="moreHistory">
								<ng-container [ngSwitch]="hist.status">
									<span *ngSwitchCase="'new'">{{us._users[hist.user]?.firstName}} scheduled the appointment at {{hist._id|mongodate|date:'short'}}</span>
									<span *ngSwitchCase="'rescheduled'">{{us._users[hist.user]?.firstName}} rescheduled the appointment {{hist.text}} at {{hist._id|mongodate|date:'short'}}</span>
									<span *ngSwitchCase="'restaffed'">{{us._users[hist.user]?.firstName}} changed the staff member from {{us._users[hist.staff1]?.firstName}} to {{us._users[hist.staff2]?.firstName}} at {{hist._id|mongodate|date:'short'}}</span>
									<span *ngSwitchCase="'canceled'">{{us._users[hist.user]?.firstName}} canceled  the appointment at {{hist._id|mongodate|date:'short'}}</span>
									<span *ngSwitchCase="'completed'">{{us._users[hist.user]?.firstName}} completed the appointment at {{hist._id|mongodate|date:'short'}}</span>
									<span *ngSwitchCase="'confirmed'">{{us._users[hist.user]?.firstName}} confirmed the appointment at {{hist._id|mongodate|date:'short'}}</span>
									<span *ngSwitchCase="'arrived'">{{us._users[hist.user]?.name}} arrived at {{hist._id|mongodate|date:'short'}}</span>
									<span *ngSwitchCase="'no-show'">{{us._users[hist.user]?.firstName}} indicated that the client was a no-show at {{hist._id|mongodate|date:'short'}}</span>
								</ng-container>
							</li>
						</ng-container>
					</ng-container>
				</ul>
				<ng-container *ngIf="profile.history.length > 5">
					<div class="appoint-history__view ai-c" (click)="moreHistory = !moreHistory">
						<ng-container *ngIf="!moreHistory">
							<span>View More</span>
							<span class="material-icons">expand_more</span>
						</ng-container>
						<ng-container *ngIf="moreHistory">
							<span>View Less</span>
							<span class="material-icons">expand_less</span>
						</ng-container>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="modal-switch" *ngIf="!profile._id">
			<label class="waw-switch">
				<input type="checkbox" name="Repeats" [(ngModel)]="repeat.enable" [disabled]="profile.expired">
				<span class="track">
					<span class="handle"></span>
				</span>
				<div class="waw-switch-text">Repeats</div>
			</label>
		</div>
		<div *ngIf="repeat.enable">
			<div class="row">
				<div class="waw-select col-2">
					<span class="waw-select__title">Repeat *</span>
					<app-select 
						placeholder="Repeat" 
						[(value)]="repeat.kind"
						[items]="kinds" 
						[clearable]="false" 
						[searchable]="false" 
						bindLabel="name"
						[disabled]="profile.expired"
						[required]="submitted&&!repeat.kind"
					></app-select>
					<div *ngIf="submitted&&!repeat.kind" class="req-text">Type is required</div>
				</div>
				<div class="waw-input col-2">
					<label [class.req]="submitted&&!repeat.count">
						<span class="waw-input__title">Count *</span>
						<input type="number" placeholder="Count" name="counter" [(ngModel)]="repeat.count" [disabled]="profile.expired">
						<div *ngIf="submitted&&!repeat.count" class="req-text">Count is required</div>
					</label>
				</div>
			</div>
		</div>
		<div class="modal-btn">
			<button mat-button color="primary" type="button" *ngIf="!profile._id || profile.expired" class="waw-btn _second" (click)="close()">Cancel</button>
			<button mat-button color="primary" type="button" *ngIf="profile._id&&!profile.expired" (click)="delete(profile);" class="waw-btn _second _delete">Delete</button>
			<button mat-flat-button color="primary" type="submit" *ngIf="!profile.expired" class="waw-btn _primary">{{profile._id&&'Save'||'Create'}}</button>
		</div>
	</form>
</div>
