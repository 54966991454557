import { Component, Input, AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { UserService, CategoryService, LocationService, RoleService, LoaderService } from '@services';
import { AlertService, ModalService, MongoService } from 'wacom'
import { Router,ActivatedRoute } from '@angular/router';
import { StaffComponent } from 'src/app/modals/staff/staff.component';
@Component({
	selector: 'app-staff-information',
	templateUrl: './staff-information.component.html',
	styleUrls: ['./staff-information.component.scss']
})
export class StaffInformationComponent implements OnInit, AfterViewInit {
	/* Create User */
	@Input() searching: string = '';
	public autocomplete;
	edit(user) {
		if(this.us.is.admin||this.us.is.owner||user._id == this.us._id) {
			this.router.navigate([],{ queryParams: { modal: 'open' } });
			this.modal.show({component: 'staff', staff: JSON.parse(JSON.stringify(user))});
		}
	}
	delete(user) {
		if(this.us.is.admin||this.us.is.owner) {
			this.router.navigate([],{ queryParams: { modal: 'open' } });
			this.modal.show({
				component: 'deleteAlert',
				title: 'Delete user',
				body: `Are you sure you want to delete user ${user.firstName} ${user.lastName}`,
				on_add: ()=> {
					this.loader.show({container: true, transparent: true}, this.eref.nativeElement.closest('.containerBox'));
					this.us.delete(user, () => {
						this.loader.remove();
					}); 
				}
			});
		}
	}
	
	public listSearchShow: any;
	public searchOn: any;
	
	/* Local */
	constructor( public us: UserService,
		public cs: CategoryService, public ls: LocationService,
		public rs: RoleService, public alert: AlertService, public modal: ModalService, public router: Router,private route: ActivatedRoute,
		private loader: LoaderService,
		private mongo: MongoService,
		private eref: ElementRef) {}
	ngOnInit() {
		if (!this.loader.isLoaderShowing) this.loader.show({container: true}, this.eref.nativeElement.closest('.containerTab'));
	}
	ngAfterViewInit() {
		this.mongo.on('user category location role', () => {
			this.loader.remove();
		});
	}
	public sort_type:any = {};
	sort(field) {
		if(this.sort_type.title != field) this.sort_type = {};
		this.sort_type = {
			title: field,
			direction: (typeof this.sort_type.direction != 'string'&&'asc')||(this.sort_type.direction == 'asc'&&'desc')||undefined
		}
	}
	ngDoCheck(){
		if( this.route.snapshot.queryParams['modal']){
		}
		else{
			this.modal.destroy()
		}
	}
}
