<!-- <button class="waw-btn _primary _primary--mob" (click)="">New letter</button> -->
<div class="modal-box">
    <div class="modal-title">Send email to client</div>
    <button mat-icon-button type="button" class="modal-close" (click)="close()">
		<span class="modal-close__icon"></span>
	</button>
    <form (ngSubmit)="us.send(us.user.email, us.user.name, email.subject, email.body); close();">
        <div class="row">
            <div class="waw-input col-1">
                <label>
                    <span class="waw-input__title">Subject</span>
                    <input type="text" placeholder="Enter title" name="title" [(ngModel)]="email.subject">
                </label>
            </div>
        </div>
        <div class="row">
            <div class="waw-textarea col-1">
                <label>
                    <span class="waw-textarea__title">Body</span>
                    <textarea placeholder="Enter text" name="Enter text" [(ngModel)]="email.body"></textarea>
                </label>
            </div>
        </div>
        <div class="modal-btn">
            <button mat-button color="primary" type="button" class="waw-btn _second" (click)="close()">Cancel</button>
            <button mat-flat-button color="primary" type="submit" class="waw-btn _primary" [disabled]="!email.subject||!email.body">Send</button>
        </div>
    </form>
</div>
