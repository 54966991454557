<div class="modal-box">
	<form (ngSubmit)="save()">
		<div class="modal-title">{{profile._id&&'Edit'||'New'}} Question</div>
		<button mat-icon-button type="button" class="modal-close" (click)="close()">
			<span class="modal-close__icon"></span>
		</button>
		<div class="row">
			<div class="waw-input col-1">
				<label [class.req]="submitted&&!profile.title">
					<span class="waw-input__title">Question *</span>
					<div class="textarea-container">
						<textarea cdkTextareaAutosize cdkAutosizeMinRows="1" placeholder="Your question" name="question name" [ngModel]="profile.title" (ngModelChange)="profile.title = removeLineBreaks($event)" (keydown)="$event.key === 'Enter' ? $event.preventDefault() : ''"></textarea>
						<div class="textarea-wrap"></div>
					</div>
					<div *ngIf="submitted&&!profile.title" class="req-text">Name is required</div>
				</label>
			</div>
			<div class="waw-select col-1">
				<span class="waw-select__title">Question Type *</span>
				<app-select 
					placeholder="Select type question" 
					[(value)]="profile.kind"
					[items]="kinds" 
					[clearable]="false" 
					[searchable]="false"
					[required]="submitted&&!profile.kind"
					bindValue="value" 
					bindLabel="name"
				></app-select>
				<div *ngIf="submitted&&!profile.kind" class="req-text">Type is required</div>
			</div>
		</div>
		<ng-container *ngIf="profile.kind=='select' || profile.kind=='multi'">
			<div class="option" *ngFor="let opt of profile.options; index as i;">
				<div class="waw-input option__input">
					<label [class.req]="submitted&&!opt.answer">
						<span class="waw-input__title">Option #{{i+1}}*</span>
						<div class="textarea-container">
							<textarea cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" [id]="'opt'+(i+1)" placeholder="Enter answer" [name]="'opt'+(i+1)" [ngModel]="opt.answer" (ngModelChange)="opt.answer = removeLineBreaks($event)" (keydown)="$event.key === 'Enter' ? $event.preventDefault() : ''"></textarea>
							<div class="textarea-wrap"></div>
						</div>
						<div *ngIf="submitted&&!opt.answer" class="req-text">Answer is required</div>
					</label>
				</div>
				<button mat-icon-button color="warn" (click)="profile.options.splice(i, 1);" type="button" class="option__button" [class.req]="submitted&&!opt.answer">
					<span class="icon-delete"></span>
				</button>
			</div>
			<div class="mb30">
				<button mat-icon-button color="primary" type="button" id="add-button" class="waw-btn__add" (click)="profile.options.push({})"></button>
				<label for="add-button" class="waw-btn__add-label">Add option</label>
			</div>
		</ng-container>
		<div class="modal-btn">
			<button mat-button color="primary" type="button" *ngIf="!profile._id" class="waw-btn _second" (click)="close()">Cancel</button>
			<button mat-button color="primary" type="button" *ngIf="profile._id" class="waw-btn _second _delete" (click)="delete()">Delete</button>
			<button mat-flat-button color="primary" type="submit" class="waw-btn _primary">{{profile._id&&'Save'||'Create'}}</button>
		</div>
	</form>
</div>
