<div class="timepicker-wrapp" [class.req]="required" [class.disabled]="disabled">
    <span class="timepicker__label" *ngIf="label">{{label}}</span>
    <div class="timepicker__container" [class.focus]="focus && !disabled" (click)="!disabled ? (focus = true) : (focus = false)" cdkOverlayOrigin #timepicker="cdkOverlayOrigin">
        <input class="timepicker__container-input" type="text" readonly [value]="str" size="11" placeholder="HH:MM">
        <button mat-icon-button color="primary" type="button" [disabled]="disabled" class="btn">
            <span class="btn-icon icon-arrow_down" style="display: block; transition: 0.15s all ease-in-out;" [ngStyle]="{'transform': focus ? 'rotate(-180deg)' : 'rotate(0deg)' }"></span>
        </button>
    </div>
    <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="timepicker"
        [cdkConnectedOverlayOpen]="focus && !disabled"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayBackdropClass]="'picker-overlay-backdrop'"
        [cdkConnectedOverlayPanelClass]="'timepickerPanel'"
        [cdkConnectedOverlayFlexibleDimensions]="true"
        (backdropClick)="focus=false"
        (detach)="focus=false"
        >
        <div class="timepicker__dropdown">
            <div class="timepicker__dropdown-time">
                <input 
                    #Hour 
                    type="text"
                    maxlength="2" 
                    size='2'
                    mask="00" 
                    inputmode="decimal"
                    placeholder="--"
                    [disabled]="disabled"
                    [ngModel]="hour" 
                    [ngModelOptions] = "{updateOn:'blur'}" 
                    (ngModelChange)="changeHour($event)"
                    (focus)="$event.target.select()"
                >
                <span class="timepicker__dropdown-time-separator">:</span>
                <input 
                    #Minute 
                    type="text" 
                    maxlength="2" 
                    size='2'
                    mask="00" 
                    inputmode="decimal"
                    placeholder="--"
                    [disabled]="disabled"
                    [ngModel]="minute" 
                    [ngModelOptions] = "{updateOn:'blur'}" 
                    (ngModelChange)="changeMinute($event)" 
                    (focus)="$event.target.select()"
                >
            </div>
            <div class="timepicker__dropdown-actions">
                <button mat-icon-button (click)="increase()" type="button">
                    <i class="material-icons">keyboard_arrow_up</i>
                </button>
                <button mat-icon-button (click)="decrease()" type="button">
                    <i class="material-icons">keyboard_arrow_down</i>
                </button>
            </div>
            <button mat-button color="primary" type="button" class="waw-btn _second timepicker__dropdown-period" (click)="changePeriod()">{{model.period}}</button>
        </div>
    </ng-template>
    <div *ngIf="required" class="req-text">{{requiredText}}</div>
</div>
