<div class="dashboard" cdkOverlayOrigin #select="cdkOverlayOrigin" [class._open]="open">
    <button mat-icon-button color="primary" type="button" [disabled]="disabledPrev" [class.disabled]="disabledPrev" class="btn prev" (click)="prev()" type="button">
        <span class="btn-icon icon-arrow_left"></span>
    </button>
    <div (click)="open = true" class="dashboard__value" [ngSwitch]="interval">
        <ng-container *ngSwitchCase="'day'">
            {{value.start| date : 'EEEE d MMMM, y'}}
        </ng-container>
        <ng-container *ngSwitchCase="'week'">
            {{((value.start| date : 'MMMM') === (value.end| date : 'MMMM')) ? (value.start| date : 'd') : (value.start| date : 'd MMMM')}} - {{value.end | date : 'd MMMM, y'}}
        </ng-container>
        <ng-container *ngSwitchCase="'month'">
            {{value.start| date : 'MMMM, y'}}
        </ng-container>
        <ng-container *ngSwitchCase="'quarter'">
            {{getQuarter()}}
        </ng-container>
        <ng-container *ngSwitchCase="'year'">
            {{value.start| date : 'y'}}
        </ng-container>
    </div>
    <button mat-icon-button color="primary" type="button" [disabled]="disabledNext" [class.disabled]="disabledNext" class="btn next" (click)="next()" type="button">
        <span class="btn-icon icon-arrow_right"></span>
    </button>
</div>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="select"
    [cdkConnectedOverlayOpen]="open"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'picker-overlay-backdrop'"
    [cdkConnectedOverlayPanelClass]="'panelClass'"
    [cdkConnectedOverlayFlexibleDimensions]="true"
    (backdropClick)="open=false"
    (detach)="open=false; period_mob=false"
>
    <div class="dashboard-picker"  [@modal]="us.mobile ? (open ? 'zoom' : open) : (open ? 'positionBottom' : open)">
        <div class="dashboard-picker__period-mob">
            <button mat-button class="dashboard-picker__period-mob__btn" (click)="period_mob = !period_mob" [ngStyle]="{'margin-bottom': period_mob ? '5%' : '0'}">
                <span>{{period_mob ? 'period' : period}}</span>
                <span class="material-icons" [ngStyle]="{'transform': period_mob ? 'rotate(180deg)' : ''}">arrow_drop_down</span>
            </button>
        </div>
        <div class="dashboard-picker__calendar" *ngIf="!period_mob" [ngSwitch]="period" [class.block_header_button]="period=='month' || period=='quarter'">
            <mat-calendar style="display: none;"></mat-calendar>
            <ng-container *ngSwitchCase="'day'">
                <mat-calendar [startAt]="day" [(selected)]="day" [minDate]="minDate" [maxDate]="maxDate"></mat-calendar>
            </ng-container>
            <ng-container *ngSwitchCase="'week'">
                <mat-calendar [startAt]="week.start" [selected]="week" [minDate]="minDate" [maxDate]="maxDate" (selectedChange)="selectedChange($event)"></mat-calendar>
            </ng-container>
            <ng-container *ngSwitchCase="'month'">     
                <div class="mat-calendar">
                    <div class="mat-calendar-header">
                        <div class="mat-calendar-controls">
                            <button class="mat-focus-indicator mat-calendar-period-button mat-button mat-button-base">{{yearCalendar}}</button>
                            <div class="mat-calendar-spacer"></div>
                            <button mat-button class="mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base" [class.mat-button-disabled]="prevYearTime < minDateTime" (click)="prevYear()"></button>
                            <button mat-button class="mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base" [class.mat-button-disabled]="nextYearTime > maxDateTime" (click)="nextYear()"></button>
                        </div>
                    </div>
                    <div class="mat-calendar-content">
                        <table role="grid" class="mat-calendar-table">
                            <thead class="mat-calendar-table-header">
                                <tr>
                                    <th colspan="4" class="mat-calendar-table-header-divider"></th>
                                </tr>
                            </thead>
                            <tbody class="mat-calendar-body">
                                <tr *ngFor="let row of ui.arr(3); index as i" role="row">
                                    <td *ngFor="let grid of ui.arr(4); index as j" 
                                        role="gridcell" 
                                        class="mat-calendar-body-cell" 
                                        style="width: 25%; padding-top: 7.14286%; padding-bottom: 7.14286%;" 
                                        tabindex="1" 
                                        [class.mat-calendar-body-active]="(months[i * 4 + j].epoc | date: 'shortDate') === (month | date: 'shortDate')" 
                                        [class.mat-calendar-body-disabled]="months[i * 4 + j].epoc > maxDateTime || months[i * 4 + j].epoc < minDateTime" 
                                        (click)="(months[i * 4 + j].epoc <= maxDateTime && months[i * 4 + j].epoc >= minDateTime) ? selectedChange(months[i * 4 + j].epoc) : ''"
                                    >
                                        <div class="mat-calendar-body-cell-content mat-focus-indicator" [class.mat-calendar-body-selected]="(months[i * 4 + j].epoc | date: 'shortDate') === (month | date: 'shortDate')" [class.mat-calendar-body-today]="thisMonthTime == months[i * 4 + j].epoc">{{months[i * 4 + j].label}}</div>
                                        <div class="mat-calendar-body-cell-preview"></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="'quarter'">
                <div class="mat-calendar">
                    <div class="mat-calendar-header">
                        <div class="mat-calendar-controls">
                            <button class="mat-focus-indicator mat-calendar-period-button mat-button mat-button-base">{{yearCalendar}}</button>
                            <div class="mat-calendar-spacer"></div>
                            <button mat-button class="mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base" [class.mat-button-disabled]="prevYearTime < minDateTime" (click)="prevYear()"></button>
                            <button mat-button class="mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base" [class.mat-button-disabled]="nextYearTime > maxDateTime" (click)="nextYear()"></button>
                        </div>
                    </div>
                    <div class="mat-calendar-content">
                        <table role="grid" class="mat-calendar-table">
                            <thead class="mat-calendar-table-header">
                                <tr>
                                    <th colspan="3" class="mat-calendar-table-header-divider"></th>
                                </tr>
                            </thead>
                            <tbody class="mat-calendar-body">
                                <tr *ngFor="let row of ui.arr(4); index as i" role="row">
                                    <td *ngFor="let grid of ui.arr(3); index as j" 
                                        role="gridcell" 
                                        class="mat-calendar-body-cell" 
                                        style="width: 25%; padding-top: 7.14286%; padding-bottom: 7.14286%;"
                                        tabindex="1" 
                                        [class.mat-calendar-body-in-preview]="quarterPrev == row" 
                                        [class.mat-calendar-body-preview-start]="quarterPrev == row && grid == 1" 
                                        [class.mat-calendar-body-preview-end]="quarterPrev == row && grid == 3" 
                                        [class.mat-calendar-body-in-range]="quarter.year == yearCalendar && quarter.no == row" 
                                        [class.mat-calendar-body-range-start]="quarter.year == yearCalendar && quarter.no == row && grid == 1" 
                                        [class.mat-calendar-body-range-end]="quarter.year == yearCalendar && quarter.no == row && grid == 3"
                                        [class.mat-calendar-body-disabled]="months[i * 3 + j].epoc > maxDateTime || months[i * 3 + j].epoc < minDateTime" 
                                        (click)="months[i * 3 + j].epoc <= maxDateTime && months[i * 3 + j].epoc >= minDateTime ? selectedChange(months[i * 3 + j].epoc) : ''"
                                        (mouseenter)="months[i * 3 + j].epoc <= maxDateTime && months[i * 3 + j].epoc >= minDateTime ? quarterPrev = row  : quarterPrev = null"
                                        (mouseleave)="quarterPrev = null"
                                    >
                                        <div class="mat-calendar-body-cell-content mat-focus-indicator" [class.mat-calendar-body-selected]="quarter.year == yearCalendar && quarter.no == row && (grid == 1 || grid == 3)" [class.mat-calendar-body-today]="thisMonthTime == months[i * 3 + j].epoc">{{months[i * 3 + j].label}}</div>
                                        <div class="mat-calendar-body-cell-preview"></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="'year'">
                <div class="mat-calendar">
                    <div class="mat-calendar-content" style="padding: 8px;">
                        <table role="grid" class="mat-calendar-table">
                            <tbody class="mat-calendar-body">
                                <tr *ngFor="let row of ui.arr(years.length / 4); index as i" role="row">
                                    <ng-container *ngFor="let grid of ui.arr(4); index as j">
                                        <td *ngIf="years[i * 4 + j]"
                                            role="gridcell" 
                                            class="mat-calendar-body-cell" 
                                            style="width: 25%; padding-top: 7.14286%; padding-bottom: 7.14286%;"
                                            tabindex="1" 
                                            [class.mat-calendar-body-active]="years[i * 4 + j] === year" 
                                            (click)="selectedChange(years[i * 4 + j])"
                                        >
                                            <!-- <button *ngIf="years[i * 4 + j]" type="button" class="mat-calendar-body-cell" tabindex="1" [class.mat-calendar-body-active]="years[i * 4 + j] === year" (click)="selectedChange(years[i * 4 + j])"> -->
                                                <div class="mat-calendar-body-cell-content mat-focus-indicator" [class.mat-calendar-body-selected]="years[i * 4 + j] === year" [class.mat-calendar-body-today]="years[i * 4 + j] === thisYear">{{years[i * 4 + j]}}</div>
                                                <div class="mat-calendar-body-cell-preview"></div>
                                            <!-- </button> -->
                                        </td>
                                        <td *ngIf="!years[i * 4 + j]"></td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-container>
            <div class="dashboard-picker__calendar-actions">
                <button mat-button class="dashboard-picker__calendar-actions__btn-secondary" (click)="clear()">Clear</button>
                <button mat-button class="dashboard-picker__calendar-actions__btn-primary" (click)="save()">Ok</button>
            </div>
        </div>
        <div class="dashboard-picker__periods" [class._show]="period_mob">
            <div class="dashboard-picker__periods-active" [ngStyle]="{'top': activePeriod}"></div>
            <button mat-button class="dashboard-picker__periods-btn" (click)="changePeriod('day')" [class._active]="period == 'day'">Day</button>
            <button mat-button class="dashboard-picker__periods-btn" (click)="changePeriod('week')" [class._active]="period == 'week'">Week</button>
            <button mat-button class="dashboard-picker__periods-btn" (click)="changePeriod('month')" [class._active]="period == 'month'">Month</button>
            <button mat-button class="dashboard-picker__periods-btn" (click)="changePeriod('quarter')" [class._active]="period == 'quarter'">Quarter</button>
            <button mat-button class="dashboard-picker__periods-btn" (click)="changePeriod('year')" [class._active]="period == 'year'">Year</button>
        </div>
    </div>
</ng-template>
