import { Injectable } from '@angular/core';
import { MongoService, AlertService, HttpService, ModalService } from 'wacom';
import { LocationService } from './location.service';
import { UserService } from './user.service';
import { PlatformService } from './platform.service'
import { Router } from '@angular/router';
import { ClosedService } from './closed.service';
import { ServiceService } from './service.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AppointmentService {
	public appointments: any = [];
	public upcoming: any = {};
	public past: any = {};
	public past_ondate:any = {};
	public past_arr: any = [];
	public all_by_client:any = {};
	public _appointments: any = {
		calendar: {},
		date: {}
	};
	private loadedSubject = new BehaviorSubject <boolean>(false);
	timeToPixel(time){
		return time / 3600000 * 80;
	}
	timeToRange(time){
		time = time / 60000;
		let h = Math.floor(time / 60);
		let m = Math.floor(time % 60);
		return (h<10&&'0'||'')+h+':'+(m<10&&'0'||'')+m;
	}
	rangeToTime(start, end){
		if(!start||!end) return 0;
		start = start.split(':');
		start[0] = Number(start[0]);
		start[1] = Number(start[1]);
		end = end.split(':');
		end[0] = Number(end[0]);
		end[1] = Number(end[1]);
		
		if(end[1]<start[1]){
			end[0]--;
			end[1]+=60;
		}
		if(end[0] < start[0]){
			end[0] += 24;
		}
		return ( (end[0]-start[0])*60 + end[1]-start[1] ) * 60000;
	}
	private replace = {
		client: (val, cb, doc)=>{
			if(doc.clients && doc.clients.length)  val = doc.clients[0];
			cb(val);
		},
		clients: (val, cb, doc)=>{
			if(!val) val = [doc.client];
			cb(val);
		},
		end: (val, cb, doc)=>{
			if(doc.duration && doc.start){
				cb(this.timeToRange(this.rangeToTime('00:00', doc.start)+(doc.duration*60000)));
			}else cb(val);
		},
		// day: (val, cb, doc)=>{
		// 	if(!val){
		// 		val = {
		// 			dateRange: false,
		// 			isRange: false,
		// 			singleDate: {
		// 				date: {year: 2020, month: 8, day: 17},
		// 				epoc: 1597611600,
		// 				formatted: "8/17/2020",
		// 				jsDate: "2020-08-16T21:00:00.000Z"
		// 			}
		// 		};
		// 	}
		// 	// val.singleDate.jsDate = new Date(val.singleDate.jsDate);
		// 	// if(doc.start&&doc.end) val.singleDate.jsDate.setHours(Number(doc.start.split(':')[0]), Number(doc.start.split(':')[1]));
		// 	cb(val);
		// },
		top: (val, cb, doc)=>{
			cb(this.timeToPixel(this.rangeToTime('00:00', doc.start)));
		},
		height: (val, cb, doc)=>{
			cb(this.timeToPixel(this.rangeToTime(doc.start, doc.end)));
		},
		orig_top: (val, cb, doc)=>{
			cb(doc.top);
		},
		orig_height: (val, cb, doc)=>{
			cb(doc.height);
		}
	};
	public now = new Date().getTime();
	refresh(){
		this.now = new Date().getTime();
	}
	constructor(
		private alert: AlertService, 
		private mongo: MongoService, 
		private us: UserService, 
		private loc: LocationService, 
		public http: HttpService, 
		public modal: ModalService, 
		private ps: PlatformService, 
		private router: Router,
		private cs: ClosedService,
		private ss: ServiceService
		) { 
			// this.mongo.config('appointment', {
			// 	replace: this.replace,
			// 	groups: {
			// 		calendar: {
			// 			field: function(doc, cb){
			// 				if(doc.day){
			// 					cb(doc.client+doc.day.singleDate.formatted);
			// 					cb(doc.user+doc.day.singleDate.formatted);
			// 				}
			// 			}
			// 		},
			// 		upcoming: {
			// 			field: function(doc){
			// 				return doc.client;
			// 			},
			// 			allow: function(doc){
			// 				if(typeof doc.day.singleDate.jsDate != 'object'){
			// 					doc.day.singleDate.jsDate = new Date(doc.day.singleDate.jsDate);
			// 				}
			// 				return doc.day.singleDate.jsDate.getTime() >= Date.now();
			// 			},
			// 			sort: this.mongo.sortAscId()
			// 		},
			// 		past: {
			// 			field: function(doc){
			// 				return doc.client;
			// 			},
			// 			allow: function(doc){
			// 				if(typeof doc.day.singleDate.jsDate != 'object'){
			// 					doc.day.singleDate.jsDate = new Date(doc.day.singleDate.jsDate);
			// 				}
			// 				return doc.day.singleDate.jsDate.getTime() < Date.now();
			// 			},
			// 			sort: this.mongo.sortDescId()
			// 		},
			// 		past_ondate: {
			// 			field: function(doc){
			// 				return doc.client + doc.day.singleDate.formatted;
			// 			},
			// 			allow: function(doc){
			// 				if(typeof doc.day.singleDate.jsDate != 'object'){
			// 					doc.day.singleDate.jsDate = new Date(doc.day.singleDate.jsDate);
			// 				}
			// 				return doc.day.singleDate.jsDate.getTime() < Date.now();
			// 			},
			// 			sort: this.mongo.sortDescId()
			// 		},
			// 		all_by_client: {
			// 			field: function(doc){
			// 				return doc.client;
			// 			},
			// 			sort: this.mongo.sortDescId()
			// 		},
			// 		date: {
			// 			field: function(doc, cb){
			// 				if(doc.day){
			// 					cb(doc.day.singleDate.formatted);
			// 				}
			// 			}
			// 		}
			// 	}, query: {
			// 		past_arr: function(doc){
			// 			if(typeof doc.day.singleDate.jsDate != 'object'){
			// 				doc.day.singleDate.jsDate = new Date(doc.day.singleDate.jsDate);
			// 			}
			// 			return  doc.day.singleDate.jsDate.getTime() < Date.now();
			// 		}
			// 	}
			// });
			this.getAppointments();
		}
	getAppointments(resp:any=event=>{}) {
		this.appointments = this.mongo.get('appointment', {
			replace: this.replace,
			groups: {
				calendar: {
					field: function(doc, cb){
						if(doc.day){
							cb(doc.client+doc.day.singleDate.formatted);
							cb(doc.user+doc.day.singleDate.formatted);
						}
					}
				},
				upcoming: {
					field: function(doc){
						return doc.client;
					},
					allow: function(doc){
						if(typeof doc.day.singleDate.jsDate != 'object'){
							doc.day.singleDate.jsDate = new Date(doc.day.singleDate.jsDate);
						}
						return doc.day.singleDate.jsDate.getTime() > Date.now();
					},
					sort: this.mongo.sortAscId()
				},
				past: {
					field: function(doc){
						return doc.client;
					},
					allow: function(doc){
						if(typeof doc.day.singleDate.jsDate != 'object'){
							doc.day.singleDate.jsDate = new Date(doc.day.singleDate.jsDate);
						}
						return doc.day.singleDate.jsDate.getTime() < Date.now();
					},
					sort: this.mongo.sortDescId()
				},
				past_ondate: {
					field: function(doc){
						return doc.client + doc.day.singleDate.formatted;
					},
					allow: function(doc){
						if(typeof doc.day.singleDate.jsDate != 'object'){
							doc.day.singleDate.jsDate = new Date(doc.day.singleDate.jsDate);
						}
						return doc.day.singleDate.jsDate.getTime() < Date.now();
					},
					sort: this.mongo.sortDescId()
				},
				all_by_client: {
					field: function(doc){
						return doc.client;
					},
					sort: this.mongo.sortDescId()
				},
				date: {
					field: function(doc, cb){
						if(doc.day){
							cb(doc.day.singleDate.formatted);
						}
					}
				}
			}, query: {
				past_arr: function(doc){
					if(typeof doc.day.singleDate.jsDate != 'object'){
						doc.day.singleDate.jsDate = new Date(doc.day.singleDate.jsDate);
					}
					return  doc.day.singleDate.jsDate.getTime() < Date.now();
				}
			}
		}, (arr, obj) => {
			// console.log(arr);
			this.past_arr = obj.past_arr;
			this.upcoming = obj.upcoming;
			this.all_by_client = obj.all_by_client;
			this.past = obj.past;
			this.past_ondate = obj.past_ondate;
			this._appointments = obj;
			resp();
		});
	}
	// NOT FINISHED

	// getAllForClient(user: any, cb:any=event=>{}) {
	// 	this.http.get('/api/appointment/getclient?client='+user, resp => {
	// 		for (let i = 0; i < resp.length; i++) {
	// 			this.mongo.push('appointment', resp[i]);
	// 		}
	// 		const appointments = [...this.appointments];
	// 		for (let i = 0; i < appointments.length; i++) {
	// 			let remove = true;
	// 			for (let j = 0; j < resp.length; j++) {					
	// 				if (resp[j]._id == appointments[i]._id) {
	// 					remove = false;
	// 					break;
	// 				}
	// 			}
	// 			if (remove) {
	// 				this.mongo.remove('appointment', appointments[i]);
	// 			}
	// 		}

	// 		const {arr, obj} = this.mongo.set('appointment');
	// 		this.past_arr = obj.past_arr;
	// 		this.upcoming = obj.upcoming;
	// 		this.all_by_client = obj.all_by_client;
	// 		this.past = obj.past;
	// 		this.past_ondate = obj.past_ondate;
	// 		this._appointments = obj;
	// 		this.appointments = arr;

	// 		this.refresh();
	// 		cb();
	// 	});
	// }
	// getAll(cb:any=event=>{}) {
	// 	this.loadedSubject.next(false);
	// 	this.http.get('/api/appointment/get', resp => {
	// 		console.log(resp);
			
	// 		for (let i = 0; i < resp.length; i++) {
	// 			this.mongo.push('appointment', resp[i]);
	// 		}
	// 		const appointments = [...this.appointments];
	// 		for (let i = 0; i < appointments.length; i++) {
	// 			let remove = true;
	// 			for (let j = 0; j < resp.length; j++) {
	// 				if (resp[j]._id == appointments[i]._id) {
	// 					remove = false;
	// 					break;
	// 				}
	// 			}
	// 			if (remove) {
	// 				this.mongo.remove('appointment', appointments[i]);
	// 			}
	// 		}

	// 		const {arr, obj} = this.mongo.set('appointment');
	// 		this.past_arr = obj.past_arr;
	// 		this.upcoming = obj.upcoming;
	// 		this.all_by_client = obj.all_by_client;
	// 		this.past = obj.past;
	// 		this.past_ondate = obj.past_ondate;
	// 		this._appointments = obj;
	// 		this.appointments = arr;

	// 		this.loadedSubject.next(true);
	// 		this.refresh();
	// 		cb();
	// 	});
	// }
	doc(appointmentId){
		if(!this._appointments[appointmentId]){
			this._appointments[appointmentId] = this.mongo.fetch('appointment', {
				query: {
					_id: appointmentId
				}
			});
		}
		return this._appointments[appointmentId];
	}
	create(appointment, cb:any=resp=>{}, show_alert=true) {
		if(appointment._id) return this.save(appointment, cb);
		if (this.timeConflictCheck(appointment)) {
			appointment.timezone = new Date(Date.now()).getTimezoneOffset();
			this.replace.end(appointment.end, (end)=>{
				appointment.end = end;
			}, appointment);
			appointment.changedStatusManually = (appointment.status == 'Canceled' || appointment.status == 'Confirmed');
			appointment.price = this.ss._services[appointment.service]?.price;
			this.mongo.create('appointment', appointment, (result)=>{
				this.refresh();
				cb(result);
				this.http.post('/api/appointment/send_confirmation', result, resp => {
					if(resp && show_alert) this.alert.show({text: 'Confirmation letter was sent to the customer'});
				});	
				if (show_alert) this.alert.show({
					text: 'Appointment has been created.'
				});
			}, { allow_multiple: true });
		}
	}
	update(appointment, cb:any=resp=>{}) {
		this.mongo.afterWhile(appointment, ()=> {
			this.save(appointment, cb);
		});
	}
	save(appointment, cb:any=resp=>{}) {
		let ask = false;
		if (this.timeConflictCheck(appointment)) {
			this.replace.end(appointment.end, end=>{
				appointment.end = end;
			}, appointment);
			this.replace.top(appointment.top, top=>{
				this._appointments[appointment._id].top = top;
				appointment.top = top;
			}, appointment);
			this.replace.height(appointment.height, height=>{
				this._appointments[appointment._id].height = height;
				appointment.height = height;
			}, appointment);
			if(appointment.clients && appointment.clients.length)  appointment.client = appointment.clients[0];
			if(appointment.top != appointment.orig_top || appointment.height != appointment.orig_height){
				/*
				this.alert.show({
					component: 'appointment',
					undo: ()=>{
						appointment.top = appointment.orig_top;
						appointment.height = appointment.orig_height;
					}
				});
				*/
			}
			appointment.changedStatusManually = (appointment.status == 'Canceled' || appointment.status == 'Confirmed')
			appointment.price = this.ss._services[appointment.service]?.price;
			this.mongo.update('appointment', appointment, {
				replace: this.replace
			}, ()=> {
				cb()
				this.refresh();
			});
		} else cb(false);
	}
	delete(appointment, cb:any=resp=>{}, cd_delete:any=resp=>{}) {
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({
			component: 'deleteAlert',
			title: 'Delete appointment',
			body: `Are you sure you want to delete appointment`,
			checkbox: appointment.repeat,
			on_add: (delete_repeat)=> {
				cd_delete();
				if(delete_repeat) {
					this.http.post('/api/appointment/delete_repeatable', appointment, resp => {
						cb();
						this.alert.show({
							text: 'Appointments has been deleted.'
						});
						this.refresh();
					});	
				} else {
					this.mongo.delete('appointment', appointment, ()=> {
						cb();
						this.alert.show({
							text: 'Appointment has been deleted.'
						});
						this.refresh();
					});
				}
				appointment.selected = false; 
				appointment.showPopup = false
			}
		})
		
	}
	setLocation(profile) {
		profile.location = undefined;
		profile.service = undefined;
		if(this.us._users[profile.user].location) {
			const locations = this.us._users[profile.user].location.map((l) => {
				const location = this.loc._locations[l];
				outerLoop: for (let i = this.cs.closeds.length-1; i >= 0; i--){
					for (let j = 0; j < this.cs.closeds[i].locations.length; j++) {
						if(
							this.cs.closeds[i].locations[j] == l && 
							(
								(
									!this.cs.closeds[i].holiday &&
									new Date(this.cs.closeds[i].start?.singleDate?.formatted) <= new Date(profile.day?.singleDate?.formatted) && 
									new Date(this.cs.closeds[i].end?.singleDate?.formatted) >= new Date(profile.day?.singleDate?.formatted)
								) ||
								(
									this.cs.closeds[i].holiday &&
									this.cs.isHoliday(new Date(profile.day?.singleDate?.formatted), this.cs.closeds[i].holiday, this.cs.closeds[i].substitute )
								)
							)
						) {
							location.closed = true;
							break outerLoop;
						} else {
							location.closed = false;
						}
					}
				}
				return location;
			});

			for (let location of locations) {
				if(!location.closed) {
					if( !profile.location ) profile.location = location._id;
					if( location._id == this.loc.primary._id ) {
						profile.location = location._id;
						break;
					}
				}
			}
		}
	}
	confirm(appointment) {
		this.http.post('/api/appointment/send_confirmation', appointment, resp => {
			if(resp) this.alert.show({text: 'Confirmation letter was sent to the customer'});
			this.refresh.bind(this)
		});	
	}
	mark(appointment) {
		appointment.history.push({
			user: this.us._id,
			status: 'arrived'
		});
		this.mongo.update('appointment', appointment, {
			name: 'arrived'
		}, updated=>{
			this.alert.show({text: "This client has been marked Arrived"});
			this.refresh();
		});
	}
	cancel(appointment) {
		appointment.history.push({
			user: this.us._id,
			status: 'canceled'
		});
		this.mongo.update('appointment', appointment, {
			name: 'cancel'
		}, updated=>{
			this.alert.show({text: "You've canceled the appointment"});
			this.refresh.bind(this);
		});
	}
	reschedule(appointment, cb:any=resp=>{}) {
		if (this.timeConflictCheck(appointment)) {
			this.replace.end(appointment.end, end=>{
				appointment.end = end;
			}, appointment);
			this.mongo.update('appointment', appointment, {
				name: 'reschedule'
			}, updated=>{
				cb();
				this.alert.show({text: "Appointment successfully rescheduled"});
				this.refresh();
			});
		}
	}
	rebook(appointment, repeat, edit=true, cb:any=resp=>{}) {
		if(repeat.enable){
			if(edit) {
				this.http.post('/api/appointment/rebook', {appointment: appointment, repeat: repeat, date: Date.now()}, resp => {
					this.rebookAppointments(appointment, repeat, edit, cb)
				});
			} else {
				this.rebookAppointments(appointment, repeat, edit, cb)
			}
		}
	}
	rebookAppointments(appointment, repeat, edit, cb:any=resp=>{}) {
		let new_appointment = JSON.parse(JSON.stringify(appointment))
		new_appointment.day.singleDate.jsDate = new Date(new_appointment.day.singleDate.jsDate);
		if(!new_appointment.parent) new_appointment.parent = new_appointment._id;
		new_appointment.status = 'New';
		new_appointment.sent = false;
		delete new_appointment._id;
		if(repeat.kind.name == 'Daily'){
			for (let i = 0; i < repeat.count; i++){
				new_appointment.day.singleDate.jsDate = new Date(new_appointment.day.singleDate.jsDate.getTime()+86400000);
				new_appointment.day.singleDate.epoc = new_appointment.day.singleDate.jsDate.getTime();
				new_appointment.day.singleDate.date = {
					year: new_appointment.day.singleDate.jsDate.getFullYear(),
					month: new_appointment.day.singleDate.jsDate.getMonth()+1,
					day: new_appointment.day.singleDate.jsDate.getDate()
				};
				new_appointment.day.singleDate.formatted = (new_appointment.day.singleDate.jsDate.getMonth()+1)+'/'+new_appointment.day.singleDate.jsDate.getDate()+'/'+new_appointment.day.singleDate.jsDate.getFullYear()
				this.create(new_appointment, ()=> {
					if(i == repeat.count - 1) cb();
				}, false);
			}
		}else if(repeat.kind.name == 'Weekly'){
			for (let i = 0; i < repeat.count; i++){
				new_appointment.day.singleDate.jsDate = new Date(new_appointment.day.singleDate.jsDate.getTime()+604800000);
				new_appointment.day.singleDate.epoc = new_appointment.day.singleDate.jsDate.getTime();
				new_appointment.day.singleDate.date = {
					year: new_appointment.day.singleDate.jsDate.getFullYear(),
					month: new_appointment.day.singleDate.jsDate.getMonth()+1,
					day: new_appointment.day.singleDate.jsDate.getDate()
				};
				new_appointment.day.singleDate.formatted = (new_appointment.day.singleDate.jsDate.getMonth()+1)+'/'+new_appointment.day.singleDate.jsDate.getDate()+'/'+new_appointment.day.singleDate.jsDate.getFullYear()
				this.create(new_appointment, ()=> {
					if(i == repeat.count - 1) cb();
				}, false);
			}
		}else if(repeat.kind.name == 'Monthly'){
			for (let i = 0; i < repeat.count; i++){
				new_appointment.day.singleDate.date.month++;
				if(new_appointment.day.singleDate.date.month>11){
					new_appointment.day.singleDate.date.month-=12;
					new_appointment.day.singleDate.date.year++;
				}
				new_appointment.day.singleDate.jsDate = new Date(new_appointment.day.singleDate.date.year, new_appointment.day.singleDate.date.month, new_appointment.day.singleDate.date.day);
				new_appointment.day.singleDate.epoc = new_appointment.day.singleDate.jsDate.getTime();
				new_appointment.day.singleDate.formatted = (new_appointment.day.singleDate.date.month)+'/'+new_appointment.day.singleDate.date.day+'/'+new_appointment.day.singleDate.date.year
				this.create(new_appointment, ()=> {
					if(i == repeat.count - 1) cb();
				}, false);
			}
		}else if(repeat.kind.name == 'Yearly'){
			for (let i = 0; i < repeat.count; i++){
				new_appointment.day.singleDate.date.year++;
				new_appointment.day.singleDate.jsDate = new Date(new_appointment.day.singleDate.date.year, new_appointment.day.singleDate.date.month, new_appointment.day.singleDate.date.day);
				new_appointment.day.singleDate.epoc = new_appointment.day.singleDate.jsDate.getTime();
				new_appointment.day.singleDate.formatted = (new_appointment.day.singleDate.date.month)+'/'+new_appointment.day.singleDate.date.day+'/'+new_appointment.day.singleDate.date.year
				this.create(new_appointment, ()=> {
					if(i == repeat.count - 1) cb();
				}, false);
			}
		}
		this.refresh.bind(this)
	}
	pre_set(profile, model){
		profile.selected = false;
		if (profile._id) { return; }
		if(!profile.start) profile.start = '08:00';
		if(!profile.day){
			profile.day = {...model};
		}
		profile.day.dateRange = false;
		profile.day.singleDate.jsDate = new Date(profile.day.singleDate.jsDate);
		profile.day.singleDate.epoc = profile.day.singleDate.jsDate.getTime();
		profile.day.singleDate.date = {
			year: profile.day.singleDate.jsDate.getFullYear(),
			month: profile.day.singleDate.jsDate.getMonth()+1,
			day: profile.day.singleDate.jsDate.getDate()
		};
	}
	timeConflictCheck(appointment) {
		if (this.ps.platform.data.block_booking) {
			var time_conflict = false;
			var appts = this._appointments.calendar[appointment.user+appointment.day.singleDate.formatted];
			if (appts?.length) {
				appts.filter(a => {return a._id && a._id != appointment._id});
				appts.sort((a, b) => {
					var aD = new Date();
					var bD = new Date();
					aD.setHours(a.start.split(':')[0], a.start.split(':')[1]);
					bD.setHours(b.start.split(':')[0], b.start.split(':')[1]);
					return aD.getTime() - bD.getTime();
				});
				const date = new Date(appointment.day.singleDate.jsDate);
				const start = new Date(date);
				start.setHours(appointment.start.split(':')[0]);
				start.setMinutes(appointment.start.split(':')[1]);
				var end_time = String(this.timeToRange(this.rangeToTime('00:00', appointment.start)+(appointment.duration*60000)));
				const end = new Date(date);
				end.setHours(Number(end_time.split(':')[0]));
				end.setMinutes(Number(end_time.split(':')[1]));
				var prev_appt;
				var next_appt;
				for (let a of appts) {
					date.setHours(a.start.split(':')[0]);
					date.setMinutes(a.start.split(':')[1]);
					if (date <= start && a._id != appointment._id) prev_appt = a;
					if (date >= start && !next_appt && a._id != appointment._id) next_appt = a;
				}
				if (prev_appt) {
					date.setHours(prev_appt.end.split(':')[0]);
					date.setMinutes(prev_appt.end.split(':')[1]);
					if (start < date) {
						if (!time_conflict) time_conflict = true;
					}
				}
				if (next_appt) {
					date.setHours(next_appt.start.split(':')[0]);
					date.setMinutes(next_appt.start.split(':')[1]);
					if (end > date) {
						if (!time_conflict) time_conflict = true;
					}
				}
				if (time_conflict) {
					this.alert.destroy();
					this.alert.show({
						text: 'Time conflict! Please change the start time.',
						timeout: 5000,
						class: 'myClass',
						type: 'error',
						closable: true
					});
					return false;
				} else return true;
			} else return true;
		} else return true;
	}
	// loaded(cb:any=event=>{}) {
	// 	if(this.loadedSubject.getValue() === true) {			
	// 		if(typeof cb === 'function') cb(true);
	// 	} else {
	// 		this.loadedSubject.subscribe((state: boolean) => {
	// 			if(state === true) {
	// 				if(typeof cb === 'function') cb(true);
	// 			}
	// 		});
	// 	}
	// }
}
