<div class="containerBox setup">
	<div class="setup-empty setup-box" *ngIf="!rs.reasons.length">
		<div class="setup-empty__img"><img src="assets/img/setup/cancel.png" alt="discount" draggable="false"/></div>
		<div class="setup-empty__title">Please add cancellation reason</div>
		<div class="setup-empty__btn">
			<button mat-flat-button color="primary" class="waw-btn _primary" (click)="openModal()">
				<span class="icon-plus"></span>
				<span>Add reason</span>
			</button>
		</div>
	</div>
	<div class="setup-container" *ngIf="rs.reasons.length">
		<div class="setup-item setup-box" *ngFor="let reason of rs.reasons">
			<div class="setup-item__title">{{reason.name}}</div>
			<div class="setup-item__actions">
				<button mat-icon-button color="primary" class="action-edit" (click)="openModal(reason);"><span class="icon-pencil"></span></button>
				<button mat-icon-button color="warn" class="action-delete" (click)="delete(reason);"><span class="icon-delete"></span></button>
			</div>
		</div>
		<div class="setup-add">
			<button mat-flat-button color="primary" class="waw-btn _primary" (click)="openModal()">
				<span class="icon-plus"></span>
				<span>Add reason</span>
			</button>
		</div>
	</div>
</div>