import { Component, OnInit, Input, OnChanges, ElementRef } from '@angular/core';
import { UserService, LocationService, AppointmentService, CheckoutService, ServiceService, ClosedService, ProductService, LoaderService } from '@services';
import { AppointmentsDatePipe } from '@pipes';
import { DatePipe } from '@angular/common';
import { ModalService, MongoService, UiService } from 'wacom';
import { ClientComponent } from 'src/app/modals/client/client.component';
import { Router } from '@angular/router';

@Component({
	selector: 'app-dashboard-overview',
	templateUrl: './dashboard-overview.component.html',
	styleUrls: ['./dashboard-overview.component.scss', '../dashboard.component.scss']
})
export class DashboardOverviewComponent implements OnInit, OnChanges {

	@Input() selector: any = {
		start: new Date(),
		end: new Date()
	};
	@Input() interval: string = 'day';
	@Input() location: any = null;

	public open_service: any = {};

	public top_services: any = [];
	
	public open_product: any = {};

	public top_products: any = [];

	public new_clients: any = [];

	public new_clients_columns: number = 1;

	public occupancy: any = {
		working_hours: 0,
		booked_hours: 0,
		unbooked_hours: 0,
		percentage: 0,
		value: '0%',
		difference: '0%',
	};

	public differenceAppts: string = '0%';

	public chartAppts: any = [];

	public revenue: string = '0';

	public chartRevenue: any = [];

	public clients: any = {
		total: 0,
		difference: '0%',
		new: 0,
		returning: 0,
	};

	public topClientsColumns: any = ['client', 'appointments', 'revenue'];

	public topClientsRows: any = [];

	public topStaff: any = [];

	public afterViewInit: boolean = false;

	private informationLoaded: any = {
		checks: false,
		newClients: false,
		occupancy: false,
		differenceAppts: false,
		chartAppts: false,
		clients: false,
		topClients: false,
		topServices: false,
		topProducts: false,
		revenue: false,
		chartRevenue: false,
		topStaff: false,
	};

	constructor(
		public us: UserService, 
		public loc: LocationService, 
		public aps: AppointmentService, 
		private appointmentsDatePipe: AppointmentsDatePipe,
		public cs: CheckoutService,
		public ss: ServiceService,
		private datePipe: DatePipe,
		private mongo: MongoService,
		private cls: ClosedService,
		public ps: ProductService,
		public ui: UiService,
		public modal: ModalService,
		private router: Router,
		private loader: LoaderService,
		private eref: ElementRef
	) {}

	ngOnInit(): void {
		if (!this.loader.isLoaderShowing) this.loader.show({container: true}, this.eref.nativeElement.closest('.containerTab'));

		this.initializeChecks(() => {
			this.mongo.on('user appointment service', () => {
				this.initializeTopClients();
				this.top_services = [];
				this.top_services = this.getTopServices();
			});
			this.mongo.on('product', () => {
				this.top_products = [];
				this.top_products = this.getTopProducts();
			});
			this.mongo.on('product', () => {
				this.initializeRevenue();
				this.initializeChartRevenue();
			});
		});
		this.mongo.on('user', () => {
			this.initializeNewClients();
		});
		this.mongo.on('appointment', () => {
			this.initializeDifferenceAppts();
			this.initializeChartAppts();
			this.initializeClients();
		});
		this.mongo.on('user appointment location closed', () => {
			this.initializeOccupancy();
			this.initializeTopStaff();
		});
	}

	ngOnChanges() {
		if(this.afterViewInit) {
			if (!this.loader.isLoaderShowing) this.loader.show({container: true, transparent: true}, this.eref.nativeElement.closest('.containerTab'));

			this.informationLoaded = {
				checks: false,
				newClients: false,
				occupancy: false,
				differenceAppts: false,
				chartAppts: false,
				clients: false,
				topClients: false,
				topServices: false,
				topProducts: false,
				revenue: false,
				chartRevenue: false,
				topStaff: false,
			};
	
			let waitForLoader = setInterval(() => {
				if (this.loader.isLoaderShowing) {
					clearInterval(waitForLoader);
					this.refresh();
				}
			}, 10);
		}
	}

	ngAfterViewInit() {
		this.mongo.on('user appointment location closed service product', () => {
			this.cs.loaded(() => {
				this.afterViewInit = true;
				let waitForInformationLoading = setInterval(() => {
					if (Object.values(this.informationLoaded).every(value => value === true)) {
						clearInterval(waitForInformationLoading);
						this.loader.remove();
					}
				}, 1);
			});
		});
	}

	initializeChecks(cb:any=event=>{}) {
		this.cs.getChecks(this.interval, this.selector, this.location).then(resp => {
			this.informationLoaded.checks = true;
			if(typeof cb === 'function') cb(true);
		}).catch(error => {
			console.error('Error:', error);
		});
	}
			
	initializeDifferenceAppts() {
		const previous: any = {};
		switch(this.interval) {
			case 'day':
				let date = new Date(this.selector.start);
				date.setDate(new Date(this.selector.start).getDate() - 1);
				previous.start = date;
				previous.end = date;
				break;
			case 'week':
				let weekStart = new Date(this.selector.start);
				weekStart.setDate(this.selector.start.getDate() - 7);
				let weekEnd = new Date(this.selector.end);
				weekEnd.setDate(this.selector.end.getDate() - 7);
				previous.start = weekStart;
				previous.end = weekEnd;
				break;
			case 'month':
				let monthStart = new Date(this.selector.start.getFullYear(), this.selector.start.getMonth() - 1, 1);
				let monthEnd = new Date(this.selector.end.getFullYear(), this.selector.end.getMonth(), 0);
				previous.start = monthStart;
				previous.end = monthEnd;
				break;
			case 'year':
				let yearStart = new Date(this.selector.start.getFullYear() - 1, 0, 1);
				let yearEnd = new Date(this.selector.end.getFullYear() - 1, 11, 31);
				previous.start = yearStart;
				previous.end = yearEnd;
				break;
			case 'quarter':
				let quarterStart = new Date(this.selector.start.getFullYear(), this.selector.start.getMonth() - 3, 1);
				let quarterEnd = new Date(this.selector.start.getFullYear(), this.selector.start.getMonth(), 0);
				previous.start = quarterStart;
				previous.end = quarterEnd;
				break;
		}
		const currentAppts = this.appointmentsDatePipe.transform(this.aps._appointments.date, this.selector, this.location).length;
		const previousAppts = this.appointmentsDatePipe.transform(this.aps._appointments.date, previous, this.location).length;
		if (previousAppts) {
			const percentageChange = ((currentAppts - previousAppts) / previousAppts) * 100;
			this.differenceAppts = (percentageChange > 0 ? '+' : '') + (Math.round(percentageChange * 100) / 100).toString() + '%';
		} else if(currentAppts){
			this.differenceAppts = '+100%';
		} else {
			this.differenceAppts = '0%';
		}
		this.informationLoaded.differenceAppts = true;
	}

	initializeChartAppts() {
		const appts = [];

		if(this.appointmentsDatePipe.transform(this.aps._appointments.date, this.selector, this.location).length) {
			switch(this.interval) {
				case 'day':
					for(let i = 0; i < 24; i++) {
						let buf = 0;
						this.appointmentsDatePipe.transform(this.aps._appointments.date, this.selector, this.location).forEach((obj) => {
							const start = obj.start.split(':')[0];
							const end = obj.end.split(':')[0];
							if (start == i) {
							// if (start == i || end == i || (start <= i && end >= i)) {
								buf++;
							}
						});
						appts.push(buf);
					}
					break;
				case 'week':
					if(this.appointmentsDatePipe.transform(this.aps._appointments.date, this.selector, this.location).length) {
						for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
							appts.push(this.appointmentsDatePipe.transform(this.aps._appointments.date, {start: d}, this.location)?.length)
						}
					}
					break;
				case 'month':
					if(this.appointmentsDatePipe.transform(this.aps._appointments.date, this.selector, this.location).length) {
						for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
							appts.push(this.appointmentsDatePipe.transform(this.aps._appointments.date, {start: d}, this.location)?.length)
						}
					}
					break;
				case 'year':
					if(this.appointmentsDatePipe.transform(this.aps._appointments.date, this.selector, this.location).length) {
						for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setMonth(d.getMonth() + 1)) {
							let end = new Date(d.getFullYear(), d.getMonth() + 1, 0);
							appts.push(this.appointmentsDatePipe.transform(this.aps._appointments.date, {start: d, end: end}, this.location)?.length)
						}
					}
					break;
				case 'quarter':
					if(this.appointmentsDatePipe.transform(this.aps._appointments.date, this.selector, this.location).length) {
						for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 7)) {
							let end = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 6);
							if(end > this.selector.end) end = new Date(this.selector.end);
							appts.push(this.appointmentsDatePipe.transform(this.aps._appointments.date, {start: d, end: end}, this.location)?.length)
						}
					}
					break;
			}
		}

		this.chartAppts = this.generateLineChart(appts);
		this.informationLoaded.chartAppts = true;
	}

	generateLineChart(dataSet) {
		const svgWidth = 75;
		const svgHeight = 65;
		const items = [...dataSet];
		const maxValue = items.sort((a, b) => b - a)[0];
		const minValue = items.sort((a, b) => a - b)[0];
		const length = items.length;
		const list = dataSet.map((value, index) => {
		  	const newValue = Math.floor(((value - minValue) / (maxValue - minValue)) * 100 * 100) / 100;
	
			const adjustedYValue = Math.floor((newValue * svgHeight) / 100);
			const adjustedXValue = Math.floor(index * (svgWidth / (length + 1)));
			return adjustedXValue + ' , ' + adjustedYValue;
		});
		return [...list];
	}
	
	refresh() {		
		this.initializeChecks(() => {
			this.top_services = [];
			this.top_services = this.getTopServices();
			this.top_products = [];
			this.top_products = this.getTopProducts();
			this.initializeRevenue();
			this.initializeChartRevenue();
			this.initializeTopClients();
		});
		this.initializeNewClients();
		this.initializeOccupancy();
		this.initializeDifferenceAppts();
		this.initializeChartAppts();
		this.initializeClients();
		this.initializeTopStaff();
		
		let waitForInformationLoading = setInterval(() => {
			if (Object.values(this.informationLoaded).every(value => value === true)) {
				clearInterval(waitForInformationLoading);
				this.refreshCharts();
				if(this.afterViewInit) this.loader.remove();
			}
		}, 1);
	}

	refreshCharts() {
		document.querySelectorAll('.chart .polyline').forEach(element => {
			if(element.classList.contains('_animation')) element.classList.remove('_animation');
			setTimeout(() => {
				element.classList.add('_animation');
			}, 10);
		});
		
		document.querySelectorAll('.chart .line').forEach(element => {
			if(element.classList.contains('_animation')) element.classList.remove('_animation');
			setTimeout(() => {
				element.classList.add('_animation');
			}, 10);
		});

		document.querySelectorAll('.circular-chart .circle').forEach(element => {
			if(element.classList.contains('_animation')) element.classList.remove('_animation');
			setTimeout(() => {
				element.classList.add('_animation');
			}, 10);
		});

		document.querySelectorAll('.horizontal-chart .horizontal-chart-body__stripe').forEach(element => {
			if(element.classList.contains('_animation')) element.classList.remove('_animation');
			setTimeout(() => {
				element.classList.add('_animation');
			}, 5);
		});
	}

	addMoneyAbbreviation(amount) {
		amount = Number(amount);
		const abbreviations = ['', 'K', 'M', 'B', 'T'];
		let index = 0;
	  
		while (amount >= 1000 && index < abbreviations.length - 1) {
			amount /= 1000;
			index++;
		}
	  
		return amount.toFixed(2) + abbreviations[index];
	}
	
	initializeRevenue() {
		var revenue = 0;				
		if(this.cs.checks.length) {
			for(let check of this.cs.checks) {
				revenue += check.total || 0;
			}
		}
		this.revenue = this.addMoneyAbbreviation(revenue);
		this.informationLoaded.revenue = true;
	}

	initializeChartRevenue() {
		const revenue = [];

		switch(this.interval) {
			case 'day':
				var result_d = [];
				
				for(let i = 0; i < 24; i++) {
					let buf = 0;
					this.cs._checks.hours[i]?.forEach((obj: any) => {						
						if (obj.creation_date.singleDate.date.hours == i) {
							buf += obj.total || 0;
						}
					});
					result_d.push(buf);
				}

				for (let i = 0; i < result_d.length; i+=2) {
					var sum = result_d[i] + result_d[i + 1];
					revenue.push(sum);
				}

				break;
			case 'week':
				for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
					let buf = 0;
					this.cs._checks.day[d.getDate()]?.forEach((obj: any) => {
						buf += obj.total || 0;
					});
					revenue.push(buf);
				}
				break;
			case 'month':
				var result_m = [];

				for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
					let buf = 0;
					this.cs._checks.day[d.getDate()]?.forEach((obj: any) => {
						buf += obj.total || 0;
					});
					result_m.push(buf);
				}

				for (let i = 0; i < result_m.length; i+=3) {
					var sum = result_m[i] + (result_m[i + 1] ? result_m[i + 1] : 0) + (result_m[i + 2] ? result_m[i + 2] : 0);
					revenue.push(sum);
				}
				break;
			case 'year':
				for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setMonth(d.getMonth() + 1)) {
					let buf = 0;
					this.cs._checks.month[d.getMonth() + 1]?.forEach((obj: any) => {
						buf += obj.total || 0;
					});
					revenue.push(buf);
				}
				break;
			case 'quarter':
				for (let start = new Date(this.selector.start); start <= new Date(this.selector.end); start.setDate(start.getDate() + 7)) {
					let end = new Date(start.getFullYear(), start.getMonth(), start.getDate() + 6);
					if(end > this.selector.end) end = new Date(this.selector.end);

					let buf = 0;
					
					for (let d = new Date(start); d <= new Date(end); d.setDate(d.getDate() + 1)) {						
						this.cs._checks.date[this.datePipe.transform(d, 'M/d/yyyy')]?.forEach((obj: any) => {
							buf += obj.total || 0;
						});
					}
					revenue.push(buf);
				}
				break;
		}

		this.chartRevenue = this.generateBarChart(revenue);
		this.informationLoaded.chartRevenue = true;
	}

	generateBarChart(data) {
		const items = [...data]; 
		const columnWidth = 5;
		const columnSpacing = 3;
		const svgWidth = items.length * (columnWidth + columnSpacing);
		const svgHeight = 70;
		const maxValue = Math.max(...items);
		const value = (svgHeight - (columnWidth * 2)) / maxValue;

		const res = {
			width: svgWidth,
			height: svgHeight,
			lines: []
		};
	  
		for (let i = 0; i < data.length; i++) {
			const x = (columnWidth + columnSpacing) * i;
			res.lines.push({
				x1: columnWidth + x,
				y1: svgHeight - columnWidth,
				x2: columnWidth + x,
				y2: data[i] ? (svgHeight - columnWidth) - (value * data[i]) : svgHeight - columnWidth
			})
		}
	  
		return res;
	}	  

	getTopServices() {
		const appts = this.appointmentsDatePipe.transform(this.aps._appointments.date, this.selector, this.location);
		const services = [];

		if(appts.length) {
			for(let appt of appts) {
				if(!services.find((s) => s.id == appt.service) && this.ss._services[appt.service]) {
					services.push({
						id: appt.service,
						name: this.ss._services[appt.service]?.name,
						bookings: 1,
						price_1: 0,
						price_2: 0,
						revenue: 0
					});
				} else {
					if (services[services.findIndex((s) => s.id == appt.service)]?.bookings) {
						services[services.findIndex((s) => s.id == appt.service)].bookings += 1;
					}
				}
			}
		}
		
		if(this.cs.checks.length) {
			for(let check of this.cs.checks) {
				if(services[services.findIndex((s) => s.id == this.aps._appointments[check.appointment?.id]?.service)]) {
					const service = services[services.findIndex((s) => s.id == this.aps._appointments[check.appointment?.id]?.service)];
					service.revenue = Number(service.revenue) + check.appointment?.money?.total;
					if(service.revenue % 1 !== 0) service.revenue = Number(service.revenue).toFixed(2);
					if(check.appointment?.money?.price > 0) {
						if(service.price_1 == 0 || !service.price_1) {
							service.price_1 = check.appointment?.money?.price;
						} else {
							if(!service.price_2) {
								if(service.price_1 < check.appointment?.money?.price) {
									service.price_2 = check.appointment?.money?.price;
								}
								if(service.price_1 > check.appointment?.money?.price) {
									service.price_2 = service.price_1;
									service.price_1 = check.appointment?.money?.price;
								}
							} else {
								if(service.price_1 > check.appointment?.money?.price) {
									service.price_1 = check.appointment?.money?.price;
								}
								if(service.price_2 < check.appointment?.money?.price) {
									service.price_2 = check.appointment?.money?.price;
								}
							}
						}
					}
					services[services.findIndex((s) => s.id == this.aps._appointments[check.appointment?.id]?.service)] = service;
				}
			}
		}

		services.sort((a, b) => b.bookings - a.bookings);
		services.splice(5);

		this.informationLoaded.topServices = true;
		return services;
	}

	initializeNewClients() {
		this.new_clients = [];
		this.new_clients_columns = 1;

		for(let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
			if(this.us.clients_by_date[this.datePipe.transform(d, 'shortDate')]) {
				for(let client of this.us.clients_by_date[this.datePipe.transform(d, 'shortDate')]) {
					if(!this.new_clients.find((c) => c._id == client._id)) {
						this.new_clients.push(client);
					}
				}
			}
		}

		this.new_clients_columns = Math.ceil(this.new_clients.length / 10);
		this.informationLoaded.newClients = true;
	}

	getOccupancyHours(startDate, endDate) {
		var res: any = {
			working_minutes: 0,
			booked_minutes: 0,
			unbooked_minutes: 0,
			percentage: 0
		};
		
		for(let d = new Date(startDate); d <= new Date(endDate); d.setDate(d.getDate() + 1)) {
			d.setHours(0, 0, 0, 0);
			let day = this.datePipe.transform(new Date(d), 'EEEE');
			let format = this.datePipe.transform(new Date(d), 'M/d/yyyy');
			
			for(let location of this.loc.locations) {
				if((!this.location && location.data.business_hours?.[day]?.length) || (this.location == location._id && location.data.business_hours?.[day]?.length)) {
					
					let closed = false;
					for (let i = this.cls.closeds.length-1; i >= 0; i--){
						if(
							this.cls.closeds[i].locations.find(l => l == location._id) && 
							(
								(
									!this.cls.closeds[i].holiday &&
									new Date(this.cls.closeds[i].start?.singleDate?.formatted) <= new Date(d) && 
									new Date(this.cls.closeds[i].end?.singleDate?.formatted) >= new Date(d)
								) ||
								(
									this.cls.closeds[i].holiday &&
									this.cls.isHoliday(new Date(d), this.cls.closeds[i].holiday, this.cls.closeds[i].substitute )
								)
							)
						) {							
							closed = true;
							break;
						}
					} 

					if(!closed) {							
						for(let staff of this.us.allowed_appointments) {

							if(staff.location.find(l => l == location._id)) {

								if(staff.data.working_hours?.[format]?.hours?.length && !staff.data.working_hours?.[format]?.vacation) {
									res.working_minutes += this.findIntersection(location.data.business_hours[day], staff.data.working_hours[format].hours);
								} else if(staff.data.working_hours?.default?.[day]?.length && !staff.data.working_hours?.[format]?.vacation) {
									res.working_minutes += this.findIntersection(location.data.business_hours[day], staff.data.working_hours.default[day]);
								}
							}
						}
					} 
				}
			}	
		}
		const appointments = this.appointmentsDatePipe.transform(this.aps._appointments.date, {start: startDate, end: endDate}, this.location);
		for (let appt of appointments) {
			res.booked_minutes += appt.duration;
		}

		res.unbooked_minutes = res.working_minutes - res.booked_minutes;
		res.percentage = res.booked_minutes ? Math.round(res.booked_minutes * 100 / res.working_minutes) : 0;

		return res;
	}

	initializeOccupancy() {
		switch(this.interval) {
			case 'day':
				let date = new Date(this.selector.start);
				date.setDate(new Date(this.selector.start).getDate() - 1);
				var prev = this.getOccupancyHours(date, date);
				break;
			case 'week':
				let weekStart = new Date(this.selector.start);
				weekStart.setDate(this.selector.start.getDate() - 7);
				let weekEnd = new Date(this.selector.end);
				weekEnd.setDate(this.selector.end.getDate() - 7);
				var prev = this.getOccupancyHours(weekStart, weekEnd);
				break;
			case 'month':
				let monthStart = new Date(this.selector.start.getFullYear(), this.selector.start.getMonth() - 1, 1);
				let monthEnd = new Date(this.selector.end.getFullYear(), this.selector.end.getMonth(), 0);
				var prev = this.getOccupancyHours(monthStart, monthEnd);
				break;
			case 'year':
				let yearStart = new Date(this.selector.start.getFullYear() - 1, 0, 1);
				let yearEnd = new Date(this.selector.end.getFullYear() - 1, 11, 31);
				var prev = this.getOccupancyHours(yearStart, yearEnd);
				break;
			case 'quarter':
				let quarterStart = new Date(this.selector.start.getFullYear(), this.selector.start.getMonth() - 3, 1);
				let quarterEnd = new Date(this.selector.start.getFullYear(), this.selector.start.getMonth(), 0);
				var prev = this.getOccupancyHours(quarterStart, quarterEnd);
				break;
		}
		this.occupancy = this.getOccupancyHours(this.selector.start, this.selector.end);
		this.occupancy.difference = ((this.occupancy.percentage - prev.percentage) > 0 ? '+' : '') + (Math.round(this.occupancy.percentage - prev.percentage)).toString() + '%';
		this.occupancy.value = this.occupancy.percentage + '%';
		this.informationLoaded.occupancy = true;
	}

	initializeClients() {
		let clients = {};
		let clients_prev = {};
		let total_clients = 0;
		let total_clients_prev = 0;
		let new_clients = 0;
		let returning_clients = 0;

		for(let appt of this.appointmentsDatePipe.transform(this.aps._appointments.date, this.selector, this.location)) {
			const appts = [...this.aps.all_by_client[appt.client]];
			appts.sort((a, b) => {
				let a_date = new Date(a.day.singleDate.jsDate);
				a_date.setHours(a.start.split(':')[0], a.start.split(':')[1]);
				let b_date = new Date(b.day.singleDate.jsDate);
				b_date.setHours(b.start.split(':')[0], b.start.split(':')[1]);
				return a_date.getTime() - b_date.getTime();
			});

			if(appts.findIndex(a => a._id == appt._id) == 0 && !clients[appt.client]) {
				clients[appt.client] = 'new';
			} else {
				clients[appt.client] = 'returning';
			}
		}

		total_clients = Object.keys(clients).length;
		Object.keys(clients).forEach(key => {
			const value = clients[key];
			if(value === 'new') new_clients++;
			if(value === 'returning') returning_clients++;
		});

		const previous: any = {};
		switch(this.interval) {
			case 'day':
				let date = new Date(this.selector.start);
				date.setDate(new Date(this.selector.start).getDate() - 1);
				previous.start = date;
				previous.end = date;
				break;
			case 'week':
				let weekStart = new Date(this.selector.start);
				weekStart.setDate(this.selector.start.getDate() - 7);
				let weekEnd = new Date(this.selector.end);
				weekEnd.setDate(this.selector.end.getDate() - 7);
				previous.start = weekStart;
				previous.end = weekEnd;
				break;
			case 'month':
				let monthStart = new Date(this.selector.start.getFullYear(), this.selector.start.getMonth() - 1, 1);
				let monthEnd = new Date(this.selector.end.getFullYear(), this.selector.end.getMonth(), 0);
				previous.start = monthStart;
				previous.end = monthEnd;
				break;
			case 'year':
				let yearStart = new Date(this.selector.start.getFullYear() - 1, 0, 1);
				let yearEnd = new Date(this.selector.end.getFullYear() - 1, 11, 31);
				previous.start = yearStart;
				previous.end = yearEnd;
				break;
			case 'quarter':
				let quarterStart = new Date(this.selector.start.getFullYear(), this.selector.start.getMonth() - 3, 1);
				let quarterEnd = new Date(this.selector.start.getFullYear(), this.selector.start.getMonth(), 0);
				previous.start = quarterStart;
				previous.end = quarterEnd;
				break;
		}

		for(let appt of this.appointmentsDatePipe.transform(this.aps._appointments.date, previous, this.location)) {
			const appts_prev = [...this.aps.all_by_client[appt.client]];
			appts_prev.sort((a, b) => {
				let a_date = new Date(a.day.singleDate.jsDate);
				a_date.setHours(a.start.split(':')[0], a.start.split(':')[1]);
				let b_date = new Date(b.day.singleDate.jsDate);
				b_date.setHours(b.start.split(':')[0], b.start.split(':')[1]);
				return a_date.getTime() - b_date.getTime();
			});

			if(appts_prev.findIndex(a => a._id == appt._id) == 0 && !clients_prev[appt.client]) {
				clients_prev[appt.client] = 'new';
			} else {
				clients_prev[appt.client] = 'returning';
			}
		}

		total_clients_prev = Object.keys(clients_prev).length;
		var difference = '0%';

		if (total_clients_prev) {
			const percentageChange = ((total_clients - total_clients_prev) / total_clients_prev) * 100;
			difference = (percentageChange > 0 ? '+' : '') + (Math.round(percentageChange * 100) / 100).toString() + '%';
		} else if(total_clients){
			difference = '+100%';
		} else {
			difference = '0%';
		}

		this.clients = {
			total: total_clients,
			difference: difference,
			new: new_clients,
			returning: returning_clients,
		};

		this.informationLoaded.clients = true;
	}

	getTopProducts() {
		const products = [];
		
		if(this.cs.checks.length) {
			for(let check of this.cs.checks) {
				for(let product of check.products) {
					if(this.ps._products[product.id]) {
						if(!products.find((p) => p.id == product.id)) {
							products.push({
								id: product.id,
								name: this.ps._products[product.id]?.name,
								items: 1,
								price_1: 0,
								price_2: 0,
								revenue: 0
							});
						} else {
							if (products[products.findIndex((p) => p.id == product.id)]?.items) {
								products[products.findIndex((p) => p.id == product.id)].items += 1;
							}
						}
					}
					
					if(products[products.findIndex((p) => p.id == product.id)]) {
						const item_product = products[products.findIndex((p) => p.id == product.id)];
						item_product.revenue = Number(item_product.revenue) + product?.money?.total;
						if(item_product.revenue % 1 !== 0) item_product.revenue = Number(item_product.revenue).toFixed(2);
						if(product?.money?.price > 0) {
							if(item_product.price_1 == 0 || !item_product.price_1) {
								item_product.price_1 = product?.money?.price;
							} else {
								if(!item_product.price_2) {
									if(item_product.price_1 < product?.money?.price) {
										item_product.price_2 = product?.money?.price;
									}
									if(item_product.price_1 > product?.money?.price) {
										item_product.price_2 = item_product.price_1;
										item_product.price_1 = product?.money?.price;
									}
								} else {
									if(item_product.price_1 > product?.money?.price) {
										item_product.price_1 = product?.money?.price;
									}
									if(item_product.price_2 < product?.money?.price) {
										item_product.price_2 = product?.money?.price;
									}
								}
							}
						}
						products[products.findIndex((p) => p.id == product.id)] = item_product;
					}
				}
			}
		}

		products.sort((a, b) => b.items - a.items);
		products.splice(5);

		this.informationLoaded.topProducts = true;
		return products;
	}

	initializeTopClients() {
		this.topClientsRows = [];

		for(let check of this.cs.checks) {			
			if(check.client?._id && this.us._users[check.client?._id] && check.total) {
				if(!this.topClientsRows.find(r => r.client._id == check.client._id)) {
					this.topClientsRows.push(
						{
							client: this.us._users[check.client._id],
							appointments: this.appointmentsDatePipe.transform(this.aps._appointments.date, this.selector, this.location).filter(a => a.client == check.client._id).length,
							revenue: check.total || 0
						}
					);
				} else {
					this.topClientsRows.find(r => r.client._id == check.client._id).revenue += check.total || 0;
				}
			}
		}

		this.topClientsRows.sort((a, b) => b.revenue - a.revenue);
		this.topClientsRows.splice(10);
		this.informationLoaded.topClients = true;
	}

	initializeTopStaff() {
		const staffs = [];

		for(let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
			d.setHours(0, 0, 0, 0);
			let day = this.datePipe.transform(new Date(d), 'EEEE');
			let format = this.datePipe.transform(new Date(d), 'M/d/yyyy');
			
			for(let location of this.loc.locations) {
				if((!this.location && location.data.business_hours?.[day]?.length) || (this.location == location._id && location.data.business_hours?.[day]?.length)) {
					
					let closed = false;
					for (let i = this.cls.closeds.length-1; i >= 0; i--){
						if(
							this.cls.closeds[i].locations.find(l => l == location._id) && 
							(
								(
									!this.cls.closeds[i].holiday &&
									new Date(this.cls.closeds[i].start?.singleDate?.formatted) <= new Date(d) && 
									new Date(this.cls.closeds[i].end?.singleDate?.formatted) >= new Date(d)
								) ||
								(
									this.cls.closeds[i].holiday &&
									this.cls.isHoliday(new Date(d), this.cls.closeds[i].holiday, this.cls.closeds[i].substitute )
								)
							)
						) {			
							closed = true;
							break;
						}
					}

					if(!closed) {							
						for(let staff of this.us.allowed_appointments) {

							if(staff.location.find(l => l == location._id)) {
								let working_minutes = 0;

								if(staff.data.working_hours?.[format]?.hours?.length && !staff.data.working_hours?.[format]?.vacation) {
									working_minutes += this.findIntersection(location.data.business_hours[day], staff.data.working_hours[format].hours);
								} else if(staff.data.working_hours?.default?.[day]?.length && !staff.data.working_hours?.[format]?.vacation) {
									working_minutes += this.findIntersection(location.data.business_hours[day], staff.data.working_hours.default[day]);
								}

								if(!staffs.find(r => r._id == staff._id)) {
									staff.occupancy = 0;
									staff.visit_minutes = 0;
									staff.working_minutes = working_minutes;
									staffs.push(staff);
								} else {
									staffs.find(r => r._id == staff._id).working_minutes += working_minutes;
								}
							}
						}
					}
				}
			}
		}

		const appointments = this.appointmentsDatePipe.transform(this.aps._appointments.date, {start: this.selector.start, end: this.selector.end}, this.location);
		for (let appt of appointments) {
			if(staffs.find(r => r._id == appt.user)) {
				staffs.find(r => r._id == appt.user).visit_minutes += appt.duration;
				if(staffs.find(r => r._id == appt.user).working_minutes > 0) {
					staffs.find(r => r._id == appt.user).occupancy = staffs.find(r => r._id == appt.user).visit_minutes ? Math.round(staffs.find(r => r._id == appt.user).visit_minutes * 100 / staffs.find(r => r._id == appt.user).working_minutes) : 0;
				} else {
					staffs.find(r => r._id == appt.user).occupancy = 0;
				}
			}
		}

		staffs.sort((a, b) => b.occupancy - a.occupancy);
		staffs.splice(10);

		this.topStaff = staffs.filter((a) => a.occupancy > 0);
		this.informationLoaded.topStaff = true;
	}

	openClient(client) {
		this.router.navigate([], { queryParams: { modal: 'open' }, queryParamsHandling: 'merge', fragment: 'tab=overview' });
		this.modal.show({component: ClientComponent, profile: client});
	}

	findIntersection(array1: any[], array2: any[]): number {
		const timeToMinutes = (time: string): number => {
			const [hours, minutes] = time.split(':').map(Number);
			return hours * 60 + minutes;
		}
		let intersectionMinutes = 0;
	
		for (const interval1 of array1) {
			const start1 = timeToMinutes(interval1.from);
			const end1 = timeToMinutes(interval1.to);
		
			for (const interval2 of array2) {
				const start2 = timeToMinutes(interval2.from);
				const end2 = timeToMinutes(interval2.to);
		
				// Calculate the intersection
				const intersectionStart = Math.max(start1, start2);
				const intersectionEnd = Math.min(end1, end2);
		
				// If there's a valid intersection, add it to the total intersection minutes
				if (intersectionStart < intersectionEnd) {
					intersectionMinutes += intersectionEnd - intersectionStart;
				}
			}
		}
	
		return intersectionMinutes;
	}
}