<!-- ===  Clients Service history Table === -->
<div class="table-container mobile-col3">
	<table class="table" [class.navShown]="!us.sidebarToggle" cellpadding="0" cellspacing="0">
		<thead>
			<tr>
				<th scope="col" class="sort" (click)="sort('nameAp')">
					<div class="ai-c">
						<span>Clients</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'nameAp'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'nameAp'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
				<th scope="col" class="sort" (click)="sort('servicesAp')">
					<div class="ai-c">
						<span>Services</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'servicesAp'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'servicesAp'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
				<th scope="col" class="sort" (click)="sort('date')">
					<div class="ai-c">
						<span>Date</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'date'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'date'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
				<th scope="col" class="sort" (click)="sort('time')">
					<div class="ai-c">
						<span>Time</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'time'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'time'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
				<th scope="col" class="sort" (click)="sort('price')">
					<div class="ai-c">
						<span>Price</span> 
						<span class="table-header__sort" [class._sortActiveDown]="sort_type.direction == 'desc' && sort_type.title == 'price'" [class._sortActiveUp]="sort_type.direction == 'asc' && sort_type.title == 'price'">
							<span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
							<span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
						</span>
					</div>
				</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let appointment of ap.past_arr | appointmentsFilter: services: date?.singleDate?.formatted: ap.now| searchByClient: searching| page: config: sort_type">
				<td data-label="Name" class="ovf-hidden" [tooltip]="us._users[appointment.client]?.name" tooltipClass="tableTooltip" tooltipShowDelay="200">
					<div class="table__user">
						<div class="table__user__img" [class._letter]="!us._users[appointment.client]?.avatarUrl">
							<picture [user]="us._users[appointment.client]"></picture>
						</div>
						<div class="table__user__info">
							<span class="table__user__info-name">{{us._users[appointment.client]?.firstName}} <wbr> {{us._users[appointment.client]?.lastName}}</span>
							<div *ngIf="us._users[appointment.client]?.deleted" class="cl-table-clients__status">(Deleted User)</div>
						</div>
					</div>
				</td>
				<td data-label="Services" class="text-elipsis" [tooltip]="ss._services[appointment.service]?.name" tooltipClass="tableTooltip" tooltipShowDelay="200">{{ss._services[appointment.service]?.name}}</td>
				<td data-label="Date" class="text-elipsis" [tooltip]="appointment.day?.singleDate?.jsDate | date: 'EE, MMM d, y'" tooltipClass="tableTooltip" tooltipShowDelay="200">{{appointment.day?.singleDate?.formatted || ''}}</td>
				<td data-label="Time" class="text-elipsis" [tooltip]="timeFormatting(appointment.start) + ' - ' + timeFormatting(appointment.end)" tooltipClass="tableTooltip" tooltipShowDelay="200">{{timeFormatting(appointment.start)}}</td>
				<td data-label="Price" class="text-elipsis" [tooltip]="appointment.price|currency" tooltipClass="tableTooltip" tooltipShowDelay="200">{{appointment.price|currency}}</td>
			</tr>
		</tbody>
	</table>
</div>
<mat-paginator [length]="(ap.past_arr | appointmentsFilter: services: date?.singleDate?.formatted: ap.now| searchByClient: searching)?.length" [pageSize]="config.perPage" [hidePageSize]="true" [showFirstLastButtons]="true" (page)="handlePageEvent($event)" aria-label="Select page"></mat-paginator>
