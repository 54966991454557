import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';

@Pipe({
	name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {
	transform(minutes, refresh?:any){
		if(minutes == 0) return '0min';
		const hours = Math.floor(minutes / 60);
		const remainingMinutes = minutes % 60;
	  
		const hoursText = hours > 0 ? `${hours}h` : '';
		const minutesText = remainingMinutes > 0 ? `${remainingMinutes}m` : '';
	  
		return `${hoursText} ${minutesText}`.trim();
	}
}
