import { Component, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { UserService, AlertService, LoaderService } from '@services';
import { ModalService, MongoService } from 'wacom';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-alerts',
	templateUrl: './alerts.component.html',
	styleUrls: ['./alerts.component.scss', '../setup.component.scss']
})
export class AlertsComponent implements OnInit, AfterViewInit {
	edit(profile){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({component: 'alertprofile', profile: JSON.parse(JSON.stringify(profile))})
	}
	constructor(public modal: ModalService,
		public ass: AlertService,
		public us: UserService,
		public router: Router,
		private route: ActivatedRoute,
		private loader: LoaderService,
		private mongo: MongoService,
		private eref: ElementRef) {
		this.us.headerTitle = 'Alerts';
		this.us.headerSubtitle = 'Setup';
	}
	ngOnInit() {
		this.loader.show({container: true}, this.eref.nativeElement.querySelector('.containerBox'));
	}
	ngAfterViewInit() {
		this.mongo.on('alert user', () => {
			this.loader.remove();
		});
	}
	ngDoCheck(){
		if( this.route.snapshot.queryParams['modal']){
		}
		else{
			this.modal.destroy()
		}
	}
	openModal(){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({component: 'alertprofile'})		
	}
	delete(profile) {
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({
			component: 'deleteAlert',
			title: 'Delete alert',
			body: `Are you sure you want to delete alert?`,
			on_add: ()=> {
				this.loader.show({container: true, transparent: true}, this.eref.nativeElement.querySelector('.containerBox'));
				this.ass.delete(profile, () => {
					this.loader.remove();
				});
			}
		});
	}
}
