<div class="modal-box">
	<div class="modal-title">{{profile._id&&'Edit'||'New'}} Payment Type</div>
	<button mat-icon-button type="button" class="modal-close" (click)="close()">
		<span class="modal-close__icon"></span>
	</button>
	<div class="row">
		<div class="waw-input col-1">
			<label>
				<span class="waw-input__title">payment type name</span>
				<input type="text" placeholder="Other" name="discount" [(ngModel)]="profile.name">
			</label>
		</div>
	</div>
	<div class="modal-btn">
		<button mat-button color="primary" type="button" *ngIf="!profile._id" (click)="close()" class="waw-btn _second">Cancel</button>
		<button mat-button color="primary" type="button" *ngIf="profile._id" (click)="delete()" class="waw-btn _second _delete">Delete</button>
		<button mat-flat-button color="primary" type="submit" class="waw-btn _primary" (click)="ps.create(profile); close();">{{profile._id&&'Save'||'Create'}}</button>
	</div>
</div>
