import { Injectable } from '@angular/core';
import { MongoService, AlertService as Alert } from 'wacom';

@Injectable({
	providedIn: 'root'
})
export class AlertService {
	public alerts: any = [];
	public _alerts: any = {};
	public by_client: any = {};
	constructor(private alert: Alert,private mongo: MongoService) { 
		this.alerts = mongo.get('alert', {
			groups: 'client'
		}, (arr, obj) => {
			this._alerts = obj;
			this.by_client = obj.client;
		});
	}
	create(alert, cb: any = () => {}) {
		if(alert._id) return this.update(alert, cb);
		this.mongo.create('alert', alert, (created)=>{
			if(typeof cb === 'function') cb(created);
			this.alert.show({
				text: 'Alert has been created.'
			});
		});
	}
	update(alert, cb: any = () => {}) {
		this.mongo.afterWhile(alert, ()=> {
			this.mongo.update('alert', alert, (updated) => {
				if(typeof cb === 'function') cb(updated);
			});
		});
	}
	delete(alert, cb: any = () => {}) {
		this.mongo.delete('alert', alert, (deleted) => {
			if(typeof cb === 'function') cb(deleted);
		});
	}
}
