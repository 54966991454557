import { Component, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { UserService, DiscountService, LoaderService } from '@services';
import { ModalService, MongoService } from 'wacom';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-discount',
	templateUrl: './discount.component.html',
	styleUrls: ['./discount.component.scss', '../setup.component.scss']
})
export class DiscountComponent implements OnInit, AfterViewInit {
	
	edit(discount){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({component: 'discountprofile', profile: JSON.parse(JSON.stringify(discount))})
	}
	constructor(public modal: ModalService,
		public ds: DiscountService,
		public us: UserService,
		public router: Router,
		private route: ActivatedRoute,
		private loader: LoaderService,
		private mongo: MongoService,
		private eref: ElementRef) {
		this.us.headerTitle = 'Discount Types';
		this.us.headerSubtitle = 'Setup';
	}
	ngOnInit() {
		this.loader.show({container: true}, this.eref.nativeElement.querySelector('.containerBox'));
	}
	ngAfterViewInit() {
		this.mongo.on('discount', () => {
			this.loader.remove();
		});
	}
	ngDoCheck(){
		if( this.route.snapshot.queryParams['modal']){
		}
		else{
			this.modal.destroy()
		}
	}
	openModal(){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({ component: 'discountprofile'});	
	}
	delete(discount) {
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({
			component: 'deleteAlert',
			title: 'Delete discount',
			body: `Are you sure you want to delete discount ${discount.name}?`,
			on_add: ()=> {
				this.loader.show({container: true, transparent: true}, this.eref.nativeElement.querySelector('.containerBox'));
				this.ds.delete(discount, () => {
					this.loader.remove();
				});
			}
		});
	}
}
