import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalService } from 'wacom';
import { AppointmentService, HashService, NoteService, ServiceService, UserService } from '@services';
import { flyAnimation } from 'src/app/common/animation/animation.component';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
	selector: 'notes',
	templateUrl: './notes.component.html',
	styleUrls: ['./notes.component.scss'],
	animations: [flyAnimation]
})
export class NotesComponent implements OnInit, OnDestroy{
	@Output('linkEvent') linkEvent = new EventEmitter();
	constructor(public modal: ModalService, 
		public aps: AppointmentService, 
		public us: UserService,
		public note: NoteService,
		public ss: ServiceService,
		public nt: NoteService,
		private route: ActivatedRoute,
		private router: Router,
		public hash: HashService) {}
		ngOnInit(): void {
			this.us.backRoute = () => {
				this.hash.remove('notes'); 
				this.linkEvent.emit(true);
			};
		}
		ngOnDestroy(): void {
			this.us.backRoute = () => {
				this.router.navigate(['/clients']);
			};
		}
		ngDoCheck(){
			if( this.route.snapshot.queryParams['modal']){
			}
			else{
				this.modal.destroy()
			}
		}
		openModal(){
			this.router.navigate([],{ queryParams: { modal: 'open' } });
			this.modal.show({component: 'note', client: false, note: {client: this.us.user._id}})		
		}
}
