<div class="modal-box">
	<form (ngSubmit)="submit()">
		<div class="modal-title">{{profile._id&&'Edit'||'New'}} Client</div>
		<button mat-icon-button type="button" class="modal-close" (click)="close()">
			<span class="modal-close__icon"></span>
		</button>
		<div class="modal-rowImg">
			<div class="modal-rowImg__left">
				<div class="modal-img">
					<picture class="_fz-46" style="height: 158px;font-size: 35px;" [user]="profile" [big]="true"></picture>
					<label for="newClientCrop">
						<button mat-icon-button color="primary" type="button" class="img-upload waw-btn__icon primary" (click)="input.click()"><span class="icon-pencil"></span></button>
					</label>
					<input type="file" id="newClientCrop" #input (change)="fileChangeEvent($event);" [hidden]="true" accept="image/*" />
				</div>
				<div class="waw-select">
					<span class="waw-select__title">Gender</span>
					<app-select 
						placeholder="Select gender" 
						[(value)]="profile.data.gender"
						[items]="['Prefer not to say', 'Female', 'Male', 'Non-binary']" 
						[clearable]="false" 
						[searchable]="false"
					></app-select>
				</div>
			</div>
			<div class="modal-rowImg__right">
				<div class="waw-input col-1">
					<label [class.req]="submitted&&!profile.firstName">
						<span class="waw-input__title">First Name *</span>
						<input type="text" placeholder="First Name" name="firstName" [(ngModel)]="profile.firstName">
						<div *ngIf="submitted&&!profile.firstName" class="req-text">First name is required</div>
					</label>
				</div>
				<div class="waw-input col-1">
					<label [class.req]="submitted&&!profile.lastName">
						<span class="waw-input__title">Last Name *</span>
						<input type="text" placeholder="Last Name" name="lastName" [(ngModel)]="profile.lastName">
						<div *ngIf="submitted&&!profile.lastName" class="req-text">Last name is required</div>
					</label>
				</div>
				<div class="waw-datepicker col-1 _icon _arrow">
					<span class="waw-datepicker__title">Date of Birth</span>
					<datepicker [date]="profile.data.birthday" (valueChange)="profile.data.birthday=$event" [icon]="true"></datepicker>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="waw-textarea col-1">
				<div [class.req]="characterLimit">
					<note-editor [notes]="noteEditor" placeholder="Add privates notes" label="notes" size="medium" (characterLimit)="characterLimit = $event" (onChange)="noteEditor = $event"></note-editor>
					<div *ngIf="characterLimit" class="req-text mb10">{{characterLimit}}</div>
				</div>
			</div>
		</div>
		<div class="modal-section">
			<h2 class="modal-h2">Additional Info</h2>
			<p class="modal-p">Client contact details are confidential and will not be shared with third parties.</p>
			<div class="row">
				<div class="waw-input col-1">
					<label>
						<span class="waw-input__title">address</span>
						<input ngx-google-places-autocomplete autocomplete="off" placeholder="Street" [(ngModel)]="profile.data.address" name="address" (onAddressChange)="profile.data.address = us.getFormattedAddress($event, true)">
					</label>
				</div>
				<div class="waw-input col-1">
					<label [class.req]="submitted&&!profile.email">
						<span class="waw-input__title">Email *</span>
						<input type="text" placeholder="Email" name="email" [(ngModel)]="profile.email">
						<div *ngIf="submitted&&!profile.email" class="req-text">Email is required</div>
					</label>
				</div>
				<div class="waw-input col-2" [class.pb0]="profile._id">
					<label>
						<span class="waw-input__title">Phone</span>
						<input type="text" [class.mb0]="profile._id" placeholder="Phone" name="phone" [(ngModel)]="profile.data.phone">
					</label>
				</div>
				<div class="waw-input col-2" [class.pb0]="profile._id">
					<label>
						<span class="waw-input__title">Additional phone</span>
						<input type="text" [class.mb0]="profile._id" placeholder="Additional Phone" name="additionalPhone" [(ngModel)]="profile.data.additionalPhone">
					</label>
				</div>
				<ng-container *ngIf="!profile._id">
					<div class="waw-select col-1 pb0" style="margin-bottom: -10px;">
						<span class="waw-select__title">Source</span>
						<app-select 
							placeholder="Select source" 
							[(value)]="profile.data.source"
							[items]="sources" 
							[clearable]="false" 
							[searchable]="false"
						></app-select>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="modal-btn">
			<button mat-button color="primary" type="button" class="waw-btn _second" (click)="close()" *ngIf="!profile._id||!(us.is.admin||us.is.owner)">Cancel</button>
			<button mat-button color="primary" type="button" class="waw-btn _second _delete" (click)="delete();" *ngIf="profile._id&&(us.is.admin||us.is.owner)">Delete</button>
			<button mat-flat-button color="primary" type="submit" class="waw-btn _primary">Save</button>
		</div>
	</form>
</div>
