import { Component } from '@angular/core';
import { PlatformService } from '@services';
@Component({
	selector: 'notificationtemplate',
	templateUrl: './notificationtemplate.component.html',
	styleUrls: ['./notificationtemplate.component.scss']
})
export class NotificationtemplateComponent{
	public notification;
	public close = () => {};
	public edit;
	public role;
	constructor(public ps: PlatformService) {}
	save() {
		this.ps.platform.data.notification[this.role][this.edit] = this.notification;
		this.ps.save();
	}
}
