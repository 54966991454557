<div class="modal-box">
	<form (ngSubmit)="save()">
		<div class="modal-title">{{profile._id && 'Edit' || 'Add'}} Lunch</div>
		<button mat-icon-button type="button" class="modal-close" (click)="close()">
			<span class="modal-close__icon"></span>
		</button>
		<div class="row">
			<div class="waw-select col-2">
				<span class="waw-select__title">staff*</span>
				<app-select 
					placeholder="Staff" 
					[(value)]="profile.user"
					[items]="us.allowed_appointments" 
					[clearable]="false" 
					[searchable]="true"
					[required]="submitted && !profile.user"
					bindLabel="name" 
					bindValue="_id"
				></app-select>
				<div *ngIf="submitted && !profile.user" class="req-text">Staff is required</div>
			</div>
			<div class="waw-datepicker col-2" [class.req]="submitted && !profile.day">
				<span class="waw-datepicker__title">Day*</span>
				<datepicker [date]="profile.day" (valueChange)="profile.day=$event" [required]="submitted&&!profile.day"></datepicker>
				<div *ngIf="submitted && !profile.day" class="req-text">Day is required</div>
			</div>
		</div>
		<div class="row">
			<div class="col-2">
				<timepicker 
					label="Start*" 
					requiredText="Start time is required"
					[value]="profile.start" 
					[required]="submitted && !profile.start" 
					(valueChange)="profile.start = $event" 
				></timepicker>
			</div>
			<div class="waw-input col-2">
				<div [class.req]="submitted && !profile.duration">
					<span class="waw-input__title">Duration*</span>
					<durationinput [(data)]="profile.duration" (onChange)="profile.duration = $event" class="h-inp-def" id="appt" name="lunch end" [required]="true"></durationinput>
					<div *ngIf="submitted&&!profile.duration" class="req-text">Duration is required</div>
				</div>
			</div>
		</div>
		<div class="modal-btn">
			<button mat-button color="primary" type="button" *ngIf="!profile._id" (click)="close()" class="waw-btn _second">Cancel</button>
			<button mat-button color="primary" type="button" *ngIf="profile._id" (click)="delete()" class="waw-btn _second _delete">Delete</button>
			<button mat-flat-button color="primary" type="submit" class="waw-btn _primary">{{profile._id&&'Save'||'Create'}}</button>
		</div>
	</form>
</div>
