import { Pipe, PipeTransform } from '@angular/core';
import { MongoService } from 'wacom';
@Pipe({
	name: 'appointmentsDate'
})
export class AppointmentsDatePipe implements PipeTransform {
	constructor(private mongo: MongoService) {}
	transform(appointments, date, location = null, refresh?) {
		if(!appointments) return [];
		if(!date.start) date.start = new Date();
		if(!date.end) date.end = date.start;

		var res = [];
		for(let d = new Date(date.start); d <= new Date(date.end); d.setDate(d.getDate() + 1)) {
			let formatted = (d.getMonth()+1)+'/'+d.getDate()+'/'+d.getFullYear();
			if(appointments[formatted]) {
				if(location) {
					res = res.concat(appointments[formatted].filter((appt) => {
						return appt.location === location;
					}));
				} else {
					res = res.concat(appointments[formatted]);
				}
			}
		}
		return res;
	}
}
