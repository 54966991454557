import { Pipe, PipeTransform } from '@angular/core';
import { UserService, ServiceService, CategoryService, TaxService, DiscountService, AppointmentService, BrandService } from '@services';
@Pipe({
	name: 'sort'
})
export class SortPipe implements PipeTransform {
	constructor(public us: UserService, 
        public ss: ServiceService, 
        public cs: CategoryService, 
        public ts: TaxService,
        public ds: DiscountService,
        public aps: AppointmentService,
        public bs: BrandService) {}
	transform(arr: any, sort: any, now?) {
		if(!Array.isArray(arr)) return [];
		arr = arr.slice();
		if(sort.direction) {
            arr.sort((a, b)=> {        
                let new_a, new_b;
                switch(sort.title) {
                    case 'nameAp': 
                        new_a = this.us._users[a.client]?.name||'';
                        new_b = this.us._users[b.client]?.name||'';
                        break;
                    case 'servicesAp': 
                        new_a = this.ss._services[a.service]?.name||'';
                        new_b = this.ss._services[b.service]?.name||'';
                        break;
                    case 'priceAp': 
                        new_a = this.ss._services[a.service]?.price||'';
                        new_b = this.ss._services[b.service]?.price||'';
                        break;
                    case 'categoryRe': 
                        new_a = this.cs._categories[a.category]?.name||'';
                        new_b = this.cs._categories[b.category]?.name||'';
                        break;
                    case 'brandRe': 
                        new_a = this.bs._brands[a.brand]?.name||'';
                        new_b = this.bs._brands[b.brand]?.name||'';
                        break;
                    case 'taxRe': 
                        new_a = this.ts._taxes[a.tax]?.name||'';
                        new_b = this.ts._taxes[b.tax]?.name||'';
                        break;
                    case 'discountRe': 
                        new_a = this.ds._discounts[a.discount]?.name||'';
                        new_b = this.ds._discounts[b.discount]?.name||'';
                        break;
                    case 'nameCheck': 
                        new_a = a.staff?.name||'';
                        new_b = b.staff?.name||'';
                        break;
                    case 'priceCheck': 
                        new_a = this.ss._services[this.aps._appointments[a.appointment]?.service]?.price||'';
                        new_b = this.ss._services[this.aps._appointments[b.appointment]?.service]?.price||'';
                        break;
                    case 'servicesNote': 
                        new_a = this.ss._services[this.aps.doc(a.appointment)?.service]?.name||'';
                        new_b = this.ss._services[this.aps.doc(b.appointment)?.service]?.name||'';
                        break;
                    default: 
                        new_a = this.value(a, sort)||'';
                        new_b = this.value(b, sort)||'';
                }
                
                if (new_a < new_b) {
                    return (sort.direction=='desc') ? 1 : -1;
                }
                if (new_a > new_b) {
                    return (sort.direction=='desc') ? -1 : 1;
                }
                return 0;
            })
        }
		return arr;
	}
    value(data, sort) {
        return sort.title.split('.').reduce((o,i)=>{
            if(o&&o[i]) {
                return o[i]
            } else {
                return
            }
        }, data);
    }
}
