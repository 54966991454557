import { Component } from '@angular/core';
import { UserService, ResourceService } from '@services';
import { ModalService } from 'wacom';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-resources-setup',
	templateUrl: './resources-setup.component.html',
	styleUrls: ['./resources-setup.component.scss']
})
export class ResourcesSetupComponent{
	
	edit(profile){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({component: 'resource', profile: JSON.parse(JSON.stringify(profile))})
	}
	constructor(public modal: ModalService,
		public rs: ResourceService,
		public us: UserService,
		public router: Router,
		private route: ActivatedRoute) {
		this.us.headerTitle = 'Resources';
		this.us.headerSubtitle = 'Setup';
	}
	ngDoCheck(){
		if( this.route.snapshot.queryParams['modal']){
		}
		else{
			this.modal.destroy()
		}
	}
	openResource(){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({ component:'resource'})		
	}
}