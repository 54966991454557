import { Component } from '@angular/core';
import { ResourceService, UserService } from '@services';
import { ModalService } from 'wacom';
@Component({
	selector: 'resource',
	templateUrl: './resource.component.html',
	styleUrls: ['./resource.component.scss']
})
export class ResourceComponent {
	public profile: any = {};
	public close;
	constructor(public rs: ResourceService,
		public us: UserService,
		public modal: ModalService) { }
	delete() {
		this.modal.show({
			component: 'deleteAlert',
			title: 'Delete resource',
			body: `Are you sure you want to delete resource ${this.profile.name}`,
			on_add: () => {
				this.close()
				this.rs.delete(this.profile);
			}
		});
	}
}
