import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
@Pipe({
	name: 'relativeTime'
})
export class RelativeTimePipe implements PipeTransform {
    constructor(private datePipe: DatePipe) {}
	transform(value: Date, refresh?) {
        if (!(value instanceof Date))
            value = new Date(value);

        let seconds: number = Math.floor(((new Date()).getTime() - value.getTime()) / 1000);
        let interval: number = Math.floor(seconds / 31536000);

        interval = Math.floor(seconds / 3600);
        if (interval >= 24) {
            return this.datePipe.transform(value, 'short');
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
            return interval + ' hours ago';
        }
        interval = Math.floor(seconds / 3600);
        if (interval == 1) {
            return 'an hour ago';
        }
        interval = Math.floor((seconds + 5) / 60);
        if (interval > 1) {
            return interval + ' minutes ago';
        }
        interval = Math.floor((seconds + 5) / 60);
        if (interval == 1) {
            return 'a minute ago';
        }
        if (seconds > 10) {
            return 'a few seconds ago';
        }
        return 'just now';
    }
}
