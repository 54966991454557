import { AfterContentChecked, Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserService, RoleService, NotificationService, LocationService, TimeService, GroupService, ServiceService, RoomService } from '@services';
import { AlertService, CoreService, ModalService } from 'wacom';
import { modalAnimation } from 'src/app/common/animation/animation.component';
// import { io } from 'socket.io-client';
import { NotificationComponent } from '../notification/notification.component';
import { timer } from 'rxjs'
import { Location } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
	selector: 'app-user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss'],
    animations: [modalAnimation,
		trigger(
			'inOutBackBtn', 
			[
				transition(
					':enter', 
					[
						style({ 'flex-basis': 0, 'overflow': 'hidden', 'opacity': 0 }),
						animate('0.2s ease-out', 
							style({ 'flex-basis': '*' }))
					]
				),
				transition(
					':leave', 
					[
						style({ 'flex-basis': '*' }),
						animate('0.1s ease-in', 
							style({ 'flex-basis': 0, 'overflow': 'hidden', 'opacity': 0 }))
					]
				)
			]
		)
	]
})
export class UserComponent implements OnInit, AfterContentChecked, OnDestroy {
	public staff = false;
	public service = false;
	public new_user: any = {data: {}};
	public new_user_submitted: boolean = false;
    public position: string = 'positionBottom';
	public ntfPopup: boolean = false;
	public connectedPosition: any = [
		{
			offsetX: undefined,
			offsetY: undefined,
			originX: 'end',
			originY: 'bottom',
			overlayX: 'end',
			overlayY: 'top'
		}
	];
	private io;
	private onClose: any = {};
	private onRead: any = {};
	animationState: 'closed' | 'open' = 'closed';
	private loadNtfs: boolean = false;
	public steps: any = [1, 2, 3, 4, 5, 6];
	public date: Date;
	private subscription: any;
	public hideTime: boolean;

	constructor(
		public us: UserService, 
		public rs: RoleService,
		public ns: NotificationService,
		private router: Router,
		public loc: LocationService,
		private alert: AlertService,
		public ts: TimeService, 
		private core: CoreService,
		public modal: ModalService,
		public gs: GroupService,
		private ss: ServiceService,
		private rms: RoomService,
		private location: Location
		){
			// this.io = io(this.core.window.location.origin.replace('4200', '8080'));
			// this.io.on('notifications', this.displayNotif);
		}
	ngOnInit() {
		this.hideTime = !(localStorage.getItem('pure_sidebar') != 'false');
		this.subscription = timer(0, 1000).subscribe(() => {
			this.date = new Date();
		});
	}
	ngAfterContentChecked() {
		if (this.router.url.indexOf('/staff') != -1) this.staff = true;
		else this.staff = false;
		if (this.router.url.indexOf('services') != -1) this.service = true;
		else this.service = false;
	}
	@ViewChild('topbar') topbar: ElementRef;
	toggle(){
		if(this.us.userPopup) {
			this.us.userPopup = false;
			setTimeout(() => {
				this.us.userPopup = true;
			}, 300);
		}
		if(this.ntfPopup) {
			this.ntfPopup = false;
			setTimeout(() => {
				this.ntfOpen();
			}, 300);
		}
		this.topbar.nativeElement.style.transition = '.3s width ease-in-out';
		setTimeout(() => {
			this.topbar.nativeElement.style.transition = '';
		}, 300);
		if(this.us.sidebarToggle) {
			this.hideTime = true;
		} else {
			setTimeout(() => {
				this.hideTime = false;
			}, 300);
		}
		this.us.sidebarToggle = !this.us.sidebarToggle;
		localStorage.setItem('pure_sidebar', this.us.sidebarToggle.toString());
	}
	@ViewChildren('openAnimate') openAnimate: QueryList<ElementRef>;
	@ViewChildren('closeAnimate') closeAnimate: QueryList<ElementRef>;
	sidebarOn() {
		this.us.sidebarOn = !this.us.sidebarOn;
		if(this.us.sidebarOn) {
			if(this.openAnimate) {
				this.openAnimate.forEach((a) => {
					a.nativeElement.beginElement();
				});
			}
		} else {
			if(this.closeAnimate) {
				this.closeAnimate.forEach((a) => {
					a.nativeElement.beginElement();
				});
			}
		}
	}
	sidebarOff() {
		if(this.us.sidebarOn != false) {
			this.us.sidebarOn = false;
			if(this.closeAnimate) {
				this.closeAnimate.forEach((a) => {
					a.nativeElement.beginElement();
				});
			}
		}
	}
	positionChange(event) {
        if(event.connectionPair.originY === 'top') this.position = 'positionTop';
        if(event.connectionPair.originY === 'bottom') this.position = 'positionBottom';
    }
	closePopup(event, id) {
		if( !this.us.mobile ) {
			let currentElement = event.target;
	
			while (currentElement !== null) {
	
				if (currentElement.id == id) {
					return;
				}
				
				currentElement = currentElement.parentElement;
			}
	
			switch(id) {
				case 'newUser':
					this.us.userPopup = false;
					break;
				case 'ntf':
					this.ntfPopup = false;
					break;
			}
		}
	}
	addUser() {
		this.new_user_submitted = true;
		if(!this.new_user.firstName||!this.new_user.lastName||!this.new_user.email) return this.alert.error({
			text: "Please fill required fields",
		});
		if (this.staff) {
			this.new_user.make_staff = true;
			this.new_user.appointment = true;
			this.new_user.role = this.rs.roles[0]._id;
			console.log(this.loc._locations.primary[0].data.business_hours);
			if(this.loc._locations.primary && this.loc._locations.primary.length){
				this.new_user.location = [this.loc._locations.primary[0]._id];
			}
			this.new_user.data.working_hours = {
				default: {
					Monday: [ { from: '08:00', to: '20:00' } ], 
					Tuesday: [ { from: '08:00', to: '20:00' } ], 
					Wednesday: [ { from: '08:00', to: '20:00' } ], 
					Thursday: [ { from: '08:00', to: '20:00' } ], 
					Friday: [ { from: '08:00', to: '20:00' } ], 
					Saturday: [], 
					Sunday: [],
				}
			};
		}
		this.us.create(this.new_user, (created) => {
			this.us.userPopup = false;
			this.us.getUsers();
			this.new_user = {data: {}};
			this.new_user_submitted = false;
		});
	}
	@HostListener('window:resize')
	onWindowResize() {
		const previous = this.us.mobile;

		this.us.mobile = this.us.isMobile;

		if( previous != this.us.mobile ) {
			if(this.us.userPopup) {
				this.us.userPopup = false;
				setTimeout(() => {
					this.us.userPopup = true;
				}, 10);
			}
			if(this.ntfPopup) {
				this.ntfPopup = false;
				setTimeout(() => {
					this.ntfOpen();
				}, 10);
			}
		}
	}
	@HostListener('window:click', ['$event'])
	onClick(event) {
		if (event.target.classList.contains('waw-alert__close')) {
			if(event.target.closest('alert').querySelector('.ntf')) {
				this.onClose[event.target.closest('alert').querySelector('.ntf').dataset.id] = true;
			}
		}
	}
	ntfOpen() {
		if (this.ntfPopup) {
			this.ntfPopup = false;
		} else {
			this.ns.getNotifications(false, (res) => {
				this.ntfPopup = true;
				var intervalId = setInterval(() => {
					if(this.ntfsContainer) {
						clearInterval(intervalId);
						if (this.ntfs) {
							this.ntfs.forEach((n, index) => {
								n.nativeElement.style.opacity = 0;
								n.nativeElement.style.transform = 'translateY(-20px)';
								n.nativeElement.style.setProperty("--delay", `${0.1 * (index + 1)}s`);
								n.nativeElement.style.animation = `fadeIn 0.2s ease-in var(--delay) forwards`;
							});
						}
					}
				}, 10);
				document.querySelectorAll('.waw-alert-container.notification').forEach((e) => {
					e.classList.add('_close');
					setTimeout(()=>{
						e.closest('alert').remove();
					}, 350);
				});
				this.checkNtfsInView();
			});
		}
	}
	ntfClose() {
		this.ntfPopup = false;
		setTimeout(() => {
			const notifications = this.ns.notifications;
			notifications.forEach(ntf => {
				if(this.onRead[ntf._id]) {
					if( !ntf.read ) {
						this.ns.read(ntf);
					}
				}
			});
			setTimeout(() => {
				if(!this.ntfPopup) this.ns.notifications = [];
			}, 10000);
			this.onRead = {};
		}, 600);
	}
	@ViewChildren('ntfItem') ntfs: QueryList<ElementRef>;
	@ViewChild('ntfsContainer', { static: false }) ntfsContainer: ElementRef;
	checkNtfsInView() {
		var intervalId = setInterval(() => {
			if(this.ntfsContainer) {
				clearInterval(intervalId);
				if (this.ntfs) {
					this.ntfs.forEach((n) => {
						const elementRect = n.nativeElement.getBoundingClientRect();
						const containerRect = this.ntfsContainer.nativeElement.getBoundingClientRect();
						const isVisible = (elementRect.bottom <= containerRect.bottom || elementRect.bottom - 50 <= containerRect.bottom) && (elementRect.top >= containerRect.top || elementRect.top + 20 >= containerRect.top);
						if (isVisible) {
							this.onRead[n.nativeElement.dataset.id] = true;
						}
					});
				}
			}
		}, 10);
	}
	openNtfStgs() {
		this.ntfPopup = false;
		setTimeout(() => {
			this.router.navigate([],{ queryParams: { modal: 'open' } });
			this.modal.show({
				component: 'notificationssettings', 
				onClose:() => {
					this.ntfOpen();
				}
			});
		}, 100);
	}
	displayNotif = (res) => {
		console.log('displayNotif');
		if(res.user == this.us._id) {
			const notif = res.notif;
			if( notif && ((this.us.data?.notifications_settings && this.us.data?.notifications_settings?.popup) || (this.us.data?.notifications_settings && this.us.data?.notifications_settings?.popup == undefined) || !this.us.data?.notifications_settings) ) {
				this.alert.show({
					progress: false,
					position: 'topRight',
					class: 'notification',
					component: NotificationComponent,
					notif: notif,
					onClose: () => {
						if (this.onClose[notif._id]) {
							this.ns.read(notif);
						}
					}
				});
			}
			this.ns.getUnreadCount();
		}
	};
	newGroup(){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({
			component: 'modal',
			title: 'Service Group',
			components: [
				{
					type: 'INPUT',
					input: 'name',
					label: 'Group name*',
					placeholder: 'Enter group name',
					reqText: 'Name is required',
					required: true,
				}, {
					type: 'EDITOR',
					input: 'description',
					label: 'Notes',
					placeholder: 'Add privates notes',
					size: 'large'
				}
			],
			onSave: (profile: any) => {
				this.modal.destroy();
				this.gs.create(profile);
			}
		});
	}
	ngOnDestroy() {
		// this.io.off('notifications', this.displayNotif);
		this.subscription.unsubscribe();
	}
	isScrolledNtfsToBottom(event: Event): void {
		const element = event.target as HTMLElement;
        const threshold = 100;
        if( element.scrollHeight - element.scrollTop <= element.clientHeight + threshold && !this.loadNtfs ) {
			this.loadNtfs = true;
			this.ns.getNotifications(true, (res) => {
				this.loadNtfs = false;
			});
		}
    }
	get finishRegistration() {
		if(localStorage.getItem('registration')) {
			if( (this.us.is.admin || this.us.is.owner) && (!this.ss.services.length || !this.us.staff.length || !this.loc.locations.length) ) {
				return true;
			} else return false;
		} else return false;
	}

	get registrationStep() {
		var step = 1;

		if(this.loc.locations?.length) step = 2;
		else return step;

		let days = false; 
		for(let day of this.us.days_of_week) {
			if(this.loc.primary[0].data.business_hours[day].length && !days) days = true;
		}
		if(days) step = 3;
		else return step;

		if(this.rms.rooms?.length && this.rms.by_locations[this.loc.primary[0]._id]?.length) step = 4;
		else return step;

		if(this.us.allowed_appointments?.length) step = 5;
		else return step;

		if(this.us.allowed_appointments[0].data?.working_hours?.default && Object.values(this.us.allowed_appointments[0].data?.working_hours?.default).some((day: any) => day?.length > 0) ) step = 6;
		else return step;

		return step;
	}

	finishRegistrationModal() {
		if( (this.us.is.admin || this.us.is.owner) && (!this.ss.services.length || !this.us.allowed_appointments.length || !this.loc.locations.length) ) {
			this.router.navigate([],{ queryParams: { modal: 'open' } });
			this.modal.show({
				component: 'registration',
				closable: false,
				navigate: false,
				onClickOutside: () => {}
			});
		}
	}


	backClicked() {
		if(this.us.backRoute) this.us.backRoute();
		else if(this.us.headerSubtitle === 'Setup' || this.us.headerSubtitle === 'Admin') this.router.navigate(['/setup']);
		else this.location.historyGo(-1);
	}
}
