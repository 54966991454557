<div class="containerBox flex-column" cdkScrollable hideOnScroll>
	<div class="navigation" [class.navShown]="!us.sidebarToggle" [class.without_filter]="tab=='information'">
		<div class="navigation__topbar display-sm">
			<button mat-button color="primary" type="button" class="topbar-user-btn waw-btn _second display-sm" (click)="us.userPopup = !us.userPopup;">
				<span class="icon-plus"></span>
				<span>New staff</span>
			</button>
		</div>

		<div class="navigation__search search-input">
			<input type="text" placeholder="Search" name="search" [(ngModel)]="searching">
		</div>

		<div class="navigation__tab">
			<tab [(value)]="tab" [tabs]="tabs"></tab>
		</div>

		<div class="navigation__right" [class._filter]="tab!='information'">
			<ng-container *ngIf="tab=='information'">
				<div class="navigation__btn">
					<button mat-flat-button color="primary" class="waw-btn _primary" (click)="openStaff()">
						<span class="icon-plus"></span>
						<span>New staff</span>
					</button>
				</div>
			</ng-container>

			<ng-container *ngIf="tab=='working-hours'">
				<div class="navigation__filter">
					<button mat-icon-button tooltip="Time Tracking" tooltipMobileDisabled="true" aria-label="Time Tracking" class="navigation__filter-btn" (click)="openClock()"><span class="icon-clock"></span></button>
					<button mat-icon-button [tooltip]="view ? 'Month' : 'Week'" tooltipMobileDisabled="true" [attr.aria-label]="view ? 'Month view' : 'Week view'" class="navigation__filter-btn" (click)="view = !view"><span>{{view ? '31' : '7'}}</span></button>
					<div class="navigation__filter-daterange">
						<ng-container *ngIf="view == true">
							<datepicker type="week" [date]="weekStart" (valueChange)="onDateChanged($event)"></datepicker>
						</ng-container>
						<ng-container *ngIf="view == false">
							<datepicker type="month" [month]="month" [year]="year" (valueChange)="changeMonth($event)"></datepicker>
						</ng-container>
					</div>
				</div>
				<div class="navigation__btn">
					<button mat-flat-button color="primary" class="waw-btn _primary" (click)="openHours()">
						<span class="icon-plus"></span>
						<span>Working Hours</span>
					</button>
				</div>
			</ng-container>
		</div>
	</div>
	<div class="containerTab fl-grow">
		<app-staff-information *ngIf="tab=='information'" [searching]="searching"></app-staff-information>
		<app-working-hours *ngIf="tab=='working-hours'" [searching]="searching" [view]="view" [weekStart]="weekStart" [month]="month" [year]="year" #working></app-working-hours>
	</div>
</div>