<div class="auth">
    <div class="auth-container">
        <form class="auth-left" (ngSubmit)="forgot()">
            <div class="flex-column">
                <h1 class="auth__title">Forgot password?</h1>
                <div class="waw-input _not_change_on_mobile">
                    <label [class.req]="auth.submitted.email&&!user.email || auth.error.email" [class.check]="!auth.error.email && user.email && auth.check.email">
                        <div class="fl-sb-c">
                            <span class="waw-input__title">Email</span>
                            <span *ngIf="auth.submitted.email&&!user.email || auth.error.email" class="req-text">{{auth.error.email ? auth.error.email : 'Enter your email'}}</span>
                        </div>
                        <input type="email" placeholder="Email" name="email" [(ngModel)]="user.email" (input)="auth.validateInputEmail($event)" (focusout)="auth.validateInputEmail($event, true)" autocomplete="email">
                    </label>
                </div>
            </div>
            <div>
                <button mat-flat-button color="primary" type="submit" class="waw-btn _primary _not_change_on_mobile">Send</button>
                <div class="auth__note">
                    <span class="auth__note-text">I have an account</span>
                    <a class="auth__note-link" routerLink="/login">Sign In</a>
                    <span class="auth__note-text">Not registered yet?</span>
                    <a class="auth__note-link" routerLink="/sign">Create an account</a>
                </div>
            </div>
        </form>

        <div class="auth-right">
            <div class="auth__logo">
                <img class="auth__logo-img" width="80" height="80" src="assets/sign/logo.svg" alt="Logo">
                <span class="auth__logo-text notranslate">Pure Planner</span>
            </div>
            <div class="auth__preview-1"><img width="305" height="159" src="assets/sign/preview_1.png"></div>
            <div class="auth__preview-2"><img width="305" height="159" src="assets/sign/preview_2.png"></div>
            <div class="auth__preview-3"><img width="305" height="159" src="assets/sign/preview_3.png"></div>
        </div>
    </div>
</div>
