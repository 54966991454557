<form (ngSubmit)="save();">
	<div class="modal-box">
		<div class="modal-title">{{new_service._id&&'Edit'||'Add'}} Service</div>
		<button mat-icon-button type="button" class="modal-close" (click)="close()">
			<span class="modal-close__icon"></span>
		</button>
		<div class="row">
			<div class="waw-input col-2">
				<label [class.req]="submitted&&!new_service.name">
					<span class="waw-input__title">Service name *</span>
					<input type="text" name="name" placeholder="Name" [(ngModel)]="new_service.name">
					<div *ngIf="submitted&&!new_service.name" class="req-text">Name is required</div>
				</label>
			</div>
			<div class="service-color col-2">
				<colorpicker title="Choose a color" [(value)]="new_service.color" [custom]="false"></colorpicker>
			</div>
			<div class="waw-select col-1">
				<span class="waw-select__title">Add staff *</span>
				<app-select 
					placeholder="Select Staff" 
					[(value)]="new_service.staffs"
					[items]="us.staff" 
					[clearable]="true" 
					[searchable]="true"
					[required]="submitted&&(!new_service.staffs||new_service.staffs&&!new_service.staffs.length)"
					[multiple]="true"
					[select_all]="true"
					[width]="width"
					bindLabel="name" 
					bindValue="_id"
				>
					<ng-template select let-items let-open="open" let-required="required">
						<div class="modal-selectMultiple" [class._open]="open" [class._req]="required" #select>
							<ng-container *ngIf="items?.length">
								<div class="modal-selectMultiple__item _delete text-elipsis" *ngFor="let item of items; index as i">
									{{item.name}}
								<button mat-icon-button color="primary" type="button" class="modal-selectMultiple__item__delete" (click)="new_service.staffs.splice(i, 1); $event.stopPropagation()"><span class="material-icons">close</span></button>
							</div>
							</ng-container>
							<ng-container *ngIf="!items?.length">
								<span class="modal-selectMultiple__placeholder">Select Staff</span>
							</ng-container>
							<button mat-icon-button color="primary" type="button" class="modal-selectMultiple__btn">
								<span class="modal-selectMultiple__btn-icon icon-arrow_down" style="display: block; transition: 0.15s all ease-in-out;" [ngStyle]="{'transform': open ? 'rotate(-180deg)' : 'rotate(0deg)' }"></span>
							</button>
						</div>
					</ng-template>
				</app-select>
				<div *ngIf="submitted&&(!new_service.staffs||new_service.staffs&&!new_service.staffs.length)" class="req-text">Staff is required</div>
			</div>
			<div class="waw-select col-2">
				<span class="waw-select__title">Location *</span>
				<app-select 
					placeholder="Select Location" 
					[(value)]="new_service.locations"
					(valueChange)="new_service.room=null;"
					[items]="ls.locations" 
					[clearable]="false" 
					[searchable]="false"
					[required]="submitted&&(!new_service.locations||new_service.locations&&!new_service.locations.length)"
					[multiple]="true"
					bindLabel="name"
					bindValue="_id"
				></app-select>
				<div *ngIf="submitted&&(!new_service.locations||new_service.locations&&!new_service.locations.length)" class="req-text">Location is required</div>
			</div>
			<div class="waw-select col-2">
				<span class="waw-select__title">Products</span>
				<app-select 
					placeholder="Select Product" 
					[(value)]="new_service.product"
					[items]="ps.products" 
					[clearable]="true" 
					[searchable]="true"
					[multiple]="true"
					bindLabel="name"
					bindValue="_id"
				></app-select>
			</div>
		</div>
		<div class="row">
			<div class="waw-select col-1">
				<span class="waw-select__title">Room *</span>
				<app-select 
					placeholder="Select Room" 
					[(value)]="new_service.room"
					[items]="rs.by_locations?.[new_service?.locations?.[0]] || []" 
					[clearable]="false" 
					[searchable]="false"
					[required]="submitted&&!new_service.room"
					bindLabel="name"
					bindValue="_id"
				></app-select>
				<div *ngIf="submitted&&!new_service.room" class="req-text">Room is required</div>
			</div>
		</div>
		<label class="waw-checkbox">
			<input type="checkbox" name="hide" [(ngModel)]="new_service.hideApi">
			<span class="checkmark"></span>
			<span class="waw-checkbox__text">Hide this service on booking API</span>
		</label>
		<div>
			<h2 class="modal-h2">Pricing and Duration</h2>
			<p class="modal-p mb30">Add the pricing options and duration of the service.</p>
		</div>
		<div class="row">
			<div class="waw-select col-2">
				<span class="waw-select__title">Price Type *</span>
				<app-select 
					placeholder="Select Price Type" 
					[(value)]="new_service.price_type"
					[items]="ss.price_types" 
					[clearable]="false" 
					[searchable]="false"
					[required]="submitted&&!new_service.price_type"
					bindLabel="name" 
					bindValue="name"
				></app-select>
				<div *ngIf="submitted&&!new_service.price_type" class="req-text">Type is required</div>
			</div>
			<div class="waw-select col-2">
				<span class="waw-select__title">Tax</span>
				<app-select 
					placeholder="Select Tax" 
					[(value)]="new_service.tax"
					[items]="ts.taxes" 
					[clearable]="false" 
					[searchable]="false"
					[multiple]="true"
					bindLabel="name"
					bindValue="_id"
				></app-select>
			</div>
			<div class="waw-input col-3">
				<div [class.req]="submitted&&!new_service.time">
					<span class="waw-input__title">Duration *</span>
					<durationinput [(data)]="new_service.time" (onChange)="new_service.time = $event" name="time"></durationinput>
					<div *ngIf="submitted&&!new_service.time" class="req-text">Duration is required</div>
				</div>
			</div>
			<div class="waw-input col-3">
				<label [class.req]="submitted&&!new_service.price">
					<span class="waw-input__title">Price *</span>
					<currency-input [value]="new_service.price" (valueChange)="new_service.price = $event"></currency-input>
					<div *ngIf="submitted&&!new_service.price" class="req-text">Price is required</div>
				</label>
			</div>
			<div class="waw-input col-3">
				<label>
					<span class="waw-input__title">capacity</span>
					<input type="number" name="capacity" placeholder="Capacity" [(ngModel)]="new_service.capacity">
				</label>
			</div>
		</div>
		<div class="modal-btn">
			<button mat-button color="primary" type="button" class="waw-btn _second" (click)="close(); new_service={};" *ngIf="!new_service._id">Cancel</button>
			<button mat-button color="primary" type="button" class="waw-btn _second _delete" (click)="deleteService(new_service)" *ngIf="new_service._id">Delete</button>
			<button mat-flat-button color="primary" type="submit" class="waw-btn _primary">{{new_service._id&&'Save'||'Create'}}</button>
		</div>
	</div>
</form>
