import { Injectable } from '@angular/core';
import { PlatformService, UserService } from '@services';
import { HttpService, MongoService } from 'wacom';

@Injectable()
export class SocialAuthService {
    private googleClientId = '786370122363-lptatgbv64n10ebdvil1pmhoiaiig2fd.apps.googleusercontent.com';
    private googleRedirectUri = 'https://platforms.pureplanner.com/api/user/google/connect';
    private googleOauthUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${this.googleClientId}&redirect_uri=${this.googleRedirectUri}&scope=profile email&access_type=offline`;

    private facebookClientId = '1497877524084814';
    private facebookRedirectUri = 'https://platforms.pureplanner.com/api/user/facebook/connect';
    private facebookOauthUrl = `https://www.facebook.com/v12.0/dialog/oauth?response_type=code&client_id=${this.facebookClientId}&redirect_uri=${this.facebookRedirectUri}&scope=email,public_profile`;
    
    constructor(private http: HttpService, private mongo: MongoService, private ps: PlatformService, private us: UserService) {
        this.mongo.on('platform', () => {
            const state = JSON.stringify({
                platform: this.ps.platform.name,
                user: this.us._id
            });
            this.googleOauthUrl = this.googleOauthUrl + `&state=${state}`;
            this.facebookOauthUrl = this.facebookOauthUrl + `&state=${state}`;
        });
    }

    /**
    * Calling Google login API and fetching account details.
    */
    public googleAuthenticate() {        
        const width = 500;
		const height = 500;
		const left = (screen.width / 2) - (width / 2);
		const top = (screen.height / 2) - (height / 2);

        const oauthWindow = window.open(this.googleOauthUrl, 'GoogleSignIn', `width=${width},height=${height},top=${top},left=${left},popup`);

        return new Promise((resolve, reject) => {
            const listener = (event: MessageEvent) => {
                if (event.origin === window.location.origin) {
                    window.removeEventListener('message', listener);
                    if (event.data.error) {
                        reject(event.data.error);
                    } else {
                        resolve(event.data);
                    }
                }
            };
            window.removeAllListeners('message');
            window.addEventListener('message', listener);
        });
    }

    /**
    * Disconnect user from Google
    */
    public googleDisconnect(callback: (resp) => void): void {
        this.http.get('/api/user/google/disconnect', (resp: any) => {
            if(typeof callback === 'function') callback(resp);
		});
    }

    /**
    * Calling Facebook login API and fetching account details.
    */
    public facebookAuthenticate() {        
        const width = 500;
		const height = 500;
		const left = (screen.width / 2) - (width / 2);
		const top = (screen.height / 2) - (height / 2);

        const oauthWindow = window.open(this.facebookOauthUrl, 'FacebookSignIn', `width=${width},height=${height},top=${top},left=${left},popup`);

        return new Promise((resolve, reject) => {
            const listener = (event: MessageEvent) => {
                if (event.origin === window.location.origin) {
                    window.removeEventListener('message', listener);
                    if (event.data.error) {
                        reject(event.data.error);
                    } else {
                        resolve(event.data);
                    }
                }
            };
            window.removeAllListeners('message');
            window.addEventListener('message', listener);
        });
    }
    
    /**
    * Disconnect user from Facebook
    */
    public facebookDisconnect(callback: (resp) => void): void {
        this.http.get('/api/user/facebook/disconnect', (resp: any) => {
            if(typeof callback === 'function') callback(resp);
		});
    }
}