import { Component } from '@angular/core';
import { LoaderService, LocationService, RoleService, UserService } from '@services';
import { AlertService } from 'wacom';
@Component({
	selector: 'createuser',
	templateUrl: './createuser.component.html',
	styleUrls: ['./createuser.component.scss']
})
export class CreateuserComponent {
	public profile:any={};
	public close;
	public submitted;
	constructor(public us: UserService,
		public rs: RoleService, public alert: AlertService, public ls: LocationService, private loader: LoaderService) {}

	save() {
		this.submitted = true;
		if(!this.profile.firstName || !this.profile.lastName || !this.profile.lastName || !this.profile.role || !this.profile.email) {
			return this.alert.error({
				text: "Please fill required fields",
			});
		}

		this.loader.show({ 
			modal: true,
			transparent: true,
			preventClick: true
		}, document.querySelector('.modal-box'));

		this.profile.make_staff = true;
		if(this.ls._locations.primary && this.ls._locations.primary.length){
			this.profile.location = [this.ls._locations.primary[0]._id];
		}
		this.us.create(this.profile, () => {
			this.loader.remove();
			this.close();
		}, () => {
			this.loader.remove();
		});
	}
}
