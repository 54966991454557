import { Component } from '@angular/core';
import { DiscountService, LoaderService, UserService } from '@services';
import { AlertService, ModalService } from 'wacom';
@Component({
	selector: 'discountprofile',
	templateUrl: './discountprofile.component.html',
	styleUrls: ['./discountprofile.component.scss']
})
export class DiscountprofileComponent{
	public profile:any = {};
	public close;
	public submitted:Boolean;
	constructor(public us: UserService, 
		public ds:DiscountService,
		public alert: AlertService,
		public modal: ModalService,
		private loader: LoaderService) {}
	save() {
		this.submitted = true;
		if(!this.profile.name||!this.profile.amount||!this.profile.kind) return this.alert.error({
			text: "Please fill required fields",
		});
		
		this.loader.show({ 
			modal: true,
			transparent: true,
			preventClick: true
		}, document.querySelector('.modal-box'));
		
		this.ds.create(this.profile, () => {
			this.loader.remove();
			this.close();
		});
	}
	delete() {
		this.modal.show({
			component: 'deleteAlert',
			title: 'Delete discount',
			body: `Are you sure you want to delete discount ${this.profile.name}`,
			on_add: ()=> {
				this.close()
				this.ds.delete(this.profile);
			}
		});
	}
}
