import { Component, HostListener, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { ModalService, MongoService } from 'wacom';
import { UserService, PlatformService, HashService, LoaderService} from '@services';
import { Router } from '@angular/router';
@Component({
	selector: 'app-notifications',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, AfterViewInit {
	public tab = 'staff';
	public tabs: any = [
		'staff',
		'clients',
	];
	constructor(
		public us: UserService, 
		public ps: PlatformService, 
		public hash: HashService, 
		public modal: ModalService, 
		public router:Router,
		private mongo: MongoService,
		private loader: LoaderService,
		private eref: ElementRef) {
		this.us.headerTitle = 'Notification Templates';
		this.us.headerSubtitle = 'Admin';
		this.hash.on('tab', tab => this.tab = tab || 'staff');
	}
	ngOnInit() {
		this.loader.show({container: true}, this.eref.nativeElement.querySelector('.containerBox'));
	}
	ngAfterViewInit() {
		this.mongo.on('user platform', () => {
			this.loader.remove();
		});
	}
	edit(notification, role) {
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({component: 'notificationtemplate', notification: JSON.parse(JSON.stringify(notification.value)), edit: notification.holder, role: role})
	}
	@HostListener('window:popstate', ['$event'])
    onPopState(event: PopStateEvent) {
		this.tab = this.hash.get('tab') || 'staff';
    }
}
