import { Component, OnInit } from '@angular/core';
import { UserService } from '@services';

@Component({
	selector: 'app-social',
	templateUrl: './social.component.html',
	styleUrls: ['./social.component.scss']
})
export class SocialComponent implements OnInit {

	constructor(public us: UserService) {
		this.us.headerTitle = 'Connected services';
		this.us.headerSubtitle = 'Setup';
	}

	ngOnInit(): void {
	}

}
