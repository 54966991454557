<div class="containerBox">
	<div class="msg">
		<div class="msg-list" [class._active]="chat_id">
			<div class="msg-list__header">
				<div class="msg-list__title">Recent</div>
				<div class="msg-list__search">
					<input type="search" [(ngModel)]="searching">
					<span class="icon-search"></span>
				</div>
			</div>
			<div class="msg-list__body">
				<div class="msg-list__item msg-item" (click)="set_user(user._id)" *ngFor="let user of us.registered|search: searching: 'name'">
					<div class="msg-item__avatar">
						<picture *ngIf="user" [user]="user"></picture>
					</div>
					<div class="msg-item__content">
						<div class="msg-item__name">{{user.name}}</div>
						<div class="msg-item__sms" *ngIf="ms.chat_id[us._id+user._id]?.length">{{ms.chat_id[us._id+user._id][ms.chat_id[us._id+user._id].length-1].text}}</div>
						<div class="msg-item__sms" *ngIf="ms.chat_id[user._id+us._id]?.length && user._id!=us._id">{{ms.chat_id[user._id+us._id][ms.chat_id[user._id+us._id].length-1].text}}</div>
					</div>
					<div class="msg-item__info">
						<div class="msg-item__date" *ngIf="ms.chat_id[us._id+user._id]?.length">{{ms.chat_id[us._id+user._id][ms.chat_id[us._id+user._id].length-1]._id|mongodate|date:'M/d/yyyy'}}</div>
						<div class="msg-item__date" *ngIf="ms.chat_id[user._id+us._id]?.length && user._id!=us._id">{{ms.chat_id[user._id+us._id][ms.chat_id[user._id+us._id].length-1]._id|mongodate|date:'M/d/yyyy'}}</div>
						<div class="msg-item__counter" *ngIf="ms.chat_id_view[us._id+user._id]?.length && user._id!=us._id && ms.chat_id[us._id+user._id][ms.chat_id[us._id+user._id].length-1].author!=us._id">{{ms.chat_id_view[us._id+user._id]?.length}}</div>
						<div class="msg-item__counter" *ngIf="ms.chat_id_view[user._id+us._id]?.length && user._id!=us._id && ms.chat_id[user._id+us._id][ms.chat_id[user._id+us._id].length-1].author!=us._id">{{ms.chat_id_view[user._id+us._id]?.length}}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="msg-view" [class._active]="chat_id">
			<div class="msg-view__header">
				<div class="msg-view__header__left">
					<div class="msg-view__header__back cur-p" (click)="chat_id = false"><span class="icon-chevron"></span></div>
					<div class="msg-view__header__avatar">
						<picture [user]="us._users[user_id]"></picture>
					</div>
					<div>
						<div class="msg-view__header__name">{{us._users[user_id]?.name}}</div>
						<!-- <div class="msg-view__header__status">Online</div> -->
					</div>
				</div>
				<!-- <div class="cur-p"><span class="icon-delete"></span></div> -->
			</div>
			<div class="msg-view__body">
				<div class="msg-view__date">Today</div>
				<div class="msg-box" [class._active]="chat_id" #messages>
					<div class="msg-box__item" *ngFor="let message of ms.chat_id[chat_id]||[]">
						<div class="msg-box__text" [class._out]="message.author==us._id" [class._in]="message.author!=us._id" [class._emoji]="isEmoji(message.text)"><span>{{message.text}}</span></div>
					</div>
				</div>
				<div class="msg-nav">
					<form class="msg-nav__input" (ngSubmit)="create({text: text, receiver: user_id}); text='';">
						<input type="text" [(ngModel)]="text" name="text">
					</form>
					<div class="msg-nav__add"><label><span class="icon-plus"></span></label></div>
					<div class="msg-nav__smile" cdkOverlayOrigin #emoji="cdkOverlayOrigin" (click)="emojiOpen = true"><span class="icon-smile"></span></div>
					<div class="msg-nav__send" (click)="create({text: text, receiver: user_id}); text='';"><span class="icon-send"></span></div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template
	cdkConnectedOverlay
	[cdkConnectedOverlayOrigin]="emoji"
	[cdkConnectedOverlayOpen]="emojiOpen"
	[cdkConnectedOverlayHasBackdrop]="true"
	[cdkConnectedOverlayBackdropClass]="'picker-overlay-backdrop'"
	[cdkConnectedOverlayFlexibleDimensions]="true"
	(backdropClick)="emojiOpen = false"
	(detach)="emojiOpen = false"
>
	<emoji-mart 
		title="Pick your emoji…" 
		emoji="point_up"
		[perLine]="perLine"
		[isNative]="true" 
		[totalFrequentLines]="2"
		(emojiSelect)="emojiSelect($event)"
		[@modal]="us.mobile ? (emojiOpen ? 'zoom' : emojiOpen) : ''"
	></emoji-mart>
</ng-template>