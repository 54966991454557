<div class="modal-box">
	<form (ngSubmit)="save()">
		<div class="modal-title">Checkout <br> {{aps._appointments[check.appointment.id].day.singleDate.formatted}}, {{ss._services[aps._appointments[check.appointment.id].service].name}} for {{formattedTime(ss._services[aps._appointments[check.appointment.id].service].time)}} with {{us._users[aps._appointments[check.appointment.id].user].name}}</div>
		<button mat-icon-button type="button" class="modal-close" (click)="close()">
			<span class="modal-close__icon"></span>
		</button>
		<div class="row">
			<div class="waw-select col-2">
				<span class="waw-select__title">Form of payment *</span>
				<app-select 
					placeholder="Payment type" 
					[(value)]="check.type"
					[items]="cs.types" 
					[clearable]="false" 
					[searchable]="false"
					[required]="submitted&&!check.type"
				></app-select>
				<div *ngIf="submitted&&!check.type" class="req-text">Type is required</div>
			</div>
			<div class="waw-input col-2">
				<span class="waw-input__title">Appointment cost</span>
				<currency-input [value]="ss._services[aps._appointments[check.appointment.id].service].price" (valueChange)="ss._services[aps._appointments[check.appointment.id].service].price = $event" class="h-inp-def" [disabled]="true"></currency-input>
			</div>
			<div class="waw-select col-2">
				<span class="waw-select__title">Appointment discount</span>
				<app-select 
					placeholder="Choose discount" 
					[(value)]="check.appointment.discount"
					[items]="ds.discounts" 
					[clearable]="true" 
					[searchable]="true"
					bindValue="_id"
					bindLabel="name"
				>
					<ng-template label let-item>
						{{item.name}} {{item.kind!='perc'&&'$'||''}}{{item.amount}}{{item.kind=='perc'&&'%'||''}}
					</ng-template>
					<ng-template option let-item>
						{{item.name}} {{item.kind!='perc'&&'$'||''}}{{item.amount}}{{item.kind=='perc'&&'%'||''}}
					</ng-template>
				</app-select>
			</div>
			<div class="waw-select col-2">
				<span class="waw-select__title">Appointment tax</span>
				<app-select 
					placeholder="Choose tax" 
					[(value)]="check.appointment.taxes"
					[items]="ts.taxes" 
					[clearable]="true" 
					[searchable]="true"
					[multiple]="true"
					bindValue="_id"
					bindLabel="name"
				></app-select>
			</div>
		</div>
		<div class="products" style="position: relative;">
			<h2 class="modal-h2">Products</h2>
			<button type="button" mat-icon-button color="primary" tooltip="Add" tooltipMobileDisabled="true" aria-label="Add product" class="waw-btn _second _edit" (click)="add_popup = true" cdkOverlayOrigin #Product="cdkOverlayOrigin"></button>
			<ng-template
				cdkConnectedOverlay
				[cdkConnectedOverlayOrigin]="Product"
				[cdkConnectedOverlayOpen]="add_popup"
				[cdkConnectedOverlayHasBackdrop]="true"
				[cdkConnectedOverlayBackdropClass]="'picker-overlay-backdrop'"
				[cdkConnectedOverlayPanelClass]="'panelClass'"
				[cdkConnectedOverlayFlexibleDimensions]="false"
				[cdkConnectedOverlayMinWidth]="350"
				[cdkConnectedOverlayMinHeight]="( ps.products?.length ? ( (64 * ps.products?.length) > 200 ? 200 : (64 * ps.products?.length) ) : 44 ) + 12 + 20"
				[cdkConnectedOverlayPositions]="[
					{
						originX: 'start',
						originY: 'bottom',
						overlayX: 'start',
						overlayY: 'top'
					}, {
						originX: 'end',
						originY: 'bottom',
						overlayX: 'end',
						overlayY: 'top'
					}, {
						originX: 'start',
						originY: 'top',
						overlayX: 'start',
						overlayY: 'bottom'
					}, {
						originX: 'end',
						originY: 'top',
						overlayX: 'end',
						overlayY: 'bottom'
					}
				]"
				(positionChange)="positionChange($event)"
				(backdropClick)="add_popup=false;"
				(detach)="add_popup=false; position = 'positionBottom'"
			>
				<div class="select-picker" [@modal]="us.mobile ? (add_popup ? 'zoom' : add_popup) : (add_popup ? position : add_popup)">
					<div class="select-list">
						<div class="select-list__empty" *ngIf="!ps.products?.length">No items</div>
                        <div class="select-list__item select-list__item-product" *ngFor="let product of ps.products" (click)="addProduct(product)">
							<span class="select-list__item-product__img">
								<img *ngIf="product.thumb" [src]="product.thumb" alt="product" draggable="false"/>
								<span *ngIf="!product.thumb" class="icon-photo"></span>
							</span>
							<div class="select-list__item-product__info text-elipsis">
								<div class="select-list__item-product__info-name text-elipsis">{{product.name}}</div>
								<div class="select-list__item-product__info-qty text-elipsis" *ngIf="product.quantity"> x {{product.quantity}}</div>
							</div>
							<span class="select-list__item-product__price">{{product.price | currency}}</span>
						</div>
					</div>
				</div>
			</ng-template>

			<div class="products-table" *ngIf="check.products?.length">
				<table class="table" cellpadding="0" cellspacing="0">
					<thead>
						<tr>
							<th>Product</th>
							<th class="hidden-sm">Price</th>
							<th class="hidden-sm">Qty</th>
							<th class="display-sm">Qty x Price</th>
							<th>Discount</th>
							<th class="hidden-xs">Total</th>
							<th class="hidden-xs">Tax</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let product of check.products; index as i">
							<td data-label="Product" class="text-elipsis">{{ps._products[product.id].name}}</td>
							<td data-label="Price" class="text-elipsis hidden-sm">{{ps._products[product.id].price | currency}}</td>
							<td data-label="Qty" class="text-elipsis hidden-sm">{{product.qty}}</td>
							<td data-label="Qty x Price" class="text-elipsis display-sm">{{product.qty}} x {{ps._products[product.id].price | currency}}</td>
							<td data-label="Discount" class="text-elipsis">{{ds._discounts[product.discount]?.kind=='amount' ? (ds._discounts[product.discount]?.amount | currency) : ( ds._discounts[product.discount]?.kind=='perc' ? (ds._discounts[product.discount]?.amount + '%') : '0' ) }}</td>
							<td data-label="Total" class="text-elipsis hidden-xs">{{productTotal(product) | currency}}</td>
							<td data-label="Tax" class="hidden-xs">
								<div *ngFor="let tax of product.taxes; last as last" class="t-hide">
									{{ts._taxes[tax]?.name}}{{ !last ? ',' : '' }}
								</div>
							</td>
							<td data-label="Actions">
								<div>
									<button mat-icon-button color="primary" type="button" tooltip="Edit" tooltipMobileDisabled="true" aria-label="Edit product" class="waw-btn _second _edit" (click)="openProductModal(product)"></button>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		
		<div class="row">
			<div class="waw-input col-2">
				<span class="waw-input__title">Collected tax</span>
				<currency-input [value]="tax" class="h-inp-def" [disabled]="true"></currency-input>
			</div>
			<div class="waw-input col-2">
				<span class="waw-input__title">Tips</span>
				<currency-input [value]="check.tips" (valueChange)="check.tips = $event" class="h-inp-def"></currency-input>
			</div>
			<div class="waw-input col-1">
				<span class="waw-input__title">Total</span>
				<currency-input [value]="total" class="h-inp-def" [disabled]="true" [negative]="true"></currency-input>
			</div>
			<div class="waw-textarea col-1">
				<div [class.req]="characterLimit">
					<note-editor [notes]="noteEditor" placeholder="Add privates notes" label="Note" size="medium" (characterLimit)="characterLimit = $event" (onChange)="noteEditor = $event"></note-editor>
					<div *ngIf="characterLimit" class="req-text">{{characterLimit}}</div>
				</div>
			</div>
		</div>
		<div class="modal-switch">
			<label class="waw-switch">
				<input type="checkbox" name="Repeats" [(ngModel)]="check.invoice_email">
				<span class="track">
					<span class="handle"></span>
				</span>
				<div class="waw-switch-text">Send invoice by email</div>
			</label>
		</div>
		<div class="modal-switch">
			<label class="waw-switch">
				<input type="checkbox" name="Repeats" [(ngModel)]="invoice_print">
				<span class="track">
					<span class="handle"></span>
				</span>
				<div class="waw-switch-text">Print invoice</div>
			</label>
		</div>
		<div class="modal-switch">
			<label class="waw-switch">
				<input type="checkbox" name="Repeats" [(ngModel)]="check.use_taxes">
				<span class="track">
					<span class="handle"></span>
				</span>
				<div class="waw-switch-text">Taxes</div>
			</label>
		</div>
		<div class="modal-btn">
			<button mat-button color="primary" type="button" class="waw-btn _second" (click)="close()">Cancel</button>
			<button mat-flat-button color="primary" type="submit" class="waw-btn _primary">Save</button>
		</div>
	</form>
</div>

<ng-template #productRef let-product>
	<div class="col-1 pb20">
		<div class="select-list__item-product">
			<span class="select-list__item-product__img">
				<img *ngIf="this.ps._products[product.profile._id]?.thumb" [src]="this.ps._products[product.profile._id]?.thumb" alt="product" draggable="false"/>
				<span *ngIf="!this.ps._products[product.profile._id]?.thumb" class="icon-photo"></span>
			</span>
			<div class="select-list__item-product__info text-elipsis">
				<div class="select-list__item-product__info-name text-elipsis">{{this.ps._products[product.profile._id]?.name}}</div>
				<div class="select-list__item-product__info-qty text-elipsis" *ngIf="this.ps._products[product.profile._id]?.quantity"> x {{this.ps._products[product.profile._id]?.quantity}}</div>
			</div>
			<span class="select-list__item-product__price">{{this.ps._products[product.profile._id]?.price | currency}}</span>
		</div>
	</div>
</ng-template>

<ng-template #selectDiscountRef let-product>
	<div class="waw-select col-1">
		<span class="waw-select__title">Discount</span>
		<app-select 
			placeholder="No discount" 
			[(value)]="product.profile.discount"
			[items]="ds.discounts" 
			[clearable]="true" 
			[searchable]="false"
			bindValue="_id"
			bindLabel="name"
		>
			<ng-template label let-item>
				{{item.name}} {{item.kind=='amount' ? (item.amount | currency) : ( item.kind=='perc' ? (item.amount + '%') : '0' ) }}
			</ng-template>
			<ng-template option let-item>
				{{item.name}} {{item.kind=='amount' ? (item.amount | currency) : ( item.kind=='perc' ? (item.amount + '%') : '0' ) }}
			</ng-template>
		</app-select>
	</div>
</ng-template>