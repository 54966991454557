<div class="modal-box">
	<div class="modal-title">Add Note</div>
	<button mat-icon-button type="button" class="modal-close" (click)="close()">
		<span class="modal-close__icon"></span>
	</button>
	<div class="row">
		<div class="waw-select col-1">
			<span class="waw-select__title">Add client *</span>
			<app-select 
				placeholder="Client" 
				[(value)]="note.client"
				(valueChange)="remove()"
				[items]="us.clients" 
				[clearable]="false" 
				[searchable]="true"
				[disabled]="!client||prefill"
				bindLabel="name" 
				bindValue="_id" 
				[required]="submitted&&!note.client"
				class="_zero_padding"
			>
				<ng-template label let-item>
					<div class="select-option-user">
						<span class="select-option-user__img">
							<picture [user]="item"></picture>
						</span>
						<span class="select-option-user__name">{{item.name}}</span>
					</div>
				</ng-template>
				<ng-template option let-item>
					<div class="select-option-user" style="margin: 0px;">
						<span class="select-option-user__img">
							<picture [user]="item"></picture>
						</span>
						<span class="select-option-user__name">{{item.name}}</span>
					</div>
				</ng-template>
			</app-select>
			<div *ngIf="submitted&&!note.client" class="req-text">Client is required</div>
		</div>
		<div class="waw-datepicker col-1" [class.req]="submitted&&!note.date">
			<span class="waw-datepicker__title">Day *</span>
			<datepicker [date]="note.date" (valueChange)="note.date=$event; remove()" [required]="submitted&&!note.date" [disabled]="prefill" [darkIcon]="true"></datepicker>
			<div *ngIf="submitted&&!note.date" class="req-text">Day is required</div>
		</div>
		<div class="waw-select col-1">
			<span class="waw-select__title">Appointment</span>
			<app-select 
				placeholder="Select an Appointment" 
				[(value)]="note.appointment"
				[items]="ap.past_ondate[note.client+(note.date&&note.date.singleDate?.formatted)]" 
				[clearable]="false" 
				[searchable]="false"
				[disabled]="prefill"
				bindValue="_id"
				bindLabel="start"
			>
				<ng-template label let-item>
					{{ formatTime(item.day, item.start) }}-{{ formatTime(item.day, item.end) }} {{ ss._services[item.service].name }}
				</ng-template>
				<ng-template option let-item>
					{{ formatTime(item.day, item.start) }}-{{ formatTime(item.day, item.end) }} {{ ss._services[item.service].name }}
				</ng-template>
			</app-select>
		</div>
	</div>
	<div class="row">
		<div class="waw-textarea col-1">
			<div [class.req]="submitted&&!note.description || characterLimit">
				<note-editor [notes]="noteEditor" placeholder="Add note" label="Client notes *" size="medium" (characterLimit)="characterLimit = $event" (onChange)="noteChange($event)"></note-editor>
				<div *ngIf="submitted&&!note.description || characterLimit" class="req-text">{{characterLimit ? characterLimit : 'Note is required'}}</div>
			</div>
		</div>
	</div>

	<div class="modal-btn">
		<button mat-button color="primary" type="button" class="waw-btn _second" (click)="close()">Cancel</button>
		<button mat-flat-button color="primary" type="submit" class="waw-btn _primary" (click)="create();">Create</button>
	</div>
</div>
