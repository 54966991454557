
import { Injectable } from '@angular/core';
import { MongoService, FileService, AlertService, HttpService, CoreService } from 'wacom';
import { Router } from '@angular/router';
import { MongodatePipe } from '@pipes';
import { DatePipe } from '@angular/common';

@Injectable({
	providedIn: 'root'
})

export class UserService {
	/*
	*	Declarations
	*/
		public colors: any = ['#b71c1c', '#d32f2f', '#f44336', '#e57373', '#ffcdd2', '#880e4f', '#c2185b', '#e91e63', '#f06292', '#f8bbd0', '#4a148c', '#7b1fa2', '#9c27b0', '#ba68c8', '#e1bee7', '#1a237e', '#303f9f', '#3f51b5', '#7986cb', '#c5cae9', '#0d47a1', '#1976d2', '#2196f3', '#64b5f6', '#bbdefb', '#01579b', '#0288d1', '#03a9f4', '#4fc3f7', '#b3e5fc', '#006064', '#0097a7', '#00bcd4', '#4dd0e1', '#b2ebf2', '#004d40', '#00796b', '#009688', '#4db6ac', '#b2dfdb', '#194d33', '#388e3c', '#4caf50', '#81c784', '#c8e6c9', '#33691e', '#689f38', '#8bc34a', '#aed581', '#dcedc8', '#827717', '#afb42b', '#cddc39', '#dce775', '#f0f4c3', '#f57f17', '#fbc02d', '#ffeb3b', '#fff176', '#fff9c4', '#ff6f00', '#ffa000', '#ffc107', '#ffd54f', '#ffecb3', '#e65100', '#f57c00', '#ff9800', '#ffb74d', '#ffe0b2', '#bf360c', '#e64a19', '#ff5722', '#ff8a65', '#ffccbc', '#3e2723', '#5d4037', '#795548', '#a1887f', '#d7ccc8', '#263238', '#455a64', '#607d8b', '#90a4ae', '#cfd8dc', '#000000', '#525252', '#969696', '#d9d9d9', '#ffffff'];
		private small_avatar_size: number = 100;
		private large_avatar_size: number = 500;

		public days_of_week = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
		public file_types = ['image/jpeg', 'image/png', 'image/tiff', 'image/gif', 'image/bmp', 'image/webp', '.doc', 'application/msword', '.docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'text/plain', 'application/rtf', '.odt', 'application/vnd.oasis.opendocument.text', 'text/csv', '.ods', 'application/vnd.oasis.opendocument.spreadsheet', '.xls', 'application/vnd.ms-excel', '.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', '.ppt', 'application/vnd.ms-powerpoint', '.pptx', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', '.odp', 'application/vnd.oasis.opendocument.presentation', '.zip', 'application/zip', 'application/x-zip', 'application/x-zip-compressed', '.rar', 'application/vnd.rar', 'application/x-tar', 'application/gzip', 'application/x-gzip', 'application/x-gzip-compressed', 'application/x-bzip', 'application/x-bzip2', '.7zip', 'application/x-7z-compressed', 'application/x-compressed'];
		public headerTitle = '';
		public headerSubtitle = '';
		public setupTab = 0;
		public sidebarOn: boolean = false;
		public sidebarToggle: boolean = localStorage.getItem('pure_sidebar') != 'false';
		public mobile: boolean = this.isMobile;
		public userPopup: boolean = false;
		public backRoute: Function = null;
		public registered: any = [];
		public allowed_appointments: any = [];
		public staff: any = [];
		public clients: any = [];
		public clients_by_date: any = {};
		public deleted: any = [];
		public users: any = [];
		public _users: any = {};
		public is: any = {};
		public data: any = {};
		public _id: any;
		public avatarUrl: any;
		public avatarBig: any;
		public lastName:any;
		public firstName:any;
		public category:any;
		public location:any;
		public name: any;
		public email: any;
		public user: any;
		public letter: any;
		public update_picture:any;
		public role:any;
		public emails:any = {};
		public google: boolean;
		public fb: boolean;
		public now;
		set(_id){
			this.mongo.on('user', ()=>{
				this.user = this._users[_id];
				if(!this.user) return;
				if(this.user.email){
					this.http.post('/api/google/mails', {
						email: this.user.email
					}, resp=>{
						this.emails[this.user.email] = resp || [];
					});
				}
			});
		}
		send(email, name, title, body){
			this.http.post('/api/google/send', { email, name, title, body }, resp=>{
				//console.log(resp);
			});
		}
		constructor(private mongo: MongoService,
			private fs: FileService,
			private alert: AlertService,
			private router: Router,
			private http: HttpService,
			private mongodatePipe: MongodatePipe,
			private datePipe: DatePipe,
			private core: CoreService) {
			let path = window.location.pathname.toLowerCase();
			if(path.indexOf('/c/')==0){
				this.set(path.replace('/c/', '').split('/')[0]);
			}
			this.http.get('/api/user/me', (resp:any) => {
				if(!resp._id) return this.logout();
				localStorage.setItem('waw_user', JSON.stringify(resp));
				if(!resp.avatarUrl) resp.avatarUrl = ''; // /assets/avatar.png
				if(resp.avatarUrl=='/api/user/default.png') resp.avatarUrl = ''; // /assets/avatar.png
				if(resp.avatarUrl=='/assets/avatar.png') resp.avatarUrl = ''; // /assets/avatar.png
				if(!resp.avatarBig) resp.avatarBig = ''; // /assets/avatar.png
				if(resp.avatarBig=='/api/user/default.png') resp.avatarBig = ''; // /assets/avatar.png
				if(resp.avatarBig=='/assets/avatar.png') resp.avatarBig = ''; // /assets/avatar.png
				if(typeof resp.data != 'object') resp.data = {};
				if(typeof resp.is != 'object') resp.is = {};
				if(typeof resp.data.request != 'object') resp.data.request = {};
				if(typeof resp.data.reason != 'object') resp.data.reason = {};
				if(!resp.name) resp.name = (resp.firstName||'')+(resp.lastName&&' ')+(resp.lastName||'')
				if(!resp.name) resp.name = resp.email.split('@')[0];
				resp.letter = resp.name && resp.name.trim()[0].toUpperCase() || '';
				for (var key in resp) {
					this[key] = resp[key];
				}

				this.getUsers();
			});
		}
	/*
	*	User Management
	*/
		getUsers(cb:any=()=>{}) {
			this.users = this.mongo.get('user', {
				local: true,
				replace: {
					email: (val, cb)=>{ cb(val || 'delete@gmail.com') },
					files: (val, cb)=>{
						if(!val) val=[];
						for (let i = 0; i < val.length; i++){
							if(val[i].extension || val[i].directory) continue;
							val[i].extension = /(?:\.([^.]+))?$/.exec(val[i].name)[1];
						}
						cb(val);
					},
					_files: (val, cb, doc)=>{
						val = {};
						doc.files?.forEach(item => {
							val[item._id] = item;
						});
						cb(val);
					},
					name: (val, cb, doc)=>{
						if(!val){
							val = (doc.firstName||'')+(doc.lastName&&' ')+(doc.lastName||'')
						}
						if(!val){
							val = doc.email.split('@')[0];
						}
						cb(val);
					},
					letter: (val, cb, doc)=>{
						let second = '';
						let name = doc.name.replace(/\s+/g, ' ').trim();
						if(!name) return cb('UN');
						try{
							if(name&&name.split(' ').length>1){
								second = name.split(' ')[1][0].toUpperCase();
							}
							cb(name&&name[0].toUpperCase()+second);
						}catch(err){}
					},
					data: (field, cb, doc) => {
						if(typeof field != 'object') field = {};
						if(typeof field.request!='object') field.request = {};
						if(typeof field.working_hours !='object') field.working_hours = {};
						cb(field);
					},
					avatarUrl: (field, cb)=>{
						if(!field) field = ''; // /assets/avatar.png
						if(field=='/api/user/default.png') field = ''; // /assets/avatar.png
						if(field=='/assets/avatar.png') field = ''; // /assets/avatar.png
						cb(field);
					},
					avatarBig: (field, cb)=>{
						if(!field) field = ''; // /assets/avatar.png
						if(field=='/api/user/default.png') field = ''; // /assets/avatar.png
						if(field=='/assets/avatar.png') field = ''; // /assets/avatar.png
						cb(field);
					},
					is: this.mongo.beObj,
					_role: (val, cb, doc) => {
						if(doc.is.owner) return cb('owner');
						if(doc.is.admin) return cb('admin');
						cb(doc.role);
					}
				},
				query: {
					appointment: function(doc){
						return !!doc.appointment && !doc.deleted;
					},
					staff: function(doc){
						return (doc.is.staff || doc.is.admin || doc.is.owner) && !doc.deleted;
					},
					registered: function(doc){
						return doc.reg_email && !doc.deleted;
					},
					clients: function(doc){
						return !doc.is.staff && !doc.deleted && !doc.is.admin && !doc.is.owner;
					},
					deleted: function(doc){
						return doc.deleted;
					}
				},
				groups: {
					clients_by_date: {
						field: doc => this.datePipe.transform(this.mongodatePipe.transform(doc._id), 'shortDate'),
						allow: doc => !(doc.is.staff || doc.is.admin || doc.is.owner)
					}
				}
			}, (arr, obj) => {
				// console.log(arr);
				this.allowed_appointments = obj.appointment;
				this.registered = obj.registered;
				this.staff = obj.staff;
				this.clients = obj.clients;
				this.deleted = obj.deleted;
				this.clients_by_date = obj.clients_by_date;
				this._users = obj;
				this.now = Date.now();
				if (typeof cb == 'function') cb(true);
			});
		}
		update(){
			this.mongo.afterWhile({
				_id: this._id,
				email: this.email,
				name: this.name,
				data: this.data,
				role: this.role,
				firstName: this.firstName,
				lastName: this.lastName,
				is: this.is,
				avatarUrl: this.avatarUrl,
				avatarBig: this.avatarBig,
			}, ()=>{
				this.name = (this.firstName||'')+(this.lastName&&' ')+(this.lastName||'');
				this.mongo.update('user', {
					_id: this._id,
					email: this.email,
					name: this.name,
					data: this.data,
					role: this.role,
					firstName: this.firstName,
					lastName: this.lastName,
					is: this.is,
					avatarUrl: this.avatarUrl,
					avatarBig: this.avatarBig
				}, ()=> {
					this.now = Date.now();
				});
			});
		}
		change_password(oldPass, newPass){
			this.http.post('/api/user/changePassword', {
				newPass: newPass,
				oldPass: oldPass
			}, resp => {
				if(resp) alert('Successfully changed password');
				else alert('Failed to change password');
			});
		}
		logout(){
			localStorage.removeItem('waw_user')
			this.http.get('/api/user/logout-local', (resp:any)=> {});
			window.location.href = '/login';
		}
	/*
	*	Admin Management
	*/
		create(user, cb:any=resp=>{}, errcb:any=error=>{}, show_alert=true){
			if(user._id) return this.save(user, 'Information has been updated', ()=> {
				cb();
			}, ()=> {
				errcb();
			});
			this.http.post('/api/user/status', {
				email: user.email
			}, (resp:any) => {
				if(resp.email) {
					errcb();
					if(show_alert) {
						this.alert.show({
							text: "This email already exists",
						});
					}
				} else {
					user.name = (user.firstName||'')+(user.lastName&&' ')+(user.lastName||'');
					if(user.avatar && /^data:image\/[a-zA-Z]+;base64,/.test(user.avatarUrl)) {
						var avatar = user.avatar;
					}
					this.mongo.create('user', user, created=>{
						if(avatar) this.addAvatarAdmin(avatar, created);
						cb(created);
						this.now = Date.now()
						if(show_alert) {
							this.alert.show({
								text: (user.make_staff&&'Staff'||'Client')+' has been created.',
							});
						}
					}, (err)=> {
						errcb();
					});
				}
			})
		}
		save(user, message='',cb:any=resp=>{}, errcb:any=error=>{}){
			user.name = (user.firstName||'')+(user.lastName&&' ')+(user.lastName||'')
			if(user.avatar && /^data:image\/[a-zA-Z]+;base64,/.test(user.avatarUrl)) {
				this.addAvatarAdmin(user.avatar, user);
			}
			
			this.mongo.update('user', user, {
				name: 'admin'
			}, updated=>{
				this.now = Date.now()
				if(typeof cb === 'function') cb(updated);
				if(!message) return;
				this.alert.show({
					text: message
				});
			});
		}
		delete(user, cb:any=resp=>{}){
			user.deleted = true;
			this.mongo.update('user', user, {
				name: 'admin'
			}, ()=> {
				if(user._id == this._id) this.logout();
				this.now = Date.now()
				if(typeof cb === 'function') cb(true);
			});
		}
		deleteClient(user = this.user){
			user.deleted = true;
			this.mongo.update('user', user, ()=> {
				this.now = Date.now()
			});
		}
		return(user, cb:any=resp=>{}) {
			user.deleted = false;
			this.mongo.update('user', user, {
				name: 'admin'
			}, ()=> {
				this.now = Date.now()
				if(typeof cb === 'function') cb(true);
			});
		}
	/*
	*	Supportive
	*/
		fual(text){ // first upper all lower
			return text[0].toUpperCase() + text.substr(1).toLowerCase();
		}
	/*
	*	End of
	*/
	getFormattedAddress(e, full_address?) {
		let location_obj:any = {
			address: '',
			city: '',
			zip: '',
			country: '',
			state: '',
			lat: 0,
			lon: 0
		};
		for (var i = 0; i < e.address_components.length; i++){
			if(e.address_components[i].types.includes('street_number')) {
				location_obj.address = e.address_components[i].long_name + ' ' + location_obj.address;
			} else if(e.address_components[i].types.includes('route')) {
				location_obj.address += e.address_components[i].long_name;
			} else if(e.address_components[i].types.includes('locality') || !e.address_components[i].types.includes('locality')&& e.address_components[i].types.includes('sublocality')) {
				location_obj.city = e.address_components[i].long_name;
			} else if(e.address_components[i].types.includes('postal_code')) {
				location_obj.zip = e.address_components[i].long_name;
			} else if(e.address_components[i].types.includes('country')) {
				location_obj.country = e.address_components[i].long_name;
			} else if(e.address_components[i].types.includes('administrative_area_level_1')) {
				location_obj.state = e.address_components[i].long_name;
			}
		}
		location_obj.lat = e.geometry.location.lat();
		location_obj.lon = e.geometry.location.lng();
		return full_address&&this.convertToFullAddress(location_obj.address, location_obj.city, location_obj.zip, location_obj.country)||location_obj;
	}
	convertToFullAddress(street, city, zip, country = '') {
		return (street&&street +', '||'')+ (city&&city + ', '||'') + (zip&&zip + ', '||'') + (country||'');

	}
	cropAvatar(event: any, fileName: string, size: number, cb: any = (res) => {}) {
		let reader = new FileReader();
		reader.onload = (loadEvent) => {
			let canvasElement = this.core.document.createElement('canvas');
			let imageElement = this.core.document.createElement('img');
			imageElement.onload = () => {
				let x = 0;
				let y = 0;
				let width = imageElement.width;
				let height = imageElement.height;

				if(imageElement.width > imageElement.height) {
					height = imageElement.height;
					width = imageElement.height;
					x = (imageElement.width - imageElement.height) / 2;
				} else if(imageElement.width < imageElement.height) {
					height = imageElement.width;
					width = imageElement.width;
					y = (imageElement.height - imageElement.width) / 2;
				}

				canvasElement.width = size;
				canvasElement.height = size;
				let context = canvasElement.getContext('2d');
				context.drawImage(imageElement, x, y, width, height, 0, 0, size, size);
				let dataUrl = canvasElement.toDataURL('image/jpeg', 1);
				const file = this.dataURLtoFile(dataUrl, fileName);
				const fileList = new DataTransfer();
				fileList.items.add(file);
				event.target.files = fileList.files;
				if(typeof cb == 'function') cb(event);
			};
			imageElement.src = loadEvent.target.result;
		};
		reader.readAsDataURL(event.target.files[0]);
	};
	addAvatar(event: any) {
		this.cropAvatar(event, this._id, this.small_avatar_size, (file) => {
			this.fs.change(file, {
				part: 'user',
				type: 'image',
				accept: 'image/*',
				body: ()=>{ return { field: 'avatarUrl' } },
				cb: (file: any) => {
					this.avatarUrl = file;
				}
			});
		});

		this.cropAvatar(event, this._id, this.large_avatar_size, (file) => {
			this.fs.change(file, {
				part: 'user',
				type: 'image',
				accept: 'image/*',
				body: ()=>{ return { field: 'avatarBig' } },
				cb: (file: any) => {
					this.avatarBig = file;
				}
			});
		});		
	}
	addAvatarAdmin(event: any, user: any) {
		this.cropAvatar(event, user._id, this.small_avatar_size, (file) => {
			this.fs.change(file, {
				part: 'user',
				name: 'admin',
				type: 'image',
				accept: 'image/*',
				body: ()=>{ return { _id: user._id, field: 'avatarUrl' } },
				cb: (file: any) => {
					if(file&&user) user.avatarUrl = file;
				}
			});
		});

		this.cropAvatar(event, user._id, this.large_avatar_size, (file) => {
			this.fs.change(file, {
				part: 'user',
				name: 'admin',
				type: 'image',
				accept: 'image/*',
				body: ()=>{ return { _id: user._id, field: 'avatarBig' } },
				cb: (file: any) => {
					if(file&&user) user.avatarBig = file;
				}
			});
		});		
	}
	dataURLtoFile(dataURL, fileName) {
		var [contentType, base64] = dataURL.split(',');
		contentType = contentType.replace('data:', '');
		contentType = contentType.replace(';base64', '');

		const byteCharacters = atob(base64);
		const byteNumbers = new Array(byteCharacters.length);
	  
		for (let i = 0; i < byteCharacters.length; i++) {
		  byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
	  
		const byteArray = new Uint8Array(byteNumbers);

		const blob = new Blob([byteArray], { type: contentType });

		const file = new File([blob], fileName, { type: contentType });
	  
		return file;
	}
	get isMobile() {
		return window.innerWidth <= 1024 ? true : false;
	}

	get randomColor() {
		let index = Math.floor(Math.random() * (this.colors.length - 3*5 ));
		if( (index + 1) % 5 === 0 ) index--;
		return this.colors[index];
	}

	addFiles(event: any, parent, cb: any = (res) => {}) {
		try {
			const imageRegex = /^image\/(jpeg|png|tiff|gif|bmp|webp)$/;
			const fileRegex = /^(application\/(?:msword|vnd\.openxmlformats-officedocument\.wordprocessingml\.document|pdf|rtf|vnd\.oasis\.opendocument\.text|vnd\.ms-excel|vnd\.openxmlformats-officedocument\.spreadsheetml\.sheet|vnd\.ms-powerpoint|vnd\.openxmlformats-officedocument\.presentationml\.presentation|vnd\.oasis\.opendocument\.presentation)|text\/(?:plain|csv))$/;
			const archiveRegex = /^application\/(zip|x-zip|x-zip-compressed|vnd\.rar|x-tar|gzip|x-gzip|x-bzip|x-gzip-compressed|x-bzip|x-bzip2|x-7z-compressed|x-compressed)$/;

			var largeFile = false;
			var notAllowedFile = false;

			const filesByExtension = {};

			for(let file of event.target.files) {
				if(file.size <= 10000000) {
					const extension = /(?:\.([^.]+))?$/.exec(file.name)[1];
					if(imageRegex.test(file.type)) {
						if(!filesByExtension[extension]) filesByExtension[extension] = { extension: extension, type: 'image', fileList: new DataTransfer() }
						filesByExtension[extension].fileList.items.add(file);
					} else if(fileRegex.test(file.type)) {
						if(!filesByExtension[extension]) filesByExtension[extension] = { extension: extension, type: 'file', fileList: new DataTransfer() }
						filesByExtension[extension].fileList.items.add(file);
					} else if(archiveRegex.test(file.type)) {
						if(!filesByExtension[extension]) filesByExtension[extension] = { extension: extension, type: 'archive', fileList: new DataTransfer() }
						filesByExtension[extension].fileList.items.add(file);
					} else {
						this.alert.error({
							text: `${extension} files are not allowed`
						});
						notAllowedFile = true;
					}
				}
				else largeFile = true;
			}

			if(largeFile) {
				this.alert.error({
					text: "File Too Large"
				});
			}

			if( Object.keys(filesByExtension).length ) {
				Object.values(filesByExtension).forEach((value: any, index) => {
					event.target.files = value.fileList.files;
		
					this.fs.change(event, {
						id: 'userAddFiles',
						part: 'user',
						type: 'file',
						name: 'admin_files',
						accept: this.file_types,
						multiple: true,
						body: ()=>{ return { _id: this.user._id, parent: parent, type: value.type } },
						resp: (files)=>{
							if( index == Object.keys(filesByExtension).length - 1 ) {
								this._users[this.user._id] = this.mongo.fetch('user', {
									force: true,
									query: {
										_id: this.user._id
									}
								}, (res) => {
									this.user = res;
									if (typeof cb == 'function') cb(true);
								});
							}
						}
					});
				});		
			} else {
				if (typeof cb == 'function') cb(false);
			}
		} catch (error) {
			if (typeof cb == 'function') cb(false);
			this.alert.error({
				text: "Something went wrong"
			});
		}
	}
	
	addFolder(parent, name, cb:any=()=>{}) {
		this.http.post('/api/user/new-folder', {
			_id: this.user._id,
			parent: parent,
			name: name
		}, () => {
			this._users[this.user._id] = this.mongo.fetch('user', {
				force: true,
				query: {
					_id: this.user._id
				}
			}, (res) => {
				this.user = res;
			});
			if (typeof cb == 'function') cb(true);
		});
	}
}
