import { Directive, Input, HostListener, HostBinding } from '@angular/core';
import { MatTooltip, MAT_TOOLTIP_SCROLL_STRATEGY, TooltipPosition } from '@angular/material/tooltip';
import { CloseScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { fromEvent } from 'rxjs';

export function scrollFactory(overlay: Overlay): () => CloseScrollStrategy {
  return () => overlay.scrollStrategies.close();
}

@Directive({
	selector: '[tooltip]',
	providers: [
		MatTooltip,
		{
			provide: MAT_TOOLTIP_SCROLL_STRATEGY,
			useFactory: scrollFactory,
			deps: [Overlay],
		}
	],
})
export class TooltipDirective {
	@Input('tooltip') tooltipText: string;
	@Input() tooltipClass: string;
	@Input() tooltipShowDelay: number = 0;
	@Input() tooltipMobileDisabled: boolean = false;
	@Input() tooltipScrollContainer: string;
	@Input() tooltipPosition: TooltipPosition = 'above';
	
	constructor(private tooltip: MatTooltip, private platform: Platform) {}

	@HostListener('mouseover') onMouseover() {
		if(!this.platform.ANDROID && !this.platform.IOS && !(navigator.maxTouchPoints > 1)) {
			this.tooltip.touchGestures = 'off';
			this.tooltip.tooltipClass = this.tooltipClass;
			this.tooltip.position = this.tooltipPosition;
			this.tooltip.message = this.tooltipText;
			this.tooltip.showDelay = this.tooltipShowDelay;
			this.tooltip.show();
		}
	}
	@HostListener('mouseleave') onMouseleave() {
		this.tooltip.hide();
	}

	@HostBinding('window:scroll') onScroll() {
		return fromEvent(document.querySelector(this.tooltipScrollContainer), 'scroll');
	}

	@HostListener('click') onClick() {
		if(this.platform.ANDROID || this.platform.IOS || navigator.maxTouchPoints > 1) {
			if(!this.tooltipMobileDisabled) {
				this.tooltip.touchGestures = 'off';
				this.tooltip.tooltipClass = this.tooltipClass;
				this.tooltip.position = this.tooltipPosition;
				this.tooltip.message = this.tooltipText;
				this.tooltip.showDelay = 0;
				this.tooltip.toggle();

				if(this.tooltipScrollContainer) {
					this.onScroll().subscribe(event => {
						this.tooltip.hide();
					});
				}

				// setTimeout(() => {
				// 	if(this.tooltip._isTooltipVisible()) this.tooltip.hide();
				// }, 5000);
			}
		}
    }
}