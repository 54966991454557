import { Component, OnInit } from '@angular/core';
import { AppointmentService, UserService, ServiceService } from '@services';
import { MongoService } from 'wacom';
@Component({
	selector: 'clientbookings',
	templateUrl: './clientbookings.component.html',
	styleUrls: ['./clientbookings.component.scss']
})
export class ClientbookingsComponent implements OnInit{
	public type: string = 'allbookings' || 'canceledbookings' || 'completedbookings';

	public close: any = () => {};

	public columns: any = [
		"appointment",
		"date",
		"duration",
		"price",
		"notes"
	];

	public rows: any = [];
	
	constructor(
		public aps: AppointmentService, 
		public us: UserService, 
		public ss: ServiceService,
		private mongo: MongoService
	) {}

	ngOnInit() {
		if(this.type == 'allbookings') {
			this.columns = [
				"appointment",
				"date",
				"duration",
				"price",
				"status",
				"notes"
			];
		}

		this.mongo.on('service', () => {			
			if(this.aps.all_by_client[this.us.user?._id]) {
				const format = (rows) => {
					return rows.map(a => {
						a.appointment = this.ss._services[a.service]?.name;
						let d = new Date(a.day?.singleDate?.jsDate);
						d.setHours(a.start.split(':')[0], a.start.split(':')[1]);
						a.date = d;
						a.price = this.ss._services[a.service]?.price;
						return a;
					});
				};

				switch(this.type) {
					case 'allbookings':
						this.rows = format(this.aps.all_by_client[this.us.user?._id]);
						break;
					case 'canceledbookings':
						this.rows = format(this.aps.all_by_client[this.us.user?._id].filter(a => { return a.status == 'Canceled' }));
						break;
					case 'completedbookings':
						this.rows = format(this.aps.all_by_client[this.us.user?._id].filter(a => { return a.status == 'Completed' }));
						break;
				}
			}
		});
	}
}
