import { Injectable } from '@angular/core';
import { MongoService, AlertService } from 'wacom';

@Injectable({
	providedIn: 'root'
})
export class RoleService {
	public modules:any = [{
		name: 'Schedule',
		icon: 'icon-permission-schedule',
		sections: [{
			id: 'acccess_schedule',
			name: 'Access',
			client: true
		}]
	}, {
		name: 'Calendar',
		icon: 'icon-permission-calendar',
		sections: [{
			id: 'acccess_calendar',
			name: 'Access'
		}]
	}, {
		name: "Clients",
		icon: 'icon-permission-clients',
		sections: [{
			id: 'acccess_clients',
			name: 'Access'
		}]
	}, {
		name: "Staff",
		icon: 'icon-permission-staff',
		sections: [{
			id: 'acccess_staff',
			name: 'Access'
		}]
	}, {
		name: "Services",
		icon: 'icon-permission-services',
		sections: [{
			id: 'acccess_services',
			name: 'Access'
		}]
	}, {
		name: "Resources",
		icon: 'icon-permission-resources',
		sections: [{
			id: 'acccess_resources',
			name: 'Access'
		}]
	}, {
		name: "Setup",
		icon: 'icon-permission-setup',
		sections: [{
			id: 'acccess_setup',
			name: 'Access'
		}]
	}, {
		name: "Customer messages",
		icon: 'icon-permission-messages',
		sections: [{
			id: 'acccess_customer_messages',
			name: 'Access',
			client: true
		}]
	}];
	public _sections:any = {};
	public roles: any = [];
	public _roles: any = {};
	constructor(private mongo: MongoService, private alert: AlertService) {
		for (let i = 0; i < this.modules.length; i++){
			for (let j = 0; j < this.modules[i].sections.length; j++){
				this._sections[this.modules[i].sections[j].id] = this.modules[i].sections[j];
			}
		}
		this.roles = mongo.get('role', {
			replace: {
				config: mongo.beObj
			}
		}, (arr, obj) => {
			if(!arr.length){
				let config = {};
				for(let each in this._sections){
					config[each] = true;
				}
				this.create({config, name: "Employee"}, () => {}, false);
			}
			this._roles = obj;
		});
	}
	create(role:any={}, cb: any = (created) => {}, showAlert = true) {
		if(role._id) return this.save(role, cb);
		this.mongo.create('role', role, created=>{
			if(typeof cb === 'function') cb(created);
			if(showAlert) this.alert.show({
				text: 'Role has been created.'
			});
		});
	}
	update(role, cb: any = () => {}) {
		this.mongo.afterWhile(role, ()=> {
			this.save(role, cb);
		});
	}
	save(role, cb: any = () => {}){
		this.mongo.update('role', role, (update)=>{
			if(typeof cb === 'function') cb(update);
			this.alert.show({
				text: 'Role has been updated.'
			});
		});
	}
	delete(role, cb: any = () => {}) {
		this.mongo.delete('role', role, (deleted)=>{
			if(typeof cb === 'function') cb(deleted);
			this.alert.show({
				text: 'Role has been deleted.'
			});
		});
	}
}
