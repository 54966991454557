import { Injectable } from '@angular/core';
import { MongoService, AlertService } from 'wacom';

@Injectable({
	providedIn: 'root'
})
export class CategoryService {
	public categories: any = [];
	public _categories: any = {};
	constructor(private alert: AlertService, private mongo: MongoService) { 
		this.categories = mongo.get('category', {}, (arr, obj) => {
			this._categories = obj;
		});
	}
	create(category, cb: any = () => {}) {
		if(category._id) return this.update(category, cb);
		this.mongo.create('category', category, (created)=>{
			if(typeof cb === 'function') cb(created);
			this.alert.show({
				text: 'Category has been created.'
			});
		});
	}
	update(category, cb: any = () => {}) {
		this.mongo.afterWhile(category, ()=> {
			this.mongo.update('category', category, (updated) => {
				if(typeof cb === 'function') cb(updated);
			});
		});
	}
	delete(category, cb: any = () => {}) {
		this.mongo.delete('category', category, (deleted) => {
			if(typeof cb === 'function') cb(deleted);
		});
	}
}
