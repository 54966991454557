import { Injectable } from '@angular/core';
import { MongoService, HttpService } from 'wacom';
import { UserService } from './user.service';

@Injectable({
	providedIn: 'root'
})
export class NotificationService {
	public notifications: any = [];
	public unread_count: number = 0;
	public all_received: boolean = false;
	constructor(private mongo: MongoService, private http: HttpService, private us: UserService) {
		this.getUnreadCount();
	}
	getNotifications(skip = false, cb = (res) => {}) {
		if(!this.all_received || !skip) {
			this.http.post('/api/notifications/get', { 
				limit: 10, 
				skip: skip ? this.notifications?.length : 0,
				unread: this.us.data?.notifications_settings?.unread
			}, (resp)=> {
				if(skip && this.notifications?.length) this.notifications = this.notifications.concat(resp);
				else this.notifications = resp;
	
				if(!resp?.length) this.all_received = true;
				else this.all_received = false;
	
				if(typeof cb == 'function') cb(resp);
			});	
		} else {
			if(typeof cb == 'function') cb(true);
		}
	}
	getUnreadCount() {
		this.http.post('/api/notifications/unread_count', { }, (resp)=> {
			this.unread_count = resp;
		});
	}
	delete(notification) {
		this.mongo.delete('notification', notification, _=>{});
	}
	read(notification) {
		notification.read = true;
		this.http.post('/api/notification/update', notification, (resp)=> {
			this.unread_count--;
		});
	}
}
