<div class="containerBox flex-column" cdkScrollable hideOnScroll>
	<div class="navigation" [class.navShown]="!us.sidebarToggle" [class.without_filter]="tab=='list'">
		<div class="navigation__topbar display-sm">
			<button mat-button color="primary" type="button" class="topbar-user-btn waw-btn _second display-sm" (click)="us.userPopup = !us.userPopup;">
				<span class="icon-plus"></span>
				<span>New client</span>
			</button>
		</div>

		<div class="navigation__search search-input">
			<input type="text" placeholder="Search" name="search" [(ngModel)]="searching">
		</div>

		<div class="navigation__tab">
			<tab [(value)]="tab" [tabs]="tabs"></tab>
		</div>
		
		<div class="navigation__right" [class._filter]="tab!='list'">
			<ng-container *ngIf="tab!='list'">
				<div class="navigation__filter">
					<div class="navigation__filter-type waw-select">
						<app-select 
							placeholder="Services" 
							[(value)]="services"
							[items]="ss.all_services" 
							[clearable]="true" 
							[searchable]="true"
							[multiple]="true"
							bindLabel="name" 
							bindValue="_id" 
							icon="service"
							class="_filter"
						>
							<ng-template label let-items>
								<span class="text-elipsis" *ngIf="items?.length == 1">
									{{items[0]?.name}}
								</span>
								<span class="text-elipsis" *ngIf="items?.length > 1">
									{{items?.length}} selected
								</span>
							</ng-template>
						</app-select>
					</div>
					<div class="navigation__filter-date waw-datepicker _arrow">
						<datepicker [date]="date" (valueChange)="date=$event" class="_filter" [icon]="true"></datepicker>
					</div>
				</div>
			</ng-container>
			
			<ng-container *ngIf="tab=='list'">
				<div class="navigation__btn">
					<button mat-flat-button color="primary" class="waw-btn _primary" (click)="openClient()">
						<span class="icon-plus"></span>
						<span>New client</span>
					</button>
				</div>
			</ng-container>
			<ng-container *ngIf="tab=='notes'">
				<div class="navigation__btn">
					<button mat-flat-button color="primary" class="waw-btn _primary" (click)="openNote()">
						<span class="icon-plus"></span>
						<span>Add note</span>
					</button>
				</div>
			</ng-container>
		</div>
	</div>
	<div class="containerTab fl-grow">
		<ng-container *ngIf="tab=='list'">
			<app-clients-list class="tab-container" [searching]="searching"></app-clients-list>
		</ng-container>
		<ng-container *ngIf="tab=='history'">
			<app-service-history class="tab-container" [searching]="searching" [services]="services" [date]="date"></app-service-history>
		</ng-container>
		<ng-container *ngIf="tab=='notes'">
			<app-notes class="tab-container" [searching]="searching" [services]="services" [date]="date"></app-notes>
		</ng-container>
	</div>
</div>