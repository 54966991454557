<div class="modal-box">
	<form (ngSubmit)="save()">
		<div class="modal-title">Rebook</div>
		<button mat-icon-button type="button" class="modal-close" (click)="close()">
			<span class="modal-close__icon"></span>
		</button>
		<div>
			<div class="row">
				<div class="waw-select col-2">
					<span class="waw-select__title">Repeat*</span>
					<app-select 
						placeholder="Repeat" 
						[(value)]="repeat.kind"
						[items]="kinds" 
						[clearable]="false" 
						[searchable]="false"
						bindLabel="name"
						[required]="submitted&&!repeat.kind"
					></app-select>
					<div *ngIf="submitted&&!repeat.kind" class="req-text">Type is required</div>
				</div>
				<div class="waw-input col-2">
					<label [class.req]="submitted&&!repeat.count">
						<span class="waw-input__title">Count*</span>
						<input type="number" placeholder="Count" name="counter" [(ngModel)]="repeat.count">
						<div *ngIf="submitted&&!repeat.count" class="req-text">Count is required</div>
					</label>
				</div>
			</div>
		</div>
		<div class="modal-btn">
			<button mat-button color="primary" type="button" class="waw-btn _second" (click)="close();">Cancel</button>
			<button mat-flat-button color="primary" type="submit" class="waw-btn _primary">Save</button>
		</div>
	</form>
</div>
