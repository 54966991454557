import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { ClosedService, LocationService } from '@services';

@Pipe({
	name: 'hours'
})
export class HoursPipe implements PipeTransform {
    constructor(
        private cs: ClosedService,
        private ls: LocationService,
        private datePipe: DatePipe
    ) {}
    
	transform(hours:any, location, date, allDay = false, refresh?:any) {
		const d = new Date(date);
		d.setHours(0, 0, 0, 0);
        var day = this.datePipe.transform(new Date(date), 'EEEE');
        if (allDay) return hours;
        var res = [];
        var closed = false;
		if (location) {
			for (let i = this.cs.closeds.length-1; i >= 0; i--){
				for (let j = 0; j < this.cs.closeds[i].locations.length; j++) {
					if(
						this.cs.closeds[i].locations[j] == location && 
						(
							(
								!this.cs.closeds[i].holiday &&
								new Date(this.cs.closeds[i].start?.singleDate?.formatted) <= new Date(d) && 
								new Date(this.cs.closeds[i].end?.singleDate?.formatted) >= new Date(d)
							) ||
							(
								this.cs.closeds[i].holiday &&
								this.cs.isHoliday(new Date(d), this.cs.closeds[i].holiday, this.cs.closeds[i].substitute )
							)
						)
					) {	
						closed = true;
					}
				}
			}
		} if (!closed && location && this.ls._locations[location].data.business_hours && this.ls._locations[location].data.business_hours[day]&& this.ls._locations[location].data.business_hours[day].length) {
			let from = Number(this.ls._locations[location].data.business_hours[day][0].from.split(':')[0]);
			let to = Number(this.ls._locations[location].data.business_hours[day][this.ls._locations[location].data.business_hours[day].length - 1].to.split(':')[0]);
			if (Number(this.ls._locations[location].data.business_hours[day][this.ls._locations[location].data.business_hours[day].length - 1].to.split(':')[1]) == 0) {
				to -= 1;
			}
			for (let j = 0; j < hours.length; j++) {
				if (new Date(hours[j]).getHours() >= from && new Date(hours[j]).getHours() <= to) res.push(hours[j]);
			}
		} else {
            let from = 8;
            let to = 19;
			for (let i = 0; i < hours.length; i++) {
                if (new Date(hours[i]).getHours() >= from && new Date(hours[i]).getHours() <= to) res.push(hours[i]);
            }
		}
		return res;
	}
}