<div class="app-table-wrapper">
    <div class="app-table-container">
        <table class="table" cellpadding="0" cellspacing="0">
            <thead class="table-header">
                <tr>
                    <th scope="col" *ngFor="let column of columns; index as i" 
                        [class.sort]="sortable[column.field]" 
                        [class._desc]="sort_type.direction === 'desc' && column.field === sort_type.title"
                        [class._asc]="sort_type.direction === 'asc' && column.field === sort_type.title"
                        (click)="sort(column)"
                    >
                        <div [class.fl-c-c]="i > 0" [class.ai-c]="i == 0">
                            <span>{{column.title}}</span>
                            <ng-container *ngIf="sortable[column.field]">
                                <div>
                                    <span class="arrow arrow-up material-icons-round">arrow_drop_up</span>
                                    <span class="arrow arrow-down material-icons-round">arrow_drop_down</span>
                                </div>
                            </ng-container>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody class="table-body">
                <tr *ngIf="!rows?.length" class="empty">
                    <td [attr.colspan]="columns.length" class="empty">
                        No Results Found
                    </td>
                </tr>
                <tr *ngFor="let row of rows|page: config: sort_type">
                    <ng-container *ngFor="let column of columns">
                        <ng-template #defaultRows>
                            <td [attr.data-label]="column.title + ':&nbsp;'">
                                {{row[column.field]}}
                            </td>
                        </ng-template>
                        <ng-template #customRows>
                            <td [attr.data-label]="column.title + ':&nbsp;'">
                                <ng-container
                                    *ngTemplateOutlet="custom_cell[column.field]; context: {$implicit: row}"
                                ></ng-container>
                            </td>
                        </ng-template>
                        <ng-container
                            *ngIf="custom_cell[column.field]; then customRows; else defaultRows"
                        ></ng-container>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="footer" *ngIf="pagination">
        <!-- <div class="footer-view">
            <span>View</span>
            <app-select [(value)]="config.perPage" [items]="[5, 10, 25, 50]" [clear]="false"></app-select>
            <span>entries per page</span>
        </div> -->
        <div class="footer-result">
            <span>
                Showing 
            </span>
            <span *ngIf="rows?.length">
                {{((config.page-1)*config.perPage)+1}} to
                {{config.page*config.perPage >
                rows?.length&&rows?.length||config.page*config.perPage}}
            </span>
            <span *ngIf="!rows?.length">
                {{rows?.length}}
            </span>
            <span> of </span>
            <span>
                {{rows?.length}}
            </span>
            <span> entries </span>
        </div>
        <div class="footer-pagination">
            <button mat-icon-button type="button" [disabled]="config.page === 1" (click)="config.page = 1">
                <span class="material-icons">first_page</span>
            </button>
            <button mat-icon-button type="button" [disabled]="config.page === 1" (click)="previous()">
                <span class="material-icons">keyboard_arrow_left</span>
            </button>
            <span class="footer-pagination__page">{{config.page}}</span>
            <button mat-icon-button type="button" [disabled]="config.page*config.perPage >= rows?.length" (click)="next()">
                <span class="material-icons">keyboard_arrow_right</span>
            </button>
            <button mat-icon-button type="button" [disabled]="config.page*config.perPage >= rows?.length" (click)="lastPage()">
                <span class="material-icons">last_page</span>
            </button>
        </div>
    </div>
</div>