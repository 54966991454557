import { Injectable } from '@angular/core';
import { MongoService, AlertService } from 'wacom';

@Injectable({
	providedIn: 'root'
})
export class GroupService {
	public groups: any = [];
	public _groups: any = {};
	constructor(private alert: AlertService, private mongo: MongoService) { 
		this.groups = mongo.get('group', (arr, obj) => {
			this._groups = obj;
		});
	}
	create(group, cb: any = (created) => {}) {
		if(group._id) return this.update(group, cb);
		this.mongo.create('group', group, (created)=>{
			if(typeof cb === 'function') cb(created);
			this.alert.show({
				text: 'Service group has been created.'
			});
		});
	}
	update(group, cb: any = () => {}) {
		this.mongo.afterWhile(group, ()=> {
			this.mongo.update('group', group, (updated) => {
				if(typeof cb === 'function') cb(updated);
			});
		});
	}
	delete(group, cb: any = () => {}) {
		this.mongo.delete('group', group, (deleted) => {
			if(typeof cb === 'function') cb(deleted);
		});
	}
}
