<div class="on-link containerBox">
	<div class="page-nav">
		<div class="page-nav__left"></div>
		<button class="btn-icon page-nav__btn" (click)="openModal()"><span class="icon-plus"></span></button>
		<!-- (click)="this.modal.getModal('resourcesModal').open()" -->
	</div>
	<div class="on-link-list">
		<div class="on-link__item" *ngFor="let website of ws.websites" (click)="edit(website);">
			<div class="on-link__icon">
				<span class="icon-instagram" *ngIf="website.icon=='instagram'"></span>
				<span class="icon-facebook" *ngIf="website.icon=='facebook'"></span>
				<span class="icon-website" *ngIf="!website.icon"></span>
			</div>
			<div class="on-link__title">Website</div>
			<div class="on-link__site">{{website.name}}</div>
		</div>
	</div>
</div>