import { Component, Output, EventEmitter, Input, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { HashService, LoaderService } from '@services';
import { UiService } from 'wacom';

@Component({
	selector: 'tab',
	templateUrl: './tab.component.html',
	styleUrls: ['./tab.component.scss'],
})
export class TabComponent implements OnInit, AfterViewInit {
	@Input('tabs') in_tabs: any = [];
	@Input() value: string = '';
	@Input('loader') useLoader: boolean = true;
	@Output() valueChange = new EventEmitter(); 

	isScrollLeftDisabled = true;
  	isScrollRightDisabled = false;

	public tabs: any = [];

	get tabActiveStyle() {
		const items = Array.from(document.querySelectorAll('.tab-list .tab-list__item__btn'));
		var left = 4;
		for(let item of items) {
			if(!item.classList.contains('_active')) left += item.getBoundingClientRect().width + 4;
			else {
				return {
					width: item.getBoundingClientRect().width + 'px',
					left: left + 'px'
				};
			}
		}		
	}

	constructor(public hash: HashService, public ui: UiService, private loader: LoaderService) {}

	ngOnInit() {
		for(let tab of this.in_tabs) {
			if(typeof tab == 'string') {
				this.tabs.push({
					title: tab,
					label: tab
				});
			} else this.tabs.push(tab);
		}
		
		this.hash.on('tab', tab => {
			if(tab) this.hash.set('tab', tab);
			this.valueChange.emit(tab || this.tabs[0].title);
			document.querySelector('label[for="' + tab || this.tabs[0].title + '"]')?.scrollIntoView({ block: 'end', inline: 'center' });
		});
	}

	ngAfterViewInit() {
		document.querySelector('label[for="' + this.value + '"]').scrollIntoView({ block: 'end', inline: 'center' });
	}

	tabMouseover(tab) {
		if(tab != this.value) {
			(document.querySelector('.tab-list__active') as HTMLElement).style.opacity = '0.7';
			(document.querySelector('.tab-list__item__btn._active') as HTMLElement).style.opacity = '0.7';
		}
	}

	tabMouseout() {
		(document.querySelector('.tab-list__active') as HTMLElement).style.opacity = '1';
		for(let tab of Array.from(document.querySelectorAll('.tab-list__item__btn')) as HTMLElement[]) {
			tab.style.opacity = '1';
		}
	}

	selectTab(tab) {
		if (!this.loader.isLoaderShowing && this.useLoader && this.value != tab) this.loader.show({container: true}, document.querySelector('.containerTab'));

		this.tabMouseout();
		this.value = tab;

		document.querySelector('label[for="' + this.value + '"]').scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
		(document.querySelector('.tab-list__active') as HTMLElement).style.transition = '0.3s all ease-in-out';

		const finished = (event) => {
			setTimeout(() => {
				this.valueChange.emit(tab); 
				this.hash.set('tab', tab);
				document.querySelector('.tab-list__active').removeEventListener('transitionend', finished);
			}, 100);
		};

		const removeTransition = (event) => {
			(document.querySelector('.tab-list__active') as HTMLElement).style.transition = '';
			document.querySelector('.tab-list__active').removeEventListener('transitionend', removeTransition);
		};

		document.querySelector('.tab-list__active').addEventListener('transitionend', removeTransition, false);
		 
		if (this.useLoader) {
			document.querySelector('.tab-list__active').addEventListener('transitionend', finished, false);
		} else {
			this.valueChange.emit(tab); 
			this.hash.set('tab', tab);
		}
	}

	@HostListener('window:popstate', ['$event'])
    onPopState(event: PopStateEvent) {
		if((this.hash.get('tab') || this.tabs[0].title) != this.value) this.valueChange.emit(this.hash.get('tab') || this.tabs[0].title);
    }
}
