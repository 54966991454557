<div class="social-container">
	<div class="social">
		<div class="row mb20">
			<div class="col-2">
				<div class="social-item">
					<div class="row">
						<div class="col-1">
							<div class="social-item__title h-title">accounting software</div>
						</div>
					</div>
					<div class="row">
						<div class="col-23">
							<div class="social-item__desc">Sign into Pure Planner with 1-click</div>
							<div class="social-item__status">You are not signed.</div>
						</div>
						<div class="col-3 fl-c-c">
							<a href="#" class="social-item__login">
								<div *ngIf="false" class="social-item__logout"><span class="icon icon-exit"></span></div>
								<img *ngIf="true" src="assets/img/setup/social/setup.png" alt="" />
							</a>
						</div>
					</div>
				</div>
			</div>
			<div class="col-2">
				<div class="social-item">
					<div class="row">
						<div class="col-1 fl-sb-c">
							<div class="social-item__title h-title">Sign in with Google</div>
						</div>
					</div>
					<div class="row">
						<div class="col-23">
							<div class="social-item__desc">Sign into Pure Planner with 1-click</div>
							<div class="social-item__status">You are not signed.</div>
						</div>
						<div class="col-3 fl-c-c">
							<a href="#" class="social-item__login">
								<div *ngIf="false" class="social-item__logout"><span class="icon icon-exit"></span></div>
								<img *ngIf="true" src="assets/img/setup/social/google.png" alt="" />
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-2">
				<div class="social-item">
					<div class="row">
						<div class="col-1 fl-sb-c">
							<div class="social-item__title h-title">Sign in with facebook</div>
						</div>
					</div>
					<div class="row">
						<div class="col-23">
							<div class="social-item__desc">Sign into Pure Planner with 1-click</div>
							<div class="social-item__status">You are not signed.</div>
						</div>
						<div class="col-3 fl-c-c">
							<a href="#" class="social-item__login">
								<div *ngIf="false" class="social-item__logout"><span class="icon icon-exit"></span></div>
								<img *ngIf="true" src="assets/img/setup/social/facebook.svg" alt="" />
							</a>
						</div>
					</div>

				</div>
			</div>
			<div class="col-2">
				<div class="social-item">
					<div class="row">
						<div class="col-1 fl-sb-c">
							<div class="social-item__title h-title">Sign in with slack</div>
						</div>
					</div>
					<div class="row">
						<div class="col-23">
							<div class="social-item__desc">Sign into Pure Planner with 1-click</div>
							<div class="social-item__status">You are not signed.</div>
						</div>
						<div class="col-3 fl-c-c">
							<a href="#" class="social-item__login">
								<div *ngIf="false" class="social-item__logout"><span class="icon icon-exit"></span></div>
								<img *ngIf="true" src="assets/img/setup/social/slack.png" alt="" />
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
