<div class="modal-box">
	<div class="modal-title">{{title}}</div>
	<!-- <button mat-icon-button type="button" class="modal-close" (click)="close()">
		<span class="modal-close__icon"></span>
	</button> -->
	<div class="modal-alert-text">{{body}}</div>
	<label class="waw-switch" *ngIf="checkbox">
		<input type="checkbox" name="Repeats" [(ngModel)]="repeat">
		<span class="track">
			<span class="handle"></span>
		</span>
		<div class="waw-switch-text">with all repeatable appointments</div>
	</label>
	<div class="modal-btn">
		<button mat-button color="primary" type="button" class="waw-btn _second" (click)="close()">{{second_btn}}</button>
		<button mat-flat-button color="primary" type="submit" class="waw-btn _primary" (click)="close(); on_add(repeat)">{{primary_btn}}</button>
	</div>
</div>
