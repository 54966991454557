import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService, LocationService, LoaderService } from '@services';
import { AlertService, ModalService } from 'wacom';
@Component({
	selector: 'client',
	templateUrl: './client.component.html',
	styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit{
	public noteEditor: any;
	public profile:any = {data: {}};
	public sources = ["Walk in", "Socials", "Referral", "Advertisement"];
	public submitted:Boolean;
	public close;
	public characterLimit: string = null;
	constructor(public us: UserService,
		public ls: LocationService,
		public alert: AlertService,
		private modal: ModalService,
		private router: Router,
		private loader: LoaderService) {}

	ngOnInit(): void {
		if (this.profile.data.note) this.noteEditor = JSON.parse(this.profile.data.note);
	}
	submit() {
		this.submitted = true;
		if(!this.profile.firstName||!this.profile.lastName||!this.profile.email) return this.alert.error({
			text: "Please fill required fields",
		});
		if(this.characterLimit) return this.alert.error({
			text: "Exceeded maximum character limit",
		});

		this.loader.show({ 
			modal: true,
			transparent: true,
			preventClick: true
		}, document.querySelector('.modal-box'));

		if (this.noteEditor) this.profile.data.note = JSON.stringify(this.noteEditor);
		this.us.create(this.profile, () => {
			this.loader.remove();
			this.close();
		}, () => {
			this.loader.remove();
		});
	}
	delete() {
		if(this.us.is.admin||this.us.is.owner) {
			this.router.navigate([],{ queryParams: { modal: 'open' } });
			this.modal.show({
				component: 'deleteAlert',
				title: 'Delete user',
				body: `Are you sure you want to delete user ${this.profile.firstName} ${this.profile.lastName}?`,
				on_add: ()=> {
					this.loader.show({ 
						modal: true,
						transparent: true,
						preventClick: true
					}, document.querySelector('.modal-box'));
					this.us.delete(this.profile, () => {
						this.loader.remove();
						this.close();
					});
				}
			});
		}
	}
	fileChangeEvent(event: any): void {
		this.profile.avatar = event;
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = ()=> {
				this.profile.avatarUrl = reader.result;
				this.profile.avatarBig = reader.result;
			};
		}
	}
}
