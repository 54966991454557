import { Component, OnInit } from '@angular/core';
import { PlatformService } from '@services';
import { MongoService } from 'wacom';
@Component({
	selector: 'booking',
	templateUrl: './booking.component.html',
	styleUrls: ['./booking.component.scss']
})
export class BookingComponent{
	public close = () => {};
	public block_booking: boolean = true;

	constructor(private ps: PlatformService, private mongo: MongoService) {
		this.mongo.on('platform', () => {
			if (this.ps.platform.data.block_booking) this.block_booking = this.ps.platform.data.block_booking;
			else this.block_booking = false;
		});
	}

	save() {
		this.ps.platform.data.block_booking = this.block_booking;
		this.ps.save();
		this.close();
	}
}
