<ng-container *ngIf="type == 'week'">
    <datepicker-week [date]="date" [touchUi]="touchUi" (valueChange)="valueChange.emit($event)"></datepicker-week>
</ng-container>
<ng-container *ngIf="type == 'month'">
    <datepicker-month [month]="month" [year]="year" [touchUi]="touchUi" (valueChange)="valueChange.emit($event)" (open)="open()"></datepicker-month>
</ng-container>
<ng-container *ngIf="type == 'date'">
    <div class="date" [class._icon]="icon || darkIcon" [class._dark_icon]="darkIcon" [class._req]="required" [class._open]="isOpen" [class._disabled]="disabled" [ngClass]="[class || '']" cdkOverlayOrigin #datePicker="cdkOverlayOrigin">
        <input #input type="text" [placeholder]="placeholder" [disabled]="disabled" [(ngModel)]="formatted" (input)="inputChange($event)" (focusout)="refreshInput()">
        <button mat-icon-button color="primary" type="button" class="btn" [disabled]="disabled" (click)="!disabled ? (isOpen = true) : (isOpen = false)">
            <span class="btn-icon icon-arrow_down" style="display: block; transition: 0.15s all ease-in-out;" [ngStyle]="{'transform': isOpen ? 'rotate(-180deg)' : 'rotate(0deg)' }"></span>
        </button>
    </div>
    <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="datePicker"
        [cdkConnectedOverlayOpen]="isOpen && !disabled"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayBackdropClass]="'picker-overlay-backdrop'"
        [cdkConnectedOverlayPanelClass]="'panelClass'"
        [cdkConnectedOverlayFlexibleDimensions]="false"
        [cdkConnectedOverlayMinHeight]="340"
        (backdropClick)="isOpen=false;"
        (positionChange)="positionChange($event)"
        (detach)="isOpen=false; position = 'positionBottom'"
        (attach)="attach()"
    >
        <div class="picker" [@modal]="us.mobile ? (isOpen ? 'zoom' : isOpen) : (isOpen ? position : isOpen)">
            <div class="picker__calendar">
                <mat-calendar [startAt]="value" [selected]="value" (selectedChange)="selectedChange($event);" [minDate]="minDate" [dateFilter]="filter"></mat-calendar>
                <div class="picker__calendar-actions" *ngIf="us.mobile">
                    <button mat-button class="picker__calendar-actions__btn" (click)="clear()">Clear</button>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>
<ng-container *ngIf="type == 'calendar'">
    <mat-form-field style="display: none;">
        <input (click)="calDpUi.open();" matInput [matDatepicker]="calDpUi" [value]="value" (dateChange)="dateChange($event)" (dateInput)="dateChange($event)" hidden readonly>
        <mat-datepicker [touchUi]="true" #calDpUi panelClass="datepicker"></mat-datepicker>
    </mat-form-field>
    <mat-form-field style="position: absolute; left: 0px; bottom: -25px; z-index: -10;">
        <input (click)="calDp.open();" matInput [matDatepicker]="calDp" [value]="value" (dateChange)="dateChange($event);" (dateInput)="dateChange($event);" hidden readonly>
        <mat-datepicker [touchUi]="false" #calDp panelClass="cal">
            <mat-datepicker-actions>
                <div class="calendar-week">
                    <div class="calendar-week__block">
                        <button class="calendar-week__btn" (click)="addWeeks(1)">In 1 week</button>
                        <button class="calendar-week__btn" (click)="addWeeks(2)">In 2 weeks</button>
                        <button class="calendar-week__btn" (click)="addWeeks(3)">In 3 weeks</button>
                        <button class="calendar-week__btn" (click)="addWeeks(4)">In 4 weeks</button>
                        <button class="calendar-week__btn" (click)="addWeeks(5)">In 5 weeks</button>
                        <button class="calendar-week__btn" (click)="addWeeks(6)">In 6 weeks</button>
                    </div>
                </div>
            </mat-datepicker-actions>
        </mat-datepicker>
    </mat-form-field>
</ng-container>
<ng-container *ngIf="type == 'dashboard'">
    <datepicker-dashboard [value]="date" [touchUi]="touchUi" [interval]="interval" (valueChange)="valueChange.emit($event)"></datepicker-dashboard>
</ng-container>