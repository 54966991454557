<div class="modal-box">
	<form (ngSubmit)="save()">
		<div class="modal-title">{{profile._id&&'Edit'||'New'}} Discount</div>
		<button mat-icon-button type="button" class="modal-close" (click)="close()">
			<span class="modal-close__icon"></span>
		</button>
		<div class="row">
			<div class="waw-input col-1">
				<label [class.req]="submitted&&!profile.name">
					<span class="waw-input__title">Discount name *</span>
					<input type="text" placeholder="e.g. Student discount" name="discount" [(ngModel)]="profile.name">
					<div *ngIf="submitted&&!profile.name" class="req-text">Name is required</div>
				</label>
			</div>
		</div>
		<div class="row">
			<div class="waw-input col-2">
				<label [class.req]="submitted&&!profile.amount">
					<span class="waw-input__title">Discount value *</span>
					<input type="number" placeholder="Enter value" name="discount" [(ngModel)]="profile.amount">
					<div *ngIf="submitted&&!profile.amount" class="req-text">Value is required</div>
				</label>
			</div>
			<div class="waw-select col-2">
				<span class="waw-select__title">Discount type *</span>
				<app-select 
					placeholder="Select type" 
					[(value)]="profile.kind"
					[items]="[{ value: 'perc', label: '% Percentage' }, { value: 'amount', label: 'Amount' }]" 
					[clearable]="false" 
					[searchable]="false"
					[required]="submitted&&!profile.kind"
					bindValue="value" 
					bindLabel="label"
				></app-select>
				<div *ngIf="submitted&&!profile.kind" class="req-text">Type is required</div>
			</div>
		</div>
		<!-- <div class="modal-switch">
			<div class="waw-switch-wrap">
				<label class="waw-switch">
					<input type="checkbox" name="notifications" [(ngModel)]="profile.service">
					<span class="track">
						<span class="handle"></span>
					</span>
				</label>
				<div class="waw-switch-text">service sales</div>
			</div>
		</div>
		<div class="modal-switch">
			<div class="waw-switch-wrap">
				<label class="waw-switch">
					<input type="checkbox" name="notifications" [(ngModel)]="profile.product">
					<span class="track">
						<span class="handle"></span>
					</span>
				</label>
				<div class="waw-switch-text">product sales</div>
			</div>
		</div>
		<div class="modal-switch">
			<div class="waw-switch-wrap">
				<label class="waw-switch">
					<input type="checkbox" name="notifications" [(ngModel)]="profile.voucher">
					<span class="track">
						<span class="handle"></span>
					</span>
				</label>
				<div class="waw-switch-text">voucher sales</div>
			</div>
		</div> -->
	
	
		<div class="modal-btn">
			<button mat-button color="primary" type="button" *ngIf="!profile._id" (click)="close()" class="waw-btn _second">Cancel</button>
			<button mat-button color="primary" type="button" *ngIf="profile._id" (click)="delete()" class="waw-btn _second _delete">Delete</button>
			<button mat-flat-button color="primary" type="submit" class="waw-btn _primary">{{profile._id&&'Save'||'Create'}}</button>
		</div>
	</form>
</div>
