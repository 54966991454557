<div class="modal-box">
	<div class="modal-title">Import/Export</div>
	<button mat-icon-button type="button" class="modal-close" (click)="close()">
		<span class="modal-close__icon"></span>
	</button>
	<div class="importexport-container">
		<div class="importexport-item">
			<button mat-button color="primary" type="button" class="waw-btn _second" (click)="fileImportInput.click()">
				<span class="icon-download"></span>
				<span>Import</span>
			</button>
			<input type="file" #fileImportInput name="File Upload" [(ngModel)]="file" id="csvFileUpload" hidden (change)="fileChangeListener($event)" accept="*.csv" />
			<div class="importexport-item__text_down" (click)="tooltip.show()">*You should import .csv format<span class="importexport-item__text_down-icon icon-info" #tooltip="matTooltip" [matTooltip]="'Ensure your file includes the following columns: ' + headers.join(', ')" aria-label="More information"></span></div>
		</div>
		<div class="importexport-item">
			<button mat-button color="primary" type="button" class="waw-btn _second" (click)="export()">
				<span class="icon-upload"></span>
				<span>Export</span>
			</button>
			<div class="importexport-item__text_down">*Clients will be exported in .csv format</div>
		</div>
	</div>
</div>
