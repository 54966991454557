<div class="color">
    <span class="color__title">{{title}}</span>
    <div class="color__input" [class._open]="open_popup" (click)="open_popup = true" cdkOverlayOrigin #popup="cdkOverlayOrigin">
        <span class="color__preview" [ngStyle]="{'background-color': getValue(), 'border': isColorVeryLight() ? '1px solid #EBEFEE' : 'none'}"></span>
        <button mat-icon-button color="primary" type="button" class="btn" (click)="open_popup = true">
            <span class="btn-icon icon-arrow_down" style="display: block; transition: 0.15s all ease-in-out;" [ngStyle]="{'transform': open_popup ? 'rotate(-180deg)' : 'rotate(0deg)' }"></span>
        </button>
    </div>
    <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="popup"
        [cdkConnectedOverlayOpen]="open_popup"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayBackdropClass]="'picker-overlay-backdrop'"
        [cdkConnectedOverlayPanelClass]="'panelClass'"
        [cdkConnectedOverlayFlexibleDimensions]="false"
        [cdkConnectedOverlayMinWidth]="290"
        [cdkConnectedOverlayMinHeight]="minHeight"
        (positionChange)="positionChange($event)"
        (backdropClick)="open_popup=false;"
        (detach)="open_popup=false; position = 'positionBottom'"
    >
        <div [@modal]="us.mobile ? (open_popup ? 'zoom' : open_popup) : (open_popup ? position : open_popup)">
            <div class="color-conatiner">
                <div class="color-conatiner__header" *ngIf="custom">
                    <div class="color__preview" [ngStyle]="{'background-color': getValue(), 'border': isColorVeryLight() ? '1px solid #EBEFEE' : 'none'}"></div>
                    <div class="waw-input">
                        <input type="text" [ngModel]="value | lowercase" (ngModelChange)="valueChange.emit(getValue($event)); value = getValue($event)" [ngModelOptions]="{updateOn: 'blur'}">
                    </div>
                </div>
                <div class="color__swatches">
                    <div class="color__swatches-group" *ngFor="let group of swatches">
                        <div class="color__swatches-color" *ngFor="let color of group; index as i" [class._active]="color == getValue()" [ngStyle]="{'background-color': color, 'border': color == '#ffffff' ? '1px solid #EBEFEE' : 'none', 'color': i > 2 ? '#343434' : '#fff'}" (click)="value = color; valueChange.emit(color)"></div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>