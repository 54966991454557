<div class="containerBox">
    <div class="mb20">
        <tab [(value)]="tab" [tabs]="tabs" [loader]="false"></tab>
    </div>
    <div class="table-container tablet-col2">
        <table class="table" cellpadding="0" cellspacing="0">
            <thead>
                <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Body</th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                <!-- Staff notifications tab -->
                <ng-container *ngIf="tab == 'staff'">
                    <tr *ngFor="let notf of ps.platform.data.notification?.staff|each:2; index as i">
                        <td data-label="Title">{{notf.value.title}}</td>
                        <td data-label="Body">{{notf.value.body}}</td>
                        <td data-label="Action">
                            <button mat-icon-button color="primary" (click)="edit(notf, 'staff')" class="waw-btn _second _edit"></button>
                        </td>
                    </tr>
                </ng-container>
                <!-- Clients notifications tab -->
                <ng-container *ngIf="tab == 'clients'">
                    <tr *ngFor="let notf of ps.platform.data.notification?.client|each:2; index as i">
                        <td data-label="Title">{{notf.value.title}}</td>
                        <td data-label="Body">{{notf.value.body}}</td>
                        <td data-label="Action">
                            <button mat-icon-button color="primary" (click)="edit(notf, 'client')" class="waw-btn _second _edit"></button>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>