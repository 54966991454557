import { Component, EventEmitter, Input, OnInit, Output, OnChanges, ViewChild } from '@angular/core';
@Component({
	selector: 'durationinput',
	templateUrl: './durationinput.component.html',
	styleUrls: ['./durationinput.component.scss']
})
export class DurationinputComponent implements OnInit, OnChanges {
	@Input('data') data;
	@Input('disabled') disabled;
	@Input('id') id;
	@Input('class') class;
	@Input('required') required;
	@Input('name') name;
	@Output('onChange') onChange = new EventEmitter();
	public focus;
	public hours;
	public minutes;
	constructor() {}
	ngOnInit(): void {
		this.reset();
	}
	ngOnChanges() {
		this.reset();
	}
	validate() {
		const hours = !isNaN(Number(this.hours)) ? Number(this.hours) : 0;
		const minutes = !isNaN(Number(this.minutes)) ? Number(this.minutes) : 0;

		if(hours || minutes) {
			this.data = minutes + hours * 60;
			this.onChange.emit(this.data);
		} else {
			this.onChange.emit(undefined);
		}
	}
	reset() {
		if(this.data) {
			if(!this.focus) {
				this.hours = Math.floor(this.data / 60) > 0 ? Math.floor(this.data / 60) : '';
				this.minutes = this.data % 60 > 0 ? this.data % 60 : '';
			}
		} else {
			this.hours = '';
			this.minutes = '';
		}
	}
}
