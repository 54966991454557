import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { LoaderService, LocationService, UserService } from '@services';
import { AlertService, ModalService } from 'wacom';
import * as moment from 'moment-timezone';
import { Router } from '@angular/router';

interface TimeZoneInfo {
	name: string;
	offset: number;
}

@Component({
	selector: 'locationprofile',
	templateUrl: './locationprofile.component.html',
	styleUrls: ['./locationprofile.component.scss']
})
export class LocationprofileComponent implements OnInit, OnChanges {
	@Input('component') compon: boolean = false;
	@Input('location') location: any = {};
	@Input('submit') submit: boolean = false;
	@Output('onChange') onChange = new EventEmitter();

	public profile: any = {};
	public close;
	public autocomplete = true;
	public countries = [
		"USA",
		"Canada"
	];
	public timeZones: any;
	public submitted:Boolean;
	constructor(public us: UserService, 
		public ls: LocationService,
		public alert: AlertService,
		public modal: ModalService,
		public router: Router,
		private loader: LoaderService
		) {}
	ngOnInit(): void {
		if (Object.keys(this.location).length !== 0) this.profile = this.location;
		if (!this.profile.timezone) this.profile.timezone = moment.tz.guess();
		const timeZoneInfos: TimeZoneInfo[] = moment.tz.names().map(name => {
			const offset = moment.tz.zone(name)?.utcOffset(Date.now());
			return { name, offset };
		});
		  
		timeZoneInfos.sort((a, b) => b.offset - a.offset);

		const filteredZones = timeZoneInfos.filter(zone => !zone.name.includes('GMT'));
		  
		this.timeZones = filteredZones.map(info => {
			const offsetHours = Math.floor(Math.abs(info.offset) / 60);
			const offsetMinutes = Math.abs(info.offset) % 60;
			const sign = (info.offset / -60) < 0 ? '-' : '+';
			const offsetString = `${sign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`;
			return {_id: info.name, name: `${info.name} (UTC${offsetString})`, offset: `(UTC${offsetString})`};
		});
	}
	ngOnChanges() {
		if(this.compon) {
			this.submitted = this.submit;
		}
	}
	selectAddress(e) {
		this.profile.address = this.us.getFormattedAddress(e).address;
		this.profile.city = this.us.getFormattedAddress(e).city;
		this.profile.state = this.us.getFormattedAddress(e).state;
		this.profile.zip = this.us.getFormattedAddress(e).zip;
	}
	selectedCountry() {
		this.autocomplete = false;
		setTimeout(()=> {
			this.autocomplete = true;
		});
	}
	save() {
		this.submitted = true;
		if(!this.profile.name || !this.profile.number || !this.profile.email || !this.profile.country || !this.profile.address || !this.profile.city || !this.profile.state || !this.profile.zip || !this.profile.timezone) return this.alert.error({
			text: "Please fill required fields",
		});
		this.close();
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({component: 'businessHours', profile: this.profile});
	}
	delete() {
		this.modal.show({
			component: 'deleteAlert',
			title: 'Delete location',
			body: `Are you sure you want to delete location ${this.profile.name}`,
			on_add: ()=> {
				this.loader.show({ 
					modal: true,
					transparent: true,
					preventClick: true
				}, document.querySelector('.modal-box'));

				this.ls.delete(this.profile, () => {
					this.loader.remove();
					this.close();
				});
			}
		});
	}
}
