<div class="profile-files profile-section" [@tabInOut]="true">
    <div class="profile-link__nav">
        <div class="profile-link__nav__back"></div>
        <div class="profile-link__nav__title">Files</div>
        <button mat-flat-button color="primary" class="waw-btn _primary profile-link__nav__btn" [matMenuTriggerFor]="new" aria-label="Open the 'New' menu">
            <span class="icon-plus"></span>
            <span>New</span>
        </button>
        <mat-menu #new="matMenu" xPosition="after" [yPosition]="us.mobile ? 'above' : 'below'" backdropClass="menuBackdrop">
            <div (click)="path.length >= 3 ? $event.stopPropagation() : ''" [tooltip]="path.length >= 3 ? 'Folder nesting limit reached' : ''" tooltipClass="fileTooltip" tooltipShowDelay="100" tooltipPosition="below">
                <button class="matMenu__item" [disabled]="path.length >= 3" mat-menu-item disableRipple matRipple matRippleColor="#0000000f" (click)="folderModal()">
                    <span class="matMenu__item-icon material-icons-round notranslate">create_new_folder</span>
                    <span>New folder</span>
                </button>
            </div>
            <button class="matMenu__item" mat-menu-item disableRipple matRipple matRippleColor="#0000000f" (click)="FileInput.click()">
                <span class="matMenu__item-icon material-icons-round notranslate">upload_file</span>
                <span>File upload</span>
            </button>
        </mat-menu>
        <input type="file" #FileInput [accept]="us.file_types" multiple hidden (change)="addFile($event); FileInput.value = null;" name="files" id="addFiles">
    </div>
    <div class="profile-files__path">
        <ng-container *ngFor="let directory of path; let last = last">
            <ng-container *ngIf="!last">
                <button mat-button class="profile-files__path-directory" (click)="selectedFolder = directory; initializePath()" [tooltip]="directory.name" tooltipClass="fileTooltip" tooltipShowDelay="400" tooltipPosition="below" tooltipMobileDisabled="true">{{directory.name}}</button>
                <span class="profile-files__path-separator">/</span>
            </ng-container>
            <ng-container *ngIf="last">
                <button mat-button class="profile-files__path-directory _selected" [tooltip]="directory.name" tooltipClass="fileTooltip" tooltipShowDelay="400" tooltipPosition="below" tooltipMobileDisabled="true" [matMenuTriggerFor]="new">{{directory.name}}</button>
            </ng-container>
        </ng-container>
    </div>
    <div class="profile-files__body" [class._dragActive]="drag" (dragenter)="onDragenter($event)" cdkOverlayOrigin #FilePreview="cdkOverlayOrigin" #Move="cdkOverlayOrigin">
        
        <ng-container *ngIf="folders?.length">
            <div class="profile-files__section">Folders</div>
            <div class="profile-files__container">
                <div class="profile-files__item" *ngFor="let folder of folders; index as i" (click)="selectedFolder = folder; initializePath()">
                    <div class="profile-files__item__head">
                        <span class="profile-files__item__head-icon material-icons-round notranslate">folder</span>
                        <div class="profile-files__item__head-title text-elipsis" [tooltip]="folder.name" tooltipClass="fileTooltip" tooltipShowDelay="400" tooltipPosition="below" tooltipMobileDisabled="true">{{folder.name}}</div>
                        <button class="profile-files__item__head-more" mat-icon-button (click)="$event.stopPropagation()" [matMenuTriggerFor]="folderActions" tooltip="More actions" tooltipClass="fileTooltip" tooltipShowDelay="400" tooltipPosition="below" tooltipMobileDisabled="true" aria-label="Open a menu with more actions">
                            <span class="material-icons notranslate">more_vert</span>
                        </button>
                        <mat-menu #folderActions="matMenu" xPosition="after" [yPosition]="us.mobile ? 'above' : 'below'" backdropClass="menuBackdrop">
                            <button class="matMenu__item" mat-menu-item disableRipple matRipple matRippleColor="#0000000f" (click)="folderModal(folder)">
                                <span class="matMenu__item-icon material-icons-round notranslate">drive_file_rename_outline</span>
                                <span>Rename</span>
                            </button>
                            <button class="matMenu__item" mat-menu-item disableRipple matRipple matRippleColor="#0000000f" (click)="moveModal(folder)">
                                <span class="matMenu__item-icon material-icons-round notranslate">drive_file_move</span>
                                <span>Move</span>
                            </button>
                            <button class="matMenu__item" mat-menu-item disableRipple matRipple matRippleColor="#0000000f" (click)="deleteFolder(folder)">
                                <span class="matMenu__item-icon material-icons-round notranslate">delete</span>
                                <span>Delete</span>
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="files?.length">
            <div class="profile-files__section" [class.mt20]="folders?.length">Files</div>
            <div class="profile-files__container">
                <div class="profile-files__item" *ngFor="let file of files; index as i" (click)="filePreview = file">
                    <div class="profile-files__item__head">
                        <div class="profile-files__item__head-title text-elipsis" [tooltip]="file.name" tooltipClass="fileTooltip" tooltipShowDelay="400" tooltipPosition="below" tooltipMobileDisabled="true">{{file.name}}</div>
                        <button class="profile-files__item__head-more notranslate" mat-icon-button (click)="$event.stopPropagation()" [matMenuTriggerFor]="fileActions" tooltip="More actions" tooltipClass="fileTooltip" tooltipShowDelay="400" tooltipPosition="below" tooltipMobileDisabled="true" aria-label="Open a menu with more actions">
                            <span class="material-icons">more_vert</span>
                        </button>
                        <mat-menu #fileActions="matMenu" xPosition="after" [yPosition]="us.mobile ? 'above' : 'below'" backdropClass="menuBackdrop">
                            <button class="matMenu__item" mat-menu-item disableRipple matRipple matRippleColor="#0000000f" (click)="downloadFile(file)">
                                <span class="matMenu__item-icon material-icons-round notranslate">file_download</span>
                                <span>Download</span>
                            </button>
                            <button class="matMenu__item" mat-menu-item disableRipple matRipple matRippleColor="#0000000f" (click)="fileModal(file)">
                                <span class="matMenu__item-icon material-icons-round notranslate">drive_file_rename_outline</span>
                                <span>Rename</span>
                            </button>
                            <button class="matMenu__item" mat-menu-item disableRipple matRipple matRippleColor="#0000000f" (click)="moveModal(file)">
                                <span class="matMenu__item-icon material-icons-round notranslate">drive_file_move</span>
                                <span>Move</span>
                            </button>
                            <button class="matMenu__item" mat-menu-item disableRipple matRipple matRippleColor="#0000000f" (click)="deleteFile(file)">
                                <span class="matMenu__item-icon material-icons-round notranslate">delete</span>
                                <span>Delete</span>
                            </button>
                        </mat-menu>
                    </div>
                    <div class="profile-files__item__container img" *ngIf="file.type == 'image'"><img [src]="file.url" alt="profile files" draggable="false"/></div>
                    <div class="profile-files__item__container file" *ngIf="file.type == 'file' || file.type == 'archive'">
                        <div class="profile-files__item__container file__icon" [ngClass]="{ 'blue': file.extension === 'doc' || file.extension === 'docx' || file.extension === 'odt', 'red': file.extension === 'pdf' || file.extension === 'ppt' || file.extension === 'pptx' || file.extension === 'odp', 'green': file.extension === 'csv' || file.extension === 'ods' || file.extension === 'xls' || file.extension === 'xlsx', 'yellow': file.type == 'archive'}">{{file.extension}}</div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="!files?.length && !folders?.length">
            <div class="profile-files__empty">
                <span class="profile-files__empty-icon material-icons-round notranslate">upload_file</span>
                <span class="profile-files__empty-text">No files have been uploaded for this client</span>
            </div>
        </ng-container>

        <div class="profile-files__drag" *ngIf="drag" #DropArea (dragover)="onDragover($event)" (dragleave)="onDragleave($event)" (drop)="onDrop($event, FileInput)"></div>
    </div>

    <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="FilePreview"
        [cdkConnectedOverlayOpen]="filePreview"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayBackdropClass]="'file-preview-backdrop'"
        [cdkConnectedOverlayPanelClass]="'file-preview-panel'"
        [cdkConnectedOverlayFlexibleDimensions]="false"
        (backdropClick)="filePreview=null"
        (attach)="!us.mobile ? zoom = 1 : zoom = 0.9; getTextFileContent(); sizeCorrectionForFile()"
        (positionChange)="correctionImageWidth()"
        (detach)="filePreview=null"
    >
        <div class="file-preview__head">
            <div class="file-preview__head__group" [class.zoom]="filePreview.extension == 'pdf'">
                <button class="file-preview__head-close" (click)="filePreview=null" mat-icon-button color="accent" tooltip="Close" tooltipClass="fileTooltip" tooltipShowDelay="400" tooltipPosition="below" tooltipMobileDisabled="true" aria-label="Close">
                    <span class="material-icons-round notranslate">arrow_back_ios</span>
                </button>
                <div class="file-preview__head-name text-elipsis" [tooltip]="filePreview.name" tooltipClass="fileTooltip" tooltipShowDelay="400" tooltipPosition="below" tooltipMobileDisabled="true" (click)="fileModal(filePreview)">{{filePreview.name}}</div>
            </div>

            <div class="file-preview__head__group" *ngIf="filePreview.extension == 'pdf'">
                <button class="file-preview__head-action" [disabled]="zoom <= 0.1" (click)="zoomOut()" mat-icon-button color="accent" tooltip="Zoom out" tooltipClass="fileTooltip" tooltipShowDelay="400" tooltipPosition="above" tooltipMobileDisabled="true" aria-label="Zoom out">
                    <span class="material-icons-round notranslate">remove</span>
                </button>
                <button class="file-preview__head-action" (click)="zoomIn()" mat-icon-button color="accent" tooltip="Zoom in" tooltipClass="fileTooltip" tooltipShowDelay="400" tooltipPosition="above" tooltipMobileDisabled="true" aria-label="Zoom in">
                    <span class="material-icons-round notranslate">add</span>
                </button>
            </div>

            <div class="file-preview__head__group">
                <button class="file-preview__head-action" (click)="deleteFile(filePreview)" mat-icon-button color="accent" tooltip="Delete" tooltipClass="fileTooltip" tooltipShowDelay="400" tooltipPosition="below" tooltipMobileDisabled="true" aria-label="Delete">
                    <span class="material-icons-round notranslate">delete</span>
                </button>
                <button class="file-preview__head-action" (click)="downloadFile(filePreview)" mat-icon-button color="accent" tooltip="Download" tooltipClass="fileTooltip" tooltipShowDelay="400" tooltipPosition="below" tooltipMobileDisabled="true" aria-label="Download">
                    <span class="material-icons-round notranslate">file_download</span>
                </button>
            </div>
        </div>
        <div class="file-preview__img" *ngIf="filePreview.type == 'image'">
            <img [src]="filePreview.url" alt="profile files" draggable="false" #Image/>
        </div>
        <ng-container *ngIf="filePreview.type != 'image'">
            <ng-container [ngSwitch]="filePreview.extension">
                <div class="file-preview__pdf" *ngSwitchCase="'pdf'">
                    <pdf-viewer 
                        [src]="filePreview.url"
                        [show-all]="true"
                        [original-size]="!us.mobile"
                        [zoom-scale]="'page-width'"
                        [render-text]="true"
                        [fit-to-page]="false"
                        [autoresize]="true"
                        [zoom]="zoom"
                        (click)="closeFilePreview($event)"
                        (after-load-complete)="addPDFScrollListener()"
                    ></pdf-viewer>
                </div>
                <div class="file-preview__txt" *ngSwitchCase="'txt'" (click)="filePreview=null" (scroll)="addBackgroundForHeadOnScroll($event)">
                    <div class="file-preview__txt__content" (click)="$event.stopPropagation()">
                        <pre>{{this.filePreview.content}}</pre>
                    </div>
                </div>
                <div class="file-preview__does-not-open" *ngSwitchDefault>No preview available</div>
            </ng-container>
        </ng-container>
    </ng-template>

    <ng-template #moveRef let-file>
        <div class="folder-tree col-1">
            <div class="ai-c mb15">
                <span class="folder-tree__location">Current location: </span>
                <button class="folder-tree__item _current" type="button" mat-button 
                    [attr.aria-label]="getFolder(file.profile[file.component.input])?.name" 
                    (click)="expandFolder(file.profile[file.component.input])"
                >
                    <span class="material-icons-round notranslate">folder</span>
                    <span class="folder-tree__item-text">{{getFolder(file.profile[file.component.input])?.name}}</span>
                </button>
            </div>

            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding matTreeNodePaddingIndent="30">
                    <button class="folder-tree__toggle" type="button" mat-icon-button disabled></button>
                    <div [tooltip]="file.profile._id == node._id ? 'Can\'t move folder into itself' : file.profile[file.component.input] == node._id ? 'Current location' : ''" tooltipClass="fileTooltip" tooltipShowDelay="100" tooltipPosition="below" style="display: inline-block;">
                        <button class="folder-tree__item" type="button" mat-button 
                            [color]="file.profile[file.component.input] != node._id ? 'primary' : ''" 
                            [class._current]="file.profile[file.component.input] == node._id"
                            [class._active]="file.profile[file.component.newValue] == node._id && file.profile[file.component.newValue] != file.profile[file.component.input]" 
                            [attr.aria-label]="node.name" 
                            [disabled]="file.profile._id == node._id" 
                            (click)="file.profile[file.component.newValue] != node._id ? file.profile[file.component.newValue] = node._id : file.profile[file.component.newValue] = file.profile[file.component.input]"
                        >
                            <span class="material-icons-round notranslate">folder</span>
                            <span class="folder-tree__item-text">{{node.name}}</span>
                        </button>
                    </div>
                </mat-tree-node>
                <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="30">
                    <button class="folder-tree__toggle" type="button" [disabled]="file.profile._id == node._id" mat-icon-button matTreeNodeToggle
                            [attr.aria-label]="'Toggle ' + node.name">
                        <span class="material-icons-round notranslate">{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}</span>
                    </button>
                    <div [tooltip]="file.profile._id == node._id ? 'Can\'t move folder into itself' : file.profile[file.component.input] == node._id ? 'Current location' : ''" tooltipClass="fileTooltip" tooltipShowDelay="100" tooltipPosition="below" style="display: inline-block;">
                        <button class="folder-tree__item" type="button" mat-button 
                            [color]="file.profile[file.component.input] != node._id ? 'primary' : ''" 
                            [class._current]="file.profile[file.component.input] == node._id"
                            [class._active]="file.profile[file.component.newValue] == node._id && file.profile[file.component.newValue] != file.profile[file.component.input]" 
                            [attr.aria-label]="node.name" 
                            [disabled]="file.profile._id == node._id" 
                            (click)="file.profile[file.component.newValue] != node._id ? file.profile[file.component.newValue] = node._id : file.profile[file.component.newValue] = file.profile[file.component.input]"
                        >
                            <span class="material-icons-round notranslate">folder</span>
                            <span class="folder-tree__item-text">{{node.name}}</span>
                        </button>
                    </div>
                </mat-tree-node>
            </mat-tree>
        </div>
    </ng-template>
</div>