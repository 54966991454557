import { Injectable } from '@angular/core';
import { MongoService, AlertService } from 'wacom';

@Injectable({
	providedIn: 'root'
})
export class DiscountService {
	public discounts: any = [];
	public _discounts: any = {};
	constructor(private alert: AlertService,private mongo: MongoService) { 
		this.discounts = mongo.get('discount', {}, (arr, obj) => {
			this._discounts = obj;
		});
	}
	create(discount, cb: any = () => {}) {
		if(discount._id) return this.update(discount, cb);
		this.mongo.create('discount', discount, (created)=>{
			if(typeof cb === 'function') cb(created);
			this.alert.show({
				text: 'Discount has been created.'
			});
		});
	}
	update(discount, cb: any = () => {}) {
		this.mongo.afterWhile(discount, ()=> {
			this.mongo.update('discount', discount, (updated) => {
				if(typeof cb === 'function') cb(updated);
			});
		});
	}
	delete(discount, cb: any = () => {}) {
		this.mongo.delete('discount', discount, (deleted) => {
			if(typeof cb === 'function') cb(deleted);
		});
	}
}
