import { Component, OnInit, Input } from '@angular/core';
import { NotificationService, PlatformService, UserService } from '@services';
import { AngularFireMessaging } from '@angular/fire/compat/messaging'
import { AlertService } from 'wacom';

@Component({
	selector: 'notificationssettings',
	templateUrl: './notificationssettings.component.html',
	styleUrls: ['./notificationssettings.component.scss']
})
export class NotificationssettingsComponent implements OnInit {
    @Input() modal: boolean = true;
	public close = () => {};
	public firebase_token: any = false;
	public notifications_are_supported = true;
	constructor(public us: UserService, private ns: NotificationService, public ps: PlatformService, private angularFireMessaging: AngularFireMessaging, private alert: AlertService) {}
	ngOnInit(): void {
		if ('PushManager' in window && 'Notification' in window) {
			this.notifications_are_supported = true;
		} else {
			this.notifications_are_supported = false;
		}
		if (localStorage.getItem('firebase_token') != "false") {
			this.firebase_token = localStorage.getItem('firebase_token');
		} else {
			this.firebase_token = false;
		}
	}
	setNtfStgs(stg: string, value: any) {
		if (!this.us.data.notifications_settings) this.us.data.notifications_settings = {};
		this.us.data.notifications_settings[stg] = value;
		this.us.update();
	}
	getNtfStgs(stg: string, def: any = true) {
		if (!this.us.data.notifications_settings) this.us.data.notifications_settings = {};
		if (this.us.data?.notifications_settings[stg] != undefined) {
			return this.us.data.notifications_settings[stg];
		} else {
			return def;
		}
	}
	setFirebaseToken(event: boolean) {
		if (event) {
			if ('Notification' in window) {
				this.angularFireMessaging.requestPermission.subscribe({
					next: (permission) => {
						if (permission === 'granted') {
							this.angularFireMessaging.requestToken.subscribe({
								next: (token) => {
									if (!this.us.data.firebase_tokens) this.us.data.firebase_tokens = [];
									this.us.data.firebase_tokens.push(token);
									this.us.update();
									this.firebase_token = token;
									localStorage.setItem('firebase_token', token);
									this.alert.show({
										text: 'You will receive desktop notifications'
									});
								},
								error: (err) => {
									  console.error("Error:", err);
									this.alert.error({
										text: 'An error occurred while creating the token'
									});
								}
							});
						} else if (permission === 'denied') {
							this.alert.show({
								text: 'You have not given permission for notifications'
							});
						}
					},
					error: (err) => {
						console.error("Error:", err);
					  	this.alert.error({
						  	text: 'An error occurred while getting permission for notifications'
					  	});
				  	}
				})
			}
		} else {
			this.angularFireMessaging.deleteToken(this.firebase_token).subscribe({
				next: (res) => {
					this.us.data.firebase_tokens.splice(this.us.data.firebase_tokens.findIndex(t => {return t == this.firebase_token}), 1);
					this.us.update();
					localStorage.setItem('firebase_token', 'false');
					this.alert.show({
						text: 'You will no longer receive desktop notifications'
					});
				},
				error: (err) => {
					console.error("Error:", err);
					this.alert.error({
						text: 'An error occurred while deleting the token'
					});
				}
			});
		}
	}
}
