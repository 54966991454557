<span class="fl-sb-c">
    <span class="note__title">{{label}}</span>
    <span class="note__title" *ngIf="size">{{characterCount}}/{{characterLimit}}</span>
</span>
<div class="NgxEditor__Wrapper" [class._hover]="hover" [class._focus]="on_focus">
    <ngx-editor-menu 
        [editor]="editor" 
        [toolbar]="toolbar" 
        [customMenuRef]="customMenu"
        [disabled]="disabled"
        [colorPresets]="colorPresets"
    ></ngx-editor-menu>
    <ngx-editor
        [editor]="editor"
        [disabled]="disabled"
        name="notes"
        [(ngModel)]="notes"
        (ngModelChange)="characterCountCheck($event); onChange.emit($event)"
        [placeholder]="placeholder || ''"
        (mouseenter)="!disabled ? hover = true : ''"
        (mouseleave)="!disabled ? hover = false : ''"
    ></ngx-editor>
</div>
<ng-template #customMenu>
    <div class="NgxEditor__Seperator"></div>
    <div class="NgxEditor__MenuItem NgxEditor__MenuItem--Icon">
        <div class="NgxEditor__MenuItem--IconContainer" 
            [ngClass]="{'NgxEditor__MenuItem--Active': emojiOpen}" 
            title="Emoji"
            (click)="emojiOpen = !emojiOpen"  
            cdkOverlayOrigin 
            #trigger="cdkOverlayOrigin"
            style="user-select: none; transition: 0s;"
        >
            <i class="material-icons">sentiment_satisfied_alt</i>
        </div>
    </div>

    <!-- Emoji picker -->
    <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="emojiOpen"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayBackdropClass]="'picker-overlay-backdrop'"
        [cdkConnectedOverlayFlexibleDimensions]="true"
        (backdropClick)="emojiOpen=false"
        (detach)="emojiOpen = false; focus();"
    >
        <emoji-mart 
            title="Pick your emoji…" 
            emoji="point_up" 
            [perLine]="perLine"
            [isNative]="true" 
            [totalFrequentLines]="2"
            (emojiSelect)="select($event)"
            [@modal]="us.mobile ? (emojiOpen ? 'zoom' : emojiOpen) : ''"
        ></emoji-mart>
    </ng-template>
</ng-template>

<div class="backdrop"></div>