<div class="auth">
    <div class="auth-container">
        <form class="auth-left" (ngSubmit)="reset()">
			<div class="flex-column">
                <h1 class="auth__title" style="margin-bottom: 25px;">Verification</h1>
                <h1 class="auth__text mb30">We've sent a code to <strong>{{user.email}}</strong></h1>
				<div class="waw-input _not_change_on_mobile">
                    <div class="code" [class.req]="submitted&&(!user.code || user.code.length != 6) || error">
                        <input #InputCode type="text" name="num_1" id="num_1" inputmode="numeric" placeholder="_" aria-label="First digit of the code" autocomplete="one-time-code">
                        <input #InputCode type="text" name="num_2" id="num_2" inputmode="numeric" placeholder="_" aria-label="Second digit of the code" autocomplete="off">
                        <input #InputCode type="text" name="num_3" id="num_3" inputmode="numeric" placeholder="_" aria-label="Third digit of the code" autocomplete="off">
                        <input #InputCode type="text" name="num_4" id="num_4" inputmode="numeric" placeholder="_" aria-label="Fourth digit of the code" autocomplete="off">
                        <input #InputCode type="text" name="num_5" id="num_5" inputmode="numeric" placeholder="_" aria-label="Fifth digit of the code" autocomplete="off">
                        <input #InputCode type="text" name="num_6" id="num_6" inputmode="numeric" placeholder="_" aria-label="Six digit of the code" autocomplete="off">
                    </div>
                    <div *ngIf="submitted&&(!user.code || user.code.length != 6) || error" class="req-text">{{error ? error : 'Enter verification code'}}</div>
                </div>
			</div>
			<div>
                <button mat-flat-button color="primary" type="submit" class="waw-btn _primary _not_change_on_mobile">Reset password</button>
                <div class="auth__note">
                    <span class="auth__note-text">Didn't get a code?</span>
                    <a class="auth__note-link" (click)="resend()">Resend code</a>
                </div>
            </div>
		</form>

		<div class="auth-right">
            <div class="auth__logo">
                <img class="auth__logo-img" width="80" height="80" src="assets/sign/logo.svg" alt="Logo">
                <span class="auth__logo-text notranslate">Pure Planner</span>
            </div>
            <div class="auth__preview-1"><img width="305" height="159" src="assets/sign/preview_1.png"></div>
            <div class="auth__preview-2"><img width="305" height="159" src="assets/sign/preview_2.png"></div>
            <div class="auth__preview-3"><img width="305" height="159" src="assets/sign/preview_3.png"></div>
        </div>
	</div>
</div>
