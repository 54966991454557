import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoaderService, UserService } from '@services';
import { AlertService } from 'wacom';

@Component({
  selector: 'app-working-days',
  templateUrl: './working-days.component.html',
  styleUrls: ['./working-days.component.scss']
})
export class WorkingDaysComponent implements OnInit {
	@Input('component') compon: boolean = false;
	@Input('staff') staff: any = {};
	@Input('submit') submit: boolean = false;
	@Output('onChange') Change = new EventEmitter();
	
	constructor(public us: UserService, private loader: LoaderService, private alert: AlertService) { }
	ngOnInit(): void {
		if (Object.keys(this.staff).length !== 0) {
			this.selected_user = this.staff;
		}
		this.onChange();
	}
	ngOnChanges(): void {
		if(this.compon) {
			this.submitted = this.submit;
		}
	}
	public selected_user:any = {};
	public close;
	public submitted:boolean;
	onChange() {
		if(!this.selected_user.data) this.selected_user.data = {};
		if(!this.selected_user.data.working_hours) this.selected_user.data.working_hours = {};
		if(!this.selected_user.data.working_hours.default) this.selected_user.data.working_hours.default = {};
		for (let i = 0; i < this.us.days_of_week.length; i++) {
			if(!this.selected_user.data.working_hours.default[this.us.days_of_week[i]]) this.selected_user.data.working_hours.default[this.us.days_of_week[i]] = []
		}
	}
	convertTimeToMinutes(time: string) {
		return Number(time.split(':')[0]) * 60 + Number(time.split(':')[1]);
	}
	addHour(day) {
		if(this.selected_user.data.working_hours.default[day]?.length === 0) {
			this.selected_user.data.working_hours.default[day].push({from: '08:00', to: '20:00'});
		} else {
			const prevHour = this.selected_user.data.working_hours.default[day][this.selected_user.data.working_hours.default[day].length - 1];
			const endOfPrevHour = this.convertTimeToMinutes(prevHour.to);
			var startOfHour = (endOfPrevHour + 60) < (24 * 60) ? (endOfPrevHour + 60) : (23 * 60);
			var endOfHour = (startOfHour + 60) < (24 * 60) ? (startOfHour + 60) : (23 * 60 + 59);
			this.selected_user.data.working_hours.default[day].push({from: `${ String( Math.floor(startOfHour / 60) ).padStart(2, '0') }:${ String( startOfHour % 60 ).padStart(2, '0') }`, to: `${ String( Math.floor(endOfHour / 60) ).padStart(2, '0') }:${ String( endOfHour % 60 ).padStart(2, '0') }`});
		}
	}
	save() {
		this.loader.show({ 
			modal: true,
			transparent: true,
			preventClick: true
		}, document.querySelector('.modal-box'));

		this.submitted = true;
		for(let day of this.us.days_of_week) {
			for(const [index, hour] of this.selected_user.data.working_hours.default[day].entries()) {
				if(!hour.from || !hour.to) {
					this.loader.remove();
					return this.alert.error({
						text: "Please fill required fields",
					});
				}
				if( index > 0 && this.convertTimeToMinutes(hour.from) <= this.convertTimeToMinutes(this.selected_user.data.working_hours.default[day][index-1].to) || !!this.selected_user.data.working_hours.default[day]?.[index+1] && this.convertTimeToMinutes(hour.to) >= this.convertTimeToMinutes(this.selected_user.data.working_hours.default[day][index+1].from) || this.convertTimeToMinutes(hour.to) <= this.convertTimeToMinutes(hour.from)) {
					this.loader.remove();
					return this.alert.error({
						text: "Please resolve the time conflict",
					});
				}
			}
		}
		
		this.us.create(this.selected_user, () => {
			this.loader.remove();
			this.close();
		}, () => {
			this.loader.remove();
		});
	}
}
