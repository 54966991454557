import { Component, OnInit, OnChanges, Output, EventEmitter, ElementRef, ViewChild, Input } from '@angular/core';
@Component({
	selector: 'timepicker',
	templateUrl: './timepicker.component.html',
	styleUrls: ['./timepicker.component.scss'],
})
export class TimepickerComponent implements OnInit, OnChanges {

	@ViewChild('Hour', { static: false }) Hour: ElementRef;

	@ViewChild('Minute', { static: false }) Minute: ElementRef;
	
	@Input() value: string = '';

	@Input() label: string = '';

	@Input() disabled: boolean = false;

	@Input() required: boolean = false;

	@Input() requiredText: string = '';
	
	@Output('valueChange') valueChange = new EventEmitter(); // 14:00

	public focus: boolean = false;

	public model: any = {hour: 8, minute: 0, period: 'AM'};

	get hour() {
		if (this.model.hour == null) return '';
		else return String(this.model.hour).padStart(2, '0');
	}

	get minute() {
		if (this.model.minute == null) return '';
		else return String(this.model.minute).padStart(2, '0');
	}

	get str() {
		let res: string = '';
		if (this.model.hour == null || this.model.minute == null) return res;
		else {
			res += this.hour + ':' + this.minute + ' ' + this.model.period;
			return res;
		}
	}

	ngOnInit(): void {
		if (!this.value) {
			this.model.hour = null;
			this.model.minute = null;
		} else {
			this.model.hour = +Number(this.value.split(':')[0]) % 12 || 12;
			this.model.minute = Number(this.value.split(':')[1]);
			this.model.period = +Number(this.value.split(':')[0]) < 12 ? 'AM' : 'PM';
		}
	}

	ngOnChanges(): void {
		this.ngOnInit();
	}

	changeHour(event): void {
		if(event == '') this.model.hour = null;
		else {
			let h = Number(event);
			if (h > 12) h = 12;
			if (h < 1) h = 1;
			this.model.hour = h;
			this.Hour.nativeElement.value = this.hour;
		}
		this.valueChange.emit(this.convert());
	}

	changeMinute(event): void {
		if(event == '') this.model.minute = null;
		else {
			let m = Number(event);
			if (m >= 60) m %= 60;
			this.model.minute = m;
			this.Minute.nativeElement.value = this.minute;
		}
		this.valueChange.emit(this.convert());
	}

	changePeriod(): void {
		if (!this.disabled) {
			this.model.period == 'AM' ? this.model.period = 'PM' : this.model.period = 'AM';
			this.valueChange.emit(this.convert());
		}
	}

	convert(): string {
		if (this.model.hour == null || this.model.minute == null) return '';
		var hours = Number(this.model.hour);
		var minutes = Number(this.model.minute);
		if(this.model.period == "PM" && hours<12) hours = hours+12;
		if(this.model.period == "AM" && hours==12) hours = hours-12;
		var sHours = hours.toString();
		var sMinutes = minutes.toString();
		if(hours<10) sHours = "0" + sHours;
		if(minutes<10) sMinutes = "0" + sMinutes;
		return sHours + ":" + sMinutes;
	}

	increase(): void {
		if (!this.disabled) {
			if (this.model.hour == null) this.model.hour = 8;
			if (this.model.minute == null) this.model.minute = 0;
			let minute = this.model.minute;
			let hour = this.model.hour;
			minute += 15 - (minute % 15);
			if (minute >= 60) {
				hour += 1;
				if (hour == 12) this.model.period == 'AM' ? this.model.period = 'PM' : this.model.period = 'AM';
				if (hour > 12) hour = 1;
				minute %= 60;
			}
			this.model.minute = minute;
			this.model.hour = hour;
			this.valueChange.emit(this.convert());
		}
	} 
	
	decrease(): void {
		if (!this.disabled) {
			if (this.model.hour == null) this.model.hour = 8;
			if (this.model.minute == null) this.model.minute = 0;
			let minute = this.model.minute;
			let hour = this.model.hour;
			(minute % 15) ? minute -= (minute % 15) : minute -= 15;
			if (minute < 0) {
				hour -= 1;
				if (hour == 11) this.model.period == 'AM' ? this.model.period = 'PM' : this.model.period = 'AM';
				if (hour < 1) hour = 12;
				minute = 60 + minute;
			}
			this.model.minute = minute;
			this.model.hour = hour;
			this.valueChange.emit(this.convert());
		}
	}
}
