import { AfterContentChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ClosedService, LocationService, UserService, TimeService } from '@services';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
// import { modalAnimation } from 'src/app/common/animation/animation.component';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
	parse: {
	  	dateInput: 'D MMM, YYYY',
	},
	display: {
	  	dateInput: 'D MMM, YYYY',
	  	monthYearLabel: 'MMM YYYY',
	  	dateA11yLabel: 'D MMM, YYYY',
	  	monthYearA11yLabel: 'MMMM YYYY',
	},
};

@Component({
	selector: 'clock',
	templateUrl: './clock.component.html',
	styleUrls: ['./clock.component.scss'],
	providers: [
		{
		  	provide: DateAdapter,
		  	useClass: MomentDateAdapter,
		  	deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
		},
	
		{provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
	],
    // animations: [modalAnimation]
})
export class ClockComponent implements OnInit, AfterContentChecked {
	public close:any;
	public selected_date = { 
		start: "", 
		end: "" 
	};
	public selected_staff: any;
	public days: any = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
	public dates: any = [];
	// public opened: boolean = false;
    // public position: string = 'positionBottom';
	@ViewChild('dateRangeEnd', { static: true }) inputEnd: ElementRef;
	constructor(public us: UserService,
		public ts: TimeService,
		public ls: LocationService, 
		public cs: ClosedService) {}
	ngOnInit() {
		this.dateChanged(this.selected_date.start, this.selected_date.end, this.selected_staff);
		this.setDates(this.selected_date.start, this.selected_date.end);
	}
	ngAfterContentChecked() {
		if(this.inputEnd) this.inputEnd.nativeElement.size = this.inputEnd.nativeElement.value.length <= 12 && this.inputEnd.nativeElement.value.length >= 11 ? this.inputEnd.nativeElement.value.length - 1 : 12;
	}
	setDates(start, end) {
		let dates = [];

		if(start && end) {
			start = new Date(start);
			start.setHours(0, 0, 0, 0);
			end = new Date(end);
			end.setDate(end.getDate() + 1);
			end.setHours(0, 0, 0, 0);
			
			for(var d = start; d < end; d.setDate(d.getDate() + 1)) {			
				d.setHours(0, 0, 0, 0);
				dates.push(d.getTime());
			}
		}
		
		this.dates = [...dates];
	}
	rangeFilter = (d: Date): boolean => {
		return new Date(d) <= new Date();
	};
	dateChanged(start, end, user) {		
		if(start !== null && end !== null && end >= start && user) {
			this.ts.get(new Date(start).toDateString(), new Date(end).toDateString(), user);
		}
	}
	timeFormat(time) {
		const [hours, minutes] = time.split(":");
		return `${parseInt(hours)}h ${parseInt(minutes)}m`;
	}
	// positionChange(event) {
    //     if(event.connectionPair.originY === 'top') this.position = 'positionTop';
    //     if(event.connectionPair.originY === 'bottom') this.position = 'positionBottom';
    // }
}
