<div class="auth">
    <div class="auth-container">
        <div class="auth-left">
            <div class="flex-column">
                <h1 class="auth__title">{{confirmed.title}}</h1>
                <p class="auth__text">{{confirmed.text}}</p>
            </div>
            <div>
                <button mat-flat-button color="primary" type="submit" class="waw-btn _primary _not_change_on_mobile" routerLink="/login">Go Home</button>
            </div>
        </div>

        <div class="auth-right">
            <div class="auth__logo">
                <img class="auth__logo-img" width="80" height="80" src="assets/sign/logo.svg" alt="Logo">
                <span class="auth__logo-text notranslate">Pure Planner</span>
            </div>
            <div class="auth__preview-1"><img width="305" height="159" src="assets/sign/preview_1.png"></div>
            <div class="auth__preview-2"><img width="305" height="159" src="assets/sign/preview_2.png"></div>
            <div class="auth__preview-3"><img width="305" height="159" src="assets/sign/preview_3.png"></div>
        </div>
    </div>
</div>