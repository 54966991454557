import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { CategoryService, LoaderService, UserService } from '@services';
import { ModalService, MongoService } from 'wacom';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-res-categories',
	templateUrl: './res-categories.component.html',
	styleUrls: ['./res-categories.component.scss']
})
export class ResCategoriesComponent implements OnInit, AfterViewInit {
	public profile:any = {};
	constructor(public modal: ModalService,
		public cs: CategoryService, 
		public us: UserService,
		private route: ActivatedRoute,
		private router: Router,
		private loader: LoaderService,
		private mongo: MongoService,
		private eref: ElementRef
		){}
	ngOnInit() {
		if (!this.loader.isLoaderShowing) this.loader.show({container: true}, this.eref.nativeElement.closest('.containerTab'));
	}
	ngAfterViewInit() {
		this.mongo.on('category', () => {
			this.loader.remove();
		});
	}
	ngDoCheck(){
		if( this.route.snapshot.queryParams['modal']){
		}
		else{
			this.modal.destroy()
		}
	}
	openModal(profile = null){
		if(this.us.is.admin||this.us.is.owner) {
			this.router.navigate([],{ queryParams: { modal: 'open' } });
			this.modal.show({
				component: 'modal',
				profile: profile ? JSON.parse(JSON.stringify(profile)) : {},
				title: 'Category',
				components: [{
					type: 'INPUT',
					input: 'name',
					label: 'Category name*',
					placeholder: 'e.g. Fitness equipment',
					reqText: 'Name is required',
					required: true,
				}],
				alertOpts: {
					title: 'Delete category',
					body: `Are you sure you want to delete category ${profile?.name}?`
				},
				onDelete: (profile: any) => {
					this.cs.delete(profile, () => {
						this.modal.destroy();
					});
				},
				onSave: (profile: any) => {
					this.cs.create(profile, () => {
						this.modal.destroy();
					});
				}
			});	
		}
	}
	delete(profile) {
		if(this.us.is.admin||this.us.is.owner) {
			this.router.navigate([],{ queryParams: { modal: 'open' } });
			this.modal.show({
				component: 'deleteAlert',
				title: 'Delete category',
				body: `Are you sure you want to delete category ${profile.name}?`,
				on_add: ()=> {
					this.loader.show({container: true, transparent: true}, this.eref.nativeElement.closest('.containerBox'));
					this.cs.delete(profile, () => {
						this.loader.remove();
					});
				}
			});
		}
	}
}
