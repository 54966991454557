import { Component } from '@angular/core';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { UserService, LunchService, LoaderService } from '@services';
import { AlertService, ModalService } from 'wacom';

@Component({
	selector: 'lunch',
	templateUrl: './lunch.component.html',
	styleUrls: ['./lunch.component.scss']
})
export class LunchComponent{
	public profile:any = {};
	public close:any;
	public submitted: boolean = false;
	constructor(public ls: LunchService, 
		public us: UserService,
		public modal: ModalService,
		public alert: AlertService,
		private loader: LoaderService) {}
	delete() {
		this.modal.show({
			component: 'deleteAlert',
			title: 'Delete lunch',
			body: `Are you sure you want to delete lunch?`,
			on_add: ()=> {
				this.loader.show({ 
					modal: true,
					transparent: true,
					preventClick: true
				}, document.querySelector('.modal-box'));
				this.ls.delete(this.profile, () => {
					this.loader.remove();
					this.close();
				});
			}
		});
	}

	save() {
		this.submitted = true;
		if(!this.profile.user || !this.profile.day || !this.profile.start || !this.profile.duration) return this.alert.error({
			text: "Please fill required fields",
		});

		this.loader.show({ 
			modal: true,
			transparent: true,
			preventClick: true
		}, document.querySelector('.modal-box'));

		this.ls.create(this.profile, () => {
			this.loader.remove();
			this.submitted = false;
			this.close();
		}); 
	}
}
