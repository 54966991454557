import { Component, OnInit } from '@angular/core';
import { UserService, LocationService, RoleService, CategoryService, LoaderService } from '@services';
import { AlertService, ModalService } from 'wacom';
@Component({
	selector: 'staff',
	templateUrl: './staff.component.html',
	styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit{
	public noteEditor: any;
	public staff: any = { data: {
		working_hours: { 
			default: {
				Monday: [], 
				Tuesday: [], 
				Wednesday: [], 
				Thursday: [], 
				Friday: [], 
				Saturday: [], 
				Sunday: [],
			}
		}
	} };
	public close;
	public submitted: Boolean;
	public characterLimit: string = null;
	constructor(public us: UserService,
		public ls: LocationService,
		public rs: RoleService,
		public modal: ModalService,
		public alert: AlertService,
		public cs: CategoryService,
		private loader: LoaderService) {
			if(this.ls._locations.primary && this.ls._locations.primary.length){
				this.staff.location = [this.ls._locations.primary[0]._id];
			}
		}

	ngOnInit(): void {
		if (this.staff.data.note) this.noteEditor = JSON.parse(this.staff.data.note);
		if(!this.staff.data.working_hours || !this.staff.data.working_hours?.default) this.staff.data.working_hours = { default: { Monday: [], Tuesday: [], Wednesday: [], Thursday: [], Friday: [], Saturday: [], Sunday: [] } };
	}
	fileChangeEvent(event: any): void {
		this.staff.avatar = event;
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = ()=> {
				this.staff.avatarUrl = reader.result;
				this.staff.avatarBig = reader.result;
			};
		}
	}
	create() {
		this.loader.show({ 
			modal: true,
			transparent: true,
			preventClick: true
		}, document.querySelector('.modal-box'));

		this.submitted = true;
		if(!this.staff.firstName||!this.staff.lastName||!this.staff.email||!this.staff.location||this.staff.location&&(!this.staff.location.length)) {
			this.loader.remove();
			return this.alert.error({
				text: "Please fill required fields",
			});
		}
		for(let day of this.us.days_of_week) {
			for(const [index, hour] of this.staff.data.working_hours.default[day].entries()) {
				if(!hour.from || !hour.to) {
					this.loader.remove();
					return this.alert.error({
						text: "Please fill required fields",
					});
				}
				if( index > 0 && this.convertTimeToMinutes(hour.from) <= this.convertTimeToMinutes(this.staff.data.working_hours.default[day][index-1].to) || !!this.staff.data.working_hours.default[day]?.[index+1] && this.convertTimeToMinutes(hour.to) >= this.convertTimeToMinutes(this.staff.data.working_hours.default[day][index+1].from) || this.convertTimeToMinutes(hour.to) <= this.convertTimeToMinutes(hour.from)) {
					this.loader.remove();
					return this.alert.error({
						text: "Please resolve the time conflict",
					});
				}
			}
		}
		if(this.characterLimit) {
			this.loader.remove();
			return this.alert.error({
				text: "Exceeded maximum character limit",
			});
		}

		this.staff.make_staff = true;
		this.staff.role = this.rs.roles[0]._id;
		if (this.noteEditor) this.staff.data.note = JSON.stringify(this.noteEditor);
		this.us.create(this.staff, ()=>{
			this.loader.remove();
			this.close();
		}, ()=> {
			this.loader.remove();
		});
	}
	convertTimeToMinutes(time: string) {
		return Number(time.split(':')[0]) * 60 + Number(time.split(':')[1]);
	}
	delete() {
		this.modal.show({
			component: 'deleteAlert',
			title: 'Delete user',
			body: `Are you sure you want to delete user ${this.staff.firstName} ${this.staff.lastName}`,
			on_add: ()=> {
				this.close(); 
				this.loader.show({ 
					modal: true,
					transparent: true,
					preventClick: true
				}, document.querySelector('.modal-box'));
				this.us.delete(this.staff, () => {
					this.loader.remove();
					this.close();
				}); 
			}
		});
	}
}
