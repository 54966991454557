import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertService } from 'wacom';
import { AppointmentService, LoaderService, UserService } from '@services';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'working-hours',
  templateUrl: './working-hours.component.html',
  styleUrls: ['./working-hours.component.scss']
})
export class WorkingHoursComponent implements OnInit {
	public close;
	public new_time:any = {};
	public selected_user;
	public edit = false;
	public add = true;
	public select_day: boolean = false;
	public date = Date.now();
	public exit;
	public submitted:Boolean;
	constructor(public us: UserService, public aps: AppointmentService, public alert: AlertService, private datePipe: DatePipe, private loader: LoaderService) { }

	ngOnInit(): void {
		let date_by_slash = this.datePipe.transform(new Date(this.date), 'M/d/yyyy'); 
		let day = this.datePipe.transform(new Date(this.date), 'EEEE'); 
		let jsDate = new Date(this.date)
		this.new_time.date = {
			singleDate: {
				epoc: this.date,
				jsDate: jsDate.toISOString(),
				date: {
					year: jsDate.getFullYear(), 
					month: jsDate.getMonth()+1, 
					day: jsDate.getDate()
				},
				formatted: date_by_slash
			}
		};

		// time from location or 8 - 20
		this.new_time.hours = [ { from: '8:00', to: '20:00' } ];

		if(this.selected_user) {
			this.new_time.staff = { ...this.selected_user };
			if (!!this.selected_user.data.working_hours && !this.add) {
				if(this.selected_user.data.working_hours[date_by_slash]?.hours?.length) {
					this.new_time.hours = JSON.parse(JSON.stringify(this.selected_user.data.working_hours[date_by_slash].hours));
				} else if(this.selected_user.data.working_hours.default[day]?.length) {
					this.new_time.hours = JSON.parse(JSON.stringify(this.selected_user.data.working_hours.default[day]));
				}
			}
		}
	}
	convertTimeToMinutes(time: string) {
		return Number(time.split(':')[0]) * 60 + Number(time.split(':')[1]);
	}
	addHour() {
		const prevHour = this.new_time.hours[this.new_time.hours.length - 1];
		const endOfPrevHour = this.convertTimeToMinutes(prevHour.to);
		var startOfHour = (endOfPrevHour + 60) < (24 * 60) ? (endOfPrevHour + 60) : (23 * 60);
		var endOfHour = (startOfHour + 60) < (24 * 60) ? (startOfHour + 60) : (23 * 60 + 59);
		this.new_time.hours.push({from: `${ String( Math.floor(startOfHour / 60) ).padStart(2, '0') }:${ String( startOfHour % 60 ).padStart(2, '0') }`, to: `${ String( Math.floor(endOfHour / 60) ).padStart(2, '0') }:${ String( endOfHour % 60 ).padStart(2, '0') }`});
	}
	save() {
		this.loader.show({ 
			modal: true,
			transparent: true,
			preventClick: true
		}, document.querySelector('.modal-box'));

		this.submitted = true;
		if(!this.new_time.staff||!this.new_time.date||this.repeat.enable&&(!this.repeat.kind||!this.repeat.count)) {
			this.loader.remove();
			return this.alert.error({
				text: "Please fill required fields",
			});
		}
		for(const [index, hour] of this.new_time.hours.entries()) {
			if(!hour.from || !hour.to) {
				this.loader.remove();
				return this.alert.error({
					text: "Please fill required fields",
				});
			}
			if( index > 0 && this.convertTimeToMinutes(hour.from) <= this.convertTimeToMinutes(this.new_time.hours[index-1].to) || !!this.new_time.hours?.[index+1] && this.convertTimeToMinutes(hour.to) >= this.convertTimeToMinutes(this.new_time.hours[index+1].from) || this.convertTimeToMinutes(hour.to) <= this.convertTimeToMinutes(hour.from)) {
				this.loader.remove();
				return this.alert.error({
					text: "Please resolve the time conflict",
				});
			}
		}

		if(!this.new_time.staff.data.working_hours) this.new_time.staff.data.working_hours = {};
		this.new_time.staff.data.working_hours[this.new_time.date.singleDate.formatted] = { hours: this.new_time.hours };
		if (this.repeat.enable) {
			const date = new Date(this.new_time.date.singleDate.epoc);
			switch (this.repeat.kind.name) {
				case 'Daily':
					for (let i = 0; i < this.repeat.count; i++) {
						this.new_time.staff.data.working_hours[this.datePipe.transform(date.setDate(date.getDate() + 1), 'M/d/yyyy')] = { hours: this.new_time.hours };
					}
					break;
				case 'Weekly':
					for (let i = 0; i < this.repeat.count; i++) {
						this.new_time.staff.data.working_hours[this.datePipe.transform(date.setDate(date.getDate() + 7), 'M/d/yyyy')] = { hours: this.new_time.hours };
					}
					break;
				case 'Monthly':
					for (let i = 0; i < this.repeat.count; i++) {
						this.new_time.staff.data.working_hours[this.datePipe.transform(date.setMonth(date.getMonth() + 1), 'M/d/yyyy')] = { hours: this.new_time.hours };
					}
					break;
				case 'Yearly':
					for (let i = 0; i < this.repeat.count; i++) {
						this.new_time.staff.data.working_hours[this.datePipe.transform(date.setFullYear(date.getFullYear() + 1), 'M/d/yyyy')] = { hours: this.new_time.hours };
					}
					break;
			}
		}

		this.us.save(this.new_time.staff, 'Working Hours edited', ()=> {
			this.aps.refresh()
			if(this.exit) this.exit();
			this.loader.remove();
			this.close();
		});
	}
	remove() {
		this.loader.show({ 
			modal: true,
			transparent: true,
			preventClick: true
		}, document.querySelector('.modal-box'));

		this.new_time.staff.data.working_hours[this.new_time.date.singleDate.formatted] = {vacation: true}
		this.us.save(this.new_time.staff, 'Working Hours edited', ()=> {
			this.aps.refresh()
			if(this.exit) this.exit();
			this.loader.remove();
			this.close();
		});
	}
	public kinds = [{name: 'Daily'}, {name: 'Weekly'}, {name: 'Monthly'}, {name: 'Yearly'}];
	public repeat:any = {
		kind: this.kinds[0],
		count: 1
	};
}
