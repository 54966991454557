import { Component, HostListener, OnInit, Input, OnChanges, ElementRef, AfterViewInit } from '@angular/core';
import { UserService, AppointmentService, CheckoutService, ServiceService, ProductService, LoaderService } from '@services';
import { AppointmentsDatePipe } from '@pipes';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { MongoService } from 'wacom';

@Component({
	selector: 'app-dashboard-sales',
	templateUrl: './dashboard-sales.component.html',
	styleUrls: ['./dashboard-sales.component.scss', '../dashboard.component.scss']
})
export class DashboardSalesComponent implements OnInit, OnChanges, AfterViewInit {

	@Input() selector: any = {
		start: new Date(),
		end: new Date()
	};
	@Input() interval: string = 'day';
	@Input() location: any = null;

	public top_services: any = [];

	public top_products: any = [];

	public windowWidth: number;

	public checksColumns = [
		{
			title: 'client',
			field: 'clientName'
		}, {
			title: 'date',
			field: 'timestamp'
		}, {
			title: 'total',
			field: 'total'
		}, {
			title: 'tips',
			field: 'tips'
		}, {
			title: 'tax',
			field: 'tax'
		}, {
			title: 'payment form',
			field: 'type'
		}
	];

	public servicesColumns = [
		{
			title: 'service',
			field: 'name'
		}, {
			title: 'bookings',
			field: 'bookings'
		}, {
			title: 'price',
			field: 'price_1'
		}, {
			title: 'revenue',
			field: 'revenue'
		},
	];

	public revenueBarChart = [];

	public yAxisRevenue = this.yAxisRevenueFormatting.bind(this);
	
	public xAxisRevenue = this.xAxisRevenueFormatting.bind(this);
	
	public productsColumns = [
		{
			title: 'product',
			field: 'name'
		}, {
			title: 'items sold',
			field: 'items'
		}, {
			title: 'price',
			field: 'price_1'
		}, {
			title: 'revenue',
			field: 'revenue'
		},
	];

	public distribution = {
		total: 0,
		items: [
			{
				name: 'Services',
				value: 0,
			}, {
				name: 'Products',
				value: 0,
			}, {
				name: 'Tips',
				value: 0,
			}
		]
	};

	public afterViewInit: boolean = false;

	private informationLoaded: any = {
		checks: false,
		revenueBarChart: false,
		distribution: false,
		topServices: false,
		topProducts: false
	};
	
	constructor(
		public us: UserService, 
		public aps: AppointmentService, 
		private appointmentsDatePipe: AppointmentsDatePipe,
		public cs: CheckoutService,
		public ss: ServiceService,
		private datePipe: DatePipe,
		private mongo: MongoService,
		private currencyPipe: CurrencyPipe,
		public ps: ProductService,
		private loader: LoaderService,
		private eref: ElementRef
	) {}

	ngOnInit(): void {
		if (!this.loader.isLoaderShowing) this.loader.show({container: true}, this.eref.nativeElement.closest('.containerTab'));

		this.windowWidth = window.innerWidth;
		this.initializeChecks(() => {
			this.initializeDistribution();
			this.initializeRevenueBarChart();
			this.mongo.on('user appointment service', () => {
				this.top_services = [];
				this.top_services = this.getTopServices();
			});
			this.mongo.on('product', () => {
				this.top_products = [];
				this.top_products = this.getTopProducts();
			});	
		});
	}

	ngOnChanges() {
		if(this.afterViewInit) {
			if (!this.loader.isLoaderShowing) this.loader.show({container: true, transparent: true}, this.eref.nativeElement.closest('.containerTab'));

			this.informationLoaded = {
				checks: false,
				revenueBarChart: false,
				distribution: false,
				topServices: false,
				topProducts: false
			};
	
			let waitForLoader = setInterval(() => {
				if (this.loader.isLoaderShowing) {
					clearInterval(waitForLoader);
					this.refresh();
				}
			}, 10);
		}
	}

	ngAfterViewInit() {
		this.mongo.on('user appointment service product', () => {
			this.cs.loaded(() => {
				this.afterViewInit = true;
				let waitForInformationLoading = setInterval(() => {
					if (Object.values(this.informationLoaded).every(value => value === true)) {
						clearInterval(waitForInformationLoading);
						this.loader.remove();
					}
				}, 1);
			});
		});
	}
	
	refresh() {
		this.initializeChecks(() => {
			this.initializeDistribution();
			this.initializeRevenueBarChart();
			this.top_services = [];
			this.top_services = this.getTopServices();
			this.top_products = [];
			this.top_products = this.getTopProducts();
		});

		let waitForInformationLoading = setInterval(() => {
			if (Object.values(this.informationLoaded).every(value => value === true)) {
				clearInterval(waitForInformationLoading);
				if(this.afterViewInit) this.loader.remove();
			}
		}, 1);
	}

	getTopServices() {
		const appts = this.appointmentsDatePipe.transform(this.aps._appointments.date, this.selector, this.location);
		const services = [];

		if(appts.length) {
			for(let appt of appts) {
				if(!services.find((s) => s.id == appt.service) && this.ss._services[appt.service]) {
					services.push({
						id: appt.service,
						name: this.ss._services[appt.service]?.name,
						bookings: 1,
						price_1: 0,
						price_2: 0,
						revenue: 0
					});
				} else {
					if (services[services.findIndex((s) => s.id == appt.service)]?.bookings) {
						services[services.findIndex((s) => s.id == appt.service)].bookings += 1;
					}
				}
			}
		}
		
		if(this.cs.checks.length) {
			for(let check of this.cs.checks) {
				if(services[services.findIndex((s) => s.id == this.aps._appointments[check.appointment?.id]?.service)]) {
					const service = services[services.findIndex((s) => s.id == this.aps._appointments[check.appointment?.id]?.service)];
					service.revenue = Number(service.revenue) + check.appointment?.money?.total;
					if(service.revenue % 1 !== 0) service.revenue = Number(service.revenue).toFixed(2);
					if(check.appointment?.money?.price > 0) {
						if(service.price_1 == 0 || !service.price_1) {
							service.price_1 = check.appointment?.money?.price;
						} else {
							if(!service.price_2) {
								if(service.price_1 < check.appointment?.money?.price) {
									service.price_2 = check.appointment?.money?.price;
								}
								if(service.price_1 > check.appointment?.money?.price) {
									service.price_2 = service.price_1;
									service.price_1 = check.appointment?.money?.price;
								}
							} else {
								if(service.price_1 > check.appointment?.money?.price) {
									service.price_1 = check.appointment?.money?.price;
								}
								if(service.price_2 < check.appointment?.money?.price) {
									service.price_2 = check.appointment?.money?.price;
								}
							}
						}
					}
					services[services.findIndex((s) => s.id == this.aps._appointments[check.appointment?.id]?.service)] = service;
				}
			}
		}

		services.sort((a, b) => b.bookings - a.bookings);
		services.splice(10);

		this.informationLoaded.topServices = true;

		return services;
	}

	initializeChecks(cb:any=event=>{}) {
		this.cs.getChecks(this.interval, this.selector, this.location).then(resp => {
			this.informationLoaded.checks = true;
			if(typeof cb === 'function') cb(true);
		}).catch(error => {
			console.error('Error:', error);
		});
	}

	yAxisRevenueFormatting(value) {
		return this.currencyPipe.transform(value);
	}

	xAxisRevenueFormatting(value) {
		switch(this.interval) {
			case 'day':
				return this.datePipe.transform(value, 'shortTime');
			case 'week':
				return this.datePipe.transform(value, 'shortDate');
			case 'month':
				return this.datePipe.transform(value, 'shortDate');
			case 'year':
				return this.datePipe.transform(value, 'MMM');
			case 'quarter':
				return this.datePipe.transform(value.split(' - ')[0], 'shortDate');
		}
	}

	initializeRevenueBarChart() {
		// this.revenueBarChart = [];
		const res = [];

		switch(this.interval) {
			case 'day':
				for(let i = 0; i < 24; i++) {
					let buf = 0;
					this.cs._checks.hours[i]?.forEach((obj: any) => {						
						if (obj.creation_date.singleDate.date.hours == i) {
							buf += obj.total || 0;
						}
					});
					let d = new Date(this.selector.start);
					d.setHours(i, 0, 0);

					res.push({
						name: d.toISOString(),
						value: buf,
					});
				}
				break;
			case 'week':
				for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
					let buf = 0;
					this.cs._checks.day[d.getDate()]?.forEach((obj: any) => {
						buf += obj.total || 0;
					});

					res.push({
						name: d.toISOString(),
						value: buf,
					});
				}
				break;
			case 'month':
				for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setDate(d.getDate() + 1)) {
					let buf = 0;
					this.cs._checks.day[d.getDate()]?.forEach((obj: any) => {
						buf += obj.total || 0;
					});

					res.push({
						name: d.toISOString(),
						value: buf,
					});
				}
				break;
			case 'year':
				for (let d = new Date(this.selector.start); d <= new Date(this.selector.end); d.setMonth(d.getMonth() + 1)) {
					let buf = 0;
					this.cs._checks.month[d.getMonth() + 1]?.forEach((obj: any) => {
						buf += obj.total || 0;
					});

					res.push({
						name: d.toISOString(),
						value: buf,
					});
				}
				break;
			case 'quarter':
				for (let start = new Date(this.selector.start); start <= new Date(this.selector.end); start.setDate(start.getDate() + 7)) {
					let end = new Date(start.getFullYear(), start.getMonth(), start.getDate() + 6);
					if(end > this.selector.end) end = new Date(this.selector.end);
					let buf = 0;
					for (let d = new Date(start); d <= new Date(end); d.setDate(d.getDate() + 1)) {						
						this.cs._checks.date[this.datePipe.transform(d, 'M/d/yyyy')]?.forEach((obj: any) => {
							buf += obj.total || 0;
						});
					}

					res.push({
						name: start.toISOString() + ' - ' + end.toISOString(),
						value: buf,
					});
				}
				break;
		}
		
		this.revenueBarChart = res;

		this.informationLoaded.revenueBarChart = true;
	}

	chartTooltipTitleFormatting(title) {
		switch(this.interval) {
			case 'day':
				return this.datePipe.transform(new Date(title), 'EEEE d MMMM, h:mm a');
			case 'week':
				return this.datePipe.transform(new Date(title), 'EEEE d MMMM');
			case 'month':
				return this.datePipe.transform(new Date(title), 'EEEE d MMMM');
			case 'year':
				return this.datePipe.transform(new Date(title), 'MMMM');
			case 'quarter':
				let start = new Date(title.split(' - ')[0]);
				let end = new Date(title.split(' - ')[1]);
				return ((this.datePipe.transform(start, 'MMMM') === this.datePipe.transform(end, 'MMMM')) ? this.datePipe.transform(start, 'd') : this.datePipe.transform(start, 'd MMMM')) + (end.getDate() != start.getDate() ? ' - ' + this.datePipe.transform(end, 'd MMMM') : this.datePipe.transform(end, ' MMMM'));
		}
	}

	getTopProducts() {
		const products = [];
		
		if(this.cs.checks.length) {
			for(let check of this.cs.checks) {
				for(let product of check.products) {
					if(this.ps._products[product.id]) {
						if(!products.find((p) => p.id == product.id)) {
							products.push({
								id: product.id,
								name: this.ps._products[product.id]?.name,
								items: 1,
								price_1: 0,
								price_2: 0,
								revenue: 0
							});
						} else {
							if (products[products.findIndex((p) => p.id == product.id)]?.items) {
								products[products.findIndex((p) => p.id == product.id)].items += 1;
							}
						}
					}
					
					if(products[products.findIndex((p) => p.id == product.id)]) {
						const item_product = products[products.findIndex((p) => p.id == product.id)];
						item_product.revenue = Number(item_product.revenue) + product?.money?.total;
						if(item_product.revenue % 1 !== 0) item_product.revenue = Number(item_product.revenue).toFixed(2);
						if(product?.money?.price > 0) {
							if(item_product.price_1 == 0 || !item_product.price_1) {
								item_product.price_1 = product?.money?.price;
							} else {
								if(!item_product.price_2) {
									if(item_product.price_1 < product?.money?.price) {
										item_product.price_2 = product?.money?.price;
									}
									if(item_product.price_1 > product?.money?.price) {
										item_product.price_2 = item_product.price_1;
										item_product.price_1 = product?.money?.price;
									}
								} else {
									if(item_product.price_1 > product?.money?.price) {
										item_product.price_1 = product?.money?.price;
									}
									if(item_product.price_2 < product?.money?.price) {
										item_product.price_2 = product?.money?.price;
									}
								}
							}
						}
						products[products.findIndex((p) => p.id == product.id)] = item_product;
					}
				}
			}
		}

		products.sort((a, b) => b.items - a.items);
		products.splice(10);

		this.informationLoaded.topProducts = true;

		return products;
	}

	initializeDistribution() {
		this.distribution = {
			total: 0,
			items: [
				{
					name: 'Services',
					value: 0,
				}, {
					name: 'Products',
					value: 0,
				}, {
					name: 'Tips',
					value: 0,
				}
			]
		};

		if(this.cs.checks.length) {
			for(let check of this.cs.checks) {
				this.distribution.total += check.total || 0;;
				if(this.distribution.items.find(i => i.name == 'Services')) {
					this.distribution.items.find(i => i.name == 'Services').value += check.appointment?.money?.total || 0;
				}
				for(let product of check.products) {
					if(this.distribution.items.find(i => i.name == 'Products')) {
						this.distribution.items.find(i => i.name == 'Products').value += product?.money?.total || 0;;
					}
				}
				if(this.distribution.items.find(i => i.name == 'Tips')) {
					this.distribution.items.find(i => i.name == 'Tips').value += check.tips || 0;;
				}
			}
		}

		this.informationLoaded.distribution = true;
	}

	@HostListener('window:resize')
	onWindowResize() {
		this.windowWidth = window.innerWidth;
	}
}