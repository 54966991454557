import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { LoaderService, LocationService, RoomService, UserService } from '@services';
import { AlertService, ModalService } from 'wacom';
@Component({
	selector: 'room',
	templateUrl: './room.component.html',
	styleUrls: ['./room.component.scss']
})
export class RoomComponent implements OnInit, OnChanges {
	@Input('component') compon: boolean = false;
	@Input('room') room: any = {};
	@Input('submit') submit: boolean = false;
	@Output('onChange') onChange = new EventEmitter();
	
	public profile:any = {}
	public close;
	public submitted:boolean;
	constructor(public rs: RoomService, 
		public ls: LocationService,
		public alert: AlertService,
		public us: UserService,
		public modal: ModalService,
		private loader: LoaderService) {}
	ngOnInit() {
		if (Object.keys(this.room).length !== 0) this.profile = this.room;
	}
	ngOnChanges() {
		if(this.compon) {
			this.submitted = this.submit;
		}
	}
	addHour(day) {
		if(this.profile.availability[day]?.length === 0) {
			this.profile.availability[day].push({from: '08:00', to: '20:00'});
		} else {
			const prevHour = this.profile.availability[day][this.profile.availability[day].length - 1];
			const endOfPrevHour = this.convertTimeToMinutes(prevHour.to);
			var startOfHour = (endOfPrevHour + 60) < (24 * 60) ? (endOfPrevHour + 60) : (23 * 60);
			var endOfHour = (startOfHour + 60) < (24 * 60) ? (startOfHour + 60) : (23 * 60 + 59);
			this.profile.availability[day].push({from: `${ String( Math.floor(startOfHour / 60) ).padStart(2, '0') }:${ String( startOfHour % 60 ).padStart(2, '0') }`, to: `${ String( Math.floor(endOfHour / 60) ).padStart(2, '0') }:${ String( endOfHour % 60 ).padStart(2, '0') }`});
		}
	}
	convertTimeToMinutes(time: string) {
		return Number(time.split(':')[0]) * 60 + Number(time.split(':')[1]);
	}
	save() {
		this.submitted = true;
		if(!this.profile.name || !this.profile.location) return this.alert.error({
			text: "Please fill required fields",
		});
		for(let day of this.us.days_of_week) {
			for(const [index, hour] of this.profile.availability[day].entries()) {
				if(!hour.from || !hour.to) return this.alert.error({
					text: "Please fill required fields",
				});
				if( index > 0 && this.convertTimeToMinutes(hour.from) <= this.convertTimeToMinutes(this.profile.availability[day][index-1].to) || !!this.profile.availability[day]?.[index+1] && this.convertTimeToMinutes(hour.to) >= this.convertTimeToMinutes(this.profile.availability[day][index+1].from) || this.convertTimeToMinutes(hour.to) <= this.convertTimeToMinutes(hour.from)) {
					return this.alert.error({
						text: "Please resolve the time conflict",
					});
				}
			}
		}

		this.loader.show({ 
			modal: true,
			transparent: true,
			preventClick: true
		}, document.querySelector('.modal-box'));

		this.rs.create(this.profile, () => {
			this.loader.remove();
			this.close();
		}); 
	}
	delete() {
		this.modal.show({
			component: 'deleteAlert',
			title: 'Delete room',
			body: `Are you sure you want to delete room ${this.profile.name}`,
			on_add: ()=> {
				this.loader.show({ 
					modal: true,
					transparent: true,
					preventClick: true
				}, document.querySelector('.modal-box'));
				this.rs.delete(this.profile, () => {
					this.loader.remove();
					this.close();
				});
			}
		});
	}
}
