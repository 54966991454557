<div class="tab-container" #scrollContainer>
    <div class="tab-list" #list>
        <div class="tab-list__active" [ngStyle]="tabActiveStyle ? tabActiveStyle : {}"></div>
        <div *ngFor="let tab of tabs; let i=index" class="tab-list__item">
            <input type="radio" [id]="tab.title" name="tab{{i}}" [ngModel]="value" [value]="tab.title" (ngModelChange)="selectTab($event);">
            <button type="button" class="tab-list__item__btn" [class._active]="value == tab.title" (mouseover)="tabMouseover(tab.title)" (mouseout)="tabMouseout()">
                <label [for]="tab.title">
                    <span>{{tab.label}}</span>
                </label>
            </button>
        </div>
    </div>
</div>