import { Injectable } from '@angular/core';
import { MongoService, AlertService } from 'wacom';

@Injectable({
	providedIn: 'root'
})
export class TaxService {
	public taxes: any = [];
	public _taxes: any = {};
	constructor(private alert: AlertService,private mongo: MongoService) {
		this.taxes = mongo.get('tax', {}, (arr, obj) => {
			this._taxes = obj;
		});
	}
	create(tax, cb: any = () => {}) {
		if(tax._id) return this.update(tax, cb);
		this.mongo.create('tax', tax, (created)=>{
			if(typeof cb === 'function') cb(created);
			this.alert.show({
				text: 'Tax has been created.'
			});
		});
	}
	update(tax, cb: any = () => {}) {
		this.mongo.afterWhile(tax, ()=> {
			this.mongo.update('tax', tax, (updated) => {
				if(typeof cb === 'function') cb(updated);
			});
		});
	}
	delete(tax, cb: any = () => {}) {
		this.mongo.delete('tax', tax, (deleted) => {
			if(typeof cb === 'function') cb(deleted);
		});
	}
}
