import { Component } from '@angular/core';
import { PlanService, UserService } from '@services';
import { ModalService } from 'wacom';
@Component({
	selector: 'payment',
	templateUrl: './payment.component.html',
	styleUrls: ['./payment.component.scss']
})
export class PaymentComponent{
	public profile:any = {};
	public close;
	constructor(public ps: PlanService,
		public us: UserService,
		public modal: ModalService) {}
	delete() {
		this.modal.show({
			component: 'deleteAlert',
			title: 'Delete payment',
			body: `Are you sure you want to delete payment ${this.profile.name}`,
			on_add: ()=> {
				this.close()
				this.ps.delete(this.profile);
			}
		});
	}
}
