import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, OnInit } from '@angular/core';
import { CurrencyMaskInputMode } from 'ngx-currency';

@Component({
	selector: 'currency-input',
	templateUrl: './currency-input.component.html',
	styleUrls: ['./currency-input.component.scss']
})
export class CurrencyInputComponent implements OnInit {

    @Input() value: string | number = '0';
    @Input() disabled: boolean = false;
    @Input() class: string = '';
    @Input() negative: boolean = false;
    @Output() valueChange = new EventEmitter();

	public options = {
		prefix: '',
		align: "left",
		allowNegative: false,
		inputMode: CurrencyMaskInputMode.FINANCIAL
	};
	
	ngOnInit() {
		this.options.allowNegative = this.negative;
	}
	
	@ViewChild('input', { static: true }) input: ElementRef;
}