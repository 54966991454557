import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, HashService } from 'wacom';
import { LoaderService, AuthService } from '@services';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	constructor(private router: Router,
		private http: HttpClient,
		private alert: AlertService,
		private hash: HashService,
		private route: ActivatedRoute,
		private loader: LoaderService,
		public auth: AuthService) {
		this.auth.reset();		
		this.user.email = this.hash.get('email')||'';
		this.user.password = this.hash.get('password')||'';
		console.log(this.user);
		if(this.hash.get('submit')){
			this.loader.show({});
			setTimeout(() => {
				this.login();
			}, 2000);
		}
	}
	public user:any = {};

	ngOnInit() {
		this.route.queryParams.subscribe(params => {
			if(params['error']) {
				this.alert.error({text: params['error'], timeout: 0, progress: false});
				this.router.navigate([], {
					queryParams: {
						'error': null,
					},
					queryParamsHandling: 'merge'
				});
			}
		});

		if(this.router.url.indexOf('/auth/') != -1) {
			this.route.params.subscribe(params => {
				if(params.user && params.token)  {
					this.loader.show({});
					setTimeout(() => {
						this.http.post('/api/user/status', { user: params.user, loginToken: params.token }).subscribe((resp:any) => {
							if (resp.user && resp.loginToken && !resp.deleted) {
								const user = {
									username: ' ',
									password: ' ',
									user: params.user,
									loginToken: params.token
								}
								this.http.post('/api/user/login', user).subscribe((res:any) => {
									localStorage.setItem('waw_user', JSON.stringify(res));
									let reschedule = sessionStorage.getItem('waw_reschedule');
									let confirm_email = sessionStorage.getItem('waw_confirm_email')
									if (!reschedule && !confirm_email && !resp.registration) {
										this.router.navigate(['/calendar']);
									} else if (reschedule) {
										this.router.navigate(['/calendar'],{ queryParams: { reschedule: reschedule } }); 
										sessionStorage.removeItem('waw_reschedule');
									} else if (confirm_email) {
										this.router.navigate(['/profile'],{ queryParams: { confirm_email: confirm_email } }); 
										sessionStorage.removeItem('waw_confirm_email');
									} else if (resp.registration) {
										localStorage.setItem('registration', 'finalize');
										this.router.navigate(['/profile'],{ queryParams: {registration: 'finalize'} });					
									}
								});
							} else {
								this.loader.remove();
								if(!resp.user) {
									this.alert.error({
										text: "User is not found",
									});
								}
								if (!resp.loginToken) {
									this.alert.error({
										text: "Invalid login token",
									});
								}
								if (resp.deleted) {
									this.alert.error({
										text: "User has been deleted",
									});
								}
							}
						});
					}, 2000);
				}
			});
		}
	}
	// ngOnInit(): void {
	// 	this.route.queryParams.subscribe(params => {
	// 		if(params['error']) {
	// 			this.alert.error({text: params['error'], timeout: 0, progress: false});
	// 			this.router.navigate([], {
	// 				queryParams: {
	// 					'error': null,
	// 				},
	// 				queryParamsHandling: 'merge'
	// 			});
	// 		}
	// 		if(params['google_id']&&params['google_token']) {
	// 			this.http.post('/api/user/login', {
	// 				username: ' ',
	// 				password: ' ',
	// 				google_id: params['google_id'],
	// 				google_token: params['google_token']
	// 			}).subscribe((res:any) => {
	// 				if(res.error) {
	// 					this.alert.error({text: res.error, timeout: 0, progress: false});
	// 					this.router.navigate([], {
	// 						queryParams: {
	// 							'google_id': null,
	// 							'google_token': null
	// 						},
	// 						queryParamsHandling: 'merge'
	// 					});
	// 				} else if(res) {
	// 					localStorage.setItem('waw_user', JSON.stringify(res));
	// 					this.router.navigate(['/calendar']);
	// 				} else {
	// 					this.alert.error({text: 'User is not found', timeout: 0, progress: false});
	// 					this.router.navigate([], {
	// 						queryParams: {
	// 							'google_id': null,
	// 							'google_token': null
	// 						},
	// 						queryParamsHandling: 'merge'
	// 					});
	// 				}
	// 			});
	// 		}
	// 		if(params['fb_id']&&params['fb_token']) {
	// 			this.http.post('/api/user/login', {
	// 				username: ' ',
	// 				password: ' ',
	// 				fb_id: params['fb_id'],
	// 				fb_token: params['fb_token']
	// 			}).subscribe((res:any) => {
	// 				if(res.error) {
	// 					this.alert.error({text: res.error, timeout: 0, progress: false});
	// 					this.router.navigate([], {
	// 						queryParams: {
	// 							'fb_id': null,
	// 							'fb_token': null
	// 						},
	// 						queryParamsHandling: 'merge'
	// 					});
	// 				} else if(res) {
	// 					localStorage.setItem('waw_user', JSON.stringify(res));
	// 					this.router.navigate(['/calendar']);
	// 				} else {
	// 					this.alert.error({text: 'User is not found', timeout: 0, progress: false});
	// 					this.router.navigate([], {
	// 						queryParams: {
	// 							'fb_id': null,
	// 							'fb_token': null
	// 						},
	// 						queryParamsHandling: 'merge'
	// 					});
	// 				}
	// 			});
	// 		}
	// 	});
	// }

	login() {
		this.auth.submitted.email = true;
		this.auth.submitted.password = true;
		if(!this.user.email) {
			this.alert.error({
				text: 'Enter your email',
			})
			return;
		}
		if (!this.auth.validateEmail(this.user.email.trim())) {
			this.auth.error.email = 'Invalid email address';
			this.auth.check.email = false;
			this.alert.error({
				text: 'Invalid email address',
			})
			return;
		}
		if(!this.user.password) {
			this.alert.error({
				text: 'Enter your password',
			})
			return;
		}
		if(!this.loader.isLoaderShowing) this.loader.show({transparent: true});
		this.http.post('/api/user/status', this.user).subscribe((resp:any) => {
			if(resp.email && resp.pass && !resp.deleted) {
				this.http.post('/api/user/login', {
					username: this.user.email,
					password: this.user.password
				}).subscribe((res:any) => {
					localStorage.setItem('waw_user', JSON.stringify(res));
					let reschedule = sessionStorage.getItem('waw_reschedule');
					let confirm_email = sessionStorage.getItem('waw_confirm_email')
					this.loader.remove();
					if (!reschedule && !confirm_email && !resp.registration) {
						this.router.navigate(['/calendar']);
					} else if (reschedule) {
						this.router.navigate(['/calendar'],{ queryParams: { reschedule: reschedule } }); 
						sessionStorage.removeItem('waw_reschedule');
					} else if (confirm_email) {
						this.router.navigate(['/profile'],{ queryParams: { confirm_email: confirm_email } }); 
						sessionStorage.removeItem('waw_confirm_email');
					} else if (resp.registration) {
						localStorage.setItem('registration', 'finalize');
						this.router.navigate(['/profile'],{ queryParams: {registration: 'finalize'} });					
					}
				})
			} else {
				this.loader.remove();
				if (resp.deleted) {
					this.auth.error.email = 'User has been deleted';
					this.auth.submitted.email = true;
					this.auth.check.email = false;
					this.alert.error({
						text: "User has been deleted",
					});
				} else if(!resp.email && !resp.pass) {
					this.auth.error.email = 'Wrong email';
					this.auth.submitted.email = true;
					this.auth.check.email = false;
					this.auth.error.password = 'Wrong password';
					this.auth.submitted.password = true;
					this.alert.error({
						text: "Wrong email and password",
					});
				} else if(!resp.email) {
					this.auth.error.email = 'Wrong email';
					this.auth.submitted.email = true;
					this.auth.check.email = false;
					this.alert.error({
						text: "Wrong email",
					});
				} else if(!resp.pass) {
					this.auth.error.password = 'Wrong password';
					this.auth.submitted.password = true;
					this.alert.error({
						text: "Wrong password",
					});
				}
			}
		});
	}
}