import { Component, Input, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { UserService, LocationService, LoaderService } from "@services";
import { Router,ActivatedRoute } from '@angular/router';
import { HttpService, ModalService, MongoService } from 'wacom';
import { ClientComponent } from 'src/app/modals/client/client.component';

@Component({
	selector: 'app-clients-list',
	templateUrl: './clients-list.component.html',
	styleUrls: ['./clients-list.component.scss']
})
export class ClientsListComponent implements OnInit, AfterViewInit {
	public imageChangedEvent: any = '';
	public sort_type:any = {};
	public close = document.getElementsByClassName('close');
	@Input() searching: string = '';
	constructor(
		public us: UserService,
		public ls: LocationService,
		public modal: ModalService,
		private route: ActivatedRoute,
		private router: Router,
		public http:HttpService,
		private loader: LoaderService,
		private mongo: MongoService,
		private eref: ElementRef){}
	ngOnInit() {
		if (!this.loader.isLoaderShowing) this.loader.show({container: true}, this.eref.nativeElement.closest('.containerTab'));
	}
	ngAfterViewInit() {
		this.mongo.on('user', () => {
			this.loader.remove();
		});
	}
	ngDoCheck(){
		if( this.route.snapshot.queryParams['modal']){
		}
		else{
			this.modal.destroy()
		}
	}
	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event;
	}
	profile(user){
		this.us.set(user._id);
		this.router.navigate(['/c/'+user._id]);
	}
	edit(user){
		this.router.navigate([],{ queryParams: { modal: 'open' } });
		this.modal.show({component: ClientComponent, profile: JSON.parse(JSON.stringify(user))})		
	}
	delete(user) {
		if(this.us.is.admin||this.us.is.owner) {
			this.router.navigate([],{ queryParams: { modal: 'open' } });
			this.modal.show({
				component: 'deleteAlert',
				title: 'Delete user',
				body: `Are you sure you want to delete user ${user.firstName} ${user.lastName}?`,
				on_add: ()=> {
					this.loader.show({container: true, transparent: true}, this.eref.nativeElement.closest('.containerBox'));
					this.us.delete(user, () => {
						this.loader.remove();
					});
				}
			});
		}
	}
	sort(field) {
		// console.log((typeof this.sort_type.direction != 'string'&&'asc')||(this.sort_type.direction == 'asc'&&'desc')||(this.sort_type.direction == 'desc'&&'asc'));
		if(this.sort_type.title != field) this.sort_type = {};
		this.sort_type = {
			title: field,
			direction: (typeof this.sort_type.direction != 'string'&&'asc')||(this.sort_type.direction == 'asc'&&'desc')||undefined
		}
	}	
}
