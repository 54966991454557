<div class="profile-notes profile-section notes" [@tabInOut]="true">
    <div class="profile-link__nav">
        <div class="profile-link__nav__back"></div>
        <div class="profile-link__nav__title">Notes</div>
        <button class="btn-icon profile-link__nav__btn page-nav__btn ml15" (click)="openModal()"><span class="icon-plus"></span></button>
    </div>
    <div class="profile-notes__body">
        <ul class="timeline">
            <li class="event" *ngFor="let note of nt.note_by_client[us.user._id]">
                <div class="event-date">
                    <div>{{note.created_date.singleDate.jsDate| date: 'd MMMM, y'}},</div>
                    <div>{{note.created_date.singleDate.jsDate| date: 'EEEE h:mm a'}}</div>
                </div>
                <div class="event-desc">
                    <div class="event-desc__right">
                        <div class="event-desc__info">
                            <div class="event-desc__clock">
                                <div class="event-desc__day">{{ note.date?.singleDate?.date?.day }}</div>
                                <div class="event-desc__month">{{ note.date.singleDate?.jsDate|date: 'LLL'  }}</div>
                            </div>
                            <div>
                                <div class="row">
                                    <div class="d-flex">
                                        <span class="event-desc__title">{{ note.appointment ? ss._services[aps._appointments[note.appointment]?.service]?.name : '' }}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="event-desc__time" *ngIf="note.appointment">{{aps._appointments[note.appointment]?.day.singleDate.jsDate | date: 'EEEE'}} {{aps._appointments[note.appointment]?.start}}-{{aps._appointments[note.appointment]?.end}}</div>
									<div class="event-desc__time" *ngIf="!note.appointment">{{ note.date.singleDate.jsDate|date: 'EEEE' }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="event-desc__desc note _limit" #Note [innerHtml]="note.description | note" [class._more]="Note.scrollHeight > Note.clientHeight" (click)="Note.scrollHeight > Note.clientHeight ? Note.classList.add('_all') : !Note.classList.contains('_all') && Note.classList.remove('_all')"></div>
					    <button class="note-btn" *ngIf="Note.classList.contains('_all') || Note.scrollHeight > Note.clientHeight" (click)="Note.classList.toggle('_all')">{{Note.scrollHeight > Note.clientHeight ? 'More' : 'Less'}}</button>
                        <div class="event-desc__price">{{ss._services[aps._appointments[note.appointment]?.service]?.price | currency }}</div>
                    </div>
                </div>
                <span class="event-desc__counter"></span>
            </li>
        </ul>
    </div>
</div>