import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'wacom';
import { LoaderService } from '@services';
@Component({
	selector: 'app-cancelappointment',
	templateUrl: './cancelappointment.component.html',
	styleUrls: ['./cancelappointment.component.scss']
})
export class CancelappointmentComponent implements OnInit{
	public data:any = this.router.url.toLowerCase().replace('/cancel_appointment/', '').split("/");
	public canceled = {title: '', text: ''};
	constructor(public router: Router, public http: HttpService, private loader: LoaderService) {}
	ngOnInit() {
		this.loader.show({});
		this.http.post('/api/appointment/cancel_appointment', {_id: this.data[1], user: {_id: this.data[0]}}, (resp)=> {
			this.loader.remove();
			this.canceled = resp;
		});	
	}
}
