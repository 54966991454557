import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { UserService } from '@services';
import { modalAnimation } from 'src/app/common/animation/animation.component';

@Component({
	selector: 'colorpicker',
	templateUrl: './colorpicker.component.html',
	styleUrls: ['./colorpicker.component.scss'],
	animations: [modalAnimation]
})
export class ColorpickerComponent {
    @Input() title: string = '';

    @Input() value: string = '';

    @Input() custom: boolean = true;
    
    @Output() valueChange = new EventEmitter();

    public swatches = [];

    public open_popup = false;

    public position: string = 'positionBottom';

    public minHeight: number = 370;

    getValue(event = null) {
        var value = this.value;
        if (event) value = event;
        if (value == '' || value == '#') value = '000000';
        if (value.indexOf('#') == -1) {
            return '#' + value;
        } else return value;
    }

    constructor(private eref: ElementRef, public us: UserService) {}
    
    ngOnInit() {
        if(!this.custom) this.minHeight -= 55;

        if (document.querySelector('.modal-content')) {
            document.querySelector('.modal-content').addEventListener('click', (event) => {
                if (!this.eref.nativeElement.contains(event.target)) {
                    this.open_popup = false;
                }
            });
        }

        this.swatches = [];
        for (let i = 0; i < this.us.colors.length; i += 5) {
            this.swatches.push( this.us.colors.slice(i, i + 5 ) );
        }
    }

    isColorVeryLight() {
        const hex = this.value.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        const lightness = (r/255 + g/255 + b/255) / 3;
        
        return lightness > 0.9;
    }

    positionChange(event) {
        if(event.connectionPair.originY === 'top') this.position = 'positionTop';
        if(event.connectionPair.originY === 'bottom') this.position = 'positionBottom';
    }
}