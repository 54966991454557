<div class="profile-questionnaire profile-section" [@tabInOut]="true">
    <div class="profile-link__nav">
        <div class="profile-link__nav__back"></div>
        <div class="profile-link__nav__title">Questionnaire</div>
    </div>
    <div class="question">
        <div class="question-item" *ngFor="let question of qs.questions; index as i">
            <div class="question-item__title" (click)="show(question)">
                <div class="question-item__title__left">{{question.title}}</div>
                <div class="question-item__title__author">
                    <span *ngIf="question.kind == 'text'&&question.text[us.user?._id]?.author != us.user?._id">{{us._users[question.text[us.user?._id]?.author]?.name}} {{question.text[us.user?._id]?.date|date:'M/d/yyyy HH:mm'}}</span>
                    <span *ngIf="question.kind == 'select'||question.kind == 'multi'">{{question.options|lastModified:now}}</span>
                </div>
                <div class="question-item__title__right" [class._active]="!!questionBuffer[question._id]">
                    <span class="icon-chevron"></span>
                </div>
            </div>

            <div class="question-item__body">
                <div>
                    <div class="question-item__row">
                        <div class="question-item__row__title" *ngIf="question.kind == 'text'&&question.text[us.user?._id]?.value">Answer: {{question.text[us.user?._id].value}}</div>
                        <div class="question-item__row__title" *ngIf="question.kind == 'select'||question.kind == 'multi'">
                            <span>Answer: </span>
                            <div>
                                <div class="question-item__row__title__options" *ngFor="let option of question.options; index as j">
                                    <span *ngIf="option.select[us.user?._id]?.value">{{option.answer}}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="question-item__body" *ngIf="!!questionBuffer[question._id]" [@flyInOut]="questionShow">
                <div class="row" *ngIf="question.kind == 'text'">
                    <div class="waw-textarea col-1">
                        <label class="question-label">
                            <span class="waw-textarea__title">Answer</span>
                            <textarea placeholder="Write here your answer" name="Your answer" [(ngModel)]="questionBuffer[question._id].text[us.user?._id].value"></textarea>
                        </label>
                    </div>
                </div>
                <div *ngIf="question.kind == 'select'||question.kind == 'multi'">
                    <div class="question-item__row mb18 pb18" *ngFor="let opt of question.options; index as j">
                        <div class="question-item__row__title"><span>{{j+1}}) </span>{{opt.answer}}</div>
                        <label class="waw-checkbox" [class._radio]="question.kind == 'select'">
                            <input type="checkbox" [(ngModel)]="question.options[j].select[us.user._id].value" (ngModelChange)="saveOptions(question, j)">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <ng-container *ngIf="question.kind == 'text'" [class._disableQuestion]="!question.edit">
                    <button class="waw-btn _second _second--mob" (click)="show(question)">Cancel</button>
                    <button class="waw-btn _primary _primary--mob" (click)="save(question)">Save</button>
                </ng-container>
                <!-- <ng-container *ngIf="question.edit">
                    <button class="waw-btn _second _second--mob" (click)="show(question)">Cancel</button>
                    <button class="waw-btn _primary _primary--mob" (click)="save(question)">Save</button>
                </ng-container>
                <span class="icon icon-pencil" *ngIf="question.kind == 'text'&&!question.edit" (click)="question.edit = true"></span> -->
            </div>
        </div>
    </div>
</div>
