import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService, LoaderService } from '@services';
import { AlertService } from 'wacom';

@Component({
	selector: 'app-forgot',
	templateUrl: './forgot.component.html',
	styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent {
	constructor(private router: Router,
		private http: HttpClient,
		private alert: AlertService,
		private loader: LoaderService,
		public auth: AuthService) {
			this.auth.reset();		
		}
	public user:any = {
		email: '',
		password: ''
	};
	forgot() {
		this.auth.submitted.email = true;
		if(!this.user.email) {
			this.alert.error({
				text: 'Enter your email',
			})
			return;
		}
		if (!this.auth.validateEmail(this.user.email.trim())) {
			this.auth.error.email = 'Invalid email address';
			this.auth.check.email = false;
			this.alert.error({
				text: 'Invalid email address',
			})
			return;
		}
		this.loader.show({transparent: true});
		this.http.post('/api/user/status', this.user).subscribe((resp:any) => {
			if(resp.email && !resp.deleted) {
				localStorage.setItem('waw_reset_email', this.user.email);
				this.router.navigate(['/reset']);
				this.http.post('/api/user/request', {
					email: this.user.email
				}).subscribe((resp:any) => {
					this.loader.remove();
					this.alert.info({
						text: "Mail sent to your email"
					});
				})
			} else {
				this.loader.remove();
				this.auth.submitted.email = true;
				this.auth.check.email = false;
				if (resp.deleted) {
					this.auth.error.email = 'User has been deleted';
					this.alert.error({
						text: "User has been deleted",
					});
				} else {
					this.auth.error.email = 'This email not used';
					this.alert.error({
						text: "This email not used"
					})
				}
			}
		})
	}
}